<template>
  <v-dialog v-model="updateDetailsDialog" fullscreen hide-overlay>
    <v-card fluid>
      <v-toolbar color="#1976D2" dense dark flat>
        <v-toolbar-title dense>UPDATE DETAILS</v-toolbar-title>
        <v-divider class="ml-1 mr-1" vertical></v-divider>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="lockDocuments = !lockDocuments"
              v-bind="attrs"
              v-on="on"
              ><v-icon color="white">{{
                lockDocuments ? "mdi-lock" : `${updateDetailsIcons.lockIcon}`
              }}</v-icon></v-btn
            >
          </template>
          <span>Scroll Lock</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="closeDialog()" v-bind="attrs" v-on="on"
              ><v-icon>mdi-arrow-left</v-icon></v-btn
            >
          </template>
          <span>Back</span>
        </v-tooltip>
        <!-- <v-btn
            color="info"
            @click="closeDialog()"
            >
                <v-icon>mdi-arrow-left</v-icon> Back
            </v-btn> -->
      </v-toolbar>
      <v-toolbar dense flat>
        <v-row>
          <v-col class="d-flex">
            <h2 class="ml-2">BEFORE</h2>
            <v-spacer></v-spacer>
            <!-- <h3 class="mt-2">legend:</h3>
              <v-icon style="color:pink">mdi-checkbox-blank</v-icon>
              <h3 class="mt-2 mr-2">deleted</h3> -->
          </v-col>
          <v-col class="d-flex">
            <h2>AFTER</h2>
            <v-spacer></v-spacer>
            <!-- <h3 class="mt-2">legend:</h3>
              <v-icon style="color:lightgreen">mdi-checkbox-blank</v-icon>
              <h3 class="mt-2 mr-8">changes</h3> -->
          </v-col>
        </v-row>
      </v-toolbar>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="6">
            <v-card
              width="100%"
              class="mt-1 mr-1"
              style="overflow: auto;"
              :height="
                lockDocuments ? '' : $vuetify.breakpoint.height - 110 + 'px'
              "
            >
              <!-- <v-flex
                class="ma-1"
                v-for="(item, index) in sekkeiPrevData.textContent"
                :key="index"
              > -->
              <v-flex>
                <div width="100%">
                  <div v-html="sekkeiPrevData.htmlContent"></div>
                  <!-- <editor
                  :init="tiny_init"
                  v-model="sekkeiPrevData.textContent"
                  height="3000"
                  id="tinymce"
                /> -->
                </div>
              </v-flex>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card
              width="100%"
              class="mt-1"
              style="overflow: auto;"
              :height="
                lockDocuments ? '' : $vuetify.breakpoint.height - 110 + 'px'
              "
            >
              <!-- <v-flex
                class="ma-1"
                v-for="(item, index) in sekkeiDetailsData.htmlContent"
                :key="index"
              > -->

              <v-flex class="ma-1">
                <div width="100%">
                  <div v-html="sekkeiDetailsData.htmlContent"></div>

                  <!-- {{ sekkeiData.textContent }} -->
                  <!-- <editor
                  :init="tiny_init"
                  v-model="sekkeiDetailsData.textContent"
                  height="3000"
                  id="tinymce"
                /> -->
                </div>
              </v-flex>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
// import axios from "axios";
// import Editor from "@tinymce/tinymce-vue";
// import tinymce from "tinymce/tinymce";
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";
export default {
  props: ["updateDetailsDialog", "historyDetails", "historyPrevDetails"],
  // components: { editor: Editor },
  data() {
    return {
      tiny_init: {
        object_resizing: false,
        inline: true,
        readonly: 1,
        toolbar: false,
        menubar: false,
        visual: false,
        skin: false,
        content_css: false,
        content_style:
          [contentUiCss].join("\n") +
          "body { font-family:Times New Roman,Arial,sans-serif}",
        // content_style: [contentCss, contentUiCss].join('\n') +'body { font-family:Times New Roman,Arial,sans-serif}',
      },
      lockDocuments: false,
      updateDetailsIcons: {
        lockIcon: "mdi-lock-open-variant",
        magnifyIcon: "mdi-magnify-plus-outline",
      },
      sekkeiDetailsData: [],
      sekkeiPrevData: "",
    };
  },
  created() {},

  watch: {
    updateDetailsDialog(val) {
      if (val) {
        // let url = "";
        // let search = "";
        // if (this.$route.query.search != "") {
        //   search = this.$route.query.search;
        // }
        this.loading = true;
        // url = `${this.api}shiyo/get?id=${this.historyDetails.id}&search=${search}`;
        // url = `${this.awsURL}sekkeiGyoumu/getSpecificSekkeiGyoumu/${id}`;
        // axios.get(url).then((res) => {
        //   this.shiyoData = res.data;
        //   console.log("line 405 ", res.data.data);
        // });
        this.sekkeiPrevData = "";
        this.sekkeiDetailsData = [];
        // console.log(this.historyDetails);
        this.sekkeiDetailsData = this.historyDetails;
        // console.log(this.sekkeiDetailsData.textContent);
        // this.sekkeiDetailsData.textContent = this.sekkeiDetailsData.textContent
        //   .split("\n")
        //   .map((rec) => {
        //     return rec.replace(
        //       /http:\\\\10.11.1.59:2929\\uploads\\/g,
        //       "https://s3.us-east-2.amazonaws.com/rulebook.files/Shiyo/"
        //     );
        //   });
        this.sekkeiDetailsData.htmlContent = this.sekkeiDetailsData.htmlContent.replace(
          /http:\\\\10.11.1.59:2929\\uploads\\/g,
          `${process.env.VUE_APP_S3LOCATIONS}/Shiyo/`
        );
        let sekkeiDataVersion = this.sekkeiDetailsData.version;
        let sekkeiDataPreviousVersion =
          sekkeiDataVersion != 0 ? sekkeiDataVersion - 1 : "";
        console.log("Version that i get", sekkeiDataVersion);
        console.log("Version that i need", sekkeiDataPreviousVersion);
        if (sekkeiDataPreviousVersion !== "") {
          console.log("pumasok dito");
          console.log(this.historyPrevDetails);
          let previousData = this.historyPrevDetails;
          // previousData = previousData.filter(
          //   (el) => el.version == sekkeiDataPreviousVersion
          // );
          // previousData[0].textContent = previousData[0].textContent
          //   .split("\n")
          //   .map((rec) => {
          //     return rec.replace(
          //       /http:\\\\10.11.1.59:2929\\uploads\\/g,
          //       `${process.env.VUE_APP_S3LOCATIONS)/Shiyo/`
          //     );
          //   });
          previousData.htmlContent = previousData.htmlContent.replace(
            /http:\\\\10.11.1.59:2929\\uploads\\/g,
            `${process.env.VUE_APP_S3LOCATIONS}/Shiyo/`
          );
          this.sekkeiPrevData = previousData;
        }
        // console.log(this.sekkeiDetailsData);
        // console.log(this.sekkeiPrevData);
      }
    },
  },

  methods: {
    closeDialog() {
      this.$emit("closeUpdateDetails", false);
      console.log(this.historyDetails);
      this.lockDocuments = false;
    },
  },
};
</script>
