<template>
	<div class="shiyo-pdf-viewer">
			<v-card >
			<v-toolbar elevation="0" color="#414241" dark dense>
			<h3 style="font-family:Arial;color:white;font-weight:bold;" class="mt-1"> {{shiyoDetails.productName}} </h3>
			<v-spacer></v-spacer>

			<v-tooltip bottom transition="scroll-y-transition">
				<template v-slot:activator="{on}">
					<v-btn @click="isFavorite = !isFavorite; addFavorite()" :disabled="isPDFLoadError" v-on="on" class="mr-3" text small color="white">
						<v-icon :color="isFavorite ? 'yellow' : 'white'">{{ isFav }}</v-icon>
						<span>Favorite </span>
					</v-btn>
				</template>
				<span> Add Favorite </span>
			</v-tooltip>

			<v-tooltip bottom transition="scroll-y-transition">
				<template v-slot:activator="{on}">
						<v-btn @click="goToFeedback()" :disabled="isPDFLoadError" text small v-on="{on}" class="mr-3" >
						<v-icon color="white">mdi-comment-quote</v-icon>
						<span style="color:white"> Feedback </span>
					</v-btn>
				</template>
				<span> Feedback </span>
			</v-tooltip>

			
			<v-tooltip bottom transition="scroll-y-transition">
				<template v-slot:activator="{on}">
					<v-btn :disabled="isPDFLoadError" @click="printPDF()" v-on="on" class="mr-3" text small color="white">
						<v-icon> mdi-printer </v-icon>
						Print
					</v-btn>
				</template>
				<span> Print PDF </span>
			</v-tooltip>

			<v-tooltip bottom transition="scroll-y-transition">
				<template v-slot:activator="{on}">
					<v-btn :disabled="isPDFLoadError" v-on="on" @click="downloadPDF()" text  small color="white;" class="mr-5">
					<v-icon> mdi-download</v-icon>
					Download
					</v-btn>		
				</template>
				<span> Download File </span>
			</v-tooltip>

			<v-tooltip bottom transition="scroll-y-transition">
				<template v-slot:activator="{on}">
					<v-btn :disabled="isPDFLoadError" v-on="on" @click="closeShiyoDialog()" text  small color="white;" class="mr-5">
					<v-icon> mdi-close</v-icon>
					Close
					</v-btn>		
				</template>
				<span> Close Dialog </span>
			</v-tooltip>


			</v-toolbar>
			<center> <h1 v-if="isPDFLoadError" class="mt-5" style="font-family:Arial"> No Attachment Found, Please contact Administrator.. </h1></center>
			<center> <h1 v-if="!isPDFLoadError && !isPDFLoad" class="mt-5" style="font-family:Arial"> Please Wait While Opening PDF File </h1></center>
			<center> <v-img v-if="!isPDFLoadError && !isPDFLoad"  max-width="500" max-height="500" :src ="PDFloadingGIF" > </v-img>  </center>
			<iframe v-if="isPDFLoad" :src="`${src}#toolbar=0`" frameborder="0" style="margin:0; padding:0px;border:0;top:0px;left:0px;bottom:0px;right:0px;" width="100%"  height="100%" allowfullscreen ></iframe>
			</v-card>
		</div>
	<!-- <div id="app" style="height:100%">
		<PDFView
		:src.sync="src"
		ref="pdfView"
		:fileName="shiyoDetails.productName"
		:sidebarFeatureVisible="true"
		
		:toolbarVisible="true"
		:scale.sync="scale"
		>

   
			<template slot="right-toolbox">

				<a-tooltip placement="bottom">
					<template #title>
						<span> Print </span>
					</template>
					<v-btn @click="pdfPrint()" text small class="mt-1">
						<v-icon color="white">mdi-printer</v-icon>
						<span style="color:white"> Print </span>
					</v-btn>
				</a-tooltip>

				<a-tooltip placement="bottom">
					<template #title>
						<span> Feedback </span>
					</template>
					<v-btn @click="goToFeedback()" text small class="mt-1">
						<v-icon color="white">mdi-comment-quote</v-icon>
						<span style="color:white"> Feedback </span>
					</v-btn>
				</a-tooltip>

				
				<a-tooltip placement="bottom">
					<template #title>
						<span>Back</span>
					</template>
					<v-btn @click="closeShiyoDialog()" text small class="mt-1 mr-n2">
						<v-icon color="white">mdi-arrow-left-bottom-bold</v-icon>
						<span style="color:white"> Back </span>
					</v-btn>
				</a-tooltip>


			</template>

			<template slot="left-toolbox">
				<span class="ml-4 fStyle">{{shiyoDetails.productName}}</span>

				<v-btn fab icon small @click="zoomIn()">
					<v-icon color="white">mdi-magnify-plus-outline</v-icon>
				</v-btn>
				<v-btn fab icon small @click="zoomOut()">
					<v-icon color="white">mdi-magnify-minus-outline</v-icon>
				</v-btn>
			</template>

			<template slot="error">
				<span>&nbsp;</span>
			</template>
			
			<template slot="loading"></template>
			
		</PDFView>
	</div> -->
</template>

<script>
import Swal from "sweetalert2";
import AWS from "aws-sdk";
import tunnel from "tunnel";
import config from "../config";
// import axios from "axios";
import * as binconv from "binconv";
// import { PDFView } from "vue_pdfjs_viewer";

import axios from 'axios'
export default {
	props: {
		closeShiyoDialog: Function,
		shiyoDetails: Object,
		linkURLBlob: String
	},
	components: {
		// PDFView,
	},
	computed:{
		isFav() {
			if (this.isFavorite) {
				return "mdi-star";
			} else {
				return "mdi-star-outline";
			}
		},
	},
	data() {
		return {
			isFavorite: false,
			sidePage: false,
			scale: "auto",
			src: "",
			isPDFLoad: false,
			isPDFRendered: false,
			isPDFLoadError: false,
			PDFloadingGIF: require('../assets/PDFLoading3.gif'),
		};
	},//End of data
	created() {
		this.loadPdf()
		// setTimeout(() => {
		// 	this.scale = '1'
		// 	this.loadPdf()
		// }, 1000);
		

	},//End of Created
	watch:{

	},//End of watch
	methods: {
		addFavorite() {
			let toInsert = {
				id: this.shiyoDetails.id,
				productName: this.shiyoDetails.productName,
				userId: this.userInfo.id,
				isFavorite: this.isFavorite,
			};
			console.log(toInsert);

			let url = `${this.api}shiyo/addFavorite`;
			let url2 = `${this.api}shiyo/addFavoriteCount`;
			axios
			.post(url2, toInsert)
			.then(() => {
			axios
				.post(url, toInsert)
				.then((res) => {
					console.log(res.data);
				if (res.data == "Add Favorite") {
					Swal.fire({
					icon: "success",
					title: `${this.shiyoDetails.shiyoNumber} added to Favorites`,
					showConfirmButton: false,
					timer: 1200,
					});
				} else {
					Swal.fire({
					icon: "error",
					title: `${this.shiyoDetails.shiyoNumber} deleted to Favorites`,
					showConfirmButton: false,
					timer: 1200,
					});
				}
				})
				.catch((err2) => {
				console.log(err2.message);
				});
			})
			.catch((err1) => {
			console.log(err1.message);
			});
		},
		pdfPrint(){
			window.open(this.src).print()
		},
		goToFeedback(){
			const url = `${this.api}shiyo/get_shiyo/${this.shiyoDetails._id}` 
              axios.defaults.headers.common["x-api-key"] =
                 "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
              axios.get(url).then(res=>{
                  if(res.data) {
                    console.log('line466', res.data)
                      if (this.$router.currentRoute.name != "feedback") {
			this.CHANGE_SHIYO_FEEDBACK(res.data);
						window.open(
						`${this.linkURL}/feedback?id=${this.shiyoDetails._id}`,
						"_blank"
						);
                      }
                  }
              })
		},
		zoomIn() {

			if (this.scale == "auto") {
				this.scale = "1";
			
			}
			else{
				let num = parseInt(this.scale);
				let b = String(num + 1);
				this.scale = b;
			}
			
		},
		zoomOut() {
			if (this.scale != "1") {
				let num = parseInt(this.scale);
				let b = String(num - 1);
				this.scale = b;
			}
		},

		promiseLoadPDF() {
			return new Promise((resolve,reject) => {

				let data = this.shiyoDetails;

				var tunnelingAgent = tunnel.httpsOverHttp({
					proxy: {
					// Proxy settings
					host: "hrdproxy.hrd-s.com",
					port: 81,
					proxyAuth: "administrator:system",
					},
				});
				AWS.config.update({
					httpOptions: { agent: tunnelingAgent },
					region: "us-east-2",
					credentials: config,
				});
				var s3 = new AWS.S3({
					AccessKeyID: this.accessKeyId,
					SecretAccessKey: this.secretAccessKey,
					Region: "us-east-2",
					params: {
					bucket: "rulebook.files",
					},
				});
				var options = {
					Bucket: "rulebook.files",
					Key: `Shiyo_pdf/${data.fileName}`,
				};
				s3.getObject(options, function(err, data) {
					if (err) {
						console.log(err, err.stack);
						reject({ message: err.message })
					}
					// an error occurred
					else {
					// console.log(data, "s3Data");
					// type: `${data.ContentType};charset=utf-8`,
					var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
						type: `application/pdf`
					});

					let url = URL.createObjectURL(blob);
					resolve( url )
					}
				});


			})
		},

		async loadPdf(){  // 2023-03-24 
			try{
				this.isPDFLoadError = false;
				let srcPDF = await this.promiseLoadPDF();
				this.src = srcPDF;
				this.isPDFLoad = true;
			}catch(err){
				console.log( err.message );
				alert( 'Error Attachment');
				this.isPDFLoadError = true;
				this.isPDFLoad = false;
			}
		},

		printPDF(){ // 2023-03-24
			var objFra = document.createElement('iframe'); // Create an IFrame.
			objFra.style.visibility = "hidden"; // Hide the frame.
			objFra.src = this.src; // Set source not done .pdf.
			objFra.onload = function(){
			objFra.contentWindow.focus(); // Set focus.
			objFra.contentWindow.print(); // Print it  
			};
			document.body.appendChild(objFra); // Add the frame to the web page.
		},
		downloadFile(pdfUrl) {  // Download function PDF using BLOB
			fetch(pdfUrl).then(resp => resp.arrayBuffer()).then(resp => {
			// set the blog type to final pdfconst file = new Blob([resp], {type: 'application/pdf'});
			// process to auto download itconst fileURL = URL.createObjectURL(file);
			const file = new Blob([resp], {type: 'application/pdf'});

			const fileURL = URL.createObjectURL(file);
			const link = document.createElement('a');
			link.href = fileURL;
			link.download = `${this.shiyoDetails.shiyoNumber}-${this.shiyoDetails.productName}`
			link.click();
			});
		},
        downloadPDF(){
          this.downloadFile(this.src);
        },

		loadPdf2(){ // old function 2023-03-24
			let data = this.shiyoDetails
            
			let self = this;

			var tunnelingAgent = tunnel.httpsOverHttp({
				proxy: {
				// Proxy settings
				host: "hrdproxy.hrd-s.com",
				port: 81,
				proxyAuth: "administrator:system",
				},
			});

			AWS.config.update({
				httpOptions: { agent: tunnelingAgent },
				region: "us-east-2",
				credentials: config,
			});
			
			var s3 = new AWS.S3({
				AccessKeyID: this.accessKeyId,
				SecretAccessKey: this.secretAccessKey,
				Region: "us-east-2",
				params: {
				bucket: "rulebook.files",
				},
			});

			var options = {
				Bucket: "rulebook.files",
				Key: `Shiyo_pdf/${data.fileName}`,
			};
			s3.getObject(options, function(err, data) {
				if (err) {
					console.log(err, err.stack);
				}
				// an error occurred
				else {
				// console.log(data, "s3Data");

				var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
					type: `${data.ContentType};charset=utf-8`,
				});

				let url = URL.createObjectURL(blob);
				self.src = url   // tanskie
			
				}
			});
		},
		
	},//End of methods
};
</script>

<style scoped >
	@media only print {
		#app {
		display: none !important;
		}
	}

	#text {
		position: fixed;
		top: 90%;
		left: 5%;
		font-size: 25px;
		color: #ff0000;
		transform: translate(-10%, -10%);
		-ms-transform: translate(-30%, -30%);
	}

	.fStyle{
		font-size: 14px; 
		font-family: Arial, Helvetica, sans-serif;
	}
	.shiyo-pdf-viewer{
		height: 100%;
		/* overflow: hidden; */
	}
	.v-card {
		border-width: thin;
		display: block;
		max-width: 100%;
		outline: none;
		text-decoration: none;
		transition-property: box-shadow, opacity;
		overflow-wrap: break-word;
		position: relative;
		white-space: normal;
		height: 100%;
    }
</style>