<template>
  <v-container pa-0 ma-0 fluid>
    <v-card class="mx-auto" flat>
      <v-layout align-center justify-center v-if="isLoading">
        <a-spin class="mt-2" tip="Loading..." />
      </v-layout>
      <v-card-text v-else class="py-0">
        <v-timeline v-if="sysLogs.length != 0">
          <v-timeline-item
            v-for="(log, i) in sysLogs"
            :key="i"
            :color="randomColor(log.modDate)"
            small
          >
            <template v-slot:opposite>

              <a-tooltip placement="top">
                <template #title>
                  <span>Update</span>
                </template>
                  <v-icon @click="updateVersion(log)" v-if="isAdmin()" color="primary">mdi-circle-edit-outline</v-icon>
              </a-tooltip>
              
              <!-- <div class="headline font-weight-bold">
                Modification Date : {{log.modDate}}
              </div> -->
              <div class="headline font-weight-bold">
               Updated Date : {{log.depDate}}
              </div>
              <!-- <div class="headline font-weight-bold">
                Presented Date : {{log.presDate}}
              </div> -->
            </template>
            <v-card class="pa-2 my-4">
              <v-list dense>
                <v-card-title :style="{backgroundColor: randomColor(log.modDate)}"  class="mx-n2 mt-n4 mb-2">
                  <div class="mt-n4">
                    <span style="color: white;" class="ml-2">Revision Details</span>
                    <a-tooltip placement="top">
                      <template #title>
                        <span>Add</span>
                      </template>
                        <v-icon class="ml-5 mt-n1" @click="addImg(log)"  v-if="isAdmin()" color="white">mdi-plus-thick</v-icon>
                    </a-tooltip>
                  </div>
                  <v-spacer></v-spacer>
                  <v-chip color="white" small>
                    <span>Ver.{{log.version}}</span>
                  </v-chip>
                  
                  <v-btn @click="deleteVersion(log)" v-if="isAdmin()" icon dark><v-icon> mdi-trash-can-outline</v-icon></v-btn>
                </v-card-title>
                <!-- ongoing -->
                <v-list v-if="log.images.length != 0">
                  <v-list-item v-for="(item, i) in log.images" :key="i" >
                    <v-list-item-icon>
                      <v-icon >mdi-image</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content style="cursor: pointer;" @click="openImage(item)">
                      <v-list-item-title
                        v-text="item.img"
                      >
                      </v-list-item-title>
                      
                      <v-list-item-subtitle>
                        <span v-if="item.Details != ''">{{item.Details}} </span>
                        <span v-else>No Details</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row no-gutters>
                        <v-btn @click="showUpdateDialog(item, i, log)" v-if="isAdmin()" icon color="primary">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn @click="deleteDetails(i, log)" v-if="isAdmin()" icon color="warning">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <!-- 
                        <v-icon color="warning">mdi-close</v-icon> -->
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                  
                </v-list>

                <span v-else ><center><h1 class="font-weight-light mb-3 mt-5">No Details</h1></center></span>
              </v-list>
            </v-card>
          </v-timeline-item>
        </v-timeline>
        <template  v-else>
          <center class="ma-5"><h1>No Record.</h1></center>
        </template>
      </v-card-text>
    </v-card>

    <!-- DETAILS DIALOG -->
    <v-row justify="center">
      <v-dialog v-model="detailsDialog" persistent>
        <v-card class="pa-5" >
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn @click="detailsDialog = false, imageSrc = ''" color="warning">Close</v-btn>
          </v-card-title>
          <v-card-text>
            <v-layout align-center justify-center class="pa-1 ma-1">
              <img width="1000" v-if="viewImage" :src='viewImage' />
              <img v-else src='/loading.gif' />
            </v-layout>
          </v-card-text>
        </v-card>
        
      </v-dialog>
    </v-row>

    <a-tooltip placement="left">
      <template #title>
        <span>Add New version</span>
      </template>
      <!-- @click="addVersion()" -->
      <v-btn
          v-if="isAdmin()"
          fab
          right
          fixed
          large
          outlined
          class="elevation-8"
          color="grey darken-1"
          elevation="6"
          @click="OpenVersionOption()"
          style="position:fixed; bottom:20px; right:40px; text-align: center; background-color: white"
      >
          <v-icon color="black">mdi-database-plus</v-icon>
      </v-btn>
    </a-tooltip>

    <!-- VERSION DIALOG -->
    <v-dialog v-model="VersionDialog" width="350px">
      <v-card>
        <v-card-title style="color: white; height: 35px; background-color: #375ac8;">
          <div class="mt-n3">
            <span
              v-if="userAction == 'VERSION'"
              style="font-family: impact;
              font-size: 19px;
              color: white;">
              New Version
            </span>
            <span
              v-if="userAction == 'UPDATE'"
              style="font-family: impact;
              font-size: 19px;
              color: white;">
              Update Version
            </span>
          </div>
          <v-spacer></v-spacer>
          <v-icon class="mt-n4" @click="CloseVersionOption()" color="white">mdi-close-thick</v-icon>
        </v-card-title>

        <v-card-text class="pa-3">
          <v-text-field
            v-model="toAdd.version"
            label="Version Number"
            prepend-icon="mdi-alpha-v-circle-outline"
            outlined
            dense class="mt-2"
            style="width:60%"
            color="black"
          ></v-text-field>
          
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="ModificationDate"
            transition="slide-y-transition"
            offset-y
            dark
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="ModificationDate"
                label="Modification Date"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                dense
                color="black"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="ModificationDate"
              no-title
              range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(ModificationDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>

          <v-menu 
            v-model="menu2"
            dark
            :close-on-content-click="false"
            transition="slide-y-transition"
            min-width="auto"
            offset-y> 
            <template v-slot:activator="{ on }">
              <v-text-field 
                v-model="toAdd.DeploymentDate"  
                label="Deployment Date" 
                prepend-icon="mdi-calendar-range" 
                readonly 
                v-on="on"
                color="black" 
                outlined 
                dense>
              </v-text-field>
            </template>
            <v-date-picker 
              v-model="toAdd.DeploymentDate" 
              @input="menu2 = false"
              no-title>
            </v-date-picker>       
          </v-menu> 


          <v-menu 
            v-model="menu3"
            dark
            :close-on-content-click="false"
            transition="slide-y-transition"
            offset-y> 
            <template v-slot:activator="{ on }">
              <v-text-field 
                v-model="toAdd.PresentedDate"  
                label="Presented Date" 
                prepend-icon="mdi-presentation" 
                readonly
                v-on="on"
                color="black" 
                outlined 
                dense>
              </v-text-field>
            </template>
            <v-date-picker 
              v-model="toAdd.PresentedDate" 
              @input="menu3 = false"
              no-title>
            </v-date-picker>       
          </v-menu> 


          <v-switch
            v-model="toAdd.isLatestActualVersion"
            :label="toAdd.isLatestActualVersion ? 'Actual Version (Latest)' : 'Actual Version ( Not Latest)'"
          ></v-switch>

            <v-switch
            v-model="toAdd.isLatestTestVersion"
            :label="toAdd.isLatestTestVersion ? 'Test Version (Latest)' : 'Test Version ( Not Latest)'"
          ></v-switch>


        </v-card-text>

        <v-row no-gutters class="mt-n5">
          <v-spacer></v-spacer>
          <v-btn @click="saveRecord()" class="ma-2" color="primary" height="28px" style="text-transform: capitalize;" v-if="userAction == 'VERSION'">
              <v-icon>mdi-content-save</v-icon>Save
          </v-btn>

          <v-btn @click="saveRecord()" class="ma-2" color="primary" height="28px" style="text-transform: capitalize;" v-if="userAction == 'UPDATE'">
              <v-icon>mdi-content-save-edit</v-icon>Update
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="updateDetailsDialog"
      persistent :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          Details
          <v-spacer></v-spacer>
          <v-btn fab small text @click="updateDetailsDialog = !updateDetailsDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Details"
            v-model="toAddDetails"
            outlined
            @change="updateRecord()"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="updateRecord()" class="success" style="font-family:Arial" block> 
            <v-icon> mdi-content-save-check</v-icon>
            Save 
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VERSION OPTIONS 2023-03-30 -->
    <v-dialog
      v-model="versionOptionDialog"
      persistent :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
           <span style="font-family:Arial"> Select Version Option  </span>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="versionOptionDialog = !versionOptionDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text>
           <v-row v-for="( option, i ) in versionOptions" :key="i">
             <v-col xs="12" sm="12" md="12" lg="12">
                <v-tooltip right transition="scroll-y-transition">
                   <template v-slot:activator="{on}">
                     <v-btn @click="addVersion( option )" v-on="on" color="primary" dense style="font-family:Arial" block rounded> {{ option }} </v-btn>
                   </template>
                  <span style="font-family:Arial"> {{ option}} </span>
                </v-tooltip>
             </v-col>
           </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- IMAGE DIALOG -->
    <v-dialog v-model="imgDialog"  width="700">
      <v-card>
        <v-card-title style="color: white; height: 35px; background-color: #375ac8; border-radius: 0px;">
          <div class="mt-n3">
            <span
              style="font-family: impact;
              font-size: 19px;
              color: white;">
              Images
            </span>
          </div>
          <v-spacer></v-spacer>
          <v-icon class="mt-n4" @click="imgDialog = false" color="white">mdi-close-thick</v-icon>
        </v-card-title>

        <v-card-text class="pa-4">
          <file-pond 
            credits="false"
            name="test"
            ref="pond"
            label-idle="Drag or Click to Add Images"
            allow-multiple="true"
            v-model="files"
            :onaddfile="handleFilePondInit"
            accepted-file-types="image/jpeg, image/png"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            fab
            right dark
            class="elevation-8"
            color="primary"
            elevation="6"
            @click="uploadImages()"
          >
          <v-icon>mdi-check</v-icon>
        </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
import shortid from 'shortid'
  import moment from 'moment'
  import axios from "axios";
  import Swal from 'sweetalert2'
import * as binconv from 'binconv'

import AWS from 'aws-sdk'
  // Import Vue FilePond
  import vueFilePond from 'vue-filepond'

  // Import FilePond styles
  import 'filepond/dist/filepond.min.css'

  // Import FilePond plugins
  // Please note that you need to install these plugins separately

  // Import image preview plugin styles
  import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

  // Import image preview and file type validation plugins
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

  // Create component
  const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
  )

const uploadToS3 = (val) => {
        let s3 = new AWS.S3({apiVersion: '2006-03-01'});
        return s3.upload(val).promise()
      }
  ////Function for UPloading Files to AWS S3////
// const uploadFileToS3 = (val) => {
//     let s3 = new AWS.S3({apiVersion: '2006-03-01'});
//     return s3.upload(val).promise()

// }

export default {
  components: {
    FilePond
  },
  data: () => ({
    isLoading: false,
    indexOfImage: '',
    imageData: '',
    toAddDetails: '',
    hidden: false,
    updateDetailsDialog:false,
    files: [],
    imageToAdd:{},
    sysLogs: [],
    DeploymentDate: '',
    ModificationDate: [],
    menu: false,
    menu2: false,
    menu3: false,
    myFiles: [],
    toAdd: {
      version: null,
      isLatestActualVersion: false,
      isLatestTestVersion: false
    },
    userAction: '',
    colorCache: {},
    images: [],
    imgDialog: false,
    VersionDialog: false,
    detailsDialog:false,
    imageSrc:'',
    imageDetails: '',

    versionCategoryArr: ["TEST VERSION", "ACTUAL VERSION"],

    versionOptions: ["MAJOR UPDATE", "MINOR UPDATE", "ERROR/BUGS"],
    versionOptionDialog: false
  }),
  computed: {
      // dateRangeText () {
      //   return this.ModificationDate.join(' ~ ')
      // },
    screenSize() {
      return this.$vuetify.breakpoint.height;
    },
    viewImage(){
      return this.imageSrc
    }
  },
  watch: {
    VersionDialog(val){
      if(val){
        if(this.userAction == 'VERSION'){
          this.ModificationDate = ""
          this.DeploymentDate = ""
          this.PresentedDate = ""
        }
      }
    },
  },
  created(){
    this.loadAll()
  },
  methods: {
    CloseVersionOption(){    // 2023-03-30
      this.VersionDialog = !this.VersionDialog;
      this.loadAll();
    },
    OpenVersionOption() {    // 2023-03-30
        this.versionOptionDialog = !this.versionOptionDialog 
        
    },
    addVersion( option = "" ){   // 2023-03-30

      // Get Latest Version 
      let latestVersionArr = this.sysLogs[0].version.split('.').map(rec=>{ return +rec});  
      const [ maxVer, minVer, bugVer ] = latestVersionArr;
      let nextLatestVersion = "";
      let tmpVersion = ""

      if( option == "MAJOR UPDATE") {
        tmpVersion = maxVer+1;
        nextLatestVersion = [ tmpVersion, 0, 0 ];
      }
      else if( option == 'MINOR UPDATE') {
        tmpVersion = minVer + 1;
        nextLatestVersion = [ maxVer, tmpVersion , bugVer ] ;
      }
      else {
        tmpVersion = bugVer +1;
        nextLatestVersion = [ maxVer , minVer , tmpVersion ];
      }
      
    
      // Condition the Version Option // old
      // if( option == "MAJOR UPDATE") {
      //   tmpVersion = latestVersionArr[0]+1;
      //   nextLatestVersion = [ tmpVersion, 0, 0 ];
      // }
      // else if( option == 'MINOR UPDATE') {
      //   tmpVersion = latestVersionArr[1]+1;
      //   nextLatestVersion = [ latestVersionArr[0], tmpVersion , latestVersionArr[2] ];
      // }
      // else {
      //   tmpVersion = latestVersionArr[2]+1;
      //   nextLatestVersion = [ latestVersionArr[0] , latestVersionArr[1] , tmpVersion ];
      // }

      this.VersionDialog = true
      this.userAction = 'VERSION'
      this.toAdd = {
         version: nextLatestVersion.join(".")
      }
      this.ModificationDate = []
      this.DeploymentDate = ''
      this.versionOptionDialog = false;
    },
    
    deleteVersion(data){
      Swal.fire({
        title: `Delete Version ${data.version} ?` ,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Confirm`,
        denyButtonText: `Cancel`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.sysLogs.splice(this.sysLogs.indexOf(data), 1)
          let url =  `${this.api}SystemLogs/deleteVersion`
          axios.post(url, data).then((res) => {
            Swal.fire({
              position: "center",
              icon: "success",
              text: res.data,
              title: 'Success',
              showConfirmButton: false,
              timer: 1000,
            })
          })
        }
      })
      
    },
    deleteDetails( indexOfImage, recordData){
      this.toAdd = recordData
      this.toAdd.images.splice(indexOfImage, 1)
      let url = `${this.api}SystemLogs/updateRecord`
      axios.post(url, this.toAdd).then(res => {
        console.log(res.data)
        this.updateDetailsDialog = false
      })
    },
    showUpdateDialog(imageData, indexOfImage, recordData){
      Object.assign(this.toAdd, recordData)
      this.indexOfImage = indexOfImage
      this.imageData = imageData
      this.toAddDetails = this.toAdd.images[indexOfImage].Details
      this.updateDetailsDialog = true
    },
    updateRecord(){
      this.toAdd.images[this.indexOfImage].Details = this.toAddDetails
      console.log(this.toAdd)
      let url = `${this.api}SystemLogs/updateRecord`
      axios.post(url, this.toAdd).then(res => {
        console.log(res.data)
        this.updateDetailsDialog = false
      })
    },
    async uploadImages(){
      await this.uploadToAWS(async (err) => {
        if(err){
          alert('Error uploading files.')
        }else{
          let url = `${this.api}SystemLogs/updateRecord`
          axios.post(url, this.toAdd).then((res) => {
            console.log(res.data)
          }).then(async () => {
              await Swal.fire({
              position: "center",
              icon: "success",
              title: 'Success',
              text: 'Uploaded',
              showConfirmButton: false,
              timer: 1000,
            }).then(() => {
              this.$refs.pond.removeFiles();
              this.files = []
            })
          })
        }
      })
    },
    async uploadToAWS(callback){
      ////AWS S3 CONFIG - TO ACCESS THE AWS SERVICES////
      await AWS.config.update({
        region: 'us-east-2', 
        accessKeyId: this.accessKeyId,
        secretAccessKey: this.secretAccessKey
      });
      let promise = []
      let files = this.handleFilePondInit()
      let fileData = {}
      for(let i = 0; i < files.length; i++){
        let fileName = files[i].name
        fileData.img = fileName
        fileData.Details = ""
        var uploadParams = {
          Bucket: 'rulebook.files', ////NAME OF YOUR BUCKET IN AWS
          Key: `SystemLogs/Images/${fileName}`, //KEY OF THE OBJECT(DXF -> FOLDER NAME ON AWS S3, ${tempFiles[i].name} -> FILENAME TO BE UPLOADED)
          Body: files[i]  
        };
        console.log('UPLOAD PARAMS', uploadParams)
        this.toAdd.images.push(fileData)
        fileData = {}
        promise.push(uploadToS3(uploadParams))
      }
      Promise.all(promise).then((data) => {
        console.log('UPLOAD DATA',data)
        callback(false)
      })
      .catch((err)=> {
        console.log(err)
        callback(true)
      })
    }, 
    loadAll(){
      this.isLoading = true
      let url = `${this.api}SystemLogs/getAll`
      axios.get(url).then(res => {
        this.sysLogs = res.data.map(r => {
          r.modDate = moment(r.ModificationDateFrom).format('LL') +"-"+ moment(r.ModificationDateTo).format('LL')
          r.depDate = moment(r.DeploymentDate).format('LL')
          r.presDate = moment(r.PresentedDate).format('LL')
          return r
        })
        .sort((a,b) => {  // 2023-03-08
          return b.PresentedDate.localeCompare(a.PresentedDate)
          //  const numA = parseFloat(a.version);
          //  const numB = parseFloat(b.version);

          //  if (numA < numB) {
          //     return -1;
          //   } else if (numA > numB) {
          //     return 1;
          //   } else {
          //     return 0;
          //   }
        })
        // .reverse()

        console.log('line603', this.sysLogs )
      }).then(() => {
        this.isLoading = false

      })
    },
    saveRecord() {
      let url = ""
      if(this.userAction == 'VERSION'){
         console.log('add version')
        this.toAdd.ModificationDateFrom = this.ModificationDate[0]
        this.toAdd.ModificationDateTo = this.ModificationDate[1]
        this.toAdd.CreatedDate = moment().format('YYYY-MM-DD')
        this.toAdd.InchargePerson = this.userInfo.Name
        this.toAdd.images = []
        this.toAdd.id = shortid.generate().substring(0,5)
        url = `${this.api}SystemLogs/newLog`;
      }else{
        console.log('update version')
        this.toAdd.ModificationDateFrom = this.ModificationDate[0]
        this.toAdd.ModificationDateTo = this.ModificationDate[1]
        this.toAdd.UpdatedDate = moment().format('YYYY-MM-DD')
        this.toAdd.UpdatedBy = this.userInfo.Name
        url = `${this.api}SystemLogs/updateRecord`;
      }

      // let url = `${this.api}SystemLogs/newLog`;
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        axios.post(url, this.toAdd).then((res) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: 'Success',
            text: res.data,
            showConfirmButton: false,
            timer: 1000,
          });
        }).then(() => {
          this.VersionDialog = false
          this.loadAll()
        })
    },
    handleFilePondInit(){
      console.log('FILES',this.files.map(r => r.file))

      return this.files.map(r => r.file)
    },
    addImg(item){
      console.log(item)
      Object.assign(this.toAdd, item)
      this.imgDialog = true

    },
    updateVersion(data){
      this.VersionDialog = true
      this.userAction = 'UPDATE'
      // console.log('HEREEEE',data)
      // Object.assign(this.toAdd, data)
      this.toAdd = data;
      this.ModificationDate = [this.toAdd.ModificationDateTo,this.toAdd.ModificationDateFrom]
    },

    randomColor(val) {
      const r = () => Math.floor(256 * Math.random());
      return this.colorCache[val] || (this.colorCache[val] = `rgb(${r()}, ${r()}, ${r()})`);
    },
    openImage(val){
      this.detailsDialog = true
      let fileName = val.img
      ////UPDATING THE AWS CREDENTIALS TO HAVE PERMISSION TO ACCESS THE S3 BUCKET
      AWS.config.update(
        {
          // httpOptions: { agent: tunnelingAgent },
          region: "us-east-2",
          credentials: {
              accessKeyId: this.accessKeyId,
              secretAccessKey: this.secretAccessKey,
              region: "us-east-2",
              apiVersions: {
                  s3: "2012-10-17",
              },
          }
        });
        
        ////configuration for PRIVATE S3 Bucket
        var s3 = new AWS.S3({
          Region: "us-east-2",
          params: {
            bucket: "rulebook.files",
          },
        }); 
        var options = {
          Bucket: "rulebook.files",
          Key: `SystemLogs/Images/${fileName}`
        };
  
        ////FUNCTION USED IN AWS S3
        s3.getObject(options, (err, data) => {
        
          if (err) console.log(err, err.stack);
          // an error occurred
          else {
            // console.log(data);
            ////CONVERTING THE FILE CONTENT INTO BLOB
            var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
              type: `${data.ContentType};charset=utf-8`,
            });
            this.imageSrc = URL.createObjectURL(blob);
            this.imageDetails = val.Details
          }
        });
    },
    hide(val) {
      // console.log(111);
      //   this.visible = false;
      let index = this.years.indexOf(val);
      this.years[index].visible = false;
    },
  },
};
</script>

<style scoped>
.v-sheet--offset {
  top: -9px;
  position: relative;
}
.img-hover-zoom  {
  transition: transform .5s ease;
}
.img-hover-zoom:hover  {
  transform: scale(1.2);
}


</style>