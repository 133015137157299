import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

// const persistedData = new createPersistedState({
// key:'spa_training',
// storage: localStorage,
// reducer : state => ({
// loggedInUser : state.loggedInUser
// })
// })
// plugins: [persistedData]

export default new Vuex.Store({
  state: {
    expiryDate: '',
    userInfo: '',
    selectedRule: '',
    token: localStorage.getItem('token') || [],
    tabCondition: 'Search',
    isFavCnt: 0,
    globalSearchWord: '',
    globalCondition: '',
    globalExceptWord: '',
    isSearchAll: false,
    systemVersion: '0.0.0',
    shiyoObjectFeedBack: {} , // 2022-01-17
    loginTime: null, //2024-04-17
    sessionExpired: false, //2024-04-17
    expiredTime: null, //2024-04-17
  },

  //2024-04-17
  getters: {
    sessionExpired: state => state.sessionExpired
  },
  
  plugins: [createPersistedState()],
  mutations: {
    SET_LOGIN_TIME(state, loginTime) {  //2024-04-17
      state.loginTime = loginTime;
    },
    setSessionExpired(state, value) {  //2024-04-17
      state.sessionExpired = value;
    },
    setExpiredTime(state, expiredTime) {
      state.expiredTime = expiredTime;
    },

    CHANGE_SHIYO_FEEDBACK: (state,data) => {   // 2022-01-17
      state.shiyoObjectFeedBack = data
    },
    SYSTEM_VERSION: (state, data) => {
      state.systemVersion = data
    },
    UPDATE_SEARCH_ALL: (state, data) => {
      // alert(data)
      state.isSearchAll = data
    },
    CHANGE_CONDITION: (state, data) => {
      state.globalCondition = data
    },
    CHANGE_SEARCH_WORD: (state, data) => {
      state.globalSearchWord = data
    },
    CHANGE_EXCEPT_WORD: (state, data) => {
      state.globalExceptWord = data
    },
    ADD_INFO: (state, data) => {
      state.userInfo = data
    },
    CHANGE_ITEM: (state, data) => {
      state.selectedRule = data
    },
    TAB_CND: (state, data) => {
      state.tabCondition = data
    },
    AUTH_SUCCESS: (state, data) => {
      state.token = data
      // state.user = Object.assign({}, state.user, payload.user)
    },
    setExpiryDate: (state, data) => {
      // Create a date
      // const date = new Date();
      // Add your delta for expiry. I am expiring in one day.
      // date.setDate(date.getDate() + 1);
      // Set the state
      state.expiryDate = data
    },
    SET_FAVORITE: (state, data) => {
      // alert(data)
      state.isFavCnt = data
    }
  },
  actions: {
    //2024-04-17
    setLoginTime({ commit }, loginTime) {  
      commit('SET_LOGIN_TIME', loginTime);
    },
    setSessionExpired({ commit }, value) {  
      commit('setSessionExpired', value);
    },
    resetSessionExpired({ commit }) {  
      commit('setSessionExpired', false);
    },
    setExpiredTime({ commit }, expiredTime) { 
      commit('setExpiredTime', expiredTime);
    },
  }
})
