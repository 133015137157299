<template>
  <v-container pa-0 ma-0 fluid>
    <!-- NOTE: disable transation in this page -->
    <div translate="no">
    <v-layout align-center justify-center v-if="loadingAllDocs">
      <a-spin class="mt-2" tip="Loading..." />
    </v-layout>
    <v-card class="ma-2 pa-2" flat style="background-color: #f6f5f5" v-else>
      <v-row dense>
        <!-- 2023-06-01 -->
        <!--<v-col cols="12" sm="8" md="2"  v-if="selectedCondition == 'ID & TITLE'" > -- changed to 帳票番号＆タイトル 20230704-->
        <!-- <v-col
          cols="12"
          sm="8"
          md="2"
          v-if="selectedCondition == '帳票番号＆タイトル'"
        >
          <a-input
            addon-before="ID"
            v-model="searchDocNo"
            placeholder="Reference No."
            append-icon="search"
            outlined
          >
          </a-input>
        </v-col> -->

        <!-- 2023-05-30 -->
        <!--<v-col cols="12" sm="8" md="2"  v-if="selectedCondition == 'ID & TITLE'" > -- changed to 帳票番号＆タイトル 20230704-->
        <!-- <v-col
          cols="12"
          sm="8"
          md="2"
          v-if="selectedCondition == '帳票番号＆タイトル'"
        >
          <a-input
            addon-before="Title"
            v-model="searchTitle"
            placeholder="Document Title"
            append-icon="search"
            outlined
          >
          </a-input>
        </v-col> -->

        <!---- <v-col cols="12" sm="8" md="4" v-if="selectedCondition != 'ID & TITLE'" >  -- changed to 帳票番号＆タイトル 20230704 --->
        <!-- <v-col
          cols="12"
          sm="8"
          md="4"
          v-if="selectedCondition != '帳票番号＆タイトル'"
        > -->
        <v-col
          cols="12"
          sm="8"
          md="4"
        >
          <!----20230704 modified 全文検索 to 検索---->
          <!------ Removed a-input component replaced with v-text-field 20231104----->
          <!-- <a-input
            addon-before="検索"
            v-model="searchText"
            placeholder="検索"
            append-icon="search"
            @paste="getPaste"
            outlined
            @compositionstart="StartCompose()"
            @compositionend="EndCompose()"
            @keyup.enter="
              selectedCondition != 'MULTI' ? onSearch() : onMultipleSearch()
            "
          >
            <a-icon
              slot="suffix"
              type="close-circle"
              style="color: rgba(0, 0, 0, 0.45)"
              @click="searchText ? clearSearch() : ''"
            />
          </a-input> -->
          <v-row dense no-gutters>
            <!-- NOTE: old searching function start -->
            <!-- <p
              class="search-label mb-0"
              style="font-size:15px; color:#000000a6"
            >
              検索
            </p>
            <v-text-field
              solo
              height="31px"
              flat
              clearable
              hide-details
              @click:clear="clearSearch()"
              :clear-icon="customClearIcon"
              class="custom-text-field"
              v-model="searchText"
              @keyup.enter="
                selectedCondition != 'MULTI' ? onSearch() : onMultipleSearch()
              "
            ></v-text-field> -->
            <!-- NOTE: old searching function end -->
            <!-- NOTE: merged searching function - added full text search with "AND" condition and "MULTI" 2024-06-15 start -->
            <span style="font-weight:bold;" class="pt-1 pr-1">全文検索</span>
            <v-text-field
              style="zoom:80%; background-color:white;"
              outlined
              dense
              hide-details
              clearable
              @keyup.enter="selectedCondition='MULTI',andMultiSearch()"
              @click:clear="clearSearch()"
              @input="searchTitleDocNo=''"
              :clear-icon="customClearIcon"
              v-model="searchText"
            ></v-text-field>
            <!-- NOTE: merged searching function - added full text search with "AND" condition and "MULTI" 2024-06-15 end -->
          </v-row>
        </v-col>
        <!-- <v-col cols="4" :sm="2" :md="1"> -->
        <v-col cols="12" :sm="8" :md="4">
          <v-row dense no-gutters>
            <!-- NOTE: merged searching function - added document number search 2024-06-15 start -->
            <span style="font-weight:bold;" class="pt-1 pr-1">タイトル検索</span>
            <v-text-field
              style="zoom:80%; background-color:white;"
              outlined
              dense
              hide-details
              clearable
              @keyup.enter="selectedCondition='帳票番号＆タイトル',titleIDSearch()"
              @click:clear="clearSearch()"
              @input="searchText=''"
              :clear-icon="customClearIcon"
              v-model="searchTitleDocNo"
            ></v-text-field>
            <!-- <a-input
              placeholder="番号検索"
              v-model="searchDocNo"
              @keyup.enter="selectedCondition='帳票番号',onSearch()"
            >
              <a-icon v-if="searchDocNo" slot="suffix" type="close-circle" @click="clearSearch()" />
            </a-input> -->
            <!-- NOTE: merged searching function - added document number search 2024-06-15 end -->
            <!-- NOTE: old searching function start -->
            <!-- <a-select v-model="selectedCondition" style="width: 100%">
              <a-select-option
                v-for="item in condition"
                :key="item"
                v-value="item"
                >{{ item }}</a-select-option
              >
            </a-select> -->
            <!-- NOTE: old searching function end -->
          </v-row>
        </v-col>

        <!-- NOTE: eliminate マイナス検索 2024-04-18 start -->
        <!-- <v-col cols="12" sm="6" md="3">
          <a-input
            addon-before="マイナス検索"
            v-model="exceptText"
            placeholder="マイナス検索"
          >
            <a-icon
              slot="suffix"
              @click="exceptText ? clearSearch() : ''"
              type="close-circle"
              style="color: rgba(0, 0, 0, 0.45)"
            />
          </a-input>
        </v-col> -->
        <!-- NOTE: eliminate マイナス検索 2024-04-18 end -->

        <!-- :disabled="!searchText" -->
        <v-col cols="4" :sm="2" :md="2">
          <!-- NOTE: old searching function start -->
          <!-- <a-button
            :style="Width"
            class="mx-1"
            dark
            type="primary"
            icon="search"
            @click="selectedCondition != 'MULTI' ? onSearch() : onMultipleSearch()"
            :disabled="!searchText && !searchDocNo && !searchTitle"
          >
            Search
          </a-button> -->
          <!-- NOTE: old searching function end -->
          <!-- NOTE: merged searching function start -->
          <a-button
            :style="Width"
            class="mx-1"
            dark
            type="primary"
            icon="search"
            @click="selectedCondition != 'MULTI' ? titleIDSearch() : andMultiSearch()"
            :disabled="!searchText && !searchDocNo && !searchTitle"
          >
            Search
          </a-button>
          <!-- NOTE: merged searching function end -->
          <a-button
            @click="searchText ? clearSearch() : ''"
            :disabled="!searchText"
            dense
            type="primary"
            >Reset</a-button>
        </v-col>
      </v-row>
      <!-- //FILTER -->
      <v-row
        dense
        class="ml-2 mb-1"
        :xs="24"
        :sm="12"
        :md="10"
        :gutter="[0, 3]"
        v-if="!isFilterOn && !hideFilter"
      >
        <!-- 2022-02-02 -->
        <!-- <a-col :xs="24" :sm="7" :md="5">
            <span class="mt-3 mr-2"
              style="font-weight: bold; font-size: 14px"
            >
              西暦:</span>
            <a-range-picker v-model="loadYear"  style="width: 70%;" class="mt-1" @change="onChangeYear" />
        </a-col> -->

        <v-col :xs="24" :sm="7" :md="4">
          <v-layout row wrap>
            <!-- @click="
                sortByFavoriteASC = true;
                sortByFavoriteDES = false;
              " -->

            <!-- <span
              class="button mt-1"
              @click="sortByFavoritesAllDocs(sortByFavoriteASC), sortByFavoriteASC = !sortByFavoriteASC"
            >
             <span v-if="sortByFavoriteASC == true ">🔼</span>
             <span v-else> 🔽</span>
              
            </span> -->

            <v-tooltip bottom v-if="sortByFavoriteASC == false">
              <template v-slot:activator="{ on }">
                <span
                  class="button mt-1"
                  v-on="on"
                  v-if="sortByFavoriteASC == false"
                  @click="
                    sortByFavoritesAllDocs(sortByFavoriteASC),
                      (sortByFavoriteASC = !sortByFavoriteASC)
                  "
                >
                  🔽
                </span>
              </template>
              <span style="font-family:Arial"> 🔽 - 昇順 </span>
            </v-tooltip>

            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span
                  class="button mt-1"
                  v-on="on"
                  @click="
                    sortByFavoritesAllDocs(sortByFavoriteASC),
                      (sortByFavoriteASC = !sortByFavoriteASC)
                  "
                >
                  🔼
                </span>
              </template>
              <span style="font-family:Arial"> 🔼- 降順 </span>
            </v-tooltip>

            <v-icon
              small
              :style="isFavorite ? 'color:#ffa62b' : 'color:#214252'"
              class="mr-1"
              >{{ isFav }}</v-icon
            >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="mt-2"
                  id="favStyle"
                  outlined
                  @click="
                    isFavorite = !isFavorite;
                    filterFavorites();
                  "
                  type="primary"
                  :style="`border-color:gray; ${Width}`"
                >
                  お気に入り
                </v-btn>
              </template>
              <span>Filter Favorites</span>
            </v-tooltip>
          </v-layout>
        </v-col>
        <!-- ONGOING -->
        <v-col :xs="24" :sm="7" :md="4" class="mt-2">
          <v-layout row wrap>
            <span class="button mt-1" style="font-weight: bold; font-size: 14px"
              >帳票:</span
            >&nbsp;
            <!-- keysha -->
            <a-select
              class="mt-1"
              mode="multiple"
              v-model="selectedCategory"
              style="width: 50%"
            >
              <a-select-option
                v-for="item in categoryList"
                :key="item.value"
                v-value="item.value"
                >{{ item.text }}</a-select-option
              >
            </a-select>
          </v-layout>
        </v-col>
        <v-col :xs="24" :sm="7" :md="4">
          <v-layout row wrap>
            <v-tooltip bottom v-if="sortByDateASC == false">
              <template v-slot:activator="{ on }">
                <span
                  class="mt-2 button"
                  style="font-weight: bold; font-size: 14px"
                  v-if="sortByDateASC == false"
                  v-on="on"
                  @click="
                    sortByUpdatedDate(sortByDateASC),
                      (sortByDateASC = !sortByDateASC)
                  "
                >
                  🔽 更新日:
                </span>
              </template>
              <span style="font-family:Arial"> 🔽 - 昇順 </span>
            </v-tooltip>

            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span
                  class="mt-2 button"
                  style="font-weight: bold; font-size: 14px"
                  v-on="on"
                  @click="
                    sortByUpdatedDate(sortByDateASC),
                      (sortByDateASC = !sortByDateASC)
                  "
                >
                  🔼 更新日:
                </span>
              </template>
              <span style="font-family:Arial"> 🔼 - 降順 </span>
            </v-tooltip>

            <!-- <span
              class="mt-2 button"
              style="font-weight: bold; font-size: 14px"
              v-if="!sortByDateASC"
              @click="
                sortByDateASC = true;
                sortByDateDES = false;
              "
            >
              🔽 更新日:
            </span
            > -->
            <!-- <span
              class="mt-2 button"
              style="font-weight: bold; font-size: 14px"
              v-else
              @click="
                sortByDateASC = false;
                sortByDateDES = true;
              "
            >
              🔼 更新日:</span
            > -->
            &emsp;
          </v-layout>
        </v-col>
      </v-row>
    </v-card>
    <v-layout class="ma-1" v-if="!loadingAllDocs">
      <!-- <v-spacer/> -->
      <span
        style="color: #0f3057; font-weight: bold"
        v-if="!isMultiple && searchAllDocsIconAlignment"
      >
        About {{ filteredDocuments.length }} results for keywords "{{
          searchText
        }}"
      </span>
      <span
        style="color: #0f3057; font-weight: bold"
        v-else-if="isMultiple && searchAllDocsIconAlignment"
      >
        About {{ filteredDocuments.length }} results for multiple keywords
      </span>

      <!-- <span style="color: #0f3057; font-weight: bold" flat v-else
        >Total: {{ filteredDocuments.length }}</span
      > -->

      <v-row no-gutters v-if="!loadingAllDocs">
        <v-col cols="8">
          <div class="ml-5">
            <strong style="font-size: small">
              Total: {{ filteredDocuments.length }}</strong
            >
          </div>
        </v-col>
      </v-row>

      <!-- <v-spacer /> -->
      <!-- sortByFavoriteASC -->
    </v-layout>

    <!-- <v-row no-gutters style="font-size: 14px">
        <v-col cols="4">
          <strong id="id-center" class="ml-9">Document No.</strong>
        </v-col>
        <v-col cols="3">
          <strong id="id-center">Title</strong>
        </v-col>
      <v-col cols="2">
        <strong id="id-center">Category
          </strong>
        </v-col>
        <v-col cols="1">
          <strong id="id-center">Update date</strong>
        </v-col>
        <v-col cols="1">
        <strong id="id-center">Views</strong>
        </v-col>
        <v-col cols="1">
          <strong id="id-center">Favorites</strong>
        </v-col>
      </v-row> -->
    <v-card
      flat
      style="overflow-y: scroll"
      :height="hideFilter ? screenSize - 260 : screenSize - 310"
      class="ma-1 pa-1 resetScrollTop"
      v-if="!loadingAllDocs"
    >
      <v-card elevation="0" v-for="(item, i) in paginatedMemo" :key="i">
        <v-divider />
        <v-row dense class="mb-n2 mt-n1 ">
          <v-col cols="12" md="7">
            <v-layout>
              <!-- Uncomment if rulebook is implemented... -->
              <!-- <template>
                <a-tag color="white" v-if="NewExpired(item)" style="padding: 0; width: 37px; text-align: center">
                  <span class="menuListNew">NEW</span>
                </a-tag>
                <a-tag color="white" v-else-if="RevisedExpired(item)" style="padding: 0; width: 37px; text-align: center">
                  <span class="menuListUpdate">UPDATE</span>
                </a-tag>
                <span v-else>
                  <a-tag color="white" style="width: 37px"></a-tag>
                </span>
              </template> -->

              <v-icon color="#f55666" class="ml-2">mdi-file-document</v-icon>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    :color="isFavColor(item)"
                    class="ml-2"
                    size="20"
                    @click="favoriteFunction(item)"
                    >{{ isFavIcon(item) }}</v-icon
                  >
                </template>
                <span>
                  {{
                    isFavIcon(item) != "mdi-star"
                      ? "Add to Favorite"
                      : "Delete from Favorite"
                  }}
                </span>
              </v-tooltip>

              <!-- <v-tooltip bottom transition="scroll-y-transition">
                <template v-slot:activator="{ on }">
                  <span
                    v-on="on"
                    id="title_ellipsis"
                    @click="
                      openDocument(item);
                      searchLists.push(item.docNo, item.category);
                    "
                    :class="listColorSearch(item.docNo, item.category)"
                    class="mx-2 mt-n2;"
                    style="font-size: 20px; font-weight: bold;  cursor:pointer"
                    v-html="
                      `<span style='background-color:#31b16e; border-radius:4px' class='px-2 mr-2 ml-2  white--text'><span style='font-size:15px;font-weight:normal'>${item.docNo}</span></span>${item.title}${title_spacer}`
                    "
                  />
                </template>
                <span
                  v-html="
                    item.title.replace(
                      'background-color:yellow',
                      'background-color:none'
                    )
                  "
                />
              </v-tooltip> -->

              <!-- NOTE: remove tooltip 2024-04-30 start-->
                  <span
                    id="title_ellipsis"
                    @click="
                      openDocument(item);
                      searchLists.push(item.docNo, item.category);
                    "
                    :class="listColorSearch(item.docNo, item.category)"
                    class="mx-2 mt-n2;"
                    style="font-size: 20px; font-weight: bold;  cursor:pointer"
                    v-html="
                      `<span style='background-color:#31b16e; border-radius:4px' class='px-2 mr-2 ml-2  white--text'><span style='font-size:15px;font-weight:normal'>${item.docNo}</span></span>${item.title}${title_spacer}`
                    "
                  />
              <!-- NOTE: remove tooltip 2024-04-30 end-->
              <!-- <span
                class="title"
                v-if="searchAllDocsIconAlignment"
                v-html="`${item.content}`"
                style="color: #555555; margin-left: 30px; font-size: 12px"
              >
              </span> -->
            </v-layout>
          </v-col>
          <v-col class="pt-1" cols="12" md="5">
            <v-row dense>
              <v-col cols="3">
                <!------Removed 20230906-------->
                <!--                   
                  <a-tooltip placement="bottom">
                    <template #title>
                       <span v-if="item.documentType == 'RULEBOOK'" > 
                        {{ 
                          item.method  == 'JIKU' ? '軸組' : 
                          item.method  == 'WAKU' ? '枠組' :
                          '軸組/枠組'
                        }} 
                      </span>

                      <span v-else-if="item.documentType == 'SHIYO'" >
                        {{ 
                              item.method 
                         }} 
                      </span>
                    </template>

                    <a-tag
                      style="width: 100%; color: white; text-align: center"
                      color="#424874"
                      v-if="item.documentType == 'RULEBOOK'"
                      > 
                      {{ 
                        item.method  == 'JIKU' ? '軸組' : 
                        item.method  == 'WAKU' ? '枠組' :
                        '軸組/枠組'
                       }}
                      </a-tag >

                      <a-tag
                          style="width: 100%; color: white; text-align: center"
                          color="#424874"
                          v-else-if="item.documentType == 'SHIYO'"
                          > {{ 
                              item.method 
                            }}  
                      </a-tag >
                    
                  </a-tooltip> -->
              </v-col>
              <v-col cols="2" class="d-flex">
                <v-spacer />
                <div v-if="searchAllDocsIconAlignment && selectedCondition != 'タイトル' && selectedCondition != '帳票番号'">
                  <v-btn color="primary" icon @click="toggleShowLine(item)">
                    <v-icon large>{{item.showLine ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
                  </v-btn>
                </div>
                <!------Removed 20230906-------->
                <!-- <a-tooltip placement="bottom">
                      <template #title>
                         <span v-if="item.documentType == 'RULEBOOK'" >  {{ item.criteria }} </span>
                         <span v-else-if="item.documentType == 'SHIYO'" > {{ item.specifications }} </span>
                      </template>

                        <a-tag
                          style="width: 100%; color: white; text-align: center"
                          color="#e36387"
                          v-if="item.documentType == 'RULEBOOK'"
                          >
                          {{ item.criteria }}
                          </a-tag >

                          <a-tag
                          style="width: 100%; color: white; text-align: center"
                          color="#5c969e"
                          v-else-if="item.documentType == 'SHIYO'"
                          >
                            {{ item.specifications }} 
                          </a-tag >

                    </a-tooltip> -->
              </v-col>

              <v-col cols="2">
                <a-tooltip placement="bottom">
                  <template #title>
                    <span v-if="item.documentType == 'RULEBOOK'">
                      {{ item.productType }}
                    </span>
                    <span v-else-if="item.documentType == 'SHIYO'">
                      {{
                        Array.isArray(item.roomPart) &&
                        Array.isArray(item.roomPart).length >= 3
                          ? getRoomPart(item.roomPart)
                          : getRoomPart(item.roomPart)
                      }}
                    </span>
                    <span v-else-if="item.documentType == 'JAPANMEMO'">
                      {{ item.category }}
                    </span>
                    <span v-else-if="item.documentType == 'SekkeiGyoumu'">
                      {{ "設計業務マニュアル" }}
                    </span>
                  </template>
                  <a-tag
                    style="width: 100%; color: white; text-align: center"
                    small
                    color="#5c969e"
                    v-if="item.documentType == 'RULEBOOK'"
                  >
                    <!-- {{ item.productType }} color="#5c969e" -->
                    {{ "ルールブック" }}
                  </a-tag>

                  <a-tag
                    style="width: 100%; color: white; text-align: center"
                    small
                    color="#e36387"
                    v-else-if="item.documentType == 'SHIYO'"
                  >
                    <!-- color="#e36387" {{ Array.isArray(item.roomPart) && Array.isArray(item.roomPart).length >= 3 ?  getRoomPart(item.roomPart) : getRoomPart(item.roomPart) }} -->
                    {{ "仕様マニュアル" }}
                  </a-tag>

                  <a-tag
                    style="width: 100%; color: white; text-align: center"
                    small
                    color="#424874"
                    v-else-if="item.documentType == 'JAPANMEMO'"
                  >
                    <!-- {{ item.category }} color="#5c969e" -->
                    {{ "連絡票・通達" }}
                  </a-tag>
                  <a-tag
                    style="width: 100%; color: white; text-align: center"
                    small
                    color="#F46B54"
                    v-if="item.documentType == 'SekkeiGyoumu'"
                  >
                    <!-- {{ item.productType }} color="#5c969e" -->
                    {{ "設計業務マニュアル" }}
                  </a-tag>
                </a-tooltip>
              </v-col>

              <!-- <v-col cols="5">
                    <a-tag v-if="item.documentType == 'JAPANMEMO'" style="width: 100%; color: white; text-align: center" color="#424874" >
                        {{ item.category }}
                    </a-tag>
                    <a-tag v-if="item.documentType == 'RULEBOOK'" style="width: 100%; color: white; text-align: center" color="#03254c" >
                        ルールブック
                    </a-tag>
                    
                    <a-tag v-if="item.documentType == 'SHIYO'" style="width: 100%; color: white; text-align: center" color="#03254c" >
                        仕様マニュアル
                    </a-tag>

                </v-col> -->

              <v-col cols="3"
                ><a-tag
                  color="#3d7ea6"
                  style="width: 100%; color: white; text-align: center"
                  small
                >
                  <span class="hidden-md-and-down">更新日:</span>
                  {{ item.updatedDate }}</a-tag
                ></v-col
              >

              <!-- <v-col :cols="item.countViews > 99 ? 'auto' : 1"> -->
              <v-col cols="1">
                <v-icon color="#90d52a" style="font-size: xx-small">
                  <!-- #6ebaa7 -->
                  mdi-eye</v-icon
                ><span
                  v-if="item.countViews"
                  style="font-size: font-size:xx-small"
                  >{{ item.countViews }}
                </span>
                <span v-else style="font-size: 12px"> 0 </span>
              </v-col>
              <v-col cols="1"
                ><v-icon small color="#ffa62b">mdi-star</v-icon>
                <span style="font-size: 12px" v-if="item.FavoritesCount">
                  {{ item.FavoritesCount }}
                </span>
                <span v-else style="font-size: 12px"> 0 </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="12">
            <!-- <pre> {{ item.textContent }} </pre>  -->
            <v-layout>
              <span
                class="title"
                v-if="searchAllDocsIconAlignment && item.showLine && selectedCondition != 'タイトル' && selectedCondition != '帳票番号'"
                v-html="`${item.textContent}`"
                style="color: #555555; margin-left: 30px; font-size: 12px"
              >
              </span>
            </v-layout>
          </v-col>
        </v-row>
      </v-card>
      <a-empty v-if="!loadingAllDocs && paginatedMemo.length == 0" />
    </v-card>

    <v-pagination
      v-model="pageNumber"
      :length="paginationLength"
      :total-visible="5"
      dark
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      v-if="!loadingAllDocs"
    ></v-pagination>

    <loading-file :isFileDownloadingProps="isFileDownloading" />
    <!-- 2022-12-07 -->
    </div>
  </v-container>
</template>
<script>
import axios from "axios";
import moji from "moji";

// import AWS from "aws-sdk";
// import tunnel from "tunnel";
// import config from "../config";
// import * as binconv from "binconv";

import Swal from "sweetalert2";
import moment from "moment";

import _ from "lodash";

import LoadingFile from "./LoadingComponent.vue";

// import FileSaver from "file-saver";
// import converter from "base64-arraybuffer";

export default {
  components: {
    "loading-file": LoadingFile,
  },
  data() {
    return {
      userTyping: false,
      loadYear: [],
      searchLists: [],
      title_spacer: "　",
      isMultiple: false,
      searchAllDocsIconAlignment: false,
      sortByCategoryDES: false,
      sortByCategoryASC: false,
      isFilterOn: false,
      hideFilter: false,
      searchText: "",
      exceptText: "",
      selectedCondition: "AND",
      // condition: ["AND", "OR", "MULTI" , "REFERENCE #", "TITLE", "ID & TITLE" ],//20230704 some change to japanese text
      // condition: ["AND", "OR", "MULTI" , "帳票番号", "タイトル", "帳票番号＆タイトル" ],// removed 帳票番号＆タイトル 20230711
      condition: ["AND", "OR", "MULTI", "帳票番号", "タイトル"],
      pageNumber: 1,
      intRowNoAllDocs: 30,
      loadingAllDocs: true,
      allDocuments: [],
      selectedCategory: ["ALL"],
      categoryList: [
        { text: "ALL", value: "ALL" },
        { text: "連絡票", value: "連絡票" },
        { text: "設計課通達", value: "設計課通達" },
        { text: "設計課・工事課通達", value: "設計課・工事課通達" },
        { text: "ルールブック", value: "RULEBOOK" },
        { text: "仕様マニュアル", value: "SHIYO" }, // 2023-06-19
        // { text: "JAPAN MEMO", value: "JAPANMEMO" },
      ],
      // categoryList: ["ALL", "連絡票", "設計課通達", "設計課・工事課通達"],
      sortByDateASC: false,
      sortByDateDES: false,
      sortByFavoriteASC: false,
      sortByFavoriteDES: false,
      isFavorite: false,
      composing: false,
      favoriteList: [],
      rulebookFavList: [],
      memoFavList: [],
      shiyoFavList: [], // 2023-02-09
      hideResult: true,
      rulebookData: [],
      memoData: [],

      dateFormat: "YYYY", // sample (2022-01-21)

      tmpFavoritesArr: [],
      allDocuments2: [],

      searchedKeywords: "", // 2024-10-17
      searchTitleDocNo: "", // 2024-10-17
      searchDocNo: "", // 2023-05-31
      searchTitle: "", // 2023-05-31
    };
  },
  watch: {
    // NOTE: added search function for search button 2024-06-21
    allSearch(){
      if(this.searchDocNo){
        this.selectedCondition = '帳票番号'
        this.onSearch()
      }
      else{
        this.selectedCondition = 'AND'
        this.fullTextSearch()
      }
    },
    //NOTE: for resetting of scroll top 2024-04-23
    pageNumber(){
      document.querySelector('.resetScrollTop').scrollTop = 0
    },
    selectedCategory() {
      if (
        this.selectedCategory.includes("ALL") &&
        this.selectedCategory.length == 2
      ) {
        this.selectedCategory.splice(0, 1);
      }
      if (this.selectedCategory.length == 0) {
        this.selectedCategory = ["ALL"];
      }
      if (
        this.selectedCategory.includes("ALL") &&
        this.selectedCategory.length != 1
      ) {
        this.selectedCategory = ["ALL"];
      }
    },

    selectedCondition(val) {
      // 2023-05-31
      if (val) {
        this.searchDocNo = "";
        this.searchTitle = "";
      }
    },
  },
  created() {
    console.log(
      "%cDocument Search System AWS ",
      "color:green;font-size:25px;font-weight:bold"
    );
    // console.log('line446', this.$vuetify.breakpoint)
    // let dateFrom = moment().subtract(1, 'y').format('YYYY-MM-DD') // 2022-01-21
    // let dateTo = moment().format('YYYY-MM-DD')
    // let tmpArr = [dateFrom, dateTo];
    // this.loadYear = tmpArr
    if (this.globalSearchWord && this.globalCondition) {
      if (this.isSearchAll) {
        this.searchText = this.globalSearchWord;
        this.selectedCondition = this.globalCondition;
        this.exceptText = this.globalExceptWord;

        if (this.selectedCondition != "MULTI") {
          this.onSearch();
        } else {
          this.onMultipleSearch();
        }
      }
    } else {
      // this.loadAllDocuments() // to delete possible
      this.loadAllDocumentsByYear(); // 2022-01-21
    }
    this.getUserFavorites();
  },
  methods: {
    async titleIDSearch(){
      try{
        this.searchedKeywords = ""
        this.pageNumber = 1;
        this.selectedCondition='帳票番号＆タイトル'
        this.searchAllDocsIconAlignment = false;
        this.loadingAllDocs = true;

        let finalData_Rulebook = [];
        let finalData_JapanMemo = [];
        let finalData_ShiyoManual = [];
        let finalData_Sekkei = [];
        //Sekkei
        finalData_Sekkei = await this.promiseGetSearchWord_SEKKEIGYOUMUMerged(
          this.searchTitleDocNo
        );
        finalData_Sekkei = finalData_Sekkei.map((r)=>{
          r.updatedDate = r.updatedDate != undefined || r.updatedDate
                    ? r.updatedDate.slice(0, 10)
                    : r.createdDate.slice(0, 10)
          return r
        })
        // Rulebook
        finalData_Rulebook = await this.promiseGetSearchWord_RULEBOOKMerged(
          this.searchTitleDocNo
        );
        finalData_Rulebook = _.uniqBy(
          finalData_Rulebook.flat(),
          "_id"
        ).map((r) => {
          r.docNo = r._id;
          r.textContent = r.content;
          r.documentType = "RULEBOOK";
          r.updatedDate = r.UpdatedDate ? r.UpdatedDate.substring(0,10) : '';
          return r;
        });

        // Japan Memo
        finalData_JapanMemo = await this.promiseGetSearchWord_JAPANMEMOMerged(
          this.searchTitleDocNo
        );
        finalData_JapanMemo = _.uniqBy(
          finalData_JapanMemo.flat(),
          "id"
        ).map((r) => {
          r.updatedDate = r.memoUpdatedDate;
          r.documentType = "JAPANMEMO";
          return r;
        });

        // Shiyo Manual
        finalData_ShiyoManual = await this.promiseGetSearchWord_SHIYOMANUALMerged(
          this.searchTitleDocNo
        );
        console.log('shiyo data',finalData_ShiyoManual)
        finalData_ShiyoManual = _.uniqBy(
          finalData_ShiyoManual.flat(),
          "id"
        ).map((r) => {
          r._id = r.id;
          r.countViews = r.CountViews ? r.CountViews : 0;
          r.method = r.framework;
          r.title = r.productName;
          r.docNo = r.shiyoNumber;
          r.updatedDate =
            r.updatedDate != undefined
              ? r.updatedDate.slice(0, 10)
              : "新規作成";
          r.textContent =
            r.textContent == " Attachment Only " ||
            r.textContent == "Attachment Only " ||
            r.textContent == " Attachment Only" ||
            r.textContent == "Attachment Only"
              ? "添付ファイルのみとなります。"
              : r.textContent;
          return r;
        })
        .filter(r=>{
          return !r.deleted
        });

        this.allDocuments = _.orderBy(
          [
            ...finalData_Rulebook,
            ...finalData_JapanMemo,
            ...finalData_ShiyoManual,
            ...finalData_Sekkei,
          ],
          ["updatedDate"],
          ["desc"]
        ).map(rec=>{
          this.$set(rec,'showLine',false)
          return rec
        });
        console.log('all data merged search shiyo',this.allDocuments.filter(r=>r.documentType=="SHIYO"))
        console.log('all data merged search rulebook',this.allDocuments.filter(r=>r.documentType=="RULEBOOK"))
        console.log('all data merged search japanmemo',this.allDocuments.filter(r=>r.documentType=="JAPANMEMO"))
        console.log('all data merged search SekkeiGyoumu',this.allDocuments.filter(r=>r.documentType=="SekkeiGyoumu"))
        this.loadingAllDocs = false;
      }catch(err){
        if(err){
          console.log(err)
        }
      }
    },
    async andMultiSearch(){
      try{
        this.searchedKeywords = ""
        this.pageNumber = 1;
        this.selectedCondition='MULTI'
        this.searchAllDocsIconAlignment = true;
        this.loadingAllDocs = true;

        let finalData_Rulebook = [];
        let finalData_JapanMemo = [];
        let finalData_ShiyoManual = [];
        let finalData_Sekkei = [];
        //Sekkei
        finalData_Sekkei = await this.promiseGetSearchWord_SEKKEIGYOUMUMerged(
          this.searchText
        );
        finalData_Sekkei = finalData_Sekkei.map((r)=>{
          r.updatedDate = r.updatedDate != undefined || r.updatedDate
                    ? r.updatedDate.slice(0, 10)
                    : r.createdDate.slice(0, 10)
          return r
        })
        // Rulebook
        finalData_Rulebook = await this.promiseGetSearchWord_RULEBOOKMerged(
          this.searchText
        );
        finalData_Rulebook = _.uniqBy(
          finalData_Rulebook.flat(),
          "_id"
        ).map((r) => {
          r.docNo = r._id;
          r.textContent = r.content;
          r.documentType = "RULEBOOK";
          r.updatedDate = r.RulebookUpdatedDate;
          return r;
        });

        // Japan Memo
        finalData_JapanMemo = await this.promiseGetSearchWord_JAPANMEMOMerged(
          this.searchText
        );
        finalData_JapanMemo = _.uniqBy(
          finalData_JapanMemo.flat(),
          "id"
        ).map((r) => {
          r.updatedDate = r.memoUpdatedDate;
          r.documentType = "JAPANMEMO";
          return r;
        });

        // Shiyo Manual
        finalData_ShiyoManual = await this.promiseGetSearchWord_SHIYOMANUALMerged(
          this.searchText
        );
        finalData_ShiyoManual = _.uniqBy(
          finalData_ShiyoManual.flat(),
          "id"
        ).map((r) => {
          r._id = r.id;
          r.countViews = r.CountViews ? r.CountViews : 0;
          r.method = r.framework;
          r.title = r.productName;
          r.docNo = r.shiyoNumber;
          r.updatedDate =
            r.updatedDate != undefined
              ? r.updatedDate.slice(0, 10)
              : "新規作成";
          r.textContent =
            r.textContent == " Attachment Only " ||
            r.textContent == "Attachment Only " ||
            r.textContent == " Attachment Only" ||
            r.textContent == "Attachment Only"
              ? "添付ファイルのみとなります。"
              : r.textContent;
          return r;
        })
        .filter(r=>{
          return !r.deleted
        });

        this.allDocuments = _.orderBy(
          [
            ...finalData_Rulebook,
            ...finalData_JapanMemo,
            ...finalData_ShiyoManual,
            ...finalData_Sekkei,
          ],
          ["updatedDate"],
          ["desc"]
        ).map(rec=>{
          this.$set(rec,'showLine',false)
          return rec
        });
        console.log('all data merged search shiyo',this.allDocuments.filter(r=>r.documentType=="SHIYO"))
        console.log('all data merged search rulebook',this.allDocuments.filter(r=>r.documentType=="RULEBOOK"))
        console.log('all data merged search japanmemo',this.allDocuments.filter(r=>r.documentType=="JAPANMEMO"))
        console.log('all data merged search SekkeiGyoumu',this.allDocuments.filter(r=>r.documentType=="SekkeiGyoumu"))
        this.loadingAllDocs = false;
      }catch(err){
        if(err){
          console.log(err)
        }
      }
    },
    promiseGetSearchWord_RULEBOOKMerged(
      searchText
    ) {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      return new Promise((resolve, reject) => {
        const url = `${this.api}search/${this.selectedCondition=='MULTI' ? 'andmultiple' : 'andmultipleTitleID'}/?searchText=${searchText}&exceptWord=`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    promiseGetSearchWord_JAPANMEMOMerged(
      searchText
    ) {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      return new Promise((resolve, reject) => {
        const url = `${this.api}japanMemo/search2/japanMemo/${this.selectedCondition=='MULTI' ? 'andmulti' : 'andmultipleTitleID'}/?searchText=${searchText}&exceptWord=`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    promiseGetSearchWord_SHIYOMANUALMerged(
      searchText
    ) {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      return new Promise((resolve, reject) => {
        const url = `${this.api}shiyo/search2/shiyo/${this.selectedCondition=='MULTI' ? 'andmultiple' : 'andmultipleTitleID'}/?searchText=${searchText}&exceptWord=`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              // this.searchedKeywords = res.data.searchedKeywords.join(" ")
              resolve(res.data.documents);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    promiseGetSearchWord_SEKKEIGYOUMUMerged(
      searchText
    ) {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      return new Promise((resolve, reject) => {
        const url = `${this.api}sekkeiGyoumu/search2/sekkeiGyoumu/${this.selectedCondition=='MULTI' ? 'andmultiple' : 'andmultipleTitleID'}?searchText=${searchText}&exceptWord=`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    // NOTE: added search function for search button 2024-06-21
    allSearch(){
      if(this.searchDocNo){
        this.selectedCondition = '帳票番号'
        this.onSearch()
      }
      else{
        this.selectedCondition = 'AND'
        this.fullTextSearch()
      }
    },
    // NOTE: added fulltext search include "AND" conditiona and "MULTI" 2024-06-15
    fullTextSearch(){
      this.loadingAllDocs = true;
      this.searchDocNo = ""
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      const url = `${this.api}check/multi?group=${this.searchText}`;
      axios.get(url).then((res)=>{
        if(res.data){
          this.onMultipleSearch()
        }
        else{
          this.onSearch()
        }
      })
    },
    toggleShowLine(item){
      let index = this.allDocuments.indexOf(item)
      if(index > -1){
        this.allDocuments[index].showLine = !this.allDocuments[index].showLine
      }
    },
    getRoomPart(roompart) {
      // 2023-03-08
      if (roompart.length > 0) {
        return roompart.toString().replace(/,/g, "・");
      } else {
        return roompart.toString();
      }
    },
    promiseGetUserFavorites() {
      // 2022-05-23
      return new Promise((resolve, reject) => {
        ////====GETTING THE USER'S JAPAN MEMO FAVORITES====////
        let tmpMemoFavList = [];
        let tmpRulebookFavList = [];
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        let toGet = {
          userId: this.userInfo.id,
        };
        let url = `${this.api}getFavorites/memo`;
        axios
          .post(url, toGet)
          .then((res) => {
            // console.log('FAV LIST MEMO',res.data);
            tmpMemoFavList = res.data;
            // this.favoriteList = res.data;
          })
          .then(() => {
            ////====GETTING THE USER'S RULEBOOK FAVORITES====////
            axios.defaults.headers.common["x-api-key"] =
              "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
            this.loading2 = true;
            let toGet = {
              userId: this.userInfo.id,
            };
            let url = `${this.api}getFavorites`;
            axios.post(url, toGet).then((res) => {
              tmpRulebookFavList = res.data;
              resolve({
                memoFavList: tmpMemoFavList,
                ruleBookFavList: tmpRulebookFavList,
                allFavList: tmpMemoFavList.concat(tmpRulebookFavList),
              });
            });
          })
          .catch((err) => {
            reject(err.message);
          });
      });
    },

    promiseGetFavoriteMemoDetails(favoriteListMemo) {
      // List Of Japan Memo Favorites List
      let count = 0;
      let tmpArr = [];
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        if (favoriteListMemo.length > 0) {
          for (let i = 0; i < favoriteListMemo.length; i++) {
            let url1 = `${this.api}getDetailsFavorite/memo?id=${favoriteListMemo[i].id}&docNo=${favoriteListMemo[i].docNo}`;
            axios
              .get(url1)
              .then((res) => {
                if (res.data) {
                  count++;
                  res.data.updatedDate = res.data.memoUpdatedDate;
                  tmpArr.push(res.data);
                  if (count == favoriteListMemo.length) {
                    resolve(tmpArr);
                  }
                }
              })
              .catch((err) => {
                reject(err.message);
              });
          }
        } else {
          resolve([]);
        }
      });
    },

    promiseGetFavoriteRulebookDetails(favoriteListRulebook) {
      // List Of Rulebook Favorites List
      let tmpArr = [];
      let count = 0;
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

        if (favoriteListRulebook.length > 0) {
          for (let i = 0; i < favoriteListRulebook.length; i++) {
            let url = `${this.api}getDetailsFavorite?id=${favoriteListRulebook[i]}`;
            axios
              .get(url)
              .then((res) => {
                if (res.data) {
                  count++;
                  res.data.updatedDate = res.data.RulebookUpdatedDate;
                  res.data.docNo = res.data._id;
                  tmpArr.push(res.data);
                  if (count == favoriteListRulebook.length) {
                    resolve(tmpArr);
                  }
                }
              })
              .catch((err) => {
                reject(err.message);
              });
          }
        } else {
          resolve([]);
        }
      });
    },

    promiseGetAllDocumentsByYear() {
      return new Promise((resolve, reject) => {
        let dateFrom = moment()
          .subtract(1, "y")
          .format("YYYY-MM-DD");
        let dateTo = moment().format("YYYY-MM-DD");
        // this.loadYear = [dateFrom, dateTo]
        let url = `${this.api}allDocuments/getAllDocumentsByYear?dateFrom=${dateFrom}&dateTo=${dateTo}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err.message);
          });
      });
    },

    sortByFavoritesAllDocs(action) {
      // New 2022-06-13
      // console.log("line617", action);
      if (action == false) {
        this.allDocuments = this.allDocuments.sort((a, b) =>
          a.FavoritesCount < b.FavoritesCount ? 1 : -1
        );
        this.sortByDateASC = false;
      } else {
        this.allDocuments = this.allDocuments.sort((b, a) =>
          b.FavoritesCount > a.FavoritesCount ? 1 : -1
        );
        this.sortByFavoriteASC = true;
        this.sortByDateASC = false;
        // this.allDocuments = finalData.sort((b, a) => (b.FavoritesCount > a.FavoritesCount ? 1 : -1));
        //  this.allDocuments = finalData.sort((a, b) => (a.FavoritesCount < b.FavoritesCount ? -1 : 1));
      }
    },

    sortByUpdatedDate(action) {
      // New 2022-06-13
      if (action == false) {
        this.allDocuments = _.orderBy(
          this.allDocuments,
          ["updatedDate"],
          ["desc"]
        );
        this.sortByFavoriteASC = false;
      } else {
        this.allDocuments = _.orderBy(
          this.allDocuments,
          ["updatedDate"],
          ["asc"]
        );
        this.sortByDateASC = true;
        this.sortByFavoriteASC = false;
      }
    },

    async sortByFavoritesAllDocs1(action) {
      // 2022-05-23 OLD

      try {
        action = !action;
        if (action == true) {
          this.allDocuments = this.allDocuments.sort((a, b) =>
            a.FavoritesCount < b.FavoritesCount ? 1 : -1
          );

          //  let sample = _.orderBy( this.allDocuments, 'FavoritesCount', 'asc')
          //  console.log(sample.filter(rec=>{ return rec.FavoritesCount >= 1}))

          //   console.log('line591', this.allDocuments )

          // let response1 = await this.promiseGetUserFavorites();
          // let responseMemoDetailsArr = await this.promiseGetFavoriteMemoDetails( response1.memoFavList);
          // let responseRulebookDetailsArr = await this.promiseGetFavoriteRulebookDetails( response1.ruleBookFavList)
          // let tmpAllDocuments = await this.promiseGetAllDocumentsByYear();
          // let mergeFavorites = [...responseMemoDetailsArr, ...responseRulebookDetailsArr ];  // [{},{},{}]

          // let favoritesDocNoArr = [...mergeFavorites ].map(rec=>{
          //   if( rec.docNo) {
          //     return rec.docNo
          //   }
          // }) // ['','','']

          // let favoritesDocTitle = [...mergeFavorites ].map(rec=>{
          //   if( rec.title) {
          //     return rec.title
          //   }
          // }) // ['','','']

          // // console.log('favoritesDocNoArr', favoritesDocNoArr)
          // // console.log('tmpAllDocuments', tmpAllDocuments)

          // // Not Includes DocNo and Doc Title
          // let tmpAllDocuments2 = tmpAllDocuments.filter(rec=>{
          //     return !favoritesDocNoArr.includes(rec.docNo) || !favoritesDocTitle.includes(rec.title)
          // })

          // this.allDocuments = [...mergeFavorites, ...tmpAllDocuments2]

          // this.allDocuments = _.orderBy(   this.allDocuments , 'FavoritesCount', 'asc')
          // console.log('>>',this.allDocuments)
        } else {
          //  this.loadAllDocumentsByYear();
          this.allDocuments = this.allDocuments.sort((a, b) =>
            a.FavoritesCount < b.FavoritesCount ? -1 : 1
          );
        }
      } catch (err) {
        console.log(err.message);
      }
    },

    async loadAllDocumentsByYear() {
      // 2022-01-22 ( tanskie )
      this.loadingAllDocs = true;
      let sekkeiRawData = "";
      let dateFrom = moment()
        .subtract(1, "y")
        .format("YYYY-MM-DD");
      let dateTo = moment().format("YYYY-MM-DD");
      let documentType = "SHIYO";

      this.loadYear = [dateFrom, dateTo];
      let url = `${this.api}allDocuments/getAllDocumentsByYear?dateFrom=${dateFrom}&dateTo=${dateTo}`;
      axios
        .get(url)
        .then(async (r) => {
          this.allDocuments2 = r.data;
          let urlSekkei = `${this.api}sekkeiGyoumu/getSekkeiGyoumuFinalDataTable`;

          await axios
            .get(urlSekkei)
            .then((res) => {
              if (res.data) {
                // console.log(res.data[0]);
                sekkeiRawData = res.data.map((rec) => {
                  return {
                    id: rec.id,
                    documentType: rec.documentType,
                    // countViews: rec.CountViews ? rec.CountViews : 0,
                    title: rec.title,
                    docNo: rec.docNo,
                    updatedDate:
                      rec.updatedDate != undefined || rec.updatedDate
                        ? rec.updatedDate.slice(0, 10)
                        : rec.createdDate.slice(0, 10),
                    sekkei_classification: rec.sekkei_classification,
                    sekkei_materials: rec.sekkei_materials,
                    sekkei_projects: rec.sekkei_projects,

                    // FavoritesCount: rec.FavoritesCount ? rec.FavoritesCount : 0,
                  };
                });
              }
            })
            .catch((err1) => {
              console.log(err1.message);
              alert("Error in Fetching Sekkei Gyoumu Data.....");
              this.loadAllDocuments = false;
            });

          let url2 = `${this.api}shiyo/load_shiyo_manual/${documentType}`;
          await axios
            .get(url2)
            .then((r2) => {
              // All Shiyo Manual Records ( 2023-02-09 )
              let shiyoManualArr = r2.data
              .filter((rec) => {
                return !rec?.deleted
              })
              .map((rec) => {
                return {
                  id: rec.id,
                  _id: rec.id,
                  documentType: rec.documentType,
                  countViews: rec.CountViews ? rec.CountViews : 0,
                  method: rec.framework, // WAKU / JIKU
                  title: rec.productName,
                  roomPart: rec.roomPart,
                  docNo: rec.shiyoNumber,
                  specifications: rec.specifications,
                  updatedDate:
                    rec.updatedDate != undefined
                      ? rec.updatedDate.slice(0, 10)
                      : "新規作成",
                  FavoritesCount: rec.FavoritesCount ? rec.FavoritesCount : 0,
                };
              })

              // Order By Shiyo Number ASCENDING ORDER
              shiyoManualArr = _.orderBy(shiyoManualArr, ["docNo"], ["asc"]);

              // Checking if Base not URL for Test Bucket ( 2022-10-28 )
              // IF Actual Link not include selected test codes rulebook
              if (this.linkURL == "https://documentsearch.hrd-s.com") {
                // let testCodesArrRulebook = ['999999-99']
                let testCodesArrRulebook = []; // 2023-03-03

                let mergeArr = [...r.data, ...shiyoManualArr, ...sekkeiRawData];
                let shiyoOriginalCopy = shiyoManualArr.filter((rec) => {
                  return rec.updatedDate === "新規作成";
                });

                let allDocsNotIncludeOriginalCopy = mergeArr
                  .filter((rec) => {
                    return (
                      rec.updatedDate != "新規作成" &&
                      !testCodesArrRulebook.includes(rec?._id)
                    );
                  })
                  .sort((a, b) => {
                    return a.updatedDate > b.updatedDate ? -1 : 1;
                  });
                this.allDocuments = [
                  ...allDocsNotIncludeOriginalCopy,
                  ...shiyoOriginalCopy,
                ];
              } else {
                // If Test Bucket Version show all records including test codes rulebook
                let mergeArr = [...r.data, ...shiyoManualArr, ...sekkeiRawData];
                let shiyoOriginalCopy = shiyoManualArr.filter((rec) => {
                  return rec.updatedDate === "新規作成";
                });
                let allDocsNotIncludeOriginalCopy = mergeArr
                  .filter((rec) => {
                    return rec.updatedDate != "新規作成";
                  })
                  .sort((a, b) => {
                    return a.updatedDate > b.updatedDate ? -1 : 1;
                  });
                this.allDocuments = [
                  ...allDocsNotIncludeOriginalCopy,
                  ...shiyoOriginalCopy,
                ];
              }

              this.loadingAllDocs = false;
            })
            .catch((err2) => {
              console.log(err2.message);
              alert("Error in Fetching records in Shiyo Manual..");
              this.loadingAllDocs = false;
            });
        })
        .catch((err1) => {
          console.log(err1.message);
          alert("Error in Fetching all Documents..");
          this.loadAllDocuments = false;
        });
    },
    onChangeYear(date, dateString, fromSearch) {
      // 2021-01-21 ( tanskie )
      console.log("line501", fromSearch);
      if (dateString.length == 2) {
        this.loadingAllDocs = true;
        this.loadYear = dateString;
        let url = `${this.api}allDocuments/getAllDocumentsByYear?dateFrom=${this.loadYear[0]}&dateTo=${this.loadYear[1]}`;
        axios
          .get(url)
          .then((r) => {
            this.allDocuments = r.data
              .sort((a, b) => {
                if (a.memoUpdatedDate && b.memoUpdatedDate) {
                  return b.memoUpdatedDate.localeCompare(a.memoUpdatedDate);
                }
              })
              .sort((a, b) => {
                if (a.docNo && b.docNo) {
                  return b.docNo.localeCompare(a.docNo);
                }
              });
          })
          .then(() => {
            this.loadingAllDocs = false;
          });
      }
    },
    NewExpired(val) {
      let today = moment().format("YYYY-MM-DD");
      let dateCheck = moment(today).isBetween(
        val.CreatedDate,
        val.ExpiryDate,
        null,
        "[]"
      );

      if (val.CreatedDate && val.isNew == true && dateCheck) {
        return true;
      } else {
        return false;
      }
    },
    RevisedExpired(val) {
      let today = moment().format("YYYY-MM-DD");
      let dateCheck = moment(today).isBetween(
        val.RevisedDate,
        val.ExpiryDate,
        null,
        "[]"
      );

      if (val.RevisedDate && val.isRevised == true && dateCheck) {
        return true;
      } else {
        return false;
      }
    },
    splitSelectedSpecs() {
      this.selectedCategory = this.selectedCategory.split(",");
    },
    onChangeCheck(e) {
      // console.log(e.target.checked);
      this.UPDATE_SEARCH_ALL(e.target.checked);
      if (e.target.checked == false) {
        this.CHANGE_SEARCH_WORD("");
        this.CHANGE_CONDITION("");
        this.CHANGE_EXCEPT_WORD("");
      }
    },
    favoriteFunction(data) {
      // console.log(data)
      if (data.documentType == "RULEBOOK") {
        if (this.favoriteList.includes(data._id)) {
          this.isFavorite = false;
        } else {
          this.isFavorite = true;
        }

        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        let toInsert = {
          id: data._id,
          userId: this.userInfo.id,
          isFavorite: this.isFavorite,
        };
        // console.log(toInsert)
        let url = `${this.api}addFavorite`;
        let url2 = `${this.api}addFavoriteCount`;
        axios.post(url2, toInsert).then(() => {
          axios.post(url, toInsert).then((res) => {
            // console.log(res.data)
            if (res.data == "Add Favorite") {
              Swal.fire({
                icon: "success",
                title: `${data._id} added to Favorites`,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: `${data._id} deleted to Favorites`,
                showConfirmButton: false,
                timer: 1200,
              });
            }
            if (this.searchText != "") {
              this.onSearch();
              this.getUserFavorites();
            } else {
              // this.loadAllDocuments();
              this.loadAllDocumentsByYear(); // 2022-01-21
              this.getUserFavorites();
            }
          });
        });
      }

      // 2023-02-09
      else if (data.documentType == "SHIYO") {
        // Add to Favorite in SHIYO MANUAL
        let favorite = true;

        if (this.isFavIcon(data) == "mdi-star") {
          favorite = false;
        }

        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

        let toInsert = {
          id: data.id,
          productName: data.title,
          userId: this.userInfo.id,
          isFavorite: favorite,
        };

        let url = `${this.api}shiyo/addFavorite`;
        let url2 = `${this.api}shiyo/addFavoriteCount`;
        axios.post(url2, toInsert).then(() => {
          axios.post(url, toInsert).then((res) => {
            // console.log(res.data)
            if (res.data == "Add Favorite") {
              Swal.fire({
                icon: "success",
                title: `${data.id} added to Favorites`,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: `${data.id} deleted to Favorites`,
                showConfirmButton: false,
                timer: 1200,
              });
            }
            if (this.searchText != "") {
              this.onSearch();
              this.getUserFavorites();
            } else {
              this.loadAllDocumentsByYear();
              this.getUserFavorites();
            }
          });
        });
      } else {
        // Add to Favorite in JAPAN MEMO

        let favorite = true;
        if (this.isFavIcon(data) == "mdi-star") {
          favorite = false;
        }
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        let toInsert = {
          id: data.id,
          docNo: data.docNo,
          category: data.category,
          userId: this.userInfo.id,
          isFavorite: favorite,
        };
        // console.log(toInsert)
        let url = `${this.api}addFavorite/memo`;
        let url2 = `${this.api}addFavoriteCount/memo`;
        axios.post(url2, toInsert).then(() => {
          axios.post(url, toInsert).then((res) => {
            // console.log(res.data)
            if (res.data == "Add Favorite") {
              Swal.fire({
                icon: "success",
                title: `${data.docNo} added to Favorites`,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: `${data.docNo} deleted to Favorites`,
                showConfirmButton: false,
                timer: 1200,
              });
            }
            if (this.searchText != "") {
              this.onSearch();
              this.getUserFavorites();
            } else {
              // this.loadAllMemo();
              // this.loadAllDocuments()
              this.loadAllDocumentsByYear(); // 2022-01-21
              this.getUserFavorites();
            }
          });
        });
      }
    },
    
    listColorSearch(id, category) {
      if (
        this.searchLists.includes(id) &&
        this.searchLists.includes(category)
      ) {
        return "viewed";
      }
    },
    openRulebookDocument(val) {
      this.SET_FAVORITE(0);
      // console.log(val);

      this.SET_FAVORITE(val.FavoritesCount);
      if (!this.searchText) {
        window.open(
          `${this.linkURL}/document/${val._id}?title=${val.title}`,
          "_blank"
        );
      } else if (
        this.searchText.match(/^[0-9]/g) == null &&
        this.selectedCondition != "MULTI"
      ) {
        window.open(
          `${this.linkURL}/document/${val._id}?search=${this.searchText}&title=${val.title}`,
          "_blank"
        );
      } else if (this.searchText && this.selectedCondition == "MULTI") {
        window.open(
          `${this.linkURL}/document/${val._id}?search=${this.searchText}&multi=true&title=${val.title}`,
          "_blank"
        );
      } else {
        window.open(
          `${this.linkURL}/document/${val._id}?title=${val.title}`,
          "_blank"
        );
      }
      let url = `${this.api}addCount/${val._id}`;
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      axios.post(url).then(() => {
        let index = this.allDocuments.indexOf(val);
        // console.log(index);
        if (this.allDocuments[index].countViews) {
          this.allDocuments[index].countViews =
            this.allDocuments[index].countViews + 1;
        } else {
          this.allDocuments[index].countViews = 1;
        }
      });
    },

    async openMemoDocument(data) {
      // 2022-12-07
      try {
        // this.isFileDownloading = true
        let url = `${this.api}addCount/memo/${data.id}?docNo=${data.docNo}`;
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        await axios.post(url);

        // 2022-12-06
        // let folderName = `Memo`
        // let fileSource = `${this.s3URL}/${folderName}/${data.attachment}`
        // this.downloadOriginalFile(fileSource , data.attachment) // Download File
        //this.openNewTabPDFFile(fileSource)  // Open New Tab 2023-03-22

        window.open(
          `${this.linkURL}/japan_memo/${data.id}/?docNo=${data.docNo}`, // 2023-03-22
          "_blank"
        );
      } catch (err) {
        console.log(err);
        this.isFileDownloading = false;
      }
    },

    openMemoDocument2(data) {
      // OLD
      // console.log("line761", data);
      let url = `${this.api}addCount/memo/${data.id}?docNo=${data.docNo}`;

      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      axios.post(url).then(() => {
        // console.log(res.data)ONGOING
        if (this.searchText !== "") {
          this.onSearch(data);
          this.getUserFavorites();
        } else {
          // this.loadAllMemo();
          // this.loadAllDocuments()
          this.loadAllDocumentsByYear(); // 2022-01-21
          this.getUserFavorites();
        }

        window.open(
          // 2022-04-29
          `${this.linkURL}/japan_memo/${data.id}?docNo=${data.docNo}`,
          "_blank"
        );
      });
    },
    openDocument(data) {
      if (data.documentType == "RULEBOOK") {
        this.openRulebookDocument(data);
      } else if (data.documentType == "SHIYO") {
        // 2023-02-09

        window.open(
          `${this.linkURL}/shiyoDocument/${data.id}?productName=${data.title}`,
          "_blank"
        );
      } else if (data.documentType == "SekkeiGyoumu") {
        // 2023-09-20
        let isFavSekkei = false;
        if (this.favoriteList.includes(data.id)) {
          isFavSekkei = true;
          // console.log(isFavSekkei);
        }
        if (!this.favoriteList.includes(data.id)) {
          isFavSekkei = false;
          // console.log(isFavSekkei);
        }

        window.open(
          `/sekkei_document/${data.id}?search=${this.searchText}&favorite=${isFavSekkei}`,
          `_blank`
        );
      } else {
        this.openMemoDocument(data);
      }
    },
    isFavColor(data) {
      if (data.documentType == "RULEBOOK") {
        let isFavDoc = false;
        if (this.favoriteList.includes(data._id)) {
          isFavDoc = true;
        }

        if (isFavDoc) {
          return "yellow";
        } else return "black";
      } else {
        let isFavDoc = false;
        this.favoriteList.map((r) => {
          if (r.id == data.id && r.category == data.category) {
            isFavDoc = true;
          }
        });
        if (isFavDoc) {
          return "yellow";
        } else return "black";
      }
    },
    StartCompose() {
      this.composing = true;
    },
    EndCompose() {
      this.composing = false;
    },

    convertSearchText(word) {
      // 2023-03-13
      return {
        ZE_HE: moji(word)
          .convert("ZE", "HE")
          .toString(),
        HE_ZE: moji(word)
          .convert("HE", "ZE")
          .toString(),
        HK_ZK: moji(word)
          .convert("HK", "ZK")
          .toString(),
        ZK_HK: moji(word)
          .convert("ZK", "HK")
          .toString(),
        HG_KK: moji(word)
          .convert("HG", "KK")
          .toString(),
        KK_HG: moji(word)
          .convert("KK", "HG")
          .toString(),
      };
    },

    promiseGetSearchWord_RULEBOOK(
      condition = "and",
      search1 = "",
      search2 = ""
    ) {
      // 2023-03-13

      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}search/rulebook/${condition}/?search1=${search1}&search2=${search2}&exceptWord=${this.exceptText}`;

        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    promiseGetSearchWord_JAPANMEMO(
      condition = "and",
      search1 = "",
      search2 = ""
    ) {
      // 2023-03-13

      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}japanMemo/search/japanMemo/${condition}/?search1=${search1}&search2=${search2}&exceptWord=${this.exceptText}`;

        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    promiseGetSearchWord_SHIYOMANUAL(
      condition = "and",
      search1 = "",
      search2 = ""
    ) {
      // 2023-03-13

      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}shiyo/search/shiyo/${condition}/?search1=${search1}&search2=${search2}&exceptWord=${this.exceptText}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    // 2023-06-01
    promiseGetSearchWord_ByCategory_Rulebook(condition = "and", search = "") {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}search/rulebook/${condition}/?search1=${search}&exceptWord=${this.exceptText}`;

        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    // 2023-06-01
    promiseGetSearchWord_ByCategory_JapanMemo(condition = "and", search = "") {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      return new Promise((resolve, reject) => {
        const url = `${this.api}japanMemo/search/japanMemo/${condition}/?search1=${search}&exceptWord=${this.exceptText}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    // 2023-06-01
    promiseGetSearchWord_ByCategory_ShiyoManual(
      condition = "and",
      search = ""
    ) {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}shiyo/search/shiyo/${condition}/?search1=${search}&exceptWord=${this.exceptText}`;

        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    //created 20230922 for sekkei
    promiseGetSearchWordByDocumentCategorySekkei(condition = "", search = "") {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}sekkeiGyoumu/search/sekkeiGyoumu/${condition}/?search1=${search}&exceptWord=${this.exceptText}`;
        // console.log(url);
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              // console.log(res.data);
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    promiseGetSearchWordSekkei(condition = "and", search1 = "", search2 = "") {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}sekkeiGyoumu/search/sekkeiGyoumu/${condition}?search1=${search1}&search2=${search2}&exceptWord=${this.exceptText}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    /////=================================FOR DYNAMIC SEARCH 20240125=========================

    promiseGetSearchWord_RULEBOOKNew(
      condition = "and",
      // search1 = "",
      // search2 = ""
      searchText
    ) {
      // 2023-03-13

      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}search2/rulebook/${condition}/?searchText=${searchText}&exceptWord=${this.exceptText}`;

        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    promiseGetSearchWord_JAPANMEMONew(
      condition = "and",
      // search1 = "",
      // search2 = ""
      searchText
    ) {
      // 2023-03-13

      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}japanMemo/search2/japanMemo/${condition}/?searchText=${searchText}&exceptWord=${this.exceptText}`;

        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    promiseGetSearchWord_SHIYOMANUALNew(
      condition = "and",
      // search1 = "",
      // search2 = ""
      searchText
    ) {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}shiyo/search2/shiyo/${condition}/?searchText=${searchText}&exceptWord=${this.exceptText}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    // 2023-06-01
    promiseGetSearchWord_ByCategory_RulebookNew(
      condition = "and",
      search = ""
    ) {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}search2/rulebook/${condition}/?search1=${search}&exceptWord=${this.exceptText}`;

        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    // 2023-06-01
    promiseGetSearchWord_ByCategory_JapanMemoNew(
      condition = "and",
      search = ""
    ) {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}japanMemo/search2/japanMemo/${condition}/?search1=${search}&exceptWord=${this.exceptText}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    promiseGetSearchWord_ByCategory_ShiyoManualNew(
      condition = "and",
      search = ""
    ) {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}shiyo/search2/shiyo/${condition}/?search1=${search}&exceptWord=${this.exceptText}`;

        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    promiseGetSearchWordByDocumentCategorySekkeiNew(
      condition = "",
      search = ""
    ) {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}sekkeiGyoumu/search2/sekkeiGyoumu/${condition}/?search1=${search}&exceptWord=${this.exceptText}`;
        // console.log(url);
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              // console.log(res.data);
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    promiseGetSearchWordSekkeiNew(
      condition = "and",
      // search1 = "",
      // search2 = ""
      searchText
    ) {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}sekkeiGyoumu/search2/sekkeiGyoumu/${condition}?searchText=${searchText}&exceptWord=${this.exceptText}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    //////////////////===============================================================
    getPaste(e) {
      e.preventDefault();

      const pastedContent = e.clipboardData.getData("text/plain");
      this.searchText = pastedContent;
      // console.log(this.searchText);
    },
    async onSearch() {
      try {
        this.pageNumber = 1;
        // this.selectedCondition='帳票番号'//NOTE: merged searching function
        this.searchAllDocsIconAlignment = true;
        this.isMultiple = false;
        this.loadingAllDocs = true;

        let search = "";
        let japaneseSpace = "　";

        let finalData_Rulebook = [];
        let finalData_JapanMemo = [];
        let finalData_ShiyoManual = [];
        let finalData_Sekkei = [];

        // CHECK SEARCH BY DOCUMENT NUMBER
        //  if( this.selectedCondition == 'REFERENCE #' ) {//changed to 帳票番号
        if (this.selectedCondition == "帳票番号") {
          this.searchText = ""
          if (this.searchDocNo.trim()) {
            //  alert('search by reference number')
            //Sekkei
            ///CHANGED 2024-01-25 FOR DYNAMIC SEARCHING
            finalData_Sekkei = await this.promiseGetSearchWordByDocumentCategorySekkeiNew(
              "byDocumentNumber",
              this.searchDocNo
            );
            finalData_Sekkei = finalData_Sekkei.map((r)=>{
              r.updatedDate = r.updatedDate != undefined || r.updatedDate
                        ? r.updatedDate.slice(0, 10)
                        : r.createdDate.slice(0, 10)
              return r
            })
            // Rulebook
            finalData_Rulebook = await this.promiseGetSearchWord_ByCategory_RulebookNew(
              "byRulebookNumber",
              this.searchDocNo
            );
            finalData_Rulebook = finalData_Rulebook.map((r) => {
              r.docNo = r._id;
              r.textContent = r.content;
              r.documentType = "RULEBOOK";
              r.updatedDate = r.RulebookUpdatedDate;
              return r;
            });

            // Japan Memo
            finalData_JapanMemo = await this.promiseGetSearchWord_ByCategory_JapanMemo(
              "byDocumentNumber",
              this.searchDocNo
            );
            finalData_JapanMemo = finalData_JapanMemo.map((r) => {
              r.updatedDate = r.memoUpdatedDate;
              r.documentType = "JAPANMEMO";
              return r;
            });

            // Shiyo Manual
            finalData_ShiyoManual = await this.promiseGetSearchWord_ByCategory_ShiyoManualNew(
              "byShiyoNumber",
              this.searchDocNo
            );
            finalData_ShiyoManual = finalData_ShiyoManual.map((r) => {
              r._id = r.id;
              r.countViews = r.CountViews ? r.CountViews : 0;
              r.method = r.framework;
              r.title = r.productName;
              r.docNo = r.shiyoNumber;
              r.updatedDate =
                r.updatedDate != undefined
                  ? r.updatedDate.slice(0, 10)
                  : "新規作成";
              r.textContent =
                r.textContent == " Attachment Only " ||
                r.textContent == "Attachment Only " ||
                r.textContent == " Attachment Only" ||
                r.textContent == "Attachment Only"
                  ? "添付ファイルのみとなります。"
                  : r.textContent;
              return r;
            })
            .filter(r=>{
              return !r.deleted
            });

            this.allDocuments = _.orderBy(
              [
                ...finalData_Rulebook,
                ...finalData_JapanMemo,
                ...finalData_ShiyoManual,
                ...finalData_Sekkei,
              ],
              ["updatedDate"],
              ["desc"]
            ).map(rec=>{
              this.$set(rec,'showLine',false)
              return rec
            });
            this.loadingAllDocs = false;
          } else {
            alert(`Please Input Reference Number`);
            this.loadingAllDocs = false;
          }
        }

        // CHECK SEARCH BY TITLE
        //  else if ( this.selectedCondition == 'TITLE' ) {// changed to タイトル
        else if (this.selectedCondition == "タイトル") {
          if (this.searchText.trim()) {
            //  alert('search by title')
            //Sekkei
            finalData_Sekkei = await this.promiseGetSearchWordByDocumentCategorySekkeiNew(
              "byDocumentTitle",
              this.searchText
            );
            // Rulebook
            finalData_Rulebook = await this.promiseGetSearchWord_ByCategory_RulebookNew(
              "byDocumentTitle",
              this.searchText
            );
            finalData_Rulebook = finalData_Rulebook.map((r) => {
              r.docNo = r._id;
              r.textContent = r.content;
              r.documentType = "RULEBOOK";
              r.updatedDate = r.RulebookUpdatedDate;
              return r;
            });

            // Japan Memo
            finalData_JapanMemo = await this.promiseGetSearchWord_ByCategory_JapanMemo(
              "byDocumentTitle",
              this.searchText
            );
            finalData_JapanMemo = finalData_JapanMemo.map((r) => {
              r.updatedDate = r.memoUpdatedDate;
              r.documentType = "JAPANMEMO";
              return r;
            });

            // Shiyo Manual
            finalData_ShiyoManual = await this.promiseGetSearchWord_ByCategory_ShiyoManualNew(
              "byDocumentTitle",
              this.searchText
            );
            finalData_ShiyoManual = finalData_ShiyoManual.map((r) => {
              r._id = r.id;
              r.countViews = r.CountViews ? r.CountViews : 0;
              r.method = r.framework;
              r.title = r.productName;
              r.docNo = r.shiyoNumber;
              r.updatedDate =
                r.updatedDate != undefined
                  ? r.updatedDate.slice(0, 10)
                  : "新規作成";
              r.textContent =
                r.textContent == " Attachment Only " ||
                r.textContent == "Attachment Only " ||
                r.textContent == " Attachment Only" ||
                r.textContent == "Attachment Only"
                  ? "添付ファイルのみとなります。"
                  : r.textContent;
              return r;
            })
            .filter(r=>{
              return !r.deleted
            });

            this.allDocuments = _.orderBy(
              [
                ...finalData_Rulebook,
                ...finalData_JapanMemo,
                ...finalData_ShiyoManual,
                ...finalData_Sekkei,
              ],
              ["updatedDate"],
              ["desc"]
            ).map(rec=>{
              this.$set(rec,'showLine',false)
              return rec
            });

            this.loadingAllDocs = false;
          } else {
            alert(`Please Input Reference Number`);
            this.loadingAllDocs = false;
          }
        }

        // CHECK SEARCH BY Document Number - TITLE
        // else if( this.selectedCondition == 'ID & TITLE') { // changed to 帳票番号＆タイトル 20230704
        else if (this.selectedCondition == "帳票番号＆タイトル") {
          if (this.searchDocNo.trim() && this.searchTitle.trim()) {
            //  alert('search id title')
            // Rulebook
            finalData_Rulebook = await this.promiseGetSearchWord_RULEBOOK(
              "byRulebookNumberAndTitle",
              this.searchDocNo,
              this.searchTitle
            );
            finalData_Rulebook = finalData_Rulebook.map((r) => {
              r.docNo = r._id;
              r.textContent = r.content;
              r.documentType = "RULEBOOK";
              r.updatedDate = r.RulebookUpdatedDate;
              return r;
            });

            // Japan Memo
            finalData_JapanMemo = await this.promiseGetSearchWord_JAPANMEMO(
              "docNoTitle",
              this.searchDocNo,
              this.searchTitle
            );
            finalData_JapanMemo = finalData_JapanMemo.map((r) => {
              r.updatedDate = r.memoUpdatedDate;
              r.documentType = "JAPANMEMO";
              return r;
            });

            // Shiyo Manual
            finalData_ShiyoManual = await this.promiseGetSearchWord_SHIYOMANUAL(
              "byShiyoNumberTitle",
              this.searchDocNo,
              this.searchTitle
            );
            finalData_ShiyoManual = finalData_ShiyoManual.map((r) => {
              r._id = r.id;
              r.countViews = r.CountViews ? r.CountViews : 0;
              r.method = r.framework;
              r.title = r.productName;
              r.docNo = r.shiyoNumber;
              r.updatedDate =
                r.updatedDate != undefined
                  ? r.updatedDate.slice(0, 10)
                  : "新規作成";
              r.textContent =
                r.textContent == " Attachment Only " ||
                r.textContent == "Attachment Only " ||
                r.textContent == " Attachment Only" ||
                r.textContent == "Attachment Only"
                  ? "添付ファイルのみとなります。"
                  : r.textContent;
              return r;
            })
            .filter(r=>{
              return !r.deleted
            });

            this.allDocuments = _.orderBy(
              [
                ...finalData_Rulebook,
                ...finalData_JapanMemo,
                ...finalData_ShiyoManual,
              ],
              ["updatedDate"],
              ["desc"]
            ).map(rec=>{
              this.$set(rec,'showLine',false)
              return rec
            });

            this.loadingAllDocs = false;
          } else {
            alert(`Please Input Reference Number and Title`);
            this.allDocuments = [];
            this.loadingAllDocs = false;
          }
        } else {
          if (this.searchText) {
            // 2 Words
            if (
              this.searchText.includes(" ") ||
              this.searchText.includes(japaneseSpace)
            ) {
              // Split Two Words into array
              let splitKeyWord = this.searchText
                .replace(/\s+/g, " ")
                .split(" ");

              // Get Keyword per index ( as of now 2 words only )
              let keywordsArr1 = Object.values(
                this.convertSearchText(splitKeyWord[0])
              );
              let keywordsArr2 = Object.values(
                this.convertSearchText(splitKeyWord[1])
              );

              console.log("Search word 1 --> ", keywordsArr1);
              console.log("Search word 2 --> ", keywordsArr2);

              // Selected Condition
              // ===================================== AND =========================================== //
              if (this.selectedCondition == "AND") {
                if (keywordsArr1.length != 0 && keywordsArr2.length != 0) {
                  //Sekkei
                  finalData_Sekkei = await this.promiseGetSearchWordSekkeiNew(
                    "and",
                    // splitKeyWord[0],
                    // splitKeyWord[1]
                    this.searchText
                  );
                  finalData_Sekkei = finalData_Sekkei.map((r)=>{
                    r.updatedDate = r.updatedDate != undefined || r.updatedDate
                              ? r.updatedDate.slice(0, 10)
                              : r.createdDate.slice(0, 10)
                    return r
                  })
                  // Rulebook
                  finalData_Rulebook = await this.promiseGetSearchWord_RULEBOOKNew(
                    "and",
                    // splitKeyWord[0],
                    // splitKeyWord[1]
                    this.searchText
                  );
                  finalData_Rulebook = _.uniqBy(
                    finalData_Rulebook.flat(),
                    "_id"
                  ).map((r) => {
                    r.docNo = r._id;
                    r.textContent = r.content;
                    r.documentType = "RULEBOOK";
                    r.updatedDate = r.RulebookUpdatedDate;
                    return r;
                  });

                  // Japan Memo
                  finalData_JapanMemo = await this.promiseGetSearchWord_JAPANMEMO(
                    "and",
                    splitKeyWord[0],
                    splitKeyWord[1]
                    // this.searchText
                  );
                  finalData_JapanMemo = _.uniqBy(
                    finalData_JapanMemo.flat(),
                    "id"
                  ).map((r) => {
                    r.updatedDate = r.memoUpdatedDate;
                    r.documentType = "JAPANMEMO";
                    return r;
                  });

                  // Shiyo Manual
                  finalData_ShiyoManual = await this.promiseGetSearchWord_SHIYOMANUALNew(
                    "and",
                    // splitKeyWord[0],
                    // splitKeyWord[1]
                    this.searchText
                  );
                  finalData_ShiyoManual = _.uniqBy(
                    finalData_ShiyoManual.flat(),
                    "id"
                  ).map((r) => {
                    r._id = r.id;
                    r.countViews = r.CountViews ? r.CountViews : 0;
                    r.method = r.framework;
                    r.title = r.productName;
                    r.docNo = r.shiyoNumber;
                    r.updatedDate =
                      r.updatedDate != undefined
                        ? r.updatedDate.slice(0, 10)
                        : "新規作成";
                    r.textContent =
                      r.textContent == " Attachment Only " ||
                      r.textContent == "Attachment Only " ||
                      r.textContent == " Attachment Only" ||
                      r.textContent == "Attachment Only"
                        ? "添付ファイルのみとなります。"
                        : r.textContent;
                    return r;
                  })
                  .filter(r=>{
                    return !r.deleted
                  });

                  // Add more for future documents

                  this.allDocuments = _.orderBy(
                    [
                      ...finalData_Rulebook,
                      ...finalData_JapanMemo,
                      ...finalData_ShiyoManual,
                      ...finalData_Sekkei,
                    ],
                    ["updatedDate"],
                    ["desc"]
                  ).map(rec=>{
                    this.$set(rec,'showLine',false)
                    return rec
                  });
                }
              } else {
                // ==================================== OR ============================================== //
                if (keywordsArr1.length != 0 && keywordsArr2.length != 0) {
                  //Sekkei
                  finalData_Sekkei = await this.promiseGetSearchWordSekkeiNew(
                    "or",
                    // splitKeyWord[0],
                    // splitKeyWord[1]
                    this.searchText
                  );
                  // Rulebook
                  finalData_Rulebook = await this.promiseGetSearchWord_RULEBOOKNew(
                    "or",
                    // splitKeyWord[0],
                    // splitKeyWord[1]
                    this.searchText
                  );
                  finalData_Rulebook = _.uniqBy(
                    finalData_Rulebook.flat(),
                    "_id"
                  ).map((r) => {
                    r.docNo = r._id;
                    r.textContent = r.content;
                    r.documentType = "RULEBOOK";
                    r.updatedDate = r.RulebookUpdatedDate;
                    return r;
                  });

                  // Japan Memo
                  finalData_JapanMemo = await this.promiseGetSearchWord_JAPANMEMO(
                    "or",
                    splitKeyWord[0],
                    splitKeyWord[1]
                    // this.searchText
                  );
                  finalData_JapanMemo = _.uniqBy(
                    finalData_JapanMemo.flat(),
                    "id"
                  ).map((r) => {
                    r.updatedDate = r.memoUpdatedDate;
                    r.documentType = "JAPANMEMO";
                    return r;
                  });

                  // Shiyo Manual
                  finalData_ShiyoManual = await this.promiseGetSearchWord_SHIYOMANUALNew(
                    "or",
                    // splitKeyWord[0],
                    // splitKeyWord[1]
                    this.searchText
                  );
                  finalData_ShiyoManual = _.uniqBy(
                    finalData_ShiyoManual.flat(),
                    "id"
                  ).map((r) => {
                    r._id = r.id;
                    r.countViews = r.CountViews ? r.CountViews : 0;
                    r.method = r.framework;
                    r.title = r.productName;
                    r.docNo = r.shiyoNumber;
                    r.updatedDate =
                      r.updatedDate != undefined
                        ? r.updatedDate.slice(0, 10)
                        : "新規作成";
                    r.textContent =
                      r.textContent == " Attachment Only " ||
                      r.textContent == "Attachment Only " ||
                      r.textContent == " Attachment Only" ||
                      r.textContent == "Attachment Only"
                        ? "添付ファイルのみとなります。"
                        : r.textContent;
                    return r;
                  })
                  .filter(r=>{
                    return !r.deleted
                  });

                  // Add more for future documents

                  this.allDocuments = _.orderBy(
                    [
                      ...finalData_Rulebook,
                      ...finalData_JapanMemo,
                      ...finalData_ShiyoManual,
                      ...finalData_Sekkei,
                    ],
                    ["updatedDate"],
                    ["desc"]
                  ).map(rec=>{
                    this.$set(rec,'showLine',false)
                    return rec
                  });
                }
              }

              this.loadingAllDocs = false;
            } else {
              // 1 Word  AND
              if (this.selectedCondition == "AND") {
                search = this.convertSearchText(this.searchText);
                let searchKeyArr = Object.values(search);

                console.log("Search Word --> ", _.uniq(searchKeyArr));

                // let finalData = await this.promiseGetSearchWord( 'and', this.searchText );
                //Sekkei
                finalData_Sekkei = await this.promiseGetSearchWordSekkeiNew(
                  "and",
                  this.searchText
                );
                finalData_Sekkei = finalData_Sekkei.map((r)=>{
                  r.updatedDate = r.updatedDate != undefined || r.updatedDate
                            ? r.updatedDate.slice(0, 10)
                            : r.createdDate.slice(0, 10)
                  return r
                })
                // Rulebook
                finalData_Rulebook = await this.promiseGetSearchWord_RULEBOOKNew(
                  "and",
                  this.searchText
                );
                finalData_Rulebook = _.uniqBy(
                  finalData_Rulebook.flat(),
                  "_id"
                ).map((r) => {
                  r.docNo = r._id;
                  r.textContent = r.content;
                  r.documentType = "RULEBOOK";
                  r.updatedDate = r.RulebookUpdatedDate;
                  return r;
                });

                // Japan Memo
                finalData_JapanMemo = await this.promiseGetSearchWord_JAPANMEMO(
                  "and",
                  this.searchText
                );
                finalData_JapanMemo = _.uniqBy(
                  finalData_JapanMemo.flat(),
                  "id"
                ).map((r) => {
                  r.updatedDate = r.memoUpdatedDate;
                  r.documentType = "JAPANMEMO";
                  return r;
                });

                // Shiyo Manual
                finalData_ShiyoManual = await this.promiseGetSearchWord_SHIYOMANUALNew(
                  "and",
                  this.searchText
                );
                finalData_ShiyoManual = _.uniqBy(
                  finalData_ShiyoManual.flat(),
                  "id"
                ).map((r) => {
                  r._id = r.id;
                  r.countViews = r.CountViews ? r.CountViews : 0;
                  r.method = r.framework;
                  r.title = r.productName;
                  r.docNo = r.shiyoNumber;
                  r.updatedDate =
                    r.updatedDate != undefined
                      ? r.updatedDate.slice(0, 10)
                      : "新規作成";
                  r.textContent =
                    r.textContent == " Attachment Only " ||
                    r.textContent == "Attachment Only " ||
                    r.textContent == " Attachment Only" ||
                    r.textContent == "Attachment Only"
                      ? "添付ファイルのみとなります。"
                      : r.textContent;
                  return r;
                })
                .filter(r=>{
                  return !r.deleted
                });

                // Add more for future documents

                this.allDocuments = _.orderBy(
                  [
                    ...finalData_Rulebook,
                    ...finalData_JapanMemo,
                    ...finalData_ShiyoManual,
                    ...finalData_Sekkei,
                  ],
                  ["updatedDate"],
                  ["desc"]
                ).map(rec=>{
                  this.$set(rec,'showLine',false)
                  return rec
                });

                this.loadingAllDocs = false;
              } else {
                alert(
                  "Search words should be atleast 2 words separated with space"
                );
                this.loadingAllDocs = false;
              }
            }
          }
        }
      } catch (err) {
        console.log(err.message);
        alert("Error in fetching records, Please try again..");
        this.allDocuments = [];
        this.searchDialog = false;
        this.loadingAllDocs = false;
      }
    },

    onSearch1() {
      // old backup 2023-03-13

      this.pageNumber = 1; // 2022-02-03

      //  let addLog = this.searchText
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      this.searchAllDocsIconAlignment = true;
      this.isMultiple = false;
      let search = this.convert2FullKatakana(this.searchText).trim();
      if (this.searchText != "") {
        this.loadingAllDocs = true;
      }
      if (this.searchText) {
        let url = "";
        let url2 = "";
        let url3 = "";
        let url4 = ""; // 2022-08-02

        let url5 = ""; // 2022-08-13

        let search2 = "";

        let url6Shiyo = ""; // 2023-02-11
        let url7Shiyo = ""; // 2023-02-11
        let url8Shiyo = ""; // 2023-02-11
        let url9Shiyo = "";
        let url10Shiyo = "";

        let tmpSearchTextArr = [];
        if (this.selectedCondition == "OR") {
          // ============================= OR ================================= //
          // let ishiragana = moji(search).filter("HG").toString();
          // let iskatakana = moji(search).filter("KK").toString();
          // let hiragana = ""
          // let katakana = ""
          // if(ishiragana){
          //   hiragana = moji(this.searchText).convert("HG", "KK").toString()
          //   search = `${search}${this.title_spacer}${hiragana}`;
          // }else if(iskatakana){
          //   search = this.convert2FullKatakana(this.searchText)
          //   katakana = moji(search).convert("KK", "HG").toString();
          //   search = this.convert2FullKatakana(search)
          //   katakana = this.convert2FullHiragana(katakana)
          //   search = `${search}${this.title_spacer}${katakana}`
          // }else{
          //   search = this.convert2FullKatakana(this.searchText)
          // }
          // //-----SMALL AND BIG ALPHA NUMERIC CHARACTERS CND
          // let numSmall = moji(search).filter("HE").toString();
          // let numBig = moji(search).filter("ZE").toString();

          // if (search.match(/[0-9]/g) || search.match(/[０-９]/g)) {
          //   if (numSmall) {
          //     numSmall = moji(search).convert("HE", "ZE").toString();
          //     search = `${search}${this.title_spacer}${numSmall}`;
          //   } else if (numBig) {
          //     numBig = search = `${search}${this.title_spacer}${moji(search)
          //       .convert("ZE", "HE")
          //       .toString()}`;
          //   }
          // }
          // //-----HALF AND FULL WIDTH  CHARACTERS

          // let fullKatakana = moji(search).filter("ZK").toString();
          // let halfKatakana = moji(search).filter("HK").toString();
          // if (fullKatakana) {
          //   fullKatakana = moji(search).convert("ZK", "HK").toString();
          //   search = `${search}${this.title_spacer}${fullKatakana}`;
          // } else if (halfKatakana) {
          //   halfKatakana = moji(search).convert("HK", "ZK").toString();
          //   search = `${search}${this.title_spacer}${halfKatakana}`;
          // }
          // url = `${this.api}get/title_rules?search=${search}&except=${this.exceptText}`;
          if (search.includes(this.title_spacer) || search.includes(" ")) {
            // Convert Katakana to Hiragana
            let katakanaToHiragana = moji(search)
              .convert("KK", "HG")
              .toString(); // 2023-03-06
            url9Shiyo = `${this.api}shiyo/search/or?search=${katakanaToHiragana}&except=${this.exceptText}`; // 2022-03-06

            // Convert Full width katakana to Half width katakana
            let fullKanaToHalfKana = moji(search)
              .convert("ZK", "HK")
              .toString();
            url10Shiyo = `${this.api}shiyo/search/or?search=${fullKanaToHalfKana}&except=${this.exceptText}`; // 2022-03-07

            url6Shiyo = `${this.api}shiyo/search/or?search=${search}&except=${this.exceptText}`; // 2022-08-15

            let searchReplaceWords = [];

            if (search.includes(this.title_spacer)) {
              searchReplaceWords.push(search);
            } else {
              search.replace(" ", this.title_spacer);
              searchReplaceWords.push(search);
            }

            // console.log("line1230", search);
            // Check if Space is japanese space or normal space
            let newSearchReplaceWords = searchReplaceWords[0].includes(
              this.title_spacer
            )
              ? searchReplaceWords.join().split(this.title_spacer)
              : searchReplaceWords.join().split(" ");

            // Convert Normal English to Japanese English
            let normalEngToJapEng = moji(newSearchReplaceWords[0])
              .convert("HE", "ZE")
              .toString();
            let japEngToNormalEng2 = moji(newSearchReplaceWords[0])
              .convert("ZE", "HE")
              .toString();

            // Convert Jap English to Normal English
            let japEngToNormalEng = moji(newSearchReplaceWords[1])
              .convert("ZE", "HE")
              .toString();
            let normalEngToJapEng2 = moji(newSearchReplaceWords[1])
              .convert("HE", "ZE")
              .toString();

            let toSearch2WordsEng1 = [normalEngToJapEng, japEngToNormalEng];
            let toSearch2WordsEng2 = [japEngToNormalEng2, normalEngToJapEng2];

            console.log(">", toSearch2WordsEng1);
            console.log(">>", toSearch2WordsEng2);

            search = [toSearch2WordsEng1.join(this.title_spacer)].join();
            search2 = [toSearch2WordsEng2.join(this.title_spacer)].join();

            console.log("line1084", search);
            console.log("line1085", search2);

            tmpSearchTextArr = [search, search2]; // 2022-08-13

            // rulebook
            url = `${this.api}get/title_rules?search=${search}&except=${this.exceptText}`;
            url2 = `${this.api}get/title_rules?search=${search2}&except=${this.exceptText}`;

            // japan memo
            url3 = `${this.api}japanMemo/search_memo/or?search=${search2}&except=${this.exceptText}`;

            url4 = `${this.api}get/title_rules?search=${search}&except=${this.exceptText}`; // 2022-08-02

            url5 = `${this.api}japanMemo/search_memo/or?search=${search2}&except=${this.exceptText}`; // 2022-08-13

            url7Shiyo = `${this.api}shiyo/search/and?search=${search}&except=${this.exceptText}`; // 2023-02-11
            url8Shiyo = `${this.api}shiyo/search/and?search=${search2}&except=${this.exceptText}`; // 2023-02-11
          } else {
            alert("2 Words Only");
            this.allDocuments = [];
            this.searchDialog = false;
            this.loadingAllDocs = false;
          }
        } else {
          // ============================ AND ========================================= //
          // if (search.match(/[０-９]/g)) {
          //   search = `${moji(search).convert("ZE", "HE").toString()}`;
          // }
          // url = `${this.api}get/title_rules/and?search=${search}&except=${this.exceptText}`;

          if (search.match(/[０-９|ａ-ｚ|Ａ-Ｚ|A-Z|a-z]/g)) {
            // search = `${moji(search).convert("ZE", "HE").toString()}`;

            let normalEngToJapEng = moji(search)
              .convert("HE", "ZE")
              .toString();

            // Convert Jap English to Normal English
            let japEngToNormalEng = moji(search)
              .convert("ZE", "HE")
              .toString();

            console.log("d3 >>", normalEngToJapEng);
            console.log("d3 >>", japEngToNormalEng);

            url5 = `${this.api}japanMemo/search_memoLatest/and?search=${search}&except=${this.exceptText}`;

            url6Shiyo = `${this.api}shiyo/search/and?search=${search}&except=${this.exceptText}`; // 2023-02-11

            // 2022-06-10
            search = `${normalEngToJapEng}${this.title_spacer}${japEngToNormalEng}`;

            if (
              this.searchText.includes(this.title_spacer) ||
              search.includes(" ")
            ) {
              tmpSearchTextArr = [normalEngToJapEng, japEngToNormalEng, search];
            } else {
              tmpSearchTextArr = [normalEngToJapEng, japEngToNormalEng, search];
            }

            url = `${this.api}get/title_rules/and?search=${search}&except=${this.exceptText}`;
            url2 = `${this.api}get/title_rules/and?search=${search}&except=${this.exceptText}`;
            url3 = `${this.api}japanMemo/search_memo_New/and?search=${search}&except=${this.exceptText}`;

            url4 = `${this.api}get/title_rules?search=${search}&except=${this.exceptText}`; // 2022-08-02

            url7Shiyo = `${this.api}shiyo/search/and?search=${search}&except=${this.exceptText}`; // 2023-02-11
            url8Shiyo = `${this.api}shiyo/search/and?search=${search}&except=${this.exceptText}`; // 2023-02-11

            let katakanaToHiragana = moji(search)
              .convert("KK", "HG")
              .toString(); // 2023-03-06
            url9Shiyo = `${this.api}shiyo/search/and?search=${katakanaToHiragana}&except=${this.exceptText}`; // 2023-03-06

            // Convert Full width katakana to Half width katakana
            let fullKanaToHalfKana = moji(search)
              .convert("ZK", "HK")
              .toString();
            url10Shiyo = `${this.api}shiyo/search/and?search=${fullKanaToHalfKana}&except=${this.exceptText}`; // 2023-03-07

            // console.log(search)
          } else if (search.match(/平均GL/g)) {
            url = `${this.api}get/title_rules/and?search=平均ＧＬ&except=${this.exceptText}`;
            url2 = `${this.api}get/title_rules/and?search=平均ＧＬ&except=${this.exceptText}`;
            url3 = `${this.api}japanMemo/search_memo_New/and?search=平均ＧＬ&except=${this.exceptText}`;

            url4 = `${this.api}get/title_rules?=search=平均ＧＬ&except=${this.exceptText}`; // 2022-08-02

            url5 = `${this.api}japanMemo/search_memoLatest/and?search=平均ＧＬ&except=${this.exceptText}`;

            url6Shiyo = `${this.api}shiyo/search/and?search=平均ＧＬ&except=${this.exceptText}`; // 2023-02-11
            url7Shiyo = `${this.api}shiyo/search/and?search=平均ＧＬ&except=${this.exceptText}`; // 2023-02-11
            url8Shiyo = `${this.api}shiyo/search/and?search=平均ＧＬ&except=${this.exceptText}`; // 2023-02-11

            url9Shiyo = `${this.api}shiyo/search/and?search=平均ＧＬ&except=${this.exceptText}`; // 2023-03-06
            url10Shiyo = `${this.api}shiyo/search/and?search=平均ＧＬ&except=${this.exceptText}`; // 2023-03-07
          } else {
            // console.log("Dito AND >>");
            // let normalEngToJapEng = moji(search).convert('HE', 'ZE').toString();
            // // Convert Jap English to Normal English
            // let japEngToNormalEng = moji(search).convert('ZE', 'HE').toString();

            if (
              this.searchText.includes(this.title_spacer) ||
              search.includes(" ")
            ) {
              tmpSearchTextArr = this.searchText.split(
                this.searchText.includes(this.title_spacer)
                  ? this.title_spacer
                  : " "
              );
            } else {
              tmpSearchTextArr = [this.searchText];
            }

            // 2022-08-13
            url5 = `${this.api}japanMemo/search_memoLatest/and?search=${search}&except=${this.exceptText}`;

            url6Shiyo = `${this.api}shiyo/search/and?search=${search}&except=${this.exceptText}`; // 2023-02-11

            // Convert Katakana to Hiragana
            let katakanaToHiragana = moji(search)
              .convert("KK", "HG")
              .toString(); // 2023-03-06
            url9Shiyo = `${this.api}shiyo/search/and?search=${katakanaToHiragana}&except=${this.exceptText}`; // 2023-03-06

            // Convert Full width katakana to Half width katakana
            let fullKanaToHalfKana = moji(search)
              .convert("ZK", "HK")
              .toString();
            url10Shiyo = `${this.api}shiyo/search/and?search=${fullKanaToHalfKana}&except=${this.exceptText}`; // 2023-03-07

            // 2022-06-14
            let bigWordToNormalEng = moji(search)
              .convert("ZE", "HE")
              .toString();
            let normalEngToBigWord = moji(search)
              .convert("HE", "ZE")
              .toString();
            search = `${bigWordToNormalEng}${this.title_spacer}${normalEngToBigWord}`;

            url = `${this.api}get/title_rules/and?search=${bigWordToNormalEng}&except=${this.exceptText}`;
            url2 = `${this.api}get/title_rules/and?search=${normalEngToBigWord}&except=${this.exceptText}`;
            // url3 = `${this.api}japanMemo/search_memo_New/and?search=${search}&except=${this.exceptText}`; // or ata to

            // 2 words
            url3 = `${this.api}japanMemo/search_memoLatest/and?search=${search}&except=${this.exceptText}`;

            // url4 = `${this.api}get/title_rules/?search=${search}&except=${this.exceptText}`;  // 2022-08-02
            url4 = `${this.api}get/title_rules/and?search=${search}&except=${this.exceptText}`; // 2022-08-02

            url7Shiyo = `${this.api}shiyo/search/and?search=${bigWordToNormalEng}&except=${this.exceptText}`; // 2023-02-11
            url8Shiyo = `${this.api}shiyo/search/and?search=${normalEngToBigWord}&except=${this.exceptText}`; // 2023-02-11

            console.log("line1440", url6Shiyo);
            console.log("line1441", url7Shiyo);
            console.log("line1442", url8Shiyo);
            console.log("line1467", url9Shiyo);
            console.log("line1484", url10Shiyo);
          }
        }
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              axios
                .get(url2)
                .then((res2) => {
                  if (res2.data) {
                    axios
                      .get(url3)
                      .then((res3) => {
                        if (res3.data) {
                          axios
                            .get(url4)
                            .then((res4) => {
                              // 2022-08-02
                              if (res4.data) {
                                axios
                                  .get(url5)
                                  .then((res5) => {
                                    if (res5.data) {
                                      axios
                                        .get(url6Shiyo)
                                        .then((res6) => {
                                          if (res6.data) {
                                            axios
                                              .get(url7Shiyo)
                                              .then((res7) => {
                                                if (res7.data) {
                                                  axios
                                                    .get(url8Shiyo)
                                                    .then((res8) => {
                                                      if (res8.data) {
                                                        axios
                                                          .get(url9Shiyo)
                                                          .then((res9) => {
                                                            if (res9.data) {
                                                              axios
                                                                .get(url10Shiyo)
                                                                .then(
                                                                  (res10) => {
                                                                    let concat1 =
                                                                      res
                                                                        .data[0] !=
                                                                      "<"
                                                                        ? _.uniqBy(
                                                                            [
                                                                              ...res.data,
                                                                              ...res2.data,
                                                                              ...res4.data,
                                                                            ],
                                                                            "_id"
                                                                          )
                                                                        : []; // 2022-08-15

                                                                    // Japan Memo
                                                                    let uniqRes3Data = _.uniqBy(
                                                                      [
                                                                        ...res3.data,
                                                                        ...res5.data,
                                                                      ].map(
                                                                        (r) => {
                                                                          r.updatedDate =
                                                                            r.memoUpdatedDate;
                                                                          r.documentType =
                                                                            "JAPANMEMO";

                                                                          return r;
                                                                        }
                                                                      ),
                                                                      "id"
                                                                    );

                                                                    // Shiyo Manual 2023-02-11
                                                                    let shiyoManualSearched = _.uniqBy(
                                                                      [
                                                                        ...res6.data,
                                                                        ...res7.data,
                                                                        ...res8.data,
                                                                        ...res9.data,
                                                                        ...res10.data,
                                                                      ].map(
                                                                        (r) => {
                                                                          r._id =
                                                                            r.id;
                                                                          r.countViews = r.CountViews
                                                                            ? r.CountViews
                                                                            : 0;
                                                                          r.method =
                                                                            r.framework;
                                                                          r.title =
                                                                            r.productName;
                                                                          r.docNo =
                                                                            r.shiyoNumber;
                                                                          r.updatedDate =
                                                                            r.updatedDate !=
                                                                            undefined
                                                                              ? r.updatedDate.slice(
                                                                                  0,
                                                                                  10
                                                                                )
                                                                              : "新規作成";
                                                                          r.textContent =
                                                                            r.textContent ==
                                                                              " Attachment Only " ||
                                                                            r.textContent ==
                                                                              "Attachment Only " ||
                                                                            r.textContent ==
                                                                              " Attachment Only" ||
                                                                            r.textContent ==
                                                                              "Attachment Only"
                                                                              ? "添付ファイルのみとなります。"
                                                                              : r.textContent;
                                                                          return r;
                                                                        }
                                                                      ),
                                                                      "id"
                                                                    );

                                                                    // let concatFinal = _.uniqBy( [...concat1 , ...res3.data ], 'docNo')
                                                                    // console.log( uniqRes3Data.map(rec=>{ return rec.docNo }))
                                                                    this.rulebookData = concat1.map(
                                                                      (r) => {
                                                                        r.docNo =
                                                                          r._id;
                                                                        r.textContent =
                                                                          r.content;
                                                                        r.documentType =
                                                                          "RULEBOOK";
                                                                        r.updatedDate =
                                                                          r.RulebookUpdatedDate;
                                                                        return r;
                                                                      }
                                                                    );

                                                                    // this.allDocuments = this.rulebookData
                                                                    this.allDocuments = _.orderBy(
                                                                      [
                                                                        ...this
                                                                          .rulebookData,
                                                                        ...uniqRes3Data,
                                                                        ...shiyoManualSearched,
                                                                      ],
                                                                      [
                                                                        "updatedDate",
                                                                      ],
                                                                      ["desc"]
                                                                    ); // 2023-02-11
                                                                    // console.log('line1184' , tmpSearchTextArr);
                                                                    let regexText = new RegExp(
                                                                      tmpSearchTextArr
                                                                        .join(
                                                                          "|"
                                                                        )
                                                                        .toString(),
                                                                      "g"
                                                                    );

                                                                    // 2022-10-28
                                                                    // If Actual Link will not show test codes rulebook
                                                                    if (
                                                                      this
                                                                        .linkURL ==
                                                                      "https://documentsearch.hrd-s.com"
                                                                    ) {
                                                                      // let testCodesArrRulebook = ['000000-00', '999999-99']
                                                                      let testCodesArrRulebook = []; // 2023-03-03
                                                                      this.allDocuments = this.allDocuments
                                                                        .map(
                                                                          (
                                                                            rec
                                                                          ) => {
                                                                            rec.textContent = rec.textContent.replace(
                                                                              regexText,
                                                                              function(
                                                                                str
                                                                              ) {
                                                                                return `<span style="background-color:yellow"><b>${str}</b></span>`;
                                                                              }
                                                                            );
                                                                            return rec;
                                                                          }
                                                                        )

                                                                        .filter(
                                                                          (
                                                                            rec1
                                                                          ) => {
                                                                            return !testCodesArrRulebook.includes(
                                                                              rec1?._id
                                                                            );
                                                                          }
                                                                        );
                                                                    } else {
                                                                      // If Test Bucket Version , show all records including test codes rulebook
                                                                      this.allDocuments = this.allDocuments.map(
                                                                        (
                                                                          rec
                                                                        ) => {
                                                                          rec.textContent = rec.textContent.replace(
                                                                            regexText,
                                                                            function(
                                                                              str
                                                                            ) {
                                                                              return `<span style="background-color:yellow"><b>${str}</b></span>`;
                                                                            }
                                                                          );
                                                                          return rec;
                                                                        }
                                                                      );
                                                                    }

                                                                    this.searchDialog = false;
                                                                    this.loadingAllDocs = false;
                                                                  }
                                                                )
                                                                .catch(
                                                                  (err10) => {
                                                                    alert(
                                                                      "Error in Searching..."
                                                                    );
                                                                    this.allDocuments = [];
                                                                    this.searchDialog = false;
                                                                    this.loadingAllDocs = false;
                                                                    console.log(
                                                                      err10.message
                                                                    );
                                                                  }
                                                                );
                                                            }
                                                          })
                                                          .catch((err9) => {
                                                            alert(
                                                              "Error in Searching.."
                                                            );
                                                            this.allDocuments = [];
                                                            this.searchDialog = false;
                                                            this.loadingAllDocs = false;
                                                            console.log(
                                                              err9.message
                                                            );
                                                          });
                                                      }
                                                    })
                                                    .catch((err8) => {
                                                      alert(
                                                        "Error in Searching.."
                                                      );
                                                      this.allDocuments = [];
                                                      this.searchDialog = false;
                                                      this.loadingAllDocs = false;
                                                      console.log(err8.message);
                                                    });
                                                }
                                              })
                                              .catch((err7) => {
                                                alert("Error in Searching..");
                                                this.allDocuments = [];
                                                this.searchDialog = false;
                                                this.loadingAllDocs = false;
                                                console.log(err7.message);
                                              });
                                          }
                                        })
                                        .catch((err6) => {
                                          alert("Error in Searching..");
                                          this.allDocuments = [];
                                          this.searchDialog = false;
                                          this.loadingAllDocs = false;
                                          console.log(err6.message);
                                        });
                                    }
                                  })
                                  .catch((err5) => {
                                    alert("2 Words Only");
                                    this.allDocuments = [];
                                    this.searchDialog = false;
                                    this.loadingAllDocs = false;
                                    console.log(err5.message);
                                  });
                              }
                            })
                            .catch((err4) => {
                              alert("2 Words Only");
                              this.allDocuments = [];
                              this.searchDialog = false;
                              this.loadingAllDocs = false;
                              console.log(err4.message);
                            });
                        }
                      })
                      .catch((err3) => {
                        alert("2 Words Only");
                        this.allDocuments = [];
                        this.searchDialog = false;
                        this.loadingAllDocs = false;
                        console.log(err3.message);
                      });
                  }
                })
                .catch((err2) => {
                  alert("2 Words Only");
                  this.allDocuments = [];
                  this.searchDialog = false;
                  this.loadingAllDocs = false;
                  console.log(err2.message);
                });
            }
          })
          .catch((err1) => {
            console.log(err1.message);
            this.allDocuments = [];
            this.searchDialog = false;
            this.loadingAllDocs = false;
          });

        // this.hideResult = false;
        //   if (this.isSearchAll) {
        //     this.CHANGE_SEARCH_WORD(this.searchText);
        //     this.CHANGE_CONDITION(this.selectedCondition);
        //     this.CHANGE_EXCEPT_WORD(this.exceptText);
        //   } else {
        //     this.CHANGE_SEARCH_WORD("");
        //     this.CHANGE_CONDITION("");
        //     this.CHANGE_EXCEPT_WORD("");
        //   }
      } else {
        // alert('clear')
        this.allDocuments = [];
        this.hideResult = true;
      }

      this.loadingAllDocs = true;
    },

    // onSearch(){
    //   let addLog = this.searchText
    //   axios.defaults.headers.common["x-api-key"] =
    //       "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
    //     this.searchAllDocsIconAlignment = true
    //     this.isMultiple = false;
    //     let search = this.convert2FullKatakana(this.searchText);
    //     if (this.searchText != "") {
    //       this.loadingAllDocs = true;
    //     }
    //     if(this.searchText){
    //       let url = "";
    //       if(this.selectedCondition == "OR"){
    //         let ishiragana = moji(search).filter("HG").toString();
    //         let iskatakana = moji(search).filter("KK").toString();
    //         let hiragana = ""
    //         let katakana = ""
    //         if(ishiragana){
    //           hiragana = moji(this.searchText).convert("HG", "KK").toString()
    //           search = `${search}${this.title_spacer}${hiragana}`;
    //         }else if(iskatakana){
    //           search = this.convert2FullKatakana(this.searchText)
    //           katakana = moji(search).convert("KK", "HG").toString();
    //           search = this.convert2FullKatakana(search)
    //           katakana = this.convert2FullHiragana(katakana)
    //           search = `${search}${this.title_spacer}${katakana}`
    //         }else{
    //           search = this.convert2FullKatakana(this.searchText)
    //         }
    //         //-----SMALL AND BIG ALPHA NUMERIC CHARACTERS CND
    //         let numSmall = moji(search).filter("HE").toString();
    //         let numBig = moji(search).filter("ZE").toString();

    //         if (search.match(/[0-9]/g) || search.match(/[０-９]/g)) {
    //           if (numSmall) {
    //             numSmall = moji(search).convert("HE", "ZE").toString();
    //             search = `${search}${this.title_spacer}${numSmall}`;
    //           } else if (numBig) {
    //             numBig = search = `${search}${this.title_spacer}${moji(search)
    //               .convert("ZE", "HE")
    //               .toString()}`;
    //           }
    //         }
    //         //-----HALF AND FULL WIDTH  CHARACTERS

    //         let fullKatakana = moji(search).filter("ZK").toString();
    //         let halfKatakana = moji(search).filter("HK").toString();
    //         if (fullKatakana) {
    //           fullKatakana = moji(search).convert("ZK", "HK").toString();
    //           search = `${search}${this.title_spacer}${fullKatakana}`;
    //         } else if (halfKatakana) {
    //           halfKatakana = moji(search).convert("HK", "ZK").toString();
    //           search = `${search}${this.title_spacer}${halfKatakana}`;
    //         }
    //         url = `${this.api}get/title_rules?search=${search}&except=${this.exceptText}`;
    //       }else{
    //         if (search.match(/[０-９]/g)) {
    //           search = `${moji(search).convert("ZE", "HE").toString()}`;
    //         }
    //         url = `${this.api}get/title_rules/and?search=${search}&except=${this.exceptText}`;
    //       }
    //       axios.defaults.headers.common["x-api-key"] =
    //         "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
    //       axios.get(url).then((res) => {
    //         let url2 = `${this.api}addKeywordLog?word=${addLog}`;
    //         axios.get(url2)
    //         this.rulebookData = res.data.map(r => {
    //           r.docNo = r._id
    //           r.textContent = r.content
    //           r.documentType = 'RULEBOOK'
    //           r.updatedDate = r.RulebookUpdatedDate
    //           return r
    //         });
    //         // console.log('RULEBOOK SEARCH RESULT', this.rulebookData)
    //         if(this.selectedCondition == 'OR'){
    //           if(this.rulebookData.length != 0){
    //             this.allDocuments = []
    //             this.rulebookData = res.data
    //             this.searchMemo()
    //           }
    //           else{
    //             this.searchMemo()
    //           }
    //         }else{
    //           if(this.rulebookData.length != 0){
    //             this.allDocuments = this.rulebookData
    //             this.allDocuments.sort((a, b) =>
    //               a.countViews < b.countViews ? 1 : -1
    //             );
    //             this.searchDialog = false;
    //           this.loadingAllDocs = false;
    //           }
    //           else{
    //             // this.allDocuments = [];
    //             // this.hideResult = true;
    //             this.searchMemo()
    //           }
    //         }

    //         // console.log('SEARCH RULEBOOK RESULT', res.data);
    //         // this.searchDialog = false;
    //         // this.loadingAllDocs = false;

    //       });
    //       // this.hideResult = false;
    //       //   if (this.isSearchAll) {
    //       //     this.CHANGE_SEARCH_WORD(this.searchText);
    //       //     this.CHANGE_CONDITION(this.selectedCondition);
    //       //     this.CHANGE_EXCEPT_WORD(this.exceptText);
    //       //   } else {
    //       //     this.CHANGE_SEARCH_WORD("");
    //       //     this.CHANGE_CONDITION("");
    //       //     this.CHANGE_EXCEPT_WORD("");
    //       //   }
    //     } else {
    //       // alert('clear')
    //       this.allDocuments = [];
    //       this.hideResult = true;
    //     }
    //     this.loadingAllDocs = true;
    //     // this.rulecontent = false;
    // },
    async searchMemo() {
      // alert('MEMO SEARCH FUNCTION')
      this.searchAllDocsIconAlignment = true;
      this.isMultiple = false;
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      let search = this.convert2FullKatakana(this.searchText);

      if (this.searchText != "") {
        this.loadingJapanMemo = true;
      }
      if (this.searchText) {
        let url = "";
        if (this.selectedCondition == "OR") {
          let ishiragana = moji(search)
            .filter("HG")
            .toString();
          let iskatakana = moji(search)
            .filter("KK")
            .toString();
          let hiragana = "";
          let katakana = "";
          if (ishiragana) {
            hiragana = moji(this.searchText)
              .convert("HG", "KK")
              .toString();
            search = `${search}${this.title_spacer}${hiragana}`;
          } else if (iskatakana) {
            search = this.convert2FullKatakana(this.searchText);
            katakana = moji(search)
              .convert("KK", "HG")
              .toString();
            search = this.convert2FullKatakana(search);
            katakana = this.convert2FullHiragana(katakana);
            search = `${search}${this.title_spacer}${katakana}`;
          } else {
            search = this.convert2FullKatakana(this.searchText);
          }
          //-----SMALL AND BIG ALPHA NUMERIC CHARACTERS CND
          let numSmall = moji(search)
            .filter("HE")
            .toString();
          let numBig = moji(search)
            .filter("ZE")
            .toString();

          if (search.match(/[0-9]/g) || search.match(/[０-９]/g)) {
            if (numSmall) {
              numSmall = moji(search)
                .convert("HE", "ZE")
                .toString();
              search = `${search}${this.title_spacer}${numSmall}`;
            } else if (numBig) {
              numBig = search = `${search}${this.title_spacer}${moji(search)
                .convert("ZE", "HE")
                .toString()}`;
            }
          }
          //-----HALF AND FULL WIDTH  CHARACTERS

          let fullKatakana = moji(search)
            .filter("ZK")
            .toString();
          let halfKatakana = moji(search)
            .filter("HK")
            .toString();
          if (fullKatakana) {
            fullKatakana = moji(search)
              .convert("ZK", "HK")
              .toString();
            search = `${search}${this.title_spacer}${fullKatakana}`;
          } else if (halfKatakana) {
            halfKatakana = moji(search)
              .convert("HK", "ZK")
              .toString();
            search = `${search}${this.title_spacer}${halfKatakana}`;
          }

          url = `${this.api}search_memo/or?search=${search}&except=${this.exceptText}`;
        } else {
          if (search.match(/[０-９]/g)) {
            search = `${moji(search)
              .convert("ZE", "HE")
              .toString()}`;
          } else if (search.match(/平均GL/g)) {
            url = `${this.api}search_memo/and?search=平均ＧＬ&except=${this.exceptText}`;
          } else {
            url = `${this.api}search_memo/and?search=${search}&except=${this.exceptText}`;
          }
          // url = `${this.api}search_memo/and?search=${search}&except=${this.exceptText}`;
        }
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        await axios
          .get(url)
          .then((res) => {
            this.memoData = res.data
              .map((r) => {
                r.documentType = "JAPANMEMO";
                r.updatedDate = r.memoUpdatedDate;
                // r.textContent = r.content
                return r;
              })

              .filter((rec1) => {
                // 2022-02-02 tanskie on going ( DONE Filter According to LoadYear)
                return rec1.memoUpdatedDate != "Invalid Date";
              })
              .filter((rec2) => rec2.memoUpdatedDate.includes("-"))
              .filter((rec3) => {
                if (
                  moment(rec3.memoUpdatedDate).isSameOrAfter(
                    this.loadYear[0]
                  ) &&
                  moment(rec3.memoUpdatedDate).isSameOrBefore(this.loadYear[1])
                ) {
                  return rec3;
                }
              });

            if (this.selectedCondition == "OR") {
              // console.log('MEMO DATA SEARCH RESULT',this.memoData)

              if (this.memoData.length > 0) {
                this.allDocuments = this.memoData.concat(this.rulebookData);
                this.searchDialog = false;
                this.loadingAllDocs = false;
              } else {
                if (
                  this.rulebookData.length == 0 &&
                  this.memoData.length == 0
                ) {
                  this.allDocuments = [];
                  // this.hideResult = true;
                  // this.searchDialog = false;
                  // this.loadingAllDocs = false;
                }
              }
            } else {
              ////AND////
              // console.log('MEMO DATA SEARCH RESULT',this.memoData)

              if (this.memoData.length > 0) {
                // this.allDocuments = this.memoData
                this.allDocuments = this.memoData.concat(this.rulebookData);
                this.searchDialog = false;
                this.loadingAllDocs = false;
              } else {
                if (
                  this.rulebookData.length == 0 &&
                  this.memoData.length == 0
                ) {
                  this.allDocuments = [];
                  // this.hideResult = true;
                  // this.searchDialog = false;
                  // this.loadingAllDocs = false;
                }
              }
              this.searchDialog = false;
              this.memoData.sort(function(a, b) {
                return ("" + a.countViews).localeCompare(b.countViews);
              });
              this.loadingAllDocs = false;
              if (this.isSearchAll) {
                this.CHANGE_SEARCH_WORD(this.searchText);
                this.CHANGE_CONDITION(this.selectedCondition);
                this.CHANGE_EXCEPT_WORD(this.exceptText);
              } else {
                this.CHANGE_SEARCH_WORD("");
                this.CHANGE_CONDITION("");
                this.CHANGE_EXCEPT_WORD("");
              }
            }
          })
          .catch((e) => {
            console.log(e); // catch error - Elmer
          });
      } else {
        this.allDocuments = [];
      }
    },

    promiseGetMultipleSearchWords_Rulebook(
      searchKeyWord = "",
      exceptWord = ""
    ) {
      // 2023-03-15
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        const url = `${this.api}search/multiple/?search1=${searchKeyWord}&exceptWord=${exceptWord}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    promiseGetMultipleSearchWords_JapanMemo(
      searchKeyWord = "",
      exceptWord = ""
    ) {
      // 2023-03-15
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        const url = `${this.api}japanMemo/search/multiple/?search1=${searchKeyWord}&exceptWord=${exceptWord}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    promiseGetMultipleSearchWords_ShiyoManual(
      searchKeyWord = "",
      exceptWord = ""
    ) {
      // 2023-03-15
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        const url = `${this.api}shiyo/search/multiple/?search1=${searchKeyWord}&exceptWord=${exceptWord}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    async onMultipleSearch() {
      // 2023-03-15

      try {
        this.pageNumber = 1;
        this.isMultiple = true;
        this.searchAllDocsIconAlignment = true;
        this.loadingAllDocs = true;
        if (!this.composing) {
          if (this.searchText) {
            let rulebookRecords = await this.promiseGetMultipleSearchWords_Rulebook(
              this.searchText,
              this.exceptText
            );

            rulebookRecords = rulebookRecords.map((r) => {
              r.docNo = r._id;
              r.textContent = r.content;
              r.documentType = "RULEBOOK";
              r.updatedDate = r.RulebookUpdatedDate;
              return r;
            });

            let japanMemoRecords = await this.promiseGetMultipleSearchWords_JapanMemo(
              this.searchText,
              this.exceptText
            );
            japanMemoRecords = japanMemoRecords.map((r) => {
              r.updatedDate = r.memoUpdatedDate;
              r.documentType = "JAPANMEMO";
              return r;
            });

            let shiyoManualRecords = await this.promiseGetMultipleSearchWords_ShiyoManual(
              this.searchText,
              this.exceptText
            );
            shiyoManualRecords = shiyoManualRecords.map((r) => {
              r._id = r.id;
              r.countViews = r.CountViews ? r.CountViews : 0;
              r.method = r.framework;
              r.title = r.productName;
              r.docNo = r.shiyoNumber;
              r.updatedDate =
                r.updatedDate != undefined
                  ? r.updatedDate.slice(0, 10)
                  : "新規作成";
              r.textContent =
                r.textContent == " Attachment Only " ||
                r.textContent == "Attachment Only " ||
                r.textContent == " Attachment Only" ||
                r.textContent == "Attachment Only"
                  ? "添付ファイルのみとなります。"
                  : r.textContent;
              return r;
            })
            .filter(r=>{
              return !r.deleted
            });
            this.allDocuments = _.orderBy(
              [...rulebookRecords, ...japanMemoRecords, ...shiyoManualRecords],
              ["updatedDate"],
              ["desc"]
            ).map(rec=>{
              this.$set(rec,'showLine',false)
              return rec
            });

            this.isMultiple = false;
            this.loadingAllDocs = false;
          } else {
            alert("Please provide search word..");
            this.allDocuments = [];
            this.isMultiple = false;
            this.loadingAllDocs = false;
          }
        }
      } catch (err) {
        console.log(err.message);
        alert(
          "Error fetching data from multiple condition, Please try again.."
        );
        this.allDocuments = [];
        this.isMultiple = false;
        this.loadingAllDocs = false;
      }
    },

    onMultipleSearch1() {
      // backup old 2023-03-15
      this.pageNumber = 1; // 2022-02-03
      if (!this.composing) {
        this.searchAllDocsIconAlignment = true;
        this.isMultiple = true;
        let search = this.convert2FullKatakana(this.searchText);
        if (this.searchText != "") {
          this.loadingAllDocs = true;
        }
        if (this.searchText) {
          let tmpDataFrom = moment(`${moment().format("YYYY-MM-DD")}`)
            .subtract(1, "years")
            .format("YYYY-MM-DD");
          let tmpDateTo = moment().format("YYYY-MM-DD");

          axios.defaults.headers.common["x-api-key"] =
            "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
          let url = "";
          let url2 = "";
          let url3 = ""; // 2023-02-11
          // url = `${this.api}search_memo/multi?search=${search}&except=${this.exceptText}`;
          // Rulebook
          url = `${this.api}get/multiple_rulesNew2?search=${search}&except=${this.exceptText}`;
          axios
            .get(url)
            .then((res) => {
              if (res.data.responseData) {
                url2 = `${this.api}japanMemo/search_memo_New/multi?search=${search}&except=${this.exceptText}&dateFrom=${tmpDataFrom}&dateTo=${tmpDateTo}`;
                axios
                  .get(url2)
                  .then((res1) => {
                    url3 = `${this.api}shiyo/search/multi_New?search=${search}&except=${this.exceptText}`; // 2023-02-11

                    axios
                      .get(url3)
                      .then((res3) => {
                        let regexText = new RegExp(
                          res.data.searchArr.join("|").toString(),
                          "g"
                        );
                        console.log("line1558", res.data.responseData);
                        console.log("line1559", res1.data.responseData);

                        let ruleBookData = res.data.responseData.map((r) => {
                          r.docNo = r._id;
                          r.textContent = r.content;

                          r.documentType = "RULEBOOK";
                          r.updatedDate = r.RulebookUpdatedDate;
                          return r;
                        });

                        let japanMemoData = res1.data.responseData.map((r) => {
                          r.updatedDate = r.memoUpdatedDate;
                          r.textContent =
                            r.textContent != undefined ? r.textContent : "-";
                          r.documentType = "JAPANMEMO";
                          return r;
                        });

                        // 2023-02-11
                        let shiyoManualData = res3.data.map((rec) => {
                          return {
                            id: rec.id,
                            _id: rec.id,
                            documentType: rec.documentType,
                            countViews: rec.CountViews ? rec.CountViews : 0,
                            method: rec.framework, // WAKU / JIKU
                            title: rec.productName,
                            roomPart: rec.roomPart,
                            docNo: rec.shiyoNumber,
                            specifications: rec.specifications,
                            updatedDate:
                              rec.updatedDate != undefined
                                ? rec.updatedDate.slice(0, 10)
                                : "新規作成",
                            FavoritesCount: rec.FavoritesCount
                              ? rec.FavoritesCount
                              : 0,
                            textContent:
                              rec.textContent == " Attachment Only " || // 2022-08-16
                              rec.textContent == "Attachment Only " ||
                              rec.textContent == " Attachment Only" ||
                              rec.textContent == "Attachment Only"
                                ? "添付ファイルのみとなります。"
                                : rec.textContent,
                          };
                        });

                        console.log("line1574", regexText);
                        console.log("ruleBookData", ruleBookData);
                        console.log("japanMemoData", ruleBookData);
                        console.log("shiyoManualData", shiyoManualData);

                        let mergeData = [
                          ...ruleBookData,
                          ...japanMemoData,
                          ...shiyoManualData,
                        ]; // 2023-02-11

                        let mapTextContentData = mergeData.map((rec) => {
                          rec.textContent = rec.textContent.replace(
                            regexText,
                            function(str) {
                              return `<span style="background-color:yellow"><b>${str}</b></span>`;
                            }
                          );
                          return rec;
                        });

                        console.log("line1588", mapTextContentData);

                        this.allDocuments = _.orderBy(
                          mapTextContentData,
                          ["updatedDate"],
                          ["desc"]
                        ).map(rec=>{
                          this.$set(rec,'showLine',false)
                          return rec
                        });
                        this.isMultiple = false;
                        this.loadingAllDocs = false;
                      })
                      .catch((err3) => {
                        console.log(err3.message);
                      });
                  })
                  .catch((err2) => {
                    console.log(err2.message);
                  });
              }
            })
            .catch((err1) => {
              console.log(err1.message);
              alert(
                `Keyword ${this.searchText} is not yet registered, Please confirm to administrator`
              );
              this.allDocuments = [];
              this.loadingAllDocs = false;
            });

          // .then(() => {
          //   this.onRulebookMultipleSearch()
          // });
        } else {
          this.allDocuments = [];
        }
      }
    },
    onRulebookMultipleSearch() {
      if (!this.composing) {
        this.searchAllDocsIconAlignment = true;
        this.isMultiple = true;
        let search = this.convert2FullKatakana(this.searchText);
        this.searchLists = [];
        if (this.searchText != "") {
          this.loadingAllDocs = true;
        }
        if (this.searchText) {
          axios.defaults.headers.common["x-api-key"] =
            "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
          let url = "";
          url = `${this.api}get/multiple_rules2?search=${search}&except=${this.exceptText}`;
          axios.get(url).then((res) => {
            if (typeof res.data != "string") {
              this.loadingAllDocs = false;
              this.rulebookData = res.data;
              this.allDocuments = this.rulebookData.concat(this.memoData);
              this.allDocuments.sort((a, b) =>
                a.countViews < b.countViews ? 1 : -1
              );
              // this.onMultipleSearch()
            } else {
              this.loadingAllDocs = true;
              // this.selectedCondition = "OR";
              this.selectedCondition = "MULTI";
              this.onSearch();
              this.isMultiple = false;
            }
            this.approvedDialog = false;
            this.searchDialog = false;
          });
          this.hideResult = false;
        } else {
          this.rulebookData = [];
          this.hideResult = true;
        }
      }
    },
    clearSearch() {
      this.isMultiple = false;
      this.searchAllDocsIconAlignment = false;
      this.isFavorite = false;
      this.loadingJanMeo = true;
      this.sortByCategoryASC = false;
      this.sortByCategoryDES = false;
      this.sortByDateASC = false;
      this.sortByDateDES = false;
      this.sortByFavoriteASC = false;
      this.sortByFavoriteDES = false;
      //   this.loadAllMemo();
      // this.loadAllDocuments()
      this.loadAllDocumentsByYear(); // 2022-01-21
      this.getUserFavorites();
      this.searchText = "";
      this.exceptText = "";

      this.searchTitle = ""; // 2023-06-01
      this.searchDocNo = ""; // 2023-06-01
    },
    async getUserFavorites() {
      ////====GETTING THE USER'S JAPAN MEMO FAVORITES====////
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      this.loadingAllDocs = true;
      let toGet = {
        userId: this.userInfo.id,
      };
      let sekkeiFavList = "";
      let url = `${this.api}getFavorites/memo`;
      let urlSekkei = `${this.api}getFavorites/sekkei`;
      await axios.post(urlSekkei, toGet).then((res) => {
        if (res.data) {
          sekkeiFavList = res.data;
        }
      });
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      await axios
        .post(url, toGet)
        .then((res) => {
          // console.log('FAV LIST MEMO',res.data);
          this.memoFavList = res.data;

          axios.defaults.headers.common["x-api-key"] =
            "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
          this.loading2 = true;

          let url2 = `${this.api}getFavorites`;

          axios
            .post(url2, toGet)
            .then((res2) => {
              this.rulebookFavList = res2.data;

              axios.defaults.headers.common["x-api-key"] =
                "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
              this.loading2 = true;

              let url3 = `${this.api}shiyo/getFavorites`;
              axios
                .post(url3, toGet)
                .then((res3) => {
                  this.shiyoFavList = res3.data;

                  this.favoriteList = [
                    ...this.rulebookFavList,
                    ...this.memoFavList,
                    ...this.shiyoFavList,
                    ...sekkeiFavList,
                  ];
                })
                .catch((err3) => {
                  console.log(err3.message);
                });
            })
            .catch((err2) => {
              console.log(err2.message);
            });
        })
        .catch((err1) => {
          console.log(err1.message);
        });
    },

    async filterFavorites() {
      this.sortByFavoriteASC = false;

      this.pageNumber = 1;

      await this.getUserFavorites();
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      this.allDocuments = [];
      let list = [];
      let toGet = {
        userId: this.userInfo.id,
      };
      if (this.isFavorite) {
        if (this.favoriteList.length > 0) {
          // 2023-02-09 ( Shiyo Manual Favorite List )
          for (let i = 0; i < this.shiyoFavList.length; i++) {
            let url3 = `${this.api}shiyo/getDetailsFavorite?id=${this.shiyoFavList[i].id}&productName=${this.shiyoFavList[i].productName}`;
            await axios.get(url3).then((res) => {
              if (res.data) {
                res.data.updatedDate =
                  res.data.updataDate != undefined
                    ? res.data.updatedDate.slice(0, 10)
                    : "新規作成";
                res.data.countViews = res.data.CountViews
                  ? res.data.CountViews
                  : 0;
                res.data.FavoritesCount = res.data.FavoritesCount
                  ? res.data.FavoritesCount
                  : 0;
                res.data.docNo = res.data.shiyoNumber;
                res.data.title = res.data.productName;
                res.data.method = res.data.framework
                  ? res.data.framework
                  : null; // 2023-03-08
                this.allDocuments.push(res.data);
                this.allDocuments = this.allDocuments.sort((a, b) => {
                  return a.updatedDate > b.updatedDate ? -1 : 1;
                });
                this.tmpFavoritesArr.push(res.data);
              }
            });
          }

          // ===================== Japan Memo Favorite List ============================== //
          for (let i = 0; i < this.memoFavList.length; i++) {
            let url1 = `${this.api}getDetailsFavorite/memo?id=${this.memoFavList[i].id}&docNo=${this.memoFavList[i].docNo}`;
            await axios.get(url1).then((res) => {
              // console.log('JAPAN MEMO FAVORITES',res.data)
              if (res.data) {
                // 2022-01-22 (tanskie)
                res.data.updatedDate = res.data.memoUpdatedDate;
                this.allDocuments.push(res.data);
                this.allDocuments = this.allDocuments.sort((a, b) => {
                  // 2023-02-09
                  return a.updatedDate > b.updatedDate ? -1 : 1;
                });
                this.tmpFavoritesArr.push(res.data); // 2022-01-22
              }
            });
          } // END Loop

          // ====================== Rulebook Favorite List ================================== //
          let url2 = `${this.api}getFavorites`;
          axios.post(url2, toGet).then((res) => {
            //  console.log(res.data)
            list = res.data;
            if (list.length > 0) {
              for (let i = 0; i < list.length; i++) {
                let url4 = `${this.api}getDetailsFavorite?id=${list[i]}`;

                axios.get(url4).then((res) => {
                  // console.log('RULE BOOK FAVORITES',res.data)
                  res.data.updatedDate = res.data.RulebookUpdatedDate;

                  res.data.docNo = res.data._id;

                  this.allDocuments.push(res.data);

                  this.allDocuments = this.allDocuments.sort((a, b) => {
                    // 2023-02-09
                    return a.updatedDate > b.updatedDate ? -1 : 1;
                  });

                  this.tmpFavoritesArr.push(res.data); // 2022-01-22
                  this.loadingAllDocs = false;
                });
              } // END Loop
            } else {
              this.loadingAllDocs = false;
            }
          });
        } else {
          this.loadingAllDocs = false;
        }
      } else {
        this.clearSearch();
      }
    },
    loadAllDocuments() {
      this.loadingAllDocs = true;

      let url = `${this.api}allDocuments/getAllDocuments`;

      axios.get(url).then((res) => {
        // console.log(res.data)
        this.allDocuments = res.data;
        this.allDocuments.sort((a, b) => {
          // return moment(b.updatedDate) - moment(a.updatedDate)
          return b.updatedDate.localeCompare(a.updatedDate);
        });

        this.loadingAllDocs = false;
      });
    },
    isFavIcon(data) {
      if (data.documentType == "RULEBOOK") {
        let isFavDoc = false;
        if (this.favoriteList.includes(data._id)) {
          isFavDoc = true;
        }
        if (isFavDoc) {
          return "mdi-star";
        } else {
          return "mdi-star-outline";
        }
      }

      // 2023-02-09
      else if (data.documentType == "SHIYO") {
        let isFavDoc = false;
        this.favoriteList.map((r) => {
          if (r.id == data.id) {
            isFavDoc = true;
          }
        });
        if (isFavDoc) {
          return "mdi-star";
        } else {
          return "mdi-star-outline";
        }
      } else {
        let isFavDoc = false;
        this.favoriteList.map((r) => {
          if (r.id == data.id && r.category == data.category) {
            isFavDoc = true;
          }
        });
        if (isFavDoc) {
          return "mdi-star";
        } else {
          return "mdi-star-outline";
        }
      }
    },
  },

  computed: {
    isFav() {
      if (this.isFavorite) {
        return "mdi-star";
      } else {
        return "mdi-star-outline";
      }
    },
    Width() {
      if (
        this.$vuetify.breakpoint.name == "md" ||
        this.$vuetify.breakpoint.name == "lg" ||
        this.$vuetify.breakpoint.name == "xl"
      ) {
        return "width:60%";
      } else return "width:98%";
    },
    screenSize() {
      return this.$vuetify.breakpoint.height;
    },
    filteredDocuments() {
      let data = [];

      data = this.allDocuments.filter((r) => {
        if (
          this.selectedCategory.includes(r.category) ||
          (this.selectedCategory.includes(r.documentType) &&
            !this.selectedCategory.includes("ALL"))
        ) {
          return r;
        } else if (this.selectedCategory.includes("ALL")) {
          return r;
        }
      });

      // if( this.sortByFavoriteASC == true ) {  // OLD 2022-06-13
      //    data = this.allDocuments;
      // } else{
      //    data = this.allDocuments.filter((r) => {
      //     if(this.selectedCategory.includes(r.category) || this.selectedCategory.includes(r.documentType) &&
      //         !this.selectedCategory.includes("ALL")){
      //       return r
      //     }else if(this.selectedCategory.includes("ALL")){
      //       return r
      //     }
      //   })
      // }

      // .filter((r) => {
      //   if (
      //     this.selectedCategory.includes("Ippan") &&
      //     !this.selectedCategory.includes("ALL")
      //   ) {
      //     return r.Ippan == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   if (
      //     this.selectedCategory.includes("YumeNoIe") &&
      //     !this.selectedCategory.includes("ALL")
      //   ) {
      //     return r.YumeNoIe == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   if (
      //     this.selectedCategory.includes("Icube") &&
      //     !this.selectedCategory.includes("ALL")
      //   ) {
      //     return r.Icube == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   if (
      //     this.selectedCategory.includes("Ismart") &&
      //     !this.selectedCategory.includes("ALL")
      //   ) {
      //     return r.Ismart == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   if (
      //     this.selectedCategory.includes("Ismile") &&
      //     !this.selectedCategory.includes("ALL")
      //   ) {
      //     return r.Ismile == true;
      //   } else {
      //     return r;
      //   }
      // }).filter((r) => {
      //   if (
      //     this.selectedCategory.includes("G") &&
      //     !this.selectedCategory.includes("ALL")
      //   ) {
      //     return r.G == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   if (
      //     this.selectedCategory.includes("F") &&
      //     !this.selectedCategory.includes("ALL")
      //   ) {
      //     return r.F == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   if (
      //     this.selectedCategory.includes("A") &&
      //     !this.selectedCategory.includes("ALL")
      //   ) {
      //     return r.A == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   if (
      //     this.selectedCategory.includes("B") &&
      //     !this.selectedCategory.includes("ALL")
      //   ) {
      //     return r.B == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   if (
      //     this.selectedCategory.includes("F2-TD") &&
      //     !this.selectedCategory.includes("ALL")
      //   ) {
      //     return r["F2-TD"] == true;
      //   } else {
      //     return r;
      //   }
      // });

      // 2022-05-23
      // if (this.sortByFavoriteASC) {
      //   data.sort((a, b) => (a.FavoritesCount > b.FavoritesCount ? 1 : -1));
      //   //  data = _.uniqBy( data, 'docNo').sort( (a, b) => (a.FavoritesCount > b.FavoritesCount ? 1 : -1) )
      // }
      // if (this.sortByFavoriteDES) {
      //   data.sort((a, b) => (a.FavoritesCount < b.FavoritesCount ? 1 : -1));
      // }

      // if(this.selectedCategory == 'ALL'){
      //   data = this.allDocuments
      // }else{
      //   data = this.allDocuments.filter(r => {
      //     return r.category == this.selectedCategory
      //   })
      // }

      // if (this.sortByCategoryASC) {
      //   data.sort((a, b) => (a.category > b.category ? 1 : -1));
      // }
      // if (this.sortByCategoryDES) {
      //   data.sort((a, b) => (a.category < b.category ? 1 : -1));
      // }

      // 2022-05-23
      // if (this.sortByDateASC) {
      //   data.sort((a, b) => {
      //     let key1 = a.updatedDate;
      //     let key2 = b.updatedDate;
      //     if (key1 < key2) {
      //       return -1;
      //     } else if (key1 == key2) {
      //       return 0;
      //     } else {
      //       return 1;
      //     }
      //   });
      // }
      // if (this.sortByDateDES) {
      //   data.sort(function (a, b) {
      //     if (a.updatedDate > b.updatedDate) return -1;
      //     if (a.updatedDate < b.updatedDate) return 1;
      //     return 0;
      //   });
      // }
      return data;
    },

    paginationLength() {
      let l = this.filteredDocuments.length,
        s = this.intRowNoAllDocs;
      return Math.ceil(l / s);
    },
    paginatedMemo() {
      const start = (this.pageNumber - 1) * this.intRowNoAllDocs,
        end = start + this.intRowNoAllDocs;
      return this.filteredDocuments.slice(start, end);
    },
    customClearIcon() {
      return "mdi-close-circle-outline";
    },
  },
};
</script>
<style scoped>
.menuListNew {
  display: inline-block; /* Inline box definition */
  content: "NEW"; /* Character to be displayed */
  background: #b64009; /* Background color */
  color: #ffffff; /* Font color */
  font-weight: bold; /* Bold */
  font-size: 10pt; /* Base character size */
  letter-spacing: -1px; /* Character spacing */
  line-height: 1; /* 1 line height */
  vertical-align: middle; /* Reference Height position */
  margin: 0; /* No outer margin * /
  padding         : 2px;                 /* Inner margin at inversion */
  transform: scale (0.9) /* Change character size */ translateX (28%)
    /* Horizontal Position adjustment */ translateY (66%); /* Vertical position adjustment */
  /* Blink animation specification */
  animation: newAnime1 0.7s infinite alternate;
}

@keyframes newAnime1 {
  0% {
    color: #b64009; /* Text color when blinking */
    background: #ffffff; /* Background color when blinking */
  }
  100% {
    color: #ffffff; /* Text color when blinking */
    background: #b64009; /* Background color when blinking */
  }
}

.menuListUpdate {
  display: inline-block; /* Inline box definition */
  content: "UPDATE"; /* Character to be displayed */
  background: #3864dd; /* Background color */
  color: #ffffff; /* Font color */
  font-weight: bold; /* Bold */
  font-size: 10pt; /* Base character size */
  letter-spacing: -1px; /* Character spacing */
  line-height: 1; /* 1 line height */
  vertical-align: middle; /* Reference Height position */
  margin: 0; /* No outer margin * /
  padding         : 2px;                 /* Inner margin at inversion */
  transform: scale (0.9) /* Change character size */ translateX (28%)
    /* Horizontal Position adjustment */ translateY (66%); /* Vertical position adjustment */
  /* Blink animation specification */
  animation: newAnime2 0.7s infinite alternate;
}

@keyframes newAnime2 {
  0% {
    color: #3864dd; /* Text color when blinking */
    background: #ffffff; /* Background color when blinking */
  }
  100% {
    color: #ffffff; /* Text color when blinking */
    background: #3864dd; /* Background color when blinking */
  }
}

#title_ellipsis {
  display: inline-block;
  width: 93%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.button {
  /* background-color: #01579b; */
  border: none;
  cursor: pointer;
  /* color: white; */
  color: black;
  padding: 6px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.title {
  display: inline-block;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px !important;
}
.viewed {
  background-color: white;
  color: purple;
}
.viewed {
  background-color: white;
  color: purple;
}
.v-input__control {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  border: 1px solid rgb(194, 194, 194);
  border-bottom-left-radius: 0%;
  border-top-left-radius: 0%;
  border-left: 0ch;
}
.theme--light.v-text-field--solo > .v-input__control > .v-input__slot:hover {
  border: 1px solid rgb(20, 130, 233);
}
/* .v-application p {
  margin-bottom: 30px;
  
  border: 1px solid rgb(194, 194, 194);
} */
.search-label {
  padding-top: 3px;
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: 0%;
}
</style>
