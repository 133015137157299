import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";
// import md5 from "md5-hex"

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("../views/Login.vue"),
    },
    {
      path: "/",
      name: "home",
      component: Home,
      // redirect: 'https://documentsearch.hrd-s.com/' ,
      props: true,
    },
    {
      path: "/shiyoDocument/:id",
      name: "shiyoDocument",
      component: () => import("../views/ShiyoLink.vue"),
      props: true,
    },

    {
      // 2022-05-23
      path: "/shiyoDocumentViewPDF/:id",
      name: "shiyoDocumentViewPDF",
      component: () => import("../views/ShiyoPDFViewerLink.vue"),
      props: true,
    },

    {
      path: "/TinyEditor/:id",
      name: "TinyEditor",
      component: () => import("../views/TinyEditor.vue"),
      props: true,
    },
    {
      path: "/document/:id",
      name: "document",
      component: () => import("../views/Link.vue"),
      props: true,
    },
    {
      // 2022-03-05
      path: "/japan_memo/:id",
      name: "japan_memo",
      component: () => import("../views/JapanMemoLink.vue"),
      props: true,
    },
    {
      // 2023-12-27
      path: "/sekkei_kikaku/:id",
      name: "sekkei_kikaku",
      component: () => import("../views/SekkeiKikakuLink.vue"),
      props: true,
    },
    {
      // 2023-12-27
      path: "/sekkei_gyoumu/:id",
      name: "sekkei_gyoumu",
      component: () => import("../views/SekkeiGyoumuLink.vue"),
      props: true,
    },
    {
      // 2022-09-29
      path: "/CAD_Request/:id",
      name: "CAD_Request",
      component: () => import("../views/CADRequestPdfViewer.vue"),
      props: true,
    },
    {
      // 2022-10-11
      path: "/ShiyoViewImage/:id",
      name: "ShiyoViewImage",
      component: () => import("../views/ShiyoViewImage.vue"),
      props: true,
    },
    {
      path: "/synonym",
      name: "synoym",
      component: () => import("../views/Synonym.vue"),
    },
    {
      path: "/synonym2",
      name: "synonym2",
      component: () => import("../views/Synonym2.vue"),
    },
    {
      path: "/usermanual",
      name: "usermanual",
      component: () => import("../views/UserManual.vue"),
    },
    {
      path: "/systemlogs",
      name: "systemlogs",
      component: () => import("../views/SystemLogs.vue"),
    },
    {
      path: "/userlogs",
      name: "userlogs",
      component: () => import("../views/UserLogs.vue"),
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("../views/Dashboard.vue"),
    },
    {
      path: "/test",
      name: "test",
      component: () => import("../views/Test.vue"),
    },
    {
      path: "/users",
      name: "users",
      component: () => import("../views/Users.vue"),
    },
    {
      // 2022-09-30
      path: "/MasterCADRequestMaterials",
      name: "MasterCADRequestMaterials",
      component: () => import("../views/MasterCADRequestMaterials.vue"),
    },
    {
      path: "/feedback2",
      name: "feedback",
      component: () => import("../views/Feedback.vue"),
    },
    // Real Feedback
    {
      path: "/feedback/:id",
      name: "feedback2",
      component: () => import("../views/Feedback2.vue"),
    },
    {
      path: "/feedback/",
      name: "feedback22",
      component: () => import("../views/Feedback2.vue"),
    },
    {
      path: "/403",
      name: "forbidden",
      component: () => import("../views/Forbidden.vue"),
    },
    {
      path: "/sekkei_document/:id",
      name: "Sekkei Document New Tab",
      component: () => import("../views/SekkeiNewTab.vue"),
      meta: { title: "Sekkei No.  " },
    },
    {
      path: "/sekkei_document_history/:id",
      name: "Sekkei Document History",
      component: () => import("../views/SekkeiNewTabHistory.vue"),
      meta: { title: "Sekkei No.  " },
    },
    {
      path: "/shiyoHistoryComparing/:id",
      name: "Shiyo Comparing History",
      component: () => import("../views/ShiyoComparingHistory.vue"),
      meta: { title: "Shiyo No.  " },
    },
    // {
    //   path: "/sekkeiKikakuNewTab/:id",
    //   name: "Sekkei Kikaku",
    //   component: () => import("../views/DesignPlanningNewTab.vue"),
    //   meta: { title: "Doc No.  " },
    // },
    {
      path: "/sekkeiGyoumuComparing/:data_id",
      name: "Sekkei Gyoumu History Compare",
      component: () => import("../views/SekkeiGyoumuComparingHistory.vue"),
      meta: { title: "Doc No.  " },
    },
    {
      path: "/rulebookComparing/:controlNumber",
      name: "RuleBook History Compare",
      component: () => import("../views/RuleBookComparingHistory.vue"),
      meta: { title: "Doc No.  " },
    },
    {
      path: "/designNewTab/:id",
      name: "Sekkei Kikaku",
      component: () => import("../views/DesignNewTab.vue"),
      meta: { title: "Doc No.  " },
    },
    {
      path: "/SekkeiPrint",
      name: "Sekkei Print",
      component: () => import("../views/SekkeiPrint.vue"),
      meta: { title: "Doc No.  " },
    },
    {
      path: "/KikakuPrint",
      name: "Kikaku Print",
      component: () => import("../views/KikakuPrint.vue"),
      meta: { title: "Doc No.  " },
    },
    {
      path: "/SecretPageJom",
      name: "Secret Page",
      component: () => import("../components/SecretPageForSearching.vue"),
      // meta: { title: "Doc No.  " },
    },
    {
      path: "/redirect",
      name: "Redirect",
      component: () => import("../views/Redirect.vue"),
    },
    {//NOTE: added route for requestMonitoring system 2024-03-14
      path: '/requestMonitoring',
      beforeEnter() { location.href = 'https://requestmonitoring.ihs-w.com/' }
    },
    {
      path: '/DSSPDFViewer',
      name: 'DSSPDFViewer',
      component: () => import("../views/DSSPDFViewer.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.path !== "/login" && to.path != "/usermanual" && to.path != "/403") {
    // alert('not')
    // if (to.path === "/" && Object.keys(to.query).length > 0) {
    //   // The path is "/" and has query parameters
    //   console.log("Path / has query parameters:", to.query);
    //   // Handle the scenario as needed
    //   next();
    // }

    if (
      typeof store.state.token == "string" ||
      (to.path == "/" && Object.keys(to.query).length > 0) ||
      to.path == "/redirect"
    ) {
      // console.log('TOKEN1', store.state.token)

      next();
    } else {
      //  if(from.path != '/login'){
      next("login");
      //  }
    }
  } else if (to.path === "/login" && typeof store.state.token == "string") {
    // console.log("TOKEN2", store.state.token);
    next(from.path);
  } else {
    next();
  }
});

export default router;
