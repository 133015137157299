<template>
    <div >
        <v-dialog v-model="isFileDownloadingProps" persistent max-width="300px">
            <v-card >
                <v-card-text >
                    <div class="text-center">
                        <v-progress-circular
                            class="mt-7"
                            :size="100"
                            color="primary"
                            indeterminate
                            width="7"
                        ></v-progress-circular>
                        <h2 style="font-family:Arial;margin-top:20px">
                             {{ isFileOpeningProps ? 'Please wait, while Opening file..' : 'Please wait, while downloading file..'}} 
                        </h2>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    props:{
        isFileDownloadingProps: {
            type:Boolean,
            required: true
        },
        isFileOpeningProps: {  // 2023-03-22
            type: Boolean
        }
    },
    data(){
        return{
            
        }
    }
}
</script>

<style scoped>


</style>