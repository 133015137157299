<template>
  <!-- NOTE: disable transation in this page -->
  <div translate="no">
    <v-layout align-center justify-center v-if="loadingPage">
      <a-spin class="mt-2" tip="Loading..." />
    </v-layout>
    <v-card v-else class="ma-2 pa-2" flat style="background-color:#f6f5f5;">
      <!-- <pre> {{listData}} </pre> -->
      <v-row dense>
        <v-col cols="12" sm="6" md="4">
          <a-input
            addon-before="Search"
            style="width: 100%"
            v-model="toSearchJWW"
            placeholder="Search file"
            append-icon="search"
            outlined
          ></a-input>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <!-- <v-layout row wrap> -->
            <span style="font-weight: bold" class="mt-1 mr-1 ml-3">項目:</span>
            <a-select v-model="Item" :style="WidthFile" id="v-step-f2">
              <a-select-option
                v-for="item in OptItem"
                :key="item"
                v-value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          <!-- </v-layout> -->
        </v-col>
        <!-- <v-col cols="12" sm="6" md="4">
              <v-layout row wrap>
                <span style="font-weight: bold" class="mt-1 mr-1">品種:</span>
              <a-select v-model="Variety" :style="WidthFile" id="v-step-f2">
                <a-select-option
                  v-for="item in OptVariety"
                  :key="item"
                  v-value="item"
                  >{{ item }}</a-select-option
                > </a-select>
              </v-layout>
            </v-col> -->

        <v-col cols="12" sm="6" md="4">
          <!-- <v-layout row wrap> -->
            <span style="font-weight: bold" class="mt-1 mr-1">構法:</span>
            <!-- {{OptConstructionMethod}} -->
            <!-- {{OptConstructionMethod}} -->
            <a-select
              v-model="selectedConstructionMethod"
              :style="WidthFile"
              id="v-step-f2"
            >
              <a-select-option
                v-for="item in OptConstructionMethod"
                :key="item"
                v-value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          <!-- </v-layout> -->
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <span style="font-weight: bold" class="mt-1 mr-1">建物タイプ:</span>
          <a-select
            id="v-step-f3"
            mode="multiple"
            v-model="Specifications"
            :style="WidthFile2"
          >
            <a-select-option
              v-for="item in OptSpecs"
              :key="item.value"
              v-value="item.value"
              >{{ item.text }}</a-select-option
            >
          </a-select>
        </v-col>

        <!-- THIS MODULE IS IMPORTED ON main.js ==> vue-json-csv -->
        <!-- 2022-02-08 -->
        <!-- <v-btn class="ml-3" style="background-color: #0f3057; color: white" small >    
            <download-csv
              name    = "jwwData.csv"
              encoding
              :data   = "jwwToExport">
              Export to CSV
              <v-icon>mdi-download</v-icon>
            </download-csv>
          </v-btn> -->
      </v-row>
    </v-card>
    <v-layout v-if="!loadingPage" class="mx-2">
      <span style="color:#0f3057; font-weight: bold; margin-right:4px">
        Total: {{ filteredJWW.length }}
      </span>
    </v-layout>

    <!-- <pre> {{filteredJWW}}</pre> -->
    <v-data-table
      v-if="!loadingPage"
      :headers="columns"
      :items="filteredJWW"
      fixed-header
      dense
      :height="$vuetify.breakpoint.height - 295"
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [50, 100, 1000, -1],
      }"
      class="mx-2"
      :page.sync="currentPage"
    >
      <template v-slot:item.JWWFile="{ item }">
        <span
          @click="FileSrc(item.JWWFile)"
          style="cursor: pointer; color: #3ba9ed"
          >データリンク</span>
      </template>
      <template v-slot:item.DXFFile="{ item }">
        <span
          @click="FileSrc(item.DXFFile)"
          style="cursor: pointer; color: #3ba9ed"
          >データリンク</span>
      </template>

      <template v-slot:item.GrandSmart="{ item }">
        <v-icon v-if="item.GrandSmart" style="font-size: 18px;"
          >mdi-circle-outline</v-icon>
      </template>

      <template v-slot:item.HUGme="{ item }">
        <v-icon v-if="item.HUGme" style="font-size: 18px;"
          >mdi-circle-outline</v-icon>
      </template>

      <template v-slot:item.HUGmefam="{ item }">
        <v-icon v-if="item.HUGmefam" style="font-size: 18px;"
          >mdi-circle-outline</v-icon>
      </template>

      <template v-slot:item.iSmart="{ item }">
        <v-icon v-if="item.iSmart" style="font-size: 18px;"
          >mdi-circle-outline</v-icon>
      </template>

      <template v-slot:item.iCube="{ item }">
        <v-icon v-if="item.iCube" style="font-size: 18px;"
          >mdi-circle-outline</v-icon>
      </template>

      <template v-slot:item.iSmile="{ item }">
        <v-icon v-if="item.iSmile" style="font-size: 18px;"
          >mdi-circle-outline</v-icon>
      </template>

      <template v-slot:item.G="{ item }">
        <v-icon v-if="item.G" style="font-size: 18px;"
          >mdi-circle-outline</v-icon>
      </template>

      <template v-slot:item.B="{ item }">
        <v-icon v-if="item.B" style="font-size: 18px;"
          >mdi-circle-outline</v-icon>
      </template>

      <template v-slot:item.F="{ item }">
        <v-icon v-if="item.F" style="font-size: 18px;"
          >mdi-circle-outline</v-icon>
      </template>

      <template v-slot:item.A="{ item }">
        <v-icon v-if="item.A" style="font-size: 18px;"
          >mdi-circle-outline</v-icon>
      </template>
      <template v-slot:item.F2-TD="{ item }">
      <v-icon v-if="item['F2-TD']" style="font-size: 18px;">
        mdi-circle-outline
      </v-icon>
    </template>
      <template v-slot:item.YumeNoIe="{ item }">
        <v-icon v-if="item.YumeNoIe" style="font-size: 18px;"
          >mdi-circle-outline</v-icon>
      </template>
      <template v-slot:item.Ippan="{ item }">
        <v-icon v-if="item.Ippan" style="font-size: 18px;"
          >mdi-circle-outline</v-icon>
      </template>

      <!-- 2023-07-04 Deleted-->
      <!-- <template v-slot:item.Saison="{ item }">
        <v-icon v-if="item.Saison" style="font-size: 18px;"
          >mdi-circle-outline</v-icon>
      </template>
      <template v-slot:item.Briard="{ item }">
        <v-icon v-if="item.Briard" style="font-size: 18px;"
          >mdi-circle-outline</v-icon>
      </template> -->
    </v-data-table>
  </div>
</template>

<script>
const columns = [
  {
    value: "item",
    text: "項目",
    divider: true,
    width: "10%",
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    value: "variety",
    text: "品種",
    divider: true,
    width: "25%",
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    text: "部材説明",
    value: "buzai",
    divider: true,
    width: "18%",
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    text: "品番",
    value: "hinban",
    divider: true,
    width: "15%",
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    text: "JWW",
    value: "JWWFile",
    divider: true,
    width: "13%",
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    text: "DXF",
    value: "DXFFile",
    divider: true,
    width: "13%",
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    value: "constructionMethod",
    text: "構法",
    width: "75px",
    divider: true,
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    text: "グラン•スマート", // 2022-08-10
    value: "GrandSmart",
    align: "center",
    width: "85px",
    divider: true,
    class: "blue darken-4 white--text",
    sortable: false,
  },
    {
    text: "HUGme", // 2022-11-23
    value: "HUGme",
    align: "center",
    width: "85px",
    divider: true,
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    text: "HUGme fam", // 2023-03-17
    value: "HUGmefam",
    align: "center",
    width: "85px",
    divider: true,
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    text: "i-smart",
    value: "iSmart",
    align: "center",
    width: "85px",
    divider: true,
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    text: "i-cube",
    value: "iCube",
    align: "center",
    width: "85px",
    divider: true,
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    text: "I-smile", // 2022-08-10
    value: "iSmile",
    align: "center",
    width: "85px",
    divider: true,
    class: "blue darken-4 white--text",
    sortable: false,
  },
    {
    text: "グランセゾン",
    value: "G",
    width: "85px",
    divider: true,
    class: "blue darken-4 white--text",
    sortable: false,
    align: "center",
  },
    {
    text: "ブリアール",
    value: "B",
    width: "93px",
    divider: true,
    align: "center",
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    text: "セゾンF",
    value: "F",
    width: "85px",
    divider: true,
    class: "blue darken-4 white--text",
    align: "center",
    sortable: false,
  },
  {
    text: "セゾンA",
    value: "A",
    width: "85px",
    divider: true,
    align: "center",
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    text: "セゾンF2-TD", // 2023-03-17
    value: "F2-TD",
    align: "center",
    width: "85px",
    divider: true,
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    text: "夢の家", 
    value: "YumeNoIe",
    align: "center",
    width: "85px",
    divider: true,
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    text: "一般", 
    value: "Ippan",
    align: "center",
    width: "90px",
    divider: true,
    class: "blue darken-4 white--text",
    sortable: false,
  },
  //2024-07-04 Deleted//
  //   {
  //   text: "セゾン(仮)", // 2023-06-28
  //   value: "Saison",
  //   align: "center",
  //   width: "75px",
  //   divider: true,
  //   class: "blue darken-4 white--text",
  //   sortable: false,
  // },

  // {
  //   text: "ブリアール(仮)", // 2023-06-28
  //   value: "Briard",
  //   align: "center",
  //   width: "75px",
  //   divider: true,
  //   class: "blue darken-4 white--text",
  //   sortable: false,
  // },
];
import axios from "axios";
import tunnel from "tunnel";
import FileSaver from "file-saver";
import AWS from "aws-sdk";
import config from "../config";
import * as binconv from "binconv";

import _ from "lodash"; // 2022-03-07

export default {
  data() {
    return {
      // pagination: {//NOTE: for resetting scroll top  2024-04-23
        currentPage: 1,
      // },
      options: {
        rowsPerPage: 30,
      },
      loadingPage: false,
      listData: [],
      columns,
      toSearchJWW: "",
      Item: "All",
      OptItem: ["All"],
      Variety: "All",
      OptVariety: [
        "All",
        "エコカラット割り付け図(｢i-cube 240+｣)",
        "エコカラット割り付け図(｢i-cube 260+｣)",
        "エコカラット割り付け図(｢i-smart 240+｣)",
        "エコカラット割り付け図(｢i-smart 260+｣)",
        "エコカラット割り付け図(｢夢の家 240｣)",
        "エコカラット割り付け図(｢夢の家 265｣)",
        "オリジナルテレビボード",
        "玄関上がり框",
        "勾配天井",
        "自在棚",
        "システムクローゼット(ユニット) ケーシング枠",
        "システムパントリー(ユニット)ケーシング枠",
        "白木調造作造作材",
        "スリットウォール",
        "スリットスライダー建具 (洋室)",
        "スリットスライダー建具 (和室)",
        "スリットルーバー",
        "ソリッドウッドパネル",
        "ソロモンマホガニー造作材",
        "タレ壁３方枠（白木調造作）",
        "タレ壁３方枠（白松造作）",
        "タレ壁３方枠（木調造作）",
        "タレ壁３方枠（木調造作/白木調造作）",
        "トータルシステムクローゼット(ウォークインタイプ)",
        "トータルシステムクローゼット(ユニット)",
        "トータルシステムクローゼット(ユニット) ケーシング枠",
        "ブックシェルフ(ユニット))ケーシング枠",
        "フリーカウンター",
        "丸棒手摺金物",
        "ユニット出窓",
        "リモコンニッチ",
        "ロフト",
        "和室建具平面図（白木調造作）",
        "和室建具平面図（白松造作）",
        "和室建具平面図（檜造作）",
        "階段関係",
        "階段関係　（240+）",
        "階段関係　（260+）",
        "掘座卓",
        "高性能樹脂サッシ(Sマホガ二ー造作)",
        "高性能樹脂サッシ(キッチン樹脂枠)",
        "高性能樹脂サッシ(白木調造作)",
        "高性能樹脂サッシ(白松造作)",
        "高性能樹脂サッシ(白松造作・内障子枠)",
        "高性能樹脂サッシ(檜造作)",
        "高性能樹脂サッシ(木調造作)",
        "高性能樹脂サッシ（木調造作/白木調造作）",
        "室内明かりとり",
        "住設",
        "造作材",
        "白松造作造作材",
        "木調造作材",
        "洋室建具平面図（Sマホガニー造作）",
        "洋室建具平面図（木調造作）",
        "洋室建具平面図（木調造作/白木調造作）",
        "檜造作造作材",
      ],
      // selectedConstructionMethod: "軸組 枠組",
      selectedConstructionMethod: "All", // 2022-02-28
      OptConstructionMethod: [
        "All", // 2022-02-28
        "軸組",
        "枠組",
        "軸組 枠組",
      ],
      Specifications: ["All"],
      OptSpecs: [
        //new Arrangement 2024-07-04
        { text: "All", value: "All" },
        { text: "グラン•スマート ", value: "GrandSmart" },
        { text: "HUGme", value: "HUGme" },
        { text: "HUGme fam", value: "HUGmefam" },
        { text: "I-smart", value: "iSmart" },
        { text: "I-cube", value: "iCube" },
        { text: "I-smile", value: "iSmile" },
        { text: "グランセゾン", value: "G" },
        { text: "ブリアール", value: "B" },
        { text: "セゾンF", value: "F" },
        { text: "セゾンA", value: "A" }, 
        { text: "セゾンF2-TD", value: "F2-TD" }, 
        { text: "夢の家", value: "YumeNoIe" },
        { text: "一般", value: "Ippan" },

        // { text: "All", value: "All" },
        // { text: "一般工法", value: "Ippan" },
        // { text: "夢の家", value: "YumeNoIe" },
        // { text: "G", value: "G" },
        // { text: "F", value: "F" },
        // { text: "A", value: "A" },
        // { text: "B", value: "B" },
        // { text: "i-cube", value: "iCube" },
        // { text: "i-smart", value: "iSmart" },
        // { text: "Grand Smart", value: "GrandSmart" },
        // { text: "I-smile", value: "iSmile" }, // 2022-08-10
        // { text: "HUGme", value: "HUGme" }, // 2022-11-23
        // { text: "HUGme fam", value: "HUGmefam" }, // 2023-03-17
        // // { text: "セゾン(仮)", value: "Saison" }, // 2023-06-28
        // // { text: "ブリアール(仮)", value: "Briard" }, // 2023-06-28
      ],
    };
  },
  watch: {
    //NOTE: for resetting of scroll top 2024-04-23
    currentPage(){
      let tables = document.querySelectorAll('.v-data-table__wrapper')
      for(let table of tables){
        table.scrollTop = 0
      }
    },
    selectedBuildingType() {
      if (
        this.selectedBuildingType.includes("ALL") &&
        this.selectedBuildingType.length == 2
      ) {
        this.selectedBuildingType.splice(0, 1);
      }
      if (this.selectedBuildingType.length == 0) {
        this.selectedBuildingType = ["ALL"];
      }
      if (
        this.selectedBuildingType.includes("ALL") &&
        this.selectedBuildingType.length != 1
      ) {
        this.selectedBuildingType = ["ALL"];
      }
    },
    selectedBuildingSpecification() {
      if (
        this.selectedBuildingSpecification.includes("ALL") &&
        this.selectedBuildingSpecification.length == 2
      ) {
        this.selectedBuildingSpecification.splice(0, 1);
      }
      if (this.selectedBuildingSpecification.length == 0) {
        this.selectedBuildingSpecification = ["ALL"];
      }
      if (
        this.selectedBuildingSpecification.includes("ALL") &&
        this.selectedBuildingSpecification.length != 1
      ) {
        this.selectedBuildingSpecification = ["ALL"];
      }
    },
    Specifications() {
      if (
        this.Specifications.includes("All") &&
        this.Specifications.length == 2
      ) {
        this.Specifications.splice(0, 1);
      }
      if (this.Specifications.length == 0) {
        this.Specifications = ["All"];
      }
      if (
        this.Specifications.includes("All") &&
        this.Specifications.length != 1
      ) {
        this.Specifications = ["All"];
      }
    },
  },
  computed: {
    jwwToExport() {
      return this.filteredJWW.map((r) => {
        return r;
      });
    },
    filteredSpecs() {
      if (this.listData.length > 0) {
        let notAll = this.SpecInclude("All");
        return (
          this.listData
            .filter((r) => {
              let search = new RegExp(this.toSearchJWW.toUpperCase(), "g");
              return JSON.stringify(Object.values(r))
                .toUpperCase()
                .match(search);
            })

            .filter((r) => {
              if (this.Variety == "All") {
                return r;
              } else {
                return r.variety == this.Variety;
              }
            })
            .filter((r) => {
              if (this.Item == "All") {
                return r;
              } else {
                return r.item == this.Item;
              }
            })
            .filter((r) => {
              if (this.selectedConstructionMethod == "All") {
                // 2022-02-28
                return r;
              } else {
                return r.constructionMethod == this.selectedConstructionMethod; // 2022-02-28
              }
            })
            .filter((s) => {
              if (this.SpecInclude("All")) {
                return s;
              } else {
                return [];
              }
            })
            .filter((r) => {
              if (this.SpecInclude("Ippan") && !notAll) {
                return r.Ippan;
              } else {
                return [];
              }
            })
            .filter((r) => {
              if (this.SpecInclude("YumeNoIe") && !notAll) {
                return r["YumeNoIe"];
              } else {
                return [];
              }
            })
            .filter((r) => {
              if (this.SpecInclude("G") && !notAll) {
                return r["G"];
              } else {
                return [];
              }
            })
            .filter((r) => {
              if (this.SpecInclude("F") && !notAll) {
                return r["F"];
              } else {
                return [];
              }
            })
            .filter((r) => {
              if (this.SpecInclude("A") && !notAll) {
                return r["A"];
              } else {
                return [];
              }
            })
            .filter((r) => {
              if (this.SpecInclude("B") && !notAll) {
                return r["B"];
              } else {
                return [];
              }
            })
            .filter((r) => {
              if (this.SpecInclude("iCube") && !notAll) {
                return r["iCube"];
              } else {
                return [];
              }
            })
            .filter((r) => {
              if (this.SpecInclude("iSmart") && !notAll) {
                return r["iSmart"];
              } else {
                return [];
              }
            })

            // 2022-08-10
            .filter((r) => {
              if (this.SpecInclude("GrandSmart") && !notAll) {
                return r["GrandSmart"];
              } else {
                return [];
              }
            })

            // 2022-08-10
            .filter((r) => {
              if (this.SpecInclude("iSmile") && !notAll) {
                return r["iSmile"];
              } else {
                return [];
              }
            })

            // 2022-11-23
            .filter((r) => {
              if (this.SpecInclude("HUGme") && !notAll) {
                return r["HUGme"];
              } else {
                return [];
              }
            })

            // 2023-03-17
            .filter((r) => {
              if (this.SpecInclude("HUGmefam") && !notAll) {
                return r["HUGmefam"];
              } else {
                return [];
              }
            })

            //2024-07-04
            .filter((r) => {
              if (this.SpecInclude("F2-TD") && !notAll) {
                return r["F2-TD"];
              } else {
                return [];
              }
            })
            //2024-07-04 Deleted//
            // // 2023-06-28
            // .filter((r) => {
            //   if (this.SpecInclude("Saison") && !notAll) {
            //     return r["Saison"];
            //   } else {
            //     return [];
            //   }
            // })

            // // 2023-06-28
            // .filter((r) => {
            //   if (this.SpecInclude("Briard") && !notAll) {
            //     return r["Briard"];
            //   } else {
            //     return [];
            //   }
            // })
        );
      } else {
        return [];
      }
    },
    WidthFile() {
      if (this.$vuetify.breakpoint.name == "xs") {
        return "width:87%; margin-left:26px";
      } else return "width:88%";
    },
    WidthFile2() {
      if (this.$vuetify.breakpoint.name == "xs") {
        return "width:80%; ";
      } else return "width: 70%";
    },
    filteredJWW() {
      if (this.filteredSpecs.length > 0) {
        return this.filteredSpecs.filter((data) => {
          let search = new RegExp(this.toSearchJWW.toUpperCase(), "g");
          return JSON.stringify(Object.values(data))
            .toUpperCase()
            .match(search);
        });
      } else {
        return [];
      }
    },
  },

  methods: {
    FileSrc(filename) {
      var tunnelingAgent = tunnel.httpsOverHttp({
        proxy: {
          // Proxy settings
          host: "hrdproxy.hrd-s.com",
          port: 81,
          proxyAuth: "administrator:system",
        },
      });
      // console.log(tunnelingAgent, "proxy");

      AWS.config.update({
        httpOptions: { agent: tunnelingAgent },
        region: "us-east-2",
        credentials: config,
      });
      var s3 = new AWS.S3({
        AccessKeyID: this.accessKeyId,
        SecretAccessKey: this.secretAccessKey,
        Region: "us-east-2",
        params: {
          bucket: "rulebook.files",
        },
      });

      var options = {
        Bucket: "rulebook.files",
        Key: `JWW_Files/${filename}`,
      };
      // console.log(s3, "s3");

      s3.getObject(options, function(err, data) {
        if (err) console.log(err, err.stack);
        // an error occurred
        else {
          // console.log(data);
          var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
            type: `${data.ContentType};charset=utf-8`,
          });

          FileSaver.saveAs(blob, `${filename}`);
        } // successful response
      });
    },
    loadFile() {
      this.loadingPage = true;
      let tmpItems = [];
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      let url = `${this.api}getAllFiles?docType=JWW`;
      axios
        .get(url)
        .then((res) => {
          res.data = res.data.map((rec, index) => {
            rec.key = index;
            return rec;
          });

          // this.listData = res.data;
          // this.listData.sort((a, b) => (a._id > b._id ? 1 : -1));
          tmpItems = _.uniq(
            _.map(
              _.filter(res.data, function(o) {
                return o.item != undefined;
              }),
              "item"
            )
          );
          // Sort By (1) item (2) variety (3) buzai (4) hinban  2022-03-07
          this.listData = _.orderBy(
            res.data,
            ["item", "variety", "buzai", "hinban"],
            ["asc", "asc", "asc", "asc"]
          );

          this.OptItem.push(...tmpItems);
        })
        .then(() => {
          this.loadingPage = false;
        });
    },
    SpecInclude(specs) {
      return this.Specifications.includes(specs);
    },
  },
  created() {
    this.loadFile();
  },
};
</script>
