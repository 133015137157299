import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import myMixins from './plugins/myMixins'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import AutoResponsive from 'autoresponsive-vue'
import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

import VueFroala from 'vue-froala-wysiwyg'
require('froala-editor/js/froala_editor.pkgd.min.js')
// require('froala-editor/js/third_party/image_aviary.min.js')
require('froala-editor/js/plugins/draggable.min.js')
require('froala-editor/js/plugins/image.min.js')
require('froala-editor/js/plugins/font_family.min.js')
require('froala-editor/js/plugins/url.min.js')
require('froala-editor/js/plugins/link.min.js')
// Require Froala Editor css files.
require('froala-editor/css/froala_editor.pkgd.min.css')
require('froala-editor/css/froala_style.min.css')
require('froala-editor/css/plugins/draggable.min.css')
require('froala-editor/css/plugins/image.min.css')
require('froala-editor/css/themes/dark.min.css')

import VueHtmlToPaper from 'vue-html-to-paper'

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=no'],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}

Vue.use(VueHtmlToPaper, options)
Vue.use(VueFroala)

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import JsonCSV from 'vue-json-csv'
Vue.component('downloadCsv', JsonCSV)

Vue.use(AutoResponsive)
import titleMixin from './plugins/titleMixin'
Vue.mixin(titleMixin)

Vue.config.productionTip = false
Vue.use(myMixins)
Vue.use(Antd)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
