import { mapState, mapMutations } from "vuex";
// import _ from 'lodash'
import axios from "axios";
import Swal from "sweetalert2";
// import moment from 'moment'

const myPlugin = {
  install(Vue) {
    Vue.mixin({
      data: () => ({
        currentVersion: "",
        someValue: "",
        api: process.env.VUE_APP_URL,
        api2: process.env.VUE_APP_URL2,
        front: process.env.VUE_APP_URL3,
        appDev: process.env.VUE_APP_DEV, // 2023-03-21
        linkURL: process.env.VUE_APP_LINK_URL,
        screenHeight: window.screen.availHeight,
        screenWidth: window.screen.availWidth,
        accessKeyId: process.env.VUE_APP_ACCESS_KEY,
        secretAccessKey: process.env.VUE_APP_SECRET_ACCESS_KEY,
        apiKey: process.env.VUE_APP_API_KEY,
        s3URL: "https://s3.us-east-2.amazonaws.com/rulebook.files", // 2022-12-06
        isFileDownloading: false,

        windowSize: {
          // 2023-05-15
          x: 0,
          y: 0,
        },

        // breakpointHeight:this.$vuetify.breakpoint.height
        // tabCondition: "Search"
      }),
      created() {
        // const title = this.getTitle(this)
        // if (title) {
        //   document.title = title
        // }
      },
      computed: {
        myScreenHeight() {
          return this.$vuetify.breakpoint.height;
        },
        ...mapState([
          "linkTitle",
          "userInfo",
          "selectedRule",
          "tabCondition",
          "expiryDate",
          "isFavCnt",
          // 'token',
          "globalSearchWord",
          "globalCondition",
          "isSearchAll",
          "globalExceptWord",
          "systemVersion",
          "shiyoObjectFeedBack", //2022-01-17 {}
        ]),

        isLeader() {
          let leader = [
            "Japanese Advisor",
            "Department In-Charge",
            "Department Head Trainee",
            "Department Head",
          ];
          if (leader.includes(this.userInfo.designation)) {
            return true;
          } else {
            return false;
          }
        },
      },
      methods: {
        // 2023-05-15
        onResize() {
          this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        },

        // 2023-09-07
        isValidEmail(email) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(email);
        },

        // 2023-03-22
        openNewTabPDFFile(pdfUrl) {
          this.isFileDownloading = true;
          fetch(pdfUrl)
            //  .then(resp => resp.arrayBuffer())
            .then((resp) => {
              if (resp.status == "403") {
                Swal.fire({
                  icon: "error",
                  title: "No attachment Found!",
                  showConfirmButton: true,
                  timer: 2000,
                });
                this.isFileDownloading = false;
                return;
              } else {
                return resp.arrayBuffer();
              }
            })
            .then((resp) => {
              // set the blog type to final pdfconst file = new Blob([resp], {type: 'application/pdf'});
              // process to auto download itconst fileURL = URL.createObjectURL(file);
              if (resp != undefined) {
                const file = new Blob([resp], { type: "application/pdf" });

                const splitPDFURL = pdfUrl.split("/"); // GET TITLE PDF

                const fileURL = URL.createObjectURL(file);

                // // window.open( fileURL , '_blank');

                const newWindow = window.open(fileURL, "_blank");
                newWindow.document.title = splitPDFURL[splitPDFURL.length - 1];
                setTimeout(() => {
                  newWindow.document.title =
                    splitPDFURL[splitPDFURL.length - 1];
                }, 500);

                // const iframe = document.createElement('iframe');
                // iframe.src = fileURL;
                // iframe.width = '100%';
                // iframe.height = '100%';
                // iframe.style.border = 'none';
                // iframe.style.top = "0px";
                // iframe.style.left = "0px";
                // iframe.style.bottom = "0px";
                // iframe.style.right = "0px";
                // const newWindow = window.open('', '_blank');
                // newWindow.document.body.appendChild(iframe);
                // newWindow.document.title = splitPDFURL[splitPDFURL.length - 1 ]

                // const newWindow = window.open()
                // newWindow.document.title = splitPDFURL[splitPDFURL.length - 1 ]
                // newWindow.document.write (`<iframe src="${fileURL}#toolbar=0" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`)

                this.isFileDownloading = false;
              }
            })
            .catch((err) => {
              console.log("Download Error", err.message);
              console.log("No file to Open");
              this.isFileDownloading = false;
            });
        },

        // 2022-12-06
        downloadOriginalFile(pdfUrl, originalFileName) {
          // 2022-07-01 Download function PDF / EXCEL in original file name
          this.isFileDownloading = true;
          fetch(pdfUrl)
            //  .then(resp => resp.arrayBuffer())
            .then((resp) => {
              if (resp.status == "403") {
                Swal.fire({
                  icon: "error",
                  title: "No attachment Found!",
                  showConfirmButton: true,
                  timer: 2000,
                });
                this.isFileDownloading = false;
                return;
              } else {
                return resp.arrayBuffer();
              }
            })
            .then((resp) => {
              // set the blog type to final pdfconst file = new Blob([resp], {type: 'application/pdf'});
              // process to auto download itconst fileURL = URL.createObjectURL(file);
              if (resp != undefined) {
                const file = new Blob([resp], { type: "application/pdf" });

                const fileURL = URL.createObjectURL(file);
                const link = document.createElement("a");
                link.href = fileURL;
                link.download = originalFileName;
                link.click();

                this.isFileDownloading = false;
                // windows.open( fileUrl, '_blank')
              }
            })
            .catch((err) => {
              console.log("Download Error", err.message);
              console.log("No file to download");
              this.isFileDownloading = false;
            });
        },

        convert2FullHiragana(str) {
          var kanaMap = {
            ぁ: "あ",
            ぃ: "い",
            ぅ: "う",
            ぇ: "え",
            ぉ: "お",
            ゃ: "や",
            ゅ: "ゆ",
            ょ: "よ",
          };

          var reg = new RegExp("(" + Object.keys(kanaMap).join("|") + ")", "g");
          return str.replace(reg, function(match) {
            return kanaMap[match];
          });
        },
        convert2FullKatakana(str) {
          var kanaMap = {
            ｶﾞ: "ガ",
            ｷﾞ: "ギ",
            ｸﾞ: "グ",
            ｹﾞ: "ゲ",
            ｺﾞ: "ゴ",
            ｻﾞ: "ザ",
            ｼﾞ: "ジ",
            ｽﾞ: "ズ",
            ｾﾞ: "ゼ",
            ｿﾞ: "ゾ",
            ﾀﾞ: "ダ",
            ﾁﾞ: "ヂ",
            ﾂﾞ: "ヅ",
            ﾃﾞ: "デ",
            ﾄﾞ: "ド",
            ﾊﾞ: "バ",
            ﾋﾞ: "ビ",
            ﾌﾞ: "ブ",
            ﾍﾞ: "ベ",
            ﾎﾞ: "ボ",
            ﾊﾟ: "パ",
            ﾋﾟ: "ピ",
            ﾌﾟ: "プ",
            ﾍﾟ: "ペ",
            ﾎﾟ: "ポ",
            ｳﾞ: "ヴ",
            ﾜﾞ: "ヷ",
            ｦﾞ: "ヺ",
            ｱ: "ア",
            ｲ: "イ",
            ｳ: "ウ",
            ｴ: "エ",
            ｵ: "オ",
            ｶ: "カ",
            ｷ: "キ",
            ｸ: "ク",
            ｹ: "ケ",
            ｺ: "コ",
            ｻ: "サ",
            ｼ: "シ",
            ｽ: "ス",
            ｾ: "セ",
            ｿ: "ソ",
            ﾀ: "タ",
            ﾁ: "チ",
            ﾂ: "ツ",
            ﾃ: "テ",
            ﾄ: "ト",
            ﾅ: "ナ",
            ﾆ: "ニ",
            ﾇ: "ヌ",
            ﾈ: "ネ",
            ﾉ: "ノ",
            ﾊ: "ハ",
            ﾋ: "ヒ",
            ﾌ: "フ",
            ﾍ: "ヘ",
            ﾎ: "ホ",
            ﾏ: "マ",
            ﾐ: "ミ",
            ﾑ: "ム",
            ﾒ: "メ",
            ﾓ: "モ",
            ﾔ: "ヤ",
            ﾕ: "ユ",
            ﾖ: "ヨ",
            ﾗ: "ラ",
            ﾘ: "リ",
            ﾙ: "ル",
            ﾚ: "レ",
            ﾛ: "ロ",
            ﾜ: "ワ",
            ｦ: "ヲ",
            ﾝ: "ン",
            ｧ: "ァ",
            ｨ: "ィ",
            ｩ: "ゥ",
            ｪ: "ェ",
            ｫ: "ォ",
            ｯ: "ッ",
            ｬ: "ャ",
            ｭ: "ュ",
            ｮ: "ョ",
            "｡": "。",
            "､": "、",
            ｰ: "ー",
            "｢": "「",
            "｣": "」",
            "･": "・",
          };

          var reg = new RegExp("(" + Object.keys(kanaMap).join("|") + ")", "g");
          return str
            .replace(reg, function(match) {
              return kanaMap[match];
            })
            .replace(/ﾞ/g, "゛")
            .replace(/ﾟ/g, "゜");
        },
        isAdmin() {
          if (this.userInfo.isAdmin) {
            return true;
          } else return false;
        },
        isGCUser() {
          // RULEBOOK
          if (this.userInfo.isRulebookUser) {
            return true;
          } else {
            return false;
          }
        },
        isIchijoUser() {
          if (this.userInfo.isIchijoUser) {
            return true;
          } else {
            return false;
          }
        },

        isGCPortalUser() {
          // 2022-09-06
          if (this.userInfo.isGCPortalUser) {
            return true;
          } else {
            return false;
          }
        },

        isInCharge() {
          // 2022-09-06
          if (this.userInfo.isIncharge) {
            return true;
          } else {
            return false;
          }
        },

        isGCUserNew() {
          // 2022-09-16
          if (this.userInfo.isGCUser) {
            return true;
          } else {
            return false;
          }
        },

        isSalesUser() {
          // 2023-04-19
          if (this.userInfo.isSalesUser) {
            return true;
          } else {
            return false;
          }
        },

        isShiyoAdmin() {
          // 2022-09-22
          // Email for Admin
          let emailsArr = [
            "s-fukaya@hrd-s.com",
            "judy@hrd-s.com",
            "l-garcia@hrd-s.com",
            "sachiko-takano@ichijo.co.jp",
            "mai-suzuki@ichijo.co.jp",
            "yuko-okada@ichijo.co.jp",
            "yoshiko-uchida@ichijo.co.jp",
            "mika-tsuda@ichijo.co.jp",
            "cadtech@hrd-s.com",
            "r-ladanan@hrd-s.com",
            "takashi-yamasaki@ichijo.co.jp",
            "sayuri-fujimoto@ichijo.co.jp",
            "sonoka-tsuduki@ichijo.co.jp",
            "s-tominaga@hrd-s.com",
            "maiko-shinohara@ichijo.co.jp",
            "s-okibayashi@hrd-s.com",
            "gcportal@hrd-s.com",
            "m-kageyama@ichijo.co.jp",
            "sd2@hrd-s.com",
            "chie-uyama@ichijo.co.jp",
            "mi-sano@ichijo.co.jp",
            "admin@ichijo.co.jp",
            "kanae-nagura@ichijo.co.jp",
            "takaomi-iwata@ichijo.co.jp",
          ];

          if (emailsArr.includes(this.userInfo.originalMailAddress)) {
            return true;
          } else {
            return false;
          }
        },

        alert(val) {
          alert(val);
        },
        ...mapMutations([
          "LINK_TITLE",
          "ADD_INFO",
          "CHANGE_ITEM",
          "TAB_CND",
          "setExpiryDate",
          "SET_FAVORITE",
          "UPDATE_SEARCH_ALL",
          "CHANGE_CONDITION",
          "CHANGE_SEARCH_WORD",
          "CHANGE_EXCEPT_WORD",
          "CHANGE_SHIYO_FEEDBACK", // 2022-01-17
        ]),
        deleteInfo: function() {
          this.ADD_INFO("");
          this.$store.commit("AUTH_SUCCESS", []);
          this.$router.push("/login");
        },
        redirectedLogout: function() {
          // ADDED 20231123
          this.ADD_INFO("");
          this.$store.commit("AUTH_SUCCESS", []);
          // this.$router.push("/login");
        },
        selectRule: function(val) {
          this.CHANGE_ITEM(val);
          this.$router.push("/modify");
        },
        goToUserManual() {
          this.$router.push("/usermanual");
        },
        backHome() {
          if (this.userInfo != "") {
            this.$router.push("/");
            this.CHANGE_CONDITION("");
            this.CHANGE_EXCEPT_WORD("");
            this.CHANGE_SEARCH_WORD("");
            this.UPDATE_SEARCH_ALL(false);
            location.reload();
          } else {
            this.$router.push("/login");
          }
        },
        backToAll() {
          this.$router.push("/synonym");
        },
        // 2022-07-01
        getSizeInBytes(obj) {
          let str = null;
          if (typeof obj === "string") {
            // If obj is a string, then use it
            str = obj;
          } else {
            // Else, make obj into a string
            str = JSON.stringify(obj);
          }
          // Get the length of the Uint8Array
          const bytes = new TextEncoder().encode(str).length;
          return bytes;
        },
        // 2022-07-01
        logSizeInKilobytes(obj) {
          const bytes = this.getSizeInBytes(obj);
          const kb = (bytes / 1000).toFixed(2);
          return kb;
        },
      },
      mounted() {
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      },
      filters: {},
    });
  },
};

export default myPlugin;
