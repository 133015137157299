<template>
  <v-container pa-0 ma-0 fluid>
    <!-- NOTE: disable transation in this page -->
    <div translate="no">
    <v-card
      class="ma-2 pa-2"
      flat
      style="background-color: #f6f5f5"
      v-if="loadingSekkei"
    >
      <v-row row dense>
        <!-- Search box -->
        <v-col
          v-if="!viewHistoryTable && loadingSekkei"
          cols="3"
          :sm="8"
          :md="4"
        >
          <!------ Removed a-input component replaced with v-text-field 20231104----->
          <!-- <a-input
            addon-before="検索"
            v-model="searchText"
            placeholder="検索"
            append-icon="search"
            outlined
            @compositionstart="StartCompose()"
            @compositionend="EndCompose()"
            @keyup.enter="
              selectedCondition != 'MULTI' ? onSearch() : onMultipleSearch()
            "
          >
            <a-icon
              slot="suffix"
              type="close-circle"
              style="color: rgba(0, 0, 0, 0.45)"
              @click="searchText ? clearSearch() : ''"
            />
          </a-input> -->
          <v-row dense no-gutters>
            <!-- NOTE: old searching function start -->
            <!-- <p
              class="search-label mb-0"
              style="font-size:15px; color:#000000a6"
            >
              検索
            </p>
            <v-text-field
              solo
              height="31px"
              flat
              clearable
              hide-details
              @click:clear="clearSearch()"
              class="custom-text-field"
              v-model="searchText"
              :clear-icon="customClearIcon"
              @keyup.enter="
                selectedCondition != 'MULTI' ? onSearch() : onMultipleSearch()
              "
            >
            </v-text-field> -->
            <!-- NOTE: old searching function start -->
            <!-- NOTE: merged searching function - added full text search with "AND" condition and "MULTI" 2024-06-15 start -->
            <span style="font-weight:bold;" class="pt-1 pr-1">全文検索</span>
            <v-text-field
              style="zoom:80%; background-color:white;"
              outlined
              dense
              hide-details
              clearable
              @keyup.enter="selectedCondition='MULTI',andMultiSearch()"
              @input="searchTitleDocNo=''"
              @click:clear="clearSearch()"
              :clear-icon="customClearIcon"
              v-model="searchText"
            ></v-text-field>
            <!-- NOTE: merged searching function - added full text search with "AND" condition and "MULTI" 2024-06-15 end -->
          </v-row>
        </v-col>

        <v-col cols="5" v-if="viewHistoryTable && loadingSekkei">
          <a-input
            addon-before="検索"
            v-model="searchText"
            placeholder="検索"
            append-icon="search"
            outlined
          >
            <a-icon
              slot="suffix"
              type="close-circle"
              style="color: rgba(0, 0, 0, 0.45)"
              @click="searchText ? clearSearch() : ''"
            />
          </a-input>
        </v-col>

        <!-- AND/OR Select box -->
        <!-- <v-col v-if="!viewHistoryTable && loadingSekkei" cols="1"> -->
        <v-col v-if="!viewHistoryTable && loadingSekkei" cols="4">
          <v-row dense no-gutters>
            <!-- NOTE: old searching function start -->
            <!-- <a-select v-model="selectedCondition" style="width: 100%">
              <a-select-option
                v-for="item in condition"
                :key="item"
                v-value="item"
                >{{ item }}</a-select-option
              >
            </a-select> -->
            <!-- NOTE: old searching function end -->
            <!-- NOTE: merged searching function start -->
            <span style="font-weight:bold;" class="pt-1 pr-1">タイトル検索</span>
            <v-text-field
              style="zoom:80%; background-color:white;"
              outlined
              dense
              hide-details
              clearable
              @keyup.enter="selectedCondition='帳票番号＆タイトル',andMultiSearch_TitleID()"
              @input="searchText=''"
              @click:clear="clearSearch()"
              :clear-icon="customClearIcon"
              v-model="searchTitleDocNo"
            ></v-text-field>
            <!-- NOTE: merged searching function end -->
          </v-row>
        </v-col>

        <!-- Except box -->
        <!-- NOTE: eliminate マイナス検索 2024-04-18 start -->
        <!-- <v-col v-if="!viewHistoryTable && loadingSekkei" cols="3">
          <a-input
            addon-before="マイナス検索"
            v-model="exceptText"
            placeholder="マイナス検索"
          >
            <a-icon
              slot="suffix"
              @click="exceptText ? clearSearch() : ''"
              type="close-circle"
              style="color: rgba(0, 0, 0, 0.45)"
            />
          </a-input>
        </v-col> -->
        <!-- NOTE: eliminate マイナス検索 2024-04-18 end -->

        <!-- Search Button -->
        <v-col v-if="!viewHistoryTable && loadingSekkei" cols="2">
          <!-- NOTE: old searching function start -->
          <!-- <a-button
            class="mx-1"
            dark
            type="primary"
            icon="search"
            @click="selectedCondition != 'MULTI' ? onSearch() : onMultipleSearch()"
            :disabled="!searchText && !searchDocNo"
          >
            Search
          </a-button> -->
          <!-- NOTE: old searching function end -->
          <!-- NOTE: merged searching function start -->
          <a-button
            class="mx-1"
            dark
            type="primary"
            icon="search"
            @click="selectedCondition != 'MULTI' ? andMultiSearch_TitleID() : andMultiSearch()"
            :disabled="!searchText && !searchDocNo && !searchTitleDocNo"
          >
            Search
          </a-button>
          <!-- NOTE: merged searching function end -->
        </v-col>
        <v-spacer v-if="!isFilterOn" />
        <!---button for history loading----->
        <v-col cols="3" v-if="viewHistoryTable">
          <v-btn
            style="border-color:gray; width:87%"
            text
            height="30px"
            outlined
            :color="viewHistoryTable ? 'primary' : ''"
            @click="(viewHistoryTable = !viewHistoryTable), loadSekkeiHistory()"
          >
            <!-- <v-btn
            class="mt-4"
            style="border-color:gray; width:87%"
            text
            height="30px"
            outlined
            :color="viewHistoryTable ? 'primary' : ''"
            @click="(viewHistoryTable = !viewHistoryTable), loadData(1)"
          > -->
            <!-- <v-btn
            class="mt-4"
            style="border-color:gray; width:87%"
            text
            height="30px"
            outlined
            :color="viewHistoryTable ? 'primary' : ''"
            @click="loadSekkeiHistory()"
          > -->
            {{ !viewHistoryTable ? "History" : "Home" }}
            <v-icon v-if="!viewHistoryTable">
              mdi-history
            </v-icon>
            <v-icon v-if="viewHistoryTable">mdi-home</v-icon>
          </v-btn>
        </v-col>

        <!-- Show Filter Button -->
        <a-button
          v-if="!isFilterOn && !viewHistoryTable"
          @click="hideFilter = !hideFilter"
          class="mt-1"
        >
          <v-icon class="mr-1" small>{{
            hideFilter ? "mdi-filter-check" : "mdi-filter-off"
          }}</v-icon>
          {{ hideFilter ? "View Filter" : "Hide Filter" }}
        </a-button>
      </v-row>

      <!-- Filter Section -->
      <v-row
        class="ml-4 mb-2 mt-n3"
        v-if="!isFilterOn && !hideFilter && !viewHistoryTable"
        no-gutters
      >
        <v-col cols="3" class="mt-5">
          <v-layout row wrap>
            &emsp;
            <span
              class="mt-2 button"
              style="font-weight: bold; font-size: 14px"
              @click="
                (sortByClassificationASC = !sortByClassificationASC),
                  sortByClassification(sortByClassificationASC)
              "
            >
              {{ sortByClassificationASC ? "🔽 区分:" : "🔼 区分:" }}
              <!-- {{ "🔽 区分:" }} -->
            </span>
            <a-select
              class="mt-2 ml-7"
              v-model="selectedClassification"
              :style="Width"
            >
              <a-select-option
                v-for="item in kubunItems"
                :key="item"
                v-value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </v-layout>
        </v-col>

        <v-col cols="3" class="mt-5">
          <v-layout row wrap>
            &emsp;
            <span
              class="mt-2 button"
              style="font-weight: bold; font-size: 14px"
              @click="
                (sortByProjectsASC = !sortByProjectsASC),
                  sortByProjects(sortByProjectsASC)
              "
            >
              {{ sortByProjectsASC ? "🔽" : "🔼" }}項目:
              <!-- {{ "🔽 項目:" }} -->
            </span>
            <a-select class="mt-2" v-model="selectedProjects" :style="Width">
              <a-select-option
                v-for="item in projectItems"
                :key="item"
                v-value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </v-layout>
        </v-col>

        <v-col cols="3" class="mt-5">
          <v-layout row wrap>
            &emsp;
            <span
              class="mt-2 button"
              style="font-weight: bold; font-size: 14px"
              @click="
                sortByMaterials(sortByMaterialsASC),
                  (sortByMaterialsASC = !sortByMaterialsASC)
              "
            >
              {{ sortByMaterialsASC ? "🔽" : "🔼" }}資料種類:
              <!-- {{ "🔽 資料種類:" }} -->
            </span>

            <!-- Specifications select -->
            <a-select class="mt-2" v-model="selectedMaterials" :style="Width">
              <a-select-option
                v-for="item in materialItems"
                :key="item"
                v-value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </v-layout>
        </v-col>
        <v-col cols="3">
          <v-btn
            class="mt-4"
            style="border-color:gray; width:87%"
            text
            height="30px"
            outlined
            :color="viewHistoryTable ? 'primary' : ''"
            @click="(viewHistoryTable = !viewHistoryTable), loadSekkeiHistory()"
          >
            <!-- <v-btn
            class="mt-4"
            style="border-color:gray; width:87%"
            text
            height="30px"
            outlined
            :color="viewHistoryTable ? 'primary' : ''"
            @click="(viewHistoryTable = !viewHistoryTable), loadData(1)"
          > -->
            <!-- <v-btn
            class="mt-4"
            style="border-color:gray; width:87%"
            text
            height="30px"
            outlined
            :color="viewHistoryTable ? 'primary' : ''"
            @click="loadSekkeiHistory()"
          > -->
            {{ !viewHistoryTable ? "History" : "Data Viewing" }}
            <v-icon>
              mdi-history
            </v-icon>
          </v-btn>
        </v-col>

        <!-- <v-col cols="3" class="mt-4">
          <v-layout row wrap>
            <span
              class="mt-2 button ml-4"
              style="font-weight: bold; font-size: 14px"
            >
            </span>
            &emsp;
          </v-layout>
        </v-col> -->
        <!-- <v-col cols="3" class="mt-4">
          <span>
            建物タイプ:
          </span>
          <a-select
            v-model="sel_buildingType"
            :style="Width"
            mode="multiple"
            @change="onChangebuilding()"
          >
            <a-select-option
              v-for="item in buildingTypeList"
              :key="item.value"
              v-value="item.value"
              >{{ item.text }}
            </a-select-option>
          </a-select>
        </v-col> -->

        <!-- <v-col cols="3" class="mt-4">
          <span>
            建物仕様:
          </span>
          <a-select
            v-model="sel_buildingSpec"
            :style="Width"
            mode="multiple"
            @change="onChangeSpec()"
          >
            <a-select-option
              v-for="item in buildingSpecList"
              :key="item.value"
              v-value="item.value"
              >{{ item.text }}
            </a-select-option>
          </a-select>
        </v-col> -->
      </v-row>

      <!-- <v-row>
        
      </v-row> -->
    </v-card>
    <!-- END OF FILTER SECTION -->
    <!-- LIST OF RECORDS SECTION -->

    <v-layout class="ml-1" v-if="!viewHistoryTable && loadingSekkei">
      <span
        style="color: #0f3057; font-weight: bold"
        v-if="!isMultiple && searchSekkeiIconAlignment"
      >
        About {{ filteredSekkei.length }} results for keywords "{{
          searchText
        }}"
      </span>
      <span style="color: #0f3057; font-weight: bold" flat v-else>
        Total: {{ filteredSekkei.length }}
      </span>
      <v-spacer />
    </v-layout>

    <v-layout
      align-center
      justify-center
      v-if="!loadingSekkei"
      :active="!loadingSekkei"
    >
      <a-spin class="mt-2" tip="Loading..." />
    </v-layout>

    <!-- --------------------------- -->
    <v-row
      no-gutters
      class="mr-6"
      style="font-size: 14px"
      v-if="!viewHistoryTable && loadingSekkei"
    >
      <!-- <v-col cols="1">
        <strong id="id-center" class="ml-9">Document No.</strong>
      </v-col>

      <v-col cols="5">
        <strong id="id-center">Title</strong>
      </v-col>
    

      <v-col cols="3">
        <strong id="id-center">Category</strong>
      </v-col>

      <v-col cols="1">
        <strong id="id-center">Update date</strong>
      </v-col>
    
      <v-col cols="1">
        <strong id="id-center">Views</strong>
      </v-col>
   
      <v-col cols="1">
        <strong id="id-center">Favorites</strong>
      </v-col> -->
    </v-row>
    <!-- ------------------------------ -->

    <v-card
      flat
      style="overflow-y: scroll"
      :height="hideFilter ? screenSize - 260 : screenSize - 300"
      class="pa-1"
      v-if="!viewHistoryTable && loadingSekkei"
    >
      <!-- <v-card v-for="(item, i) in paginatedSekkei" :key="i">  Removed 20231122-->
      <v-card v-for="(item, i) in paginatedSekkei" :key="i">
        <v-divider />
        <v-row no-gutters style="cursor:pointer">
          <v-col cols="5">
            <v-layout>
              <div id="id-title">
                <!-- NOTE: delete icon not necessary in aws 2024-08-07 -->
                <!-- <v-icon color="#f55666">mdi-file-document</v-icon> -->
                <!-- ADD/DELETE FAVORITE BUTTON -->
                <v-tooltip bottom color="black">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      size="large"
                      @click="favoriteFunction(item)"
                      :color="isFavColor(item)"
                      >{{ isFavIcon(item) }}</v-icon
                    >
                  </template>
                  <span>
                    {{
                      isFavIcon(item) != "mdi-star"
                        ? "Add to Favorite"
                        : "Delete from Favorite"
                    }}
                  </span>
                </v-tooltip>
                <!-- OPEN RECORD BUTTON -->

                <!-- <v-tooltip bottom color="black">
                  <template v-slot:activator="{ on }">
                    <span
                      v-on="on"
                      @click="
                        openSekkei(item);
                        searchLists.push(item.id, item.sekkei_classification);
                      "
                      :class="
                        listColorSearch(item.id, item.sekkei_classification)
                      "
                      class="mx-2 ml-1;"
                      style="font-size: 20px; font-weight: bold;  cursor:pointer"
                      v-html="
                        `<span style='background-color:#31b16e; border-radius:4px' class='px-3 white--text'><span style='font-size:15px;font-weight:normal'>${item.docNo}</span></span>${title_spacer}<span>${item.title}</span>`
                      "
                    />
                  </template>

                  <span v-html="item.title" />
                </v-tooltip> -->

                <!-- NOTE: remove tooltip 2024-04-30 start -->
                    <span
                      @click="
                        openSekkei(item);
                        searchLists.push(item.id, item.sekkei_classification);
                      "
                      :class="
                        listColorSearch(item.id, item.sekkei_classification)
                      "
                      class="mx-2 ml-1;"
                      style="font-size: 20px; font-weight: bold;  cursor:pointer"
                      v-html="
                        `<span style='background-color:#31b16e; border-radius:4px' class='px-3 white--text'><span style='font-size:15px;font-weight:normal'>${item.docNo}</span></span>${title_spacer}<span>${item.title}</span>`
                      "
                    />
                <!-- NOTE: remove tooltip 2024-04-30 end -->
              </div>
            </v-layout>
          </v-col>

          <v-col cols="1" class="d-flex">
            <v-spacer></v-spacer>
            <div v-if="searchSekkeiIconAlignment && selectedCondition != 'タイトル' && selectedCondition != '帳票番号'">
              <v-btn color="primary" icon @click="toggleShowLine(item)">
                <v-icon large>{{item.showLine ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
              </v-btn>
            </div>
          </v-col>

          <!-- Room Part Label -->
          <v-col cols="1">
            <a-tooltip placement="left">
              <template slot="title">
                <!-- {{
                  Array.isArray(item.roomPart) &&
                  Array.isArray(item.roomPart).length >= 3
                    ? getRoomPart(item.roomPart)
                    : getRoomPart(item.roomPart)
                }} -->{{ item.sekkei_classification }}
              </template>
              <a-tag
                style="color: white; text-align: center"
                color="#424874"
                class="mt-2 mx-1"
              >
                <span id="id-center">
                  <!-- {{
                    Array.isArray(item.roomPart) &&
                    Array.isArray(item.roomPart).length >= 3
                      ? getRoomPart(item.roomPart)
                      : getRoomPart(item.roomPart)
                  }} -->
                  {{ item.sekkei_classification }}
                </span>
              </a-tag>
            </a-tooltip>
          </v-col>

          <!-- Product Specification Label -->
          <v-col cols="2">
            <a-tag
              style="color: white; text-align: center"
              color="#e36387"
              class="mt-2 mx-1"
            >
              <span id="id-center" width="100%">
                <!-- {{ item.specifications }} -->
                {{ item.sekkei_projects }}
              </span>
            </a-tag>
          </v-col>

          <!-- Framework Label -->
          <v-col cols="1">
            <a-tag
              style="color: white; text-align: center"
              class="mt-2 mx-1"
              color="#5c969e"
            >
              <!-- <span id="id-center" v-if="item.framework == '軸組'">軸組 </span>
              <span
                id="id-center"
                class="mr-1"
                v-else-if="item.framework == '枠組'"
                >枠組
              </span> -->
              <span id="id-center">{{ item.sekkei_materials }} </span>
            </a-tag>
          </v-col>

          <v-col cols="1">
            <!-- v-if="item.data[item.data.length - 1].updatedDate != ''" -->
            <a-tag
              class="mt-2 mx-1"
              color="#3d7ea6"
              style=" width: 100%; color: white; text-align: center"
              small
            >
              <span>
                {{
                  !item.updatedDate
                    ? myUpdatedDate(item.createdDate)
                    : myUpdatedDate(item.updatedDate)
                }}
                <!-- {{
                  !item.updatedDate
                    ? moment(item.createdDate).format("YYYY-MM-DD")
                    : moment(item.updatedDate).format("YYYY-MM-DD")
                }} -->
              </span>
              <!-- {{item}} -->
            </a-tag>
          </v-col>

          <v-col cols="1" id="id-center">
            <!-- <a-tag color="#4382BB" class="mt-2 ml-3"> -->
            <span class="mr-2">
              <v-icon color="#90d52a" style="font-size: small">mdi-eye</v-icon>
              {{ item.countViews }}
            </span>
            <!-- </a-tag> -->
            <!-- </v-col>

          <v-col cols="1"> -->
            <!-- <a-tag color="#26474E" class="mt-2"> -->
            <span class="ml-4">
              <v-icon small color="#ffa62b">mdi-star</v-icon>
              {{ item.FavoritesCount }}
            </span>
            <!-- </a-tag> -->
          </v-col>

          <v-col cols="12" md="12">
            <v-layout>
              <span
                class="title"
                v-if="searchSekkeiIconAlignment == true && item.showLine && selectedCondition != 'タイトル' && selectedCondition != '帳票番号'"
                v-html="`${item.ellipsis}`"
                :style="
                  item.ellipsis == 'Attachment Only'
                    ? 'color: white; '
                    : 'color: #555555;' + 'margin-left: 30px; font-size: 12px'
                "
              >
              </span>
            </v-layout>
          </v-col>
        </v-row>
      </v-card>

      <!-- No Data Record Label -->
      <a-empty
        v-if="loadingSekkei && !viewHistoryTable && paginatedSekkei.length == 0"
      />
    </v-card>

    <template v-if="viewHistoryTable">
      <div v-if="loadingSekkei">
        <v-row class="mb-n2">
          <v-col cols="8">
            <span class="ma-4" style="color: #0f3057; font-weight: bold" flat>
              Total: {{ filteredSekkeiHistory.length }}</span
            >|
            <span class="ma-4" style="color: #0f3057; font-weight: bold" flat>
              Total New: {{ sekkeiHistoryNewData.length }}
            </span>
            |
            <span class="ma-4" style="color: #0f3057; font-weight: bold" flat>
              Total Revised: {{ sekkeiHistoryReviseData.length }}
            </span>
            |
            <span class="ma-4" style="color: #0f3057; font-weight: bold" flat>
              Total Deleted: {{ sekkeiHistoryDeletedData.length }}
            </span>
          </v-col>
          <v-col cols="4">
            <span class="ma-4" style="color: #0f3057; font-weight: bold" flat
              >LEGEND :</span
            >
            <v-chip
              color="#FCF3CF"
              text-color="#0f3057"
              width
              style="width: 80px; font-weight: bold; height: 20px"
              class="pl-6 mx-1"
            >
              New
            </v-chip>
            <v-chip
              color="#0f3057"
              outlined
              style="width: 80px; font-weight: bold; height: 20px"
              class="mx-1"
            >
              Deleted
            </v-chip>
            <v-chip
              color="#D1F2EB"
              text-color="#0f3057"
              style="width: 80px; font-weight: bold; height: 20px"
              class="mx-1"
            >
              Revised
            </v-chip>
          </v-col>
        </v-row>

        <a-table
          :rowClassName="rowColor"
          showSizeChange
          :pagination="false"
          size="middle"
          row-key="id"
          bordered
          :columns="headerAdmin"
          :data-source="paginatedSekkeiHistory"
          :scroll="{ y: 500 }"
        >
          <template slot="updatedDate" slot-scope="text">
            <span v-if="text.updatedDate">
              {{ text.updatedDate }}
            </span>
            <span v-else>
              {{ text.createdDate }}
            </span>
          </template>

          <template slot="docNo" slot-scope="text">
            <a color="primary" text small @click="viewSekkeiHistory(text)">
              <div slot="title" style="color: #1890ff">
                {{ text.docNo }}
              </div>
            </a>
          </template>

          <template slot="updateDetails" slot-scope="text">
            <!-- <a-button
              type="primary"
              text
              size="small"
              style="width: 55px"
              v-if="text.version > 1"
              @click="openUpdateDetails(text)"
              >View</a-button
            > -->

            <a-button
              type="primary"
              text
              size="small"
              style="width: 55px"
              @click="
                openUpdateDetails2(text, paginatedSekkeiHistory.indexOf(text))
              "
              >View</a-button
            >

            <a-spin
              v-if="viewLoading[paginatedSekkeiHistory.indexOf(text)]"
            ></a-spin>
          </template>
          <template slot="sekkei_remarks" slot-scope="text">
            <span style="width: 80px" v-if="text.version == 0">
              <!-- <center> -->
              {{ "新規作成" }}
              <!-- </center> -->
            </span>
            <span v-else>
              {{ text.sekkei_remarks }}
            </span>
          </template>
        </a-table>
      </div>
    </template>

    <!-- Pagination -->
    <v-row>
      <!-- -- Removed 20231123- -->
      <v-col>
        <v-pagination
          v-model="pageNumber"
          :length="paginationLength"
          :total-visible="5"
          dark
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          v-if="loadingSekkei"
        ></v-pagination>
      </v-col>
    </v-row>

    <v-dialog
      v-model="ViewPdfDialog"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-if="ViewPdfDialog" @scroll="onScroll()">
        <shiyo-pdf-viewer
          :closeSekkeiDialog="onCloseSekkeiDialog"
          :sekkeiDetails="sekkeiDetails"
        />
      </template>
    </v-dialog>

    <update-details
      :updateDetailsDialog="updateDetailsDialog"
      @closeUpdateDetails="closeUpdateDetails"
      :historyDetails="historyDetails"
      :historyPrevDetails="historyPrevDetails"
    ></update-details>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import moji from "moji";
import _ from "lodash";
// import ShiyoPDFViewer from "./ShiyoPdfViewer.vue";
import UpdateDetails from "./UpdateDetailsSekkei.vue";

export default {
  components: {
    // "shiyo-pdf-viewer": ShiyoPDFViewer,
    "update-details": UpdateDetails,
  },
  data() {
    return {
      viewLoading: [],
      sekkeiDetails: {},
      ViewPdfDialog: false,
      sekkeiData: [],
      loadingSekkei: false,
      searchDocNo: "",
      searchTitleDocNo: "", // 2024-10-14
      searchText: "",
      selectedCondition: "AND",
      // condition: ["AND", "OR"],
      // condition: ["AND", "OR", "MULTI"],
      // condition: ["AND", "OR", "MULTI", "REFERENCE #", "TITLE"],
      condition: ["AND", "OR", "MULTI", "帳票番号", "タイトル"],
      pageRow: 25, // ADDED 20231122
      pageRow_menu: [25, 50, 100, "ALL"],
      exceptText: "",
      isFilterOn: false,
      hideFilter: false,//NOTE: show the filter in default 2024-04-18
      sortByProjectsASC: true,
      sortByProjectsDES: false,
      // roomPartList: ["ALL", "基本仕様"],
      selectedProjects: "ALL",
      roomPartList: [
        { text: "ALL", value: "ALL" },
        { text: "基本仕様", value: "基本仕様" },
        { text: "付帯", value: "付帯" },
        { text: "構造", value: "構造" },
        { text: "屋根", value: "屋根" },
        { text: "外壁", value: "外壁" },
        { text: "窓まわり", value: "窓まわり" },
        { text: "基礎", value: "基礎" },
        { text: "バルコニー", value: "バルコニー" },
        { text: "外部その他", value: "外部その他" },
        { text: "玄関", value: "玄関" },
        { text: "洋室", value: "洋室" },
        { text: "和室", value: "和室" },
        { text: "書斎", value: "書斎" },
        { text: "キッチン", value: "キッチン" },
        { text: "洗面所", value: "洗面所" },
        { text: "トイレ", value: "トイレ" },
        { text: "浴室", value: "浴室" },
        { text: "収納", value: "収納" },
        { text: "インテリア", value: "インテリア" },
        { text: "給湯器", value: "給湯器" },
        { text: "電気工事", value: "電気工事" },
        { text: "設備その他", value: "設備その他" },
      ],
      projectItems: [
        "ALL",
        "000_設計基本業務フロー",
        "010_敷地調査依頼・報告",
        "020_上棟枠予約",
        "030_水道事前調査依頼・報告",
        "040_ガス事前調査依頼・報告",
        "050_打合わせ",
        "060_CAD図面作成依頼",
        "070_カラーパース作成依頼",
        "080_配置図作成",
        "090_仕様検討ツール作成依頼",
        "100_地盤問合わせ",
        "110_施工連絡表作成",
        "120_設定外工事見積依頼",
        "130_電気図面作成依頼",
        "140_屋外計画図の決定",
        "150_水道図面（水道設備図面）",
        "160_ゼロチェック",
        "170_着手御承諾",
        "180_各種申請用配置図",
        "190_長期物件",
        "200_確認申請図面",
        "210_構造計算書・図書チェック",
        "220_フラット３５適合証明検査申請",
        "230_長期優良住宅認定申請",
        "240_低炭素建築物認定申請",
        "250_建築物省エネルギー性能表示制度",
        "260_すまい給付金",
        "270_押印依頼",
        "280_追加変更申請",
        "290_加工ストップ",
        "300_完了検査",
        "310_建築士事務所",
        "320_お引渡し日同席",
        "330_新規業者登録",
        "340_設計事務所業務",
        "350_追加請求",
        "360_預り金精算項目",
        "370_特建設計",
        "380_情報発信",
        "390_問い合わせ先",
        "400_役割",
        "410_仕様打合わせ室備品管理",
        "420_備品",
        "430_年間休日",
        "440_インテリア業務のアウトソーシング",
        "450_パソコン",
        "460_引継ぎ・各種変更業務",
        "470_iCAD",
        "480_ルールブックの確認",
        "490_設計課各種データ閲覧",
        "500_PDF編集",
        "510_Ｇｏｏｇｌｅ",
        "520_Zoom",
        "530_JW-CAD",
        "540_OJT"
      ],
      sortByMaterialsASC: true,
      sortByMaterialsDES: false,
      specsList: ["ALL", "計画ルール", "商品仕様・特徴", "参照資料"],
      materialItems: ["ALL", "業務マニュアル", "関連資料"],
      selectedMaterials: "ALL",
      sortByFavoriteASC: false,
      sortByFavoriteDES: false,
      isFavorite: false,
      sortByDateASC: true,
      sortByDateDES: false,
      isMultiple: false,
      searchSekkeiIconAlignment: false,
      pageNumber: 1,
      intRowNoSekkei: 30,
      searchLists: [],
      title_spacer: "　",
      favoriteList: [],
      frameworkList: ["ALL", "軸組", "枠組", "軸組/枠組"],
      kubunItems: ["ALL", "1_基本業務", "2_その他業務関係", "3_ツール"],
      sortByClassificationASC: true,
      sortByFrameworkDES: false,
      selectedClassification: "ALL",
      composing: false,
      sel_buildingType: ["ALL"],
      sel_buildingSpec: ["ALL"],

      buildingTypeList: [
        { text: "ALL", value: "ALL" },
        { text: "i-smart", value: "Ismart" },
        { text: "耐震", value: "Taishin" },
        { text: "i-cube", value: "Icube" },
        { text: "i-smile", value: "Ismile" },
        { text: "ファミーユ", value: "Famille" },
        { text: "グランセゾン", value: "G" },
        { text: "和風百年", value: "JapaneseStyle" },
        { text: "グラン・スマート", value: "Gsmart" },
        { text: "ブリアール", value: "B" },
        { text: "セゾンA", value: "A" },
        { text: "セゾンF", value: "F" },
        { text: "免", value: "Menshin" },
        { text: "セゾンF2-TD", value: "F2TD" },
        { text: "セゾンアシュレ", value: "Saison" },
        { text: "夢の家", value: "YumeNoIe" },
        { text: "一般", value: "Ippan" },
        { text: "HUGme", value: "HUGme" },
        { text: "HUGme fam", value: "HUGmefam" },
      ],

      buildingSpecList: [
        { text: "ALL", value: "ALL" },
        { text: "耐震", value: "Taishin" },
        { text: "免震", value: "Menshin" },
      ],

      isSearch: false,
      viewHistoryTable: false,
      headerAdmin: [
        {
          title: "更新日",
          width: 60,
          // dataIndex: "updatedDate",
          scopedSlots: { customRender: "updatedDate" },
          key: "updatedDate",
          // fixed: 'left',
          align: "left",
          sorter: (a, b) => a.updatedDate.localeCompare(b.updatedDate),
        },
        {
          title: "商品名（項目）",
          dataIndex: "sekkei_projects",
          width: 80,
          key: "sekkei_projects",
          align: "left",
          sorter: (a, b) => a.sekkei_projects.localeCompare(b.sekkei_projects),
        },
        {
          title: "区分",
          dataIndex: "sekkei_classification",
          width: 60,
          key: "sekkei_classification",
          align: "left",
          sorter: (a, b) =>
            a.sekkei_classification.localeCompare(b.sekkei_classification),
        },
        {
          title: "変更区分",
          // dataIndex: "sekkei_remarks",
          width: 80,
          key: "sekkei_remarks",
          scopedSlots: { customRender: "sekkei_remarks" },
          align: "left",
          sorter: (a, b) => a.sekkei_remarks.localeCompare(b.sekkei_remarks),
        },
        // {
        //   title: '前管理番号',
        //   key: 'preShiyoNumber',
        //   width:70,
        //   // dataIndex: 'shiyoNumber',
        //   scopedSlots: { customRender: 'preShiyoNumber' },
        //   align: 'left',
        //   sorter: (a, b) => a.preShiyoNumber.localeCompare(b.preShiyoNumber)
        // },
        {
          title: "新管理番号",
          key: "docNo",
          width: 50,
          // dataIndex: 'shiyoNumber',
          scopedSlots: { customRender: "docNo" },
          align: "left",
          sorter: (a, b) => a.docNo.localeCompare(b.docNo),
        },
        {
          title: "更新内容",
          key: "updateDetails",
          width: 70,
          scopedSlots: { customRender: "updateDetails" },
          align: "left",
          sorter: (a, b) => a.title.localeCompare(b.title),
        },
        {
          title: "更新内容",
          key: "title",
          width: 90,
          dataIndex: "title",
          scopedSlots: { customRender: "title" },
          align: "left",
          sorter: (a, b) => a.title.localeCompare(b.title),
        },

        // {
        //   title: "構法",
        //   width: 50,
        //   key: "framework",
        //   // scopedSlots: { customRender: "framework" },
        //   align: "left",
        //   sorter: (a, b) => a.framework.localeCompare(b.framework),
        // },
        // {
        //   title: 'Actions',
        //   width:140,
        //   key: 'actions',
        //   fixed: 'right',
        //   scopedSlots: { customRender: 'actions' },
        //   align: 'center'
        // },
      ],
      historyDetails: {},
      historyPrevDetails: {},
      dataHistory: [],
      updateDetailsDialog: false,
      sampleHistoryItems: [],
      lastEvaluatedKeyForHistory: null,
    };
  },
  methods: {
    //NOTE: merged search function for "AND" & "MULTI" - TITLE&ID search 2024-10-14
    async andMultiSearch_TitleID(){
      try{
        this.pageNumber = 1;
        this.selectedCondition='帳票番号＆タイトル'
        this.searchSekkeiIconAlignment = false;
        this.loadingSekkei = false;
        this.sekkeiData = await this.titleIDSearch()
        this.sekkeiData = _.uniqBy(this.sekkeiData.flat(), "id").map(r=>{
        this.$set(r,'showLine',false)
          return r
        });
        this.sekkeiData = this.sekkeiData
        .sort((a, b) => {
          return a.updatedDate > b.updatedDate ? -1 : 1;
        })
        .map((item) =>
          Object.assign({}, item, { ellipsis: item.textContent })
        );
      }catch(err){
        if(err){
          console.log(err)
        }
      }
    },
    titleIDSearch(){
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      return new Promise((resolve, reject) => {
        const url = `${this.api}sekkeiGyoumu/search2/sekkeiGyoumu/andmultipleTitleID?searchText=${this.searchTitleDocNo}&exceptWord=`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              this.loadingSekkei = true;
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    //NOTE: merged search function for "AND" & "MULTI" 2024-09-25
    async andMultiSearch(){
      try{
        this.pageNumber = 1;
        this.selectedCondition='MULTI'
        this.searchSekkeiIconAlignment = true;
        this.loadingSekkei = false;
        this.sekkeiData = await this.mergedSearch()
        this.sekkeiData = _.uniqBy(this.sekkeiData.flat(), "id").map(r=>{
        this.$set(r,'showLine',false)
          return r
        });
        this.sekkeiData = this.sekkeiData
        .sort((a, b) => {
          return a.updatedDate > b.updatedDate ? -1 : 1;
        })
        .map((item) =>
          Object.assign({}, item, { ellipsis: item.textContent })
        );
      }catch(err){
        if(err){
          console.log(err)
        }
      }
    },
    mergedSearch() {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      return new Promise((resolve, reject) => {
        const url = `${this.api}sekkeiGyoumu/search2/sekkeiGyoumu/andmultiple?searchText=${this.searchText}&exceptWord=`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              this.loadingSekkei = true;
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    // NOTE: added search function for search button 2024-06-21
    allSearch(){
      if(this.searchDocNo){
        this.selectedCondition = '帳票番号'
        this.onSearch()
      }
      else{
        this.selectedCondition = 'AND'
        this.fullTextSearch()
      }
    },
    // NOTE: added fulltext search include "AND" conditiona and "MULTI" 2024-06-15
    fullTextSearch(){
      this.loadingSekkei = false;
      this.searchDocNo = ""
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      const url = `${this.api}check/multi?group=${this.searchText}`;
      axios.get(url).then((res)=>{
        if(res.data){
          this.onMultipleSearch()
        }
        else{
          this.onSearch()
        }
      })
    },
    // NOTE: added toggle for show/hide of subline after searching 2024-05-23
    toggleShowLine(item){
      let index = this.sekkeiData.indexOf(item)
      if(index > -1){
        this.sekkeiData[index].showLine = !this.sekkeiData[index].showLine
      }
    },

    onScroll() {
      // console.log("zzzzzzzzzzzzzz");
    },
    rowColor(rec) {
      // console.log('rec',rec)
      return rec.sekkei_status == "deleted"
        ? ""
        : rec.version > 1 || rec.sekkei_status == "revised"
        ? "bg-green"
        : "bg-yellow";
      // return (rec.remarks == "Original Copy" || rec.remark == null || undefined ? 'bg-green' : rec.status == "New"? 'bg-yellow' : '')
    },
    viewSekkeiHistory(item, prevIndex) {
      if (!prevIndex) {
        window.open(`sekkei_document_history/${item.id}`, "_blank");
      } else {
        if (item.version > 1) {
          window.open(
            `sekkei_document_history/${item.id}?index=${item.prevIndex}`,
            "_blank"
          );
        } else {
          alert("new");
        }
      }
    },
    async openUpdateDetails(data, key) {
      // console.log(key);
      // console.log("Picked Data", data);
      // console.log("Picked Data", data.version);
      let historyData = this.dataHistory;
      let data_docNo = data.docNo;
      let data_id = data.id;
      this.viewLoading[key] = true;
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      let url = `${this.api}sekkeiGyoumu/getSpecificSekkeiGyoumuHistory/${data_id}`;
      await axios.get(url).then((res) => {
        if (res.data) {
          // console.log(res.data);
          this.historyDetails = res.data;
        }
      });
      let sekkeiDataPreviousVersion = data.version != 0 ? data.version - 1 : "";
      // console.log(sekkeiDataPreviousVersion);
      let filteredData = historyData
        .filter((el) => el.docNo == data_docNo)
        .filter((el) => el.id != data_id)
        .filter((el) => sekkeiDataPreviousVersion == el.version);
      // console.log("filteredData", filteredData);
      if (filteredData[filteredData.length - 1]) {
        let url2 = `${this.api}sekkeiGyoumu/getSpecificSekkeiGyoumuHistory/${
          filteredData[filteredData.length - 1].id
        }`;
        await axios.get(url2).then((res) => {
          if (res.data) {
            // console.log(res.data);
            this.viewLoading[key] = false;
            this.historyPrevDetails = res.data;
            this.updateDetailsDialog = true;
          }
        });
      }
      if (!filteredData[0]) {
        this.viewLoading[key] = false;
        this.updateDetailsDialog = true;
      }
      // this.historyDetails = data;
      // this.historyPrevDetails = filteredData;
      // this.viewLoading = false;
      // this.updateDetailsDialog = true;
    },
    async openUpdateDetails2(data, key) {
      console.log(data);
      console.log(key);
      let historyData = this.dataHistory;
      let data_docNo = data.docNo;
      let data_id = data.id;
      // this.viewLoading[key] = true;
      // axios.defaults.headers.common["x-api-key"] =
      //   "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      // let url = `${this.api}sekkeiGyoumu/getSpecificSekkeiGyoumuHistory/${data_id}`;
      // await axios.get(url).then((res) => {
      //   if (res.data) {
      //     // console.log(res.data);
      //     this.historyDetails = res.data;
      //   }
      // });
      let sekkeiDataPreviousVersion = data.version != 0 ? data.version - 1 : "";
      // console.log(sekkeiDataPreviousVersion);
      let filteredData = historyData
        .filter((el) => el.docNo == data_docNo)
        .filter((el) => el.id != data_id)
        .filter((el) => sekkeiDataPreviousVersion == el.version);
      // console.log(filteredData);
      let data_id2 = filteredData[filteredData.length - 1]
        ? filteredData[filteredData.length - 1].id
        : "";
      window.open(
        `sekkeiGyoumuComparing/${data_id}?search=${this.searchText}&id2=${data_id2}`,
        "_blank"
      );
      // console.log("filteredData", filteredData);
      // if (filteredData[filteredData.length - 1]) {
      //   let url2 = `${this.api}sekkeiGyoumu/getSpecificSekkeiGyoumuHistory/${
      //     filteredData[filteredData.length - 1].id
      //   }`;
      //   await axios.get(url2).then((res) => {
      //     if (res.data) {
      //       // console.log(res.data);
      //       this.viewLoading[key] = false;
      //       this.historyPrevDetails = res.data;
      //       this.updateDetailsDialog = true;
      //     }
      //   });
      // }
      // if (!filteredData[0]) {
      //   this.viewLoading[key] = false;
      //   this.updateDetailsDialog = true;
      // }
      // // this.historyDetails = data;
      // // this.historyPrevDetails = filteredData;
      // // this.viewLoading = false;
      // // this.updateDetailsDialog = true;
    },

    closeUpdateDetails(val) {
      this.updateDetailsDialog = val;
    },

    onCloseSekkeiDialog() {
      this.ViewPdfDialog = false;
    },
    onChangebuilding() {
      if (
        this.sel_buildingType.includes("ALL") &&
        this.sel_buildingType.length == 2
      ) {
        this.sel_buildingType.splice(0, 1);
      }
      if (this.sel_buildingType.length == 0) {
        this.sel_buildingType = ["ALL"];
      }
      if (
        this.sel_buildingType.includes("ALL") &&
        this.sel_buildingType.length != 1
      ) {
        this.sel_buildingType = ["ALL"];
      }
    },
    onChangeSpec() {
      if (
        this.sel_buildingSpec.includes("ALL") &&
        this.sel_buildingSpec.length == 2
      ) {
        this.sel_buildingSpec.splice(0, 1);
      }
      if (this.sel_buildingSpec.length == 0) {
        this.sel_buildingSpec = ["ALL"];
      }
      if (
        this.sel_buildingSpec.includes("ALL") &&
        this.sel_buildingSpec.length != 1
      ) {
        this.sel_buildingSpec = ["ALL"];
      }
    },

    sortByProjects(asc) {
      let withProjects = this.sekkeiData.filter((rec) => {
        return rec.sekkei_projects !== undefined && rec.sekkei_projects != "";
      });

      let noProjects = this.sekkeiData.filter((rec) => {
        return rec.sekkei_projects === undefined || rec.sekkei_projects == "";
      });

      if (asc) {
        withProjects.sort((a, b) => {
          return a.sekkei_projects.localeCompare(b.sekkei_projects);
        });
        this.sekkeiData = [...withProjects, ...noProjects];
      } else {
        withProjects.sort((a, b) => {
          return b.sekkei_projects.localeCompare(a.sekkei_projects);
        });
        this.sekkeiData = [...noProjects, ...withProjects];
      }
    },

    sortByClassification(asc) {
      let withClassification = this.sekkeiData.filter((rec) => {
        return (
          rec.sekkei_classification !== undefined &&
          rec.sekkei_classification != ""
        );
      });

      let noClassification = this.sekkeiData.filter((rec) => {
        return (
          rec.sekkei_classification === undefined ||
          rec.sekkei_classification == ""
        );
      });

      if (asc) {
        withClassification.sort((a, b) => {
          return a.sekkei_classification.localeCompare(b.sekkei_classification);
        });
        this.sekkeiData = [...withClassification, ...noClassification];
      } else {
        withClassification.sort((a, b) => {
          return b.sekkei_classification.localeCompare(a.sekkei_classification);
        });
        this.sekkeiData = [...noClassification, ...withClassification];
      }
    },

    sortByDate(action) {
      // if(action){
      //   return this.shiyoData.sort((a,b) => {
      //     return a.updatedDate.localeCompare(b.updatedDate)
      //   })
      // }else{
      //   return this.shiyoData.sort((a,b) => {
      //     return b.updatedDate.localeCompare(a.updatedDate)
      //   })
      // }
      let withData = this.sekkeiData
        .filter((rec) => {
          return rec.data !== undefined;
        })
        .filter((rec) => {
          return (
            rec.data[rec.data.length - 1].updatedDate !== undefined &&
            rec.data[rec.data.length - 1].updatedDate != ""
          );
        });

      let withNoData = this.sekkeiData.filter((rec) => {
        return (
          rec.data === undefined ||
          rec.data[rec.data.length - 1].updatedDate === undefined ||
          rec.data[rec.data.length - 1].updatedDate == ""
        );
      });

      // let withUpdatedDate = withData.filter(rec =>{

      //   return rec.data[rec.data.length -1].updatedDate !== undefined &&
      //   rec.data[rec.data.length -1].updatedDate != ''
      // })

      // let noUpdatedDate = withData.filter(rec =>{
      //   return rec.data[rec.data.length -1].updatedDate === undefined ||
      //   rec.data[rec.data.length -1].updatedDate == ''
      // })

      if (action) {
        withData.sort((a, b) => {
          // console.log("line 673", a);
          return a.data[a.data.length - 1].updatedDate.localeCompare(
            b.data[b.data.length - 1].updatedDate
          );
        });
        this.sekkeiData = [...withData, ...withNoData];
      } else {
        withData.sort((a, b) => {
          // console.log("line679", a);
          return b.data[b.data.length - 1].updatedDate.localeCompare(
            a.data[a.data.length - 1].updatedDate
          );
        });
        this.sekkeiData = [...withNoData, ...withData];
      }
    },
    sortByMaterials(action) {
      // console.log('line 661')
      // if(this.selectedSpecs){
      //   this.sortByDocNumber(action)
      // }else{
      //   if(action){
      //     return this.shiyoData.sort((a,b) => {
      //       return a.specifications.localeCompare(b.specifications)
      //     })
      //   }else{
      //     return this.shiyoData.sort((a,b) => {
      //       return b.specifications.localeCompare(a.specifications)
      //     })
      //   }
      // }

      let withMaterials = this.sekkeiData.filter((rec) => {
        return rec.sekkei_materials !== undefined && rec.sekkei_materials != "";
      });

      let noMaterials = this.sekkeiData.filter((rec) => {
        return rec.sekkei_materials === undefined || rec.sekkei_materials == "";
      });

      if (action) {
        withMaterials.sort((a, b) => {
          return a.sekkei_materials.localeCompare(b.sekkei_materials);
        });
      } else {
        withMaterials.sort((a, b) => {
          return b.sekkei_materials.localeCompare(a.sekkei_materials);
        });
      }

      this.sekkeiData = [...withMaterials, ...noMaterials];
    },
    sortByDocNumber(action) {
      if (action) {
        return this.sekkeiData.sort((a, b) => {
          return a.id.localeCompare(b.id);
        });
      } else {
        return this.sekkeiData.sort((a, b) => {
          return b.id.localeCompare(a.id);
        });
      }
    },
    // sortByFramework(action){
    //   if(this.selectedFramework){
    //     this.sortByDocNumber(action)
    //   }else{
    //     if(action){
    //     return this.shiyoData.sort((a,b) => {
    //         return a.framework.localeCompare(b.framework)
    //       })
    //     }else{
    //       return this.shiyoData.sort((a,b) => {
    //         return b.framework.localeCompare(a.framework)
    //       })
    //     }
    //   }
    // },

    ////Hazel - 20211030 - changed the function on getting the value of roomPart (before: 基本仕様 + 0 ===>>> after: 基本仕様・キッチン・洗面所・キッチン)
    getRoomPart(roompart) {
      if (roompart.length > 0) {
        return roompart.toString().replace(/,/g, "・");
      } else {
        return roompart.toString();
      }
    },
    // keysha for UpdatedDate
    myUpdatedDate(paramDate) {
      // if (paramDate) {
      //   console.log(paramDate);
      return `更新日:${moment(paramDate).format("YYYY-MM-DD")}`;
      // } else {
      //   return "Original Copy";
      // }
    },
    getUserFavorites() {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      // this.loadingSekkei = true;
      let toGet = {
        userId: this.userInfo.id,
      };
      // console.log(toGet)
      let url = `https://385rohlp8h.execute-api.us-east-2.amazonaws.com/prod/getFavorites/sekkei`;
      axios
        .post(url, toGet)
        .then((res) => {
          // console.log(res.data);

          this.favoriteList = res.data;
          // console.log(this.favoriteList);
          // console.log(this.favoriteList)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    listColorSearch(id, sekkei_classification) {
      if (
        this.searchLists.includes(id) &&
        this.searchLists.includes(sekkei_classification)
      ) {
        return "viewed";
      }
    },
    async openSekkei(item) {
      // if (item.data[item.version - 1].isWithPDFAttachment) {
      //   this.sekkeiDetails = item.data[item.version - 1];
      //   // this.ViewPdfDialog = true
      // window.open(
      //   `/sekkei_document/${item.id}?search=${this.searchText}`,
      //   `_blank`
      // );
      // if (item) {
      //   return console.log(item);
      // }
      let isFavSekkei = false;
      for (let x in this.favoriteList) {
        if (this.favoriteList[x].id == item.id) {
          isFavSekkei = true;
          break; // No need to continue checking if a match is found
        }
      }

      if (
        item.attachmentFileName &&
        item.attachmentFileName.toLowerCase().includes(".pdf")
      ) {
        return window.open(
          `/sekkei_gyoumu/${item.id}?docNo=${item.docNo}&attachmentFileName=${item.attachmentFileName}&title=${item.title}`,
          `_blank`
        );
      }
      // if (
      //   item.attachmentFileName &&
      //   (item.attachmentFileName.includes(".xlsx") ||
      //     item.attachmentFileName.includes(".xls"))
      // ) {
      //   return console.log(item.attachmentFileName);
      // }
      //heres the true new window
      window.open(
        `/sekkei_document/${item.id}?search=${this.searchText}&favorite=${isFavSekkei}`,
        `_blank`
      );
      // console.log(item.countViews);
      //start from here
      // if (item.countViews == undefined) {
      //   let count = 1;
      //   item.countViews = count;
      //   //   console.log(item);

      //   //   console.log("non");
      //   axios.defaults.headers.common["x-api-key"] =
      //     "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      //   await axios
      //     .post(
      //       `${this.awsURL}sekkeiGyoumu/insertSekkeiGyoumuFinalDataTableCountViews`,
      //       item
      //     )
      //     .then(() => {
      //       console.log("Views Added");
      //     });
      // } else if (item.countViews != undefined) {
      //   let countplus = item.countViews + 1;
      //   let itemId = item.id;
      //   let obj = { id: itemId, countViews: countplus };
      //   axios.defaults.headers.common["x-api-key"] =
      //     "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      //   await axios.put(
      //     `${this.awsURL}/sekkeiGyoumu/updateSekkeiGyoumuFinalDataTableCountViews`,
      //     obj
      //   );
      // }
      //up to here
      //   // window.open(`japan_memo/${data.id}?docNo=${data.docNo}`, "_blank")
      // } else {
      //   window.open(
      //     `/sekkei_document/${item._id}?search=${this.searchText}`,
      //     `_blank`
      //   );
      // }
      // console.log(item,'aaaaaaaaaa')
    },

    favoriteFunction(data) {
      let favorite = true;
      if (this.isFavIcon(data) == "mdi-star") {
        favorite = false;
      }
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      let toInsert = {
        id: data.id,
        docNo: data.docNo,
        title: data.title,
        userId: this.userInfo.id,
        isFavorite: favorite,
      };

      let url = `${this.api}addFavorite/sekkei`;
      let url2 = `${this.api}addFavoriteCount/sekkei`;
      axios.post(url2, toInsert).then(() => {
        axios.post(url, toInsert).then((res) => {
          if (res.data == "Add Favorite") {
            Swal.fire({
              icon: "success",
              title: `${data.docNo} added to Favorites`,
              showConfirmButton: false,
              timer: 1200,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: `${data.docNo} deleted to Favorites`,
              showConfirmButton: false,
              timer: 1200,
            });
          }
          if (this.searchText != "") {
            this.onSearch();
            // this.getUserFavorites()
          } else {
            this.loadSekkeiRecords();
            // this.getUserFavorites()
          }
        });
      });
    },

    isFavColor(data) {
      let isFavShiyo = false;

      this.favoriteList.map((r) => {
        if (r.id == data.id && r.docNo == data.docNo) {
          isFavShiyo = true;
        }
      });
      if (isFavShiyo) {
        return "yellow";
      } else {
        return "black";
      }
    },

    isFavIcon(data) {
      let isFavShiyo = false;
      this.favoriteList.map((r) => {
        if (r.id == data.id && r.docNo == data.docNo) {
          isFavShiyo = true;
        }
      });
      if (isFavShiyo) {
        return "mdi-star";
      } else {
        return "mdi-star-outline";
      }
    },
    // async filterFavorites(){
    //   // await this.getUserFavorites();
    //   axios.defaults.headers.common["x-api-key"] =
    //     "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
    //   this.shiyoData = [];
    //   if (this.isFavorite) {
    //     if (this.favoriteList.length > 0) {
    //       for (let i = 0; i < this.favoriteList.length; i++) {
    //         let url2 = `${this.awsURL}shiyo/getDetailsFavorite?id=${this.favoriteList[i].id}&docNo=${this.favoriteList[i].docNo}`;
    //         // console.log(url2)
    //         await axios.get(url2).then((res) => {
    //           this.shiyoData.push(res.data);
    //           this.loadingShiyo = false;
    //         });
    //       }
    //     } else {
    //       this.loadingShiyo = false;
    //     }
    //   } else {
    //     this.clearSearch();
    //   }
    // },
    clearSearch() {
      this.isMultiple = false;
      this.searchSekkeiIconAlignment = false;
      this.isFavorite = false;
      this.loadingSekkei = true;
      this.sortByProjectsASC = false;
      this.sortByProjectsDES = false;
      this.sortByMaterialsASC = false;
      this.sortByMaterialsDES = false;
      this.sortByFavoriteASC = false;
      this.sortByFavoriteDES = false;
      this.sortByDateASC = false;
      this.sortByDateDES = false;
      this.loadSekkeiRecords();
      // this.getUserFavorites();
      this.searchText = "";
      this.searchDocNo = "";
      this.exceptText = "";
    },
    convertSearchText(word) {
      return {
        ZE_HE: moji(word)
          .convert("ZE", "HE")
          .toString(),
        HE_ZE: moji(word)
          .convert("HE", "ZE")
          .toString(),
        HK_ZK: moji(word)
          .convert("HK", "ZK")
          .toString(),
        ZK_HK: moji(word)
          .convert("ZK", "HK")
          .toString(),
        HG_KK: moji(word)
          .convert("HG", "KK")
          .toString(),
        KK_HG: moji(word)
          .convert("KK", "HG")
          .toString(),
      };
    },
    promiseGetSearchWord(condition = "and", search1 = "", search2 = "") {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}sekkeiGyoumu/search/sekkeiGyoumu/${condition}?search1=${search1}&search2=${search2}&exceptWord=${this.exceptText}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    promiseGetSearchWordNew(condition = "and", searchText) {
      // 2024-01-23
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}sekkeiGyoumu/search2/sekkeiGyoumu/${condition}?searchText=${searchText}&exceptWord=${this.exceptText}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    promiseGetAllSearchWords(
      selectedCondition = "and",
      keywordsArr1 = [],
      keywordsArr2 = []
    ) {
      let tmpSearchData = [];
      let count = 0;

      return new Promise((resolve, reject) => {
        for (let i = 0; i < keywordsArr1.length; i++) {
          for (let j = i; j < keywordsArr2.length; j++) {
            const url = `${this.api}sekkeiGyoumu/search/sekkeiGyoumu/${selectedCondition}?search1=${keywordsArr1}&search2=${keywordsArr2}&exceptWord=${this.exceptText}`;
            axios
              .get(url)
              .then((res) => {
                if (res.data) {
                  tmpSearchData.push(res.data);
                  count++;

                  if (count == keywordsArr1.length) {
                    // console.log("finished");
                    let toResolve = _.uniqBy(tmpSearchData.flat(), "id");
                    resolve(toResolve);
                  }
                }
              })
              .catch((err) => {
                reject({ message: err.message });
                this.loadingKanren = false;
              });
          }
        }
      });
    },
    //newly added 20230803
    promiseGetSearchWordByDocumentCategory(condition = "", search = "") {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}sekkeiGyoumu/search/sekkeiGyoumu/${condition}/?search1=${search}&exceptWord=${this.exceptText}`;
        // console.log(url);
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              // console.log(res.data);
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    //2024-01-23 FOR DYNAMIC SEARCHING
    promiseGetSearchWordByDocumentCategoryNew(condition = "", search = "") {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}sekkeiGyoumu/search2/sekkeiGyoumu/${condition}/?search1=${search}&exceptWord=${this.exceptText}`;
        // console.log(url);
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              // console.log(res.data);
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    async onSearch() {
      this.pageNumber = 1;
      // this.selectedCondition='帳票番号'//NOTE: merged searching function
      let japaneseSpace = "　";
      if (!this.composing) {
        this.searchSekkeiIconAlignment = true;
        this.isMultiple = false;
        // let searchText = this.searchText.toString().split(" ");
        // console.log(searchText, "earchText");
        // let url = `${this.awsURL}sekkeiGyoumu/search/sekkeiGyoumu/and/?search1=${searchText[0]}&search2=${searchText[1]}&exceptWord=${this.exceptText}`;
        // console.log(this.searchText, "search");
        // await axios.get(url).then((res) => {
        //   if (res.data) {
        //     console.log(res.data);
        //   }
        // });
        if (this.searchText) {
          this.loadingSekkei = false;
          // 2 Words
          if (
            this.searchText.includes(" ") ||
            this.searchText.includes(japaneseSpace)
          ) {
            // Split Two Words into array
            let splitKeyWord = this.searchText.replace(/\s+/g, " ").split(" ");

            // Get Keyword per index ( as of now 2 words only )
            let keywordsArr1 = Object.values(
              this.convertSearchText(splitKeyWord[0])
            );
            let keywordsArr2 = Object.values(
              this.convertSearchText(splitKeyWord[1])
            );

            console.log("Search word 1 --> ", keywordsArr1);
            console.log("Search word 2 --> ", keywordsArr2);

            // Selected Condition
            // ===================================== AND =========================================== //
            if (this.selectedCondition == "AND") {
              if (keywordsArr1.length != 0 && keywordsArr2.length != 0) {
                let finalData = await this.promiseGetSearchWordNew(
                  "and",
                  // splitKeyWord[0],
                  // splitKeyWord[1]
                  this.searchText
                );
                // console.log(finalData);
                this.sekkeiData = _.uniqBy(finalData.flat(), "id").map(r=>{
                  this.$set(r,'showLine',false)
                  return r
                });
                this.sekkeiData = this.sekkeiData
                  .sort((a, b) => {
                    return a.updatedDate > b.updatedDate ? -1 : 1;
                  })
                  .map((item) =>
                    Object.assign({}, item, { ellipsis: item.textContent })
                  );

                this.loadingSekkei = true;
              }
            } else {
              // ==================================== OR ============================================== //
              if (keywordsArr1.length != 0 && keywordsArr2.length != 0) {
                let finalData = await this.promiseGetSearchWordNew(
                  "or",
                  // splitKeyWord[0],
                  // splitKeyWord[1]
                  this.searchText
                );
                this.sekkeiData = _.uniqBy(finalData.flat(), "id").map(r=>{
                  this.$set(r,'showLine',false)
                  return r
                });
                this.sekkeiData = this.sekkeiData
                  .sort((a, b) => {
                    return a.updatedDate > b.updatedDate ? -1 : 1;
                  })
                  .map((item) =>
                    Object.assign({}, item, { ellipsis: item.textContent })
                  );
              }
              this.loadingSekkei = true;
            }
          } else {
            //added 20230803
            if (this.selectedCondition == "帳票番号") {
              this.searchText= ""
              if (this.searchDocNo.trim()) {
                let finalData = await this.promiseGetSearchWordByDocumentCategoryNew(
                  "byDocumentNumber",
                  this.searchDocNo
                );
                this.sekkeiData = finalData.map(r=>{
                  this.$set(r,'showLine',false)
                  return r
                });
                this.sekkeiData = this.sekkeiData
                  .sort((a, b) => {
                    return a.updatedDate > b.updatedDate ? -1 : 1;
                  })
                  .map((item) =>
                    Object.assign({}, item, { ellipsis: item.textContent })
                  );
                // console.log("sekkeiData", this.sekkeiData);
                // this.searchDialog = false;
                return (this.loadingSekkei = true);
              } else {
                alert(`Please Input Reference Number`);
                return (this.loadingSekkei = true);
              }
            }
            // Check If Selected Condition is BY TITLE
            if (this.selectedCondition == "タイトル") {
              if (this.searchText.trim()) {
                let finalData = await this.promiseGetSearchWordByDocumentCategoryNew(
                  "byDocumentTitle",
                  this.searchText
                );
                this.sekkeiData = finalData.map(r=>{
                  this.$set(r,'showLine',false)
                  return r
                });
                this.sekkeiData = this.sekkeiData
                  .sort((a, b) => {
                    return a.updatedDate > b.updatedDate ? -1 : 1;
                  })
                  .map((item) =>
                    Object.assign({}, item, { ellipsis: item.textContent })
                  );
                // this.searchDialog = false;
                return (this.loadingSekkei = true);
              } else {
                alert(`Please Input Document Title`);
                return (this.loadingSekkei = true);
              }
            }

            // 1 Word  AND
            if (this.selectedCondition == "AND") {
              // let search = this.convertSearchText(this.searchText);
              // let searchKeyArr = Object.values(search);

              // console.log("Search Word --> ", _.uniq(searchKeyArr));

              let finalData = await this.promiseGetSearchWord(
                "and",
                this.searchText
              );
              // console.log(finalData);
              this.sekkeiData = _.uniqBy(finalData.flat(), "id").map(r=>{
                this.$set(r,'showLine',false)
                return r
              });
              // console.log(this.sekkeiData);
              this.sekkeiData = this.sekkeiData
                .sort((a, b) => {
                  return a.updatedDate > b.updatedDate ? -1 : 1;
                })
                .map((item) =>
                  Object.assign({}, item, { ellipsis: item.textContent })
                );

              this.loadingSekkei = true;
            } else {
              alert(
                "Search words should be atleast 2 words separated with space"
              );
            }
          }
        }
        else if(this.searchDocNo && this.selectedCondition == "帳票番号"){
          this.loadingSekkei = false;
          this.searchText= ""
          if (this.searchDocNo.trim()) {
            let finalData = await this.promiseGetSearchWordByDocumentCategoryNew(
              "byDocumentNumber",
              this.searchDocNo
            );
            this.sekkeiData = finalData.map(r=>{
              this.$set(r,'showLine',false)
              return r
            });
            this.sekkeiData = this.sekkeiData
              .sort((a, b) => {
                return a.updatedDate > b.updatedDate ? -1 : 1;
              })
              .map((item) =>
                Object.assign({}, item, { ellipsis: item.textContent })
              );
            // console.log("sekkeiData", this.sekkeiData);
            // this.searchDialog = false;
            return (this.loadingSekkei = true);
          } else {
            alert(`Please Input Reference Number`);
            return (this.loadingSekkei = true);
          }
        }

        // // ////the this.convert2FullKatakana() function is located at the plugins/myMixins////
        // let search = this.convert2FullKatakana(this.searchText);
        // let except = "";
        // if (this.exceptText != "") {
        //   except = this.convert2FullKatakana(this.exceptText);
        // }

        // if (this.searchText != "") {
        //   this.loadingSekkei = false;
        // }

        // if (this.searchText) {
        //   this.isSearch = true;
        //   let url = "";
        //   let ishiragana = moji(search)
        //     .filter("HG")
        //     .toString();
        //   let iskatakana = moji(search)
        //     .filter("KK")
        //     .toString();
        //   let hiragana = "";
        //   let katakana = "";
        //   if (ishiragana) {
        //     hiragana = moji(this.searchText)
        //       .convert("HG", "KK")
        //       .toString();
        //     search = `${search}${this.title_spacer}${hiragana}`;
        //   } else if (iskatakana) {
        //     search = this.convert2FullKatakana(this.searchText);
        //     katakana = moji(search)
        //       .convert("KK", "HG")
        //       .toString();
        //     search = this.convert2FullKatakana(search);
        //     katakana = this.convert2FullHiragana(katakana);
        //     search = `${search}${this.title_spacer}${katakana}`;
        //   } else {
        //     search = this.convert2FullKatakana(this.searchText);
        //   }
        //   //-----SMALL AND BIG ALPHA NUMERIC CHARACTERS CND
        //   let numSmall = moji(search)
        //     .filter("HE")
        //     .toString();
        //   let numBig = moji(search)
        //     .filter("ZE")
        //     .toString();

        //   if (search.match(/[0-9]/g) || search.match(/[0-9]/g)) {
        //     if (numSmall) {
        //       numSmall = moji(search)
        //         .convert("HE", "ZE")
        //         .toString();
        //       search = `${search}${this.title_spacer}${numSmall}`;
        //     } else if (numBig) {
        //       numBig = search = `${search}${this.title_spacer}${moji(search)
        //         .convert("ZE", "HE")
        //         .toString()}`;
        //     }
        //   }
        //   //-----HALF AND FULL WIDTH  CHARACTERS
        //   let fullKatakana = moji(search)
        //     .filter("ZK")
        //     .toString();
        //   let halfKatakana = moji(search)
        //     .filter("HK")
        //     .toString();
        //   if (fullKatakana) {
        //     fullKatakana = moji(search)
        //       .convert("ZK", "HK")
        //       .toString();
        //     search = `${search}${this.title_spacer}${fullKatakana}`;
        //   } else if (halfKatakana) {
        //     halfKatakana = moji(search)
        //       .convert("HK", "ZK")
        //       .toString();
        //     search = `${search}${this.title_spacer}${halfKatakana}`;
        //   }

        //   let ishiragana_except = moji(except)
        //     .filter("HG")
        //     .toString();
        //   let iskatakana_except = moji(except)
        //     .filter("KK")
        //     .toString();
        //   let hiragana_except = "";
        //   let katakana_except = "";

        //   if (ishiragana_except) {
        //     hiragana_except = moji(this.exceptText)
        //       .convert("HG", "KK")
        //       .toString();
        //     except = `${except}${this.title_spacer}${hiragana_except}`;
        //   } else if (iskatakana_except) {
        //     except = this.convert2FullKatakana(this.exceptText);
        //     katakana_except = moji(except)
        //       .convert("KK", "HG")
        //       .toString();
        //     except = this.convert2FullKatakana(except);
        //     katakana_except = this.convert2FullHiragana(katakana_except);
        //     except = `${except}${this.title_spacer}${katakana_except}`;
        //   } else {
        //     except = this.convert2FullKatakana(this.exceptText);
        //   }

        //   //-----SMALL AND BIG ALPHA NUMERIC CHARACTERS CND
        //   let numSmall_except = moji(except)
        //     .filter("HE")
        //     .toString();
        //   let numBig_except = moji(except)
        //     .filter("ZE")
        //     .toString();

        //   if (except.match(/[0-9]/g) || except.match(/[0-9]/g)) {
        //     if (numSmall_except) {
        //       numSmall_except = moji(except)
        //         .convert("HE", "ZE")
        //         .toString();
        //       except = `${except}${this.title_spacer}${numSmall_except}`;
        //     } else if (numBig_except) {
        //       numBig_except = except = `${except}${this.title_spacer}${moji(
        //         except
        //       )
        //         .convert("ZE", "HE")
        //         .toString()}`;
        //     }
        //   }
        //   //-----HALF AND FULL WIDTH  CHARACTERS
        //   let fullKatakana_except = moji(except)
        //     .filter("ZK")
        //     .toString();
        //   let halfKatakana_except = moji(except)
        //     .filter("HK")
        //     .toString();
        //   if (fullKatakana_except) {
        //     fullKatakana_except = moji(except)
        //       .convert("ZK", "HK")
        //       .toString();
        //     except = `${except}${this.title_spacer}${fullKatakana_except}`;
        //   } else if (halfKatakana_except) {
        //     halfKatakana_except = moji(except)
        //       .convert("HK", "ZK")
        //       .toString();
        //     except = `${except}${this.title_spacer}${halfKatakana_except}`;
        //   }

        //   if (this.selectedCondition == "OR") {
        //     if (this.exceptText == "") {
        //       console.log("OR search");
        //       url = `${this.api}shiyo/shiyoSearch/or/${search}`;
        //     } else {
        //       console.log("OR with EXCEPT search");
        //       url = `${this.api}shiyo/shiyoSearch/orWithExcept/?search=${search}&except=${except}`;
        //       // console.log(url)
        //     }
        //   } else {
        //     if (this.exceptText == "") {
        //       console.log("AND search");
        //       // if(search.match(/[０-９]/g)){
        //       //   search = `${moji(search).convert("ZE", "HE").toString()}`;
        //       // }
        //       // url = `${this.api}shiyo/shiyoSearch/and/${search}`; original API of AND
        //       url = `${this.api}shiyo/shiyoSearch/or/${search}`;
        //     } else {
        //       console.log("AND with EXCEPT search");
        //       // if(search.match(/[０-９]/g) && except.match(/[０-９]/g)){
        //       //   search = `${moji(search).convert("ZE", "HE").toString()}`;
        //       //   except = `${moji(except).convert("ZE", "HE").toString()}`;
        //       // }
        //       url = `${this.api}shiyo/shiyoSearch/andWithExcept?search=${search}&except=${except}`;
        //     }
        //   }
        //   // console.log(url)
        //   axios.get(url).then((res) => {
        //     // console.log(res.data)
        //     this.sekkeiData = res.data;
        //     this.loadingSekkei = true;
        //   });
        // } else {
        //   if (this.isSearch) {
        //     this.clearSearch();
        //   }
        // }
        // //  -----------------------------------------------------------------
        // // let search = this.convert2FullKatakana(this.searchText);
        // // console.log(search)

        // // if(this.searchText != ""){
        // //   this.loadingShiyo = true
        // // }
        // // if(this.searchText){
        // //   let url = ""
        // //   if(this.selectedCondition == "OR"){
        // //     let ishiragana = moji(search).filter("HG").toString();
        // //     let iskatakana = moji(search).filter("KK").toString();
        // //     let hiragana = "";
        // //     let katakana = ""
        // //     if(ishiragana){
        // //       hiragana = moji(this.searchText).convert("HG", "KK").toString()
        // //       search = `${search}${this.title_spacer}${hiragana}`
        // //     }else if(iskatakana){
        // //       search = this.convert2FullKatakana(this.searchText)
        // //       katakana = moji(search).convert("KK", "HG").toString()
        // //       search = this.convert2FullKatakana(search)
        // //       katakana = this.convert2FullHiragana(katakana)
        // //       search = `${search}${this.title_spacer}${katakana}`
        // //     }else{
        // //       search = this.convert2FullKatakana(this.searchText)
        // //     }
        // //     //-----SMALL AND BIG ALPHA NUMERIC CHARACTERS CND
        // //     let numSmall = moji(search).filter("HE").toString()
        // //     let numBig = moji(search).filter("ZE").toString()

        // //     if(search.match(/[0-9]/g) || search.match (/[0-9]/g)){
        // //       if(numSmall){
        // //         numSmall = moji(search).convert("HE", "ZE").toString()
        // //         search = `${search}${this.title_spacer}${numSmall}`
        // //       }else if(numBig){
        // //         numBig = search = `${search}${this.title_spacer}${moji(search).convert("ZE", "HE").toString()}`
        // //       }
        // //     }
        // //     //-----HALF AND FULL WIDTH  CHARACTERS
        // //     let fullKatakana = moji(search).filter("ZK").toString();
        // //     let halfKatakana = moji(search).filter("HK").toString();
        // //     if (fullKatakana) {
        // //       fullKatakana = moji(search).convert("ZK", "HK").toString();
        // //       search = `${search}${this.title_spacer}${fullKatakana}`;
        // //     } else if (halfKatakana) {
        // //       halfKatakana = moji(search).convert("HK", "ZK").toString();
        // //       search = `${search}${this.title_spacer}${halfKatakana}`;
        // //     }

        // //     url = `${this.awsURL}shiyo/search/or?search=${search}&except=${this.exceptText}`
        // //   }else{
        // //     ////Search AND Function////
        // //     if(search.match(/[０-９]/g)){
        // //       search = `${moji(search).convert("ZE", "HE").toString()}`;
        // //     }
        // //     url = `${this.awsURL}shiyo/search/and?search=${search}&except=${this.exceptText}`;
        // //   }
        // //   axios.defaults.headers.common["x-api-key"] = "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        // //   axios.get(url).then((res) => {
        // //     this.shiyoData = res.data
        // //     this.shiyoData.sort(function (a, b){
        // //       return ("" + a.CountViews).localeCompare(b.CountViews)
        // //     })
        // //     this.loadingShiyo = false

        // //   })
        // // }else{
        // //   this.shiyoData = []
        // // }
      }
    },

    onMultipleSearch() {
      let search = this.searchText;
      if (search.includes(" ") || search.includes("\u3000")) {
        return Swal.fire({
          icon: "warning",
          title: `Space is not allowed in MULTI search`,
          showConfirmButton: false,
          timer: 1200,
        });
      }
      if (!this.composing) {
        this.searchSekkeiIconAlignment = true;
        this.isMultiple = true;
        let search = this.convert2FullKatakana(this.searchText);
        if (this.searchText != "") {
          this.loadingSekkei = false;
        }
        if (this.searchText) {
          // console.log("nagpunta sa multi");
          axios.defaults.headers.common["x-api-key"] =
            "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
          let url = "";
          // url = `${this.awsURL}get/multiple_rules2?search=${search}&except=${this.exceptText}`;
          // url = `${this.awsURL}shiyo/search/multi?search=${search}&except=${this.exceptText}`;
          // url = `${this.awsURL}shiyo/search/multi_New?search=${search}&except=${this.exceptText}`;
          url = `${this.api}sekkeiGyoumu/search/multiple?search1=${search}&excepWord=${this.exceptText}`;

          axios.get(url).then((res) => {
            if (typeof res.data != "string") {
              this.sekkeiData = res.data.map(r=>{
                this.$set(r,'showLine',false)
                return r
              });
              this.sekkeiData
                .sort(function(a, b) {
                  return ("" + a.CountViews).localeCompare(b.CountViews);
                })
                .map((rec) => {
                  rec.ellipsis = rec.textContent; //2022-06-09 added ellipsis property
                });
              this.loadingSekkei = true;
            } else {
              this.loadingSekkei = true;
              this.selectedCondition = "OR";
              this.onSearch();
              // console.log("nagpunta sa OR");
              this.isMultiple = false;
            }
          });
        } else {
          this.sekkeiData = [];
        }
      }
    },
    inputtingSearch() {
      let japaneseSpace = "　";
      if (
        (this.searchText.includes(" ") && this.selectedCondition == "MULTI") ||
        (this.searchText.includes(japaneseSpace) &&
          this.selectedCondition == "MULTI")
      ) {
        const newValue = this.searchText.replace(/[\u0020\u3000]/g, "");
        this.searchText = newValue;
      } else {
        // console.log(this.searchText, this.selectedCondition);
      }
    },
    StartCompose() {
      // console.log(this.searchText);
      this.composing = true;
    },
    EndCompose() {
      this.composing = false;
    },
    loadSekkeiRecords() {
      this.loadingSekkei = false;
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      // let url = `${this.awsURL}sekkeiGyoumu/getSekkeiGyoumuRecords`;
      // let url = `${this.awsURL}sekkeiGyoumu/getSekkeiGyoumuFinalData`;
      let url = `${this.api}sekkeiGyoumu/getSekkeiGyoumuFinalDataTable`;
      // let url = `${this.api}sekkeiGyoumo/Sekkeipractice`;
      // let url = `${this.api}shiyo/getData`;

      // let url = `${this.api}searchAnd/${"00-00000000"}`;
      axios
        .get(url)
        .then((res) => {
          // if (res.data) {
          //   return console.log(res.data);
          // }
          // console.log('test',res.data)
          // console.log("Sekkei tab ", res.data);
          this.sekkeiData = res.data.map(r=>{
            this.$set(r,'showLine',false)
            return r
          })

            .filter((r) => {
              return r.title;
            })
            .map((r) => {
              // if (r.updatedDate) {
              //   r.updatedDate;
              // } else {
              //   r.updatedDate = "Original Copy";
              // }
              // if (r.specifications) {
              //   if (r.specifications.match(/[0-9]/g)) {
              //     r.specifications = r.specifications.substring(
              //       0,
              //       r.specifications.length - 3
              //     );
              //   }
              // }
              // if (r.roomPart) {
              //   if (typeof r.roomPart == "string") {
              //     r.roomPart = [r.roomPart];
              //   }
              // }

              return r;
            });
          // console.log(this.sekkeiData);
          // .sort((a,b) => {
          //   return a.productName.localeCompare(b.productName)
          // })
          // console.log(this.sekkeiData);
          // this.sekkeiData.sort((a, b) => {
          //   return a.docNo.localeCompare(b.docNo);
          // });
          // this.sekkeiData.sort((a, b) => {
          //   return moment(b.updatedDate) - moment(a.updatedDate);
          // });

          // console.log("line 1141", this.sekkeiData);
          // this.loadingShiyo = false;
        })
        .then(() => {
          // console.log("line 1468", this.sekkeiData);
          this.sekkeiData;
          this.loadingSekkei = true;
        });
      // let obj = [
      //   {
      //     framework: "軸組",
      //     _id: "TwnlFEq6M",
      //     productName: "ガスオーブン【廃番】",
      //     roomPart: ["外部その他"],
      //     sekkeiNumber: "00-00000000",
      //     specifications: "計画ルール",
      //     updatedDate: "2023-01-20 03:31:21",
      //   },
      //   {
      //     framework: "軸組",
      //     _id: "TwnlFEq6M",
      //     productName: "ガスオーブン【廃番】",
      //     roomPart: ["外部その他"],
      //     sekkeiNumber: "00-00000000",
      //     specifications: "計画ルール",
      //     updatedDate: "2023-01-20 03:31:21",
      //   },
      //   {
      //     framework: "軸組",
      //     _id: "TwnlFEq6M",
      //     productName: "ガスオーブン【廃番】",
      //     roomPart: ["外部その他"],
      //     sekkeiNumber: "00-00000000",
      //     specifications: "計画ルール",
      //     updatedDate: "2023-01-20 03:31:21",
      //   },
      // ];
      // this.loadingSekkei = true;
      // this.sekkeiData = obj;
      // console.log(this.sekkeiData);
    },
    // .then(()=>{
    //     let url = `${this.api}shiyo/getShiyoData`
    //     axios.get(url).then(res => {
    //       console.log('history',res.data)
    //       this.dataHistory = res.data
    //       this.loadingShiyoHistory = false;
    // })
    //   })
    loadSekkeiHistory() {
      // return alert("This part is still on development");
      // console.log(this.viewHistoryTable);
      this.searchText = "";
      this.loadingSekkei = false;
      if (this.viewHistoryTable) {
        // let url = `${this.api}shiyo/getShiyoHistoryData`;
        // let url = `${this.awsURL}sekkeiGyoumu/getSekkeiGyoumuFinalDataTable`; //sample only to get data
        let url = `${this.api}sekkeiGyoumu/getSekkeiGoumuHistory`;

        axios
          .get(url)
          .then((res) => {
            this.dataHistory = res.data;
            // console.log(this.dataHistory);
            for (let x in this.dataHistory) {
              if (x < this.dataHistory.length - 1) this.viewLoading.push(false);
            }
            // this.dataHistory.forEach((el) => {
            //   if (el.updatedDate == null) {
            //     console.log("dumaan dito", el.updatedDate);
            //     el.updatedDate = "wow";
            //     console.log("dumaan dito", el.updatedDate);
            //     return;
            //   }
            // });
          })
          .then(() => {
            this.loadingSekkei = true;
          });
      } else {
        this.loadSekkeiRecords();
      }
    },
    async loadData(quarter) {
      if (this.viewHistoryTable) {
        this.loadingSekkei = false;

        await axios
          .get(`${this.api}sekkeiGyoumu/getAllSekkeiHistoryTest2/${quarter}`, {
            params: {
              lastEvaluatedKey: this.lastEvaluatedKeyForHistory,
            },
          })
          .then((response) => {
            this.sampleHistoryItems = [
              ...this.sampleHistoryItems,
              ...response.data.items,
            ];
            this.lastEvaluatedKeyForHistory = response.data.lastEvaluatedKey;
            // console.log("sampleItems", this.sampleHistoryItems);
            // console.log("key", this.lastEvaluatedKeyForHistory);
            if (response.data.items.length < 50) {
              this.dataHistory = this.sampleHistoryItems;
              this.loadingSekkei = true;
              const jsonString = JSON.stringify(this.dataHistory);
              const dataSizeInBytes = Buffer.byteLength(jsonString, "utf8");
              console.log(dataSizeInBytes);
              return;
            } else {
              this.loadNextQuarter();
            }
          })
          .catch((error) => console.error("Error fetching data:", error));
      } else {
        this.sampleHistoryItems = [];
        this.loadSekkeiRecords();
      }
    },
    loadNextQuarter() {
      const nextQuarter = this.sampleHistoryItems.length / 50 + 1;
      this.loadData(nextQuarter);
    },
  },

  created() {
    this.loadSekkeiRecords();
    // this.loadSekkeiHistory()
    this.getUserFavorites();
  },
  computed: {
    paginationLength() {
      let l = this.filteredSekkei.length,
        s = this.intRowNoSekkei;
      return Math.ceil(l / s);
    },
    paginationLengthNew() {
      // ADDED SUB for paginationLength
      let l = this.filteredSekkei.length,
        s = this.pageRow;
      return this.pageRow == "ALL" ? 1 : Math.ceil(l / s);
    },
    paginatedSekkei() {
      const start = (this.pageNumber - 1) * this.intRowNoSekkei,
        end = start + this.intRowNoSekkei;
      return this.filteredSekkei.slice(start, end);
    },
    paginatedSekkeiNew() {
      //ADDED SUB for paginatedKikaku
      // const start = (this.pageNumber - 1) * this.pageRow,
      //   end =
      //     this.pageRow == "ALL"
      //       ? this.filteredSekkei.length
      //       : start + this.pageRow;
      // return this.filteredSekkei.slice(start, end);
      return this.filteredSekkei;
    },
    paginatedSekkeiHistory() {
      const start = (this.pageNumber - 1) * this.intRowNoSekkei,
        end = start + this.intRowNoSekkei;

      return this.filteredSekkeiHistory.slice(start, end);
    },
    filteredSekkei() {
      return this.sekkeiData
        .filter((r) => {
          if (this.selectedMaterials == "ALL") {
            return r;
          } else {
            return r.sekkei_materials == this.selectedMaterials;
          }
        })
        .filter((r) => {
          if (this.selectedClassification == "ALL") {
            return r;
          } else {
            return r.sekkei_classification == this.selectedClassification;
          }
        })
        .filter((r) => {
          if (this.selectedProjects == "ALL") {
            return r;
          } else {
            // console.log('HEEEEERR',r.roomPart)
            return r.sekkei_projects == this.selectedProjects;
          }
        })
        .sort((a, b) => {
          return a.docNo > b.docNo ? 1 : -1;
        })
        .sort((a, b) => {
          a = a.updatedDate ? a.updatedDate.substring(0,10) : a.createdDate.substring(0,10)
          b = b.updatedDate ? b.updatedDate.substring(0,10) : b.createdDate.substring(0,10)
          return b.localeCompare(a);
        });
      //   .filter((r) => {  // 一般
      //         if (
      //           this.sel_buildingType.includes("Ippan") &&
      //           !this.sel_buildingType.includes("ALL")
      //         ) {
      //           return r.Ippan == true;
      //         } else {
      //           return r;
      //         }
      //   })
      //  .filter((r) => { // 夢の家
      //     if (
      //       this.sel_buildingType.includes("YumeNoIe") &&
      //       !this.sel_buildingType.includes("ALL")
      //     ) {
      //       return r.YumeNoIe == true;
      //     } else {
      //       return r;
      //     }
      //   })
      //  .filter((r) => { //
      //     if (
      //       this.sel_buildingType.includes("Ismile") &&
      //       !this.sel_buildingType.includes("ALL")
      //     ) {
      //       return r.Ismile == true;
      //     } else {
      //       return r;
      //     }
      //   })
      //   .filter((r) => { //i-cube
      //     if (
      //       this.sel_buildingType.includes("Icube") &&
      //       !this.sel_buildingType.includes("ALL")
      //     ) {
      //       return r.Icube == true;
      //     } else {
      //       return r;
      //     }
      //   })
      //  .filter((r) => {  //i-smart
      //     if (
      //       this.sel_buildingType.includes("Ismart") &&
      //       !this.sel_buildingType.includes("ALL")
      //     ) {
      //       return r.Ismart == true;
      //     } else {
      //       return r;
      //     }
      //   })
      //  .filter((r) => {  // グラン・スマート
      //     if (
      //       this.sel_buildingType.includes("Gsmart") &&
      //       !this.sel_buildingType.includes("ALL")
      //     ) {
      //       return r.Gsmart == true;
      //     } else {
      //       return r;
      //     }
      //   })
      //  .filter((r) => {  // ブリアール
      //     if (
      //       this.sel_buildingType.includes("B") &&
      //       !this.sel_buildingType.includes("ALL")
      //     ) {
      //       return r.B == true;
      //     } else {
      //       return r;
      //     }
      //   })
      //   .filter((r) => {  // セゾンA
      //     if (
      //       this.sel_buildingType.includes("A") &&
      //       !this.sel_buildingType.includes("ALL")
      //     ) {
      //       return r.A == true;
      //     } else {
      //       return r;
      //     }
      //   })
      //   .filter((r) => {  // セゾンF2-TD
      //     if (
      //       this.sel_buildingType.includes("F2TD") &&
      //       !this.sel_buildingType.includes("ALL")
      //     ) {
      //       return r.F2TD == true;
      //     } else {
      //       return r;
      //     }
      //   })
      //   .filter((r) => {  // セゾンF
      //     if (
      //       this.sel_buildingType.includes("F") &&
      //       !this.sel_buildingType.includes("ALL")
      //     ) {
      //       return r.F == true;
      //     } else {
      //       return r;
      //     }
      //   })
      //   .filter((r) => {  // グラン・セゾン
      //     if (
      //       this.sel_buildingType.includes("G") &&
      //       !this.sel_buildingType.includes("ALL")
      //     ) {
      //       return r.G == true;
      //     } else {
      //       return r;
      //     }
      //   })

      //   .filter((r) => {  //耐震
      //     if (
      //       this.sel_buildingSpec.includes("Taishin") &&
      //       !this.sel_buildingSpec.includes("ALL")
      //     ) {
      //       return r.Taishin == true;
      //     } else {
      //       return r;
      //     }
      //   })
      //   .filter((r) => {  //免震
      //     if (
      //       this.sel_buildingSpec.includes("Menshin") &&
      //       !this.sel_buildingSpec.includes("ALL")
      //     ) {
      //       return r.Menshin == true;
      //     } else {
      //       return r;
      //     }
      //   })
      //   .filter((r) => {  //免震
      //     if (
      //       this.sel_buildingSpec.includes("JapaneseStyle") &&
      //       !this.sel_buildingSpec.includes("ALL")
      //     ) {
      //       return r.JapaneseStyle == true;
      //     } else {
      //       return r;
      //     }
      //   })
      //   .filter((r) => {  //免震
      //     if (
      //       this.sel_buildingSpec.includes("Saison") &&
      //       !this.sel_buildingSpec.includes("ALL")
      //     ) {
      //       return r.Saison == true;
      //     } else {
      //       return r;
      //     }
      //   })
      //   .filter((r) => {  //免震
      //     if (
      //       this.sel_buildingSpec.includes("Famille") &&
      //       !this.sel_buildingSpec.includes("ALL")
      //     ) {
      //       return r.Famille == true;
      //     } else {
      //       return r;
      //     }
      //   })
      //   .filter((r) => {  //免震
      //     if (
      //       this.sel_buildingSpec.includes("HUGme") &&
      //       !this.sel_buildingSpec.includes("ALL")
      //     ) {
      //       return r.HUGme == true;
      //     } else {
      //       return r;
      //     }
      //   })
      //   .filter((r) => {  //免震
      //     if (
      //       this.sel_buildingSpec.includes("HUGmefam") &&
      //       !this.sel_buildingSpec.includes("ALL")
      //     ) {
      //       return r.HUGmefam == true;
      //     } else {
      //       return r;
      //     }
      //   })
    },
    filteredSekkeiHistory() {
      return this.dataHistory
        .filter((r) => {
          if (this.selectedMaterials == "ALL") {
            return r;
          } else {
            return r.sekkei_materials == this.selectedMaterials;
          }
        })
        .filter((r) => {
          if (this.selectedClassification == "ALL") {
            return r;
          } else {
            return r.sekkei_classification == this.selectedClassification;
          }
        })
        .filter((r) => {
          if (this.selectedProjects == "ALL") {
            return r;
          } else {
            // console.log('HEEEEERR',r.roomPart)
            return r.sekkei_projects == this.selectedProjects;
          }
        })
        .filter((r) => {
          if (this.searchText) {
            return (
              r.docNo.toUpperCase().includes(this.searchText.toUpperCase()) ||
              // r.specifications.toUpperCase().includes(this.searchText.toUpperCase()) ||
              // r.roomPart.toUpperCase().includes(this.searchText.toUpperCase())
              r.title.toUpperCase().includes(this.searchText.toUpperCase())
            );
            // r.framework.toUpperCase().includes(this.searchText.toUpperCase())
          } else {
            return r;
            // console.log('HEEEEERR',r.roomPart)
          }
        });
      // .filter((r) => {
      //   // 一般
      //   if (
      //     this.sel_buildingType.includes("Ippan") &&
      //     !this.sel_buildingType.includes("ALL")
      //   ) {
      //     return r.Ippan == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   // 夢の家
      //   if (
      //     this.sel_buildingType.includes("YumeNoIe") &&
      //     !this.sel_buildingType.includes("ALL")
      //   ) {
      //     return r.YumeNoIe == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   //
      //   if (
      //     this.sel_buildingType.includes("Ismile") &&
      //     !this.sel_buildingType.includes("ALL")
      //   ) {
      //     return r.Ismile == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   //i-cube
      //   if (
      //     this.sel_buildingType.includes("Icube") &&
      //     !this.sel_buildingType.includes("ALL")
      //   ) {
      //     return r.Icube == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   //i-smart
      //   if (
      //     this.sel_buildingType.includes("Ismart") &&
      //     !this.sel_buildingType.includes("ALL")
      //   ) {
      //     return r.Ismart == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   // グラン・スマート
      //   if (
      //     this.sel_buildingType.includes("Gsmart") &&
      //     !this.sel_buildingType.includes("ALL")
      //   ) {
      //     return r.Gsmart == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   // ブリアール
      //   if (
      //     this.sel_buildingType.includes("B") &&
      //     !this.sel_buildingType.includes("ALL")
      //   ) {
      //     return r.B == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   // セゾンA
      //   if (
      //     this.sel_buildingType.includes("A") &&
      //     !this.sel_buildingType.includes("ALL")
      //   ) {
      //     return r.A == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   // セゾンF2-TD
      //   if (
      //     this.sel_buildingType.includes("F2TD") &&
      //     !this.sel_buildingType.includes("ALL")
      //   ) {
      //     return r.F2TD == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   // セゾンF
      //   if (
      //     this.sel_buildingType.includes("F") &&
      //     !this.sel_buildingType.includes("ALL")
      //   ) {
      //     return r.F == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   // グラン・セゾン
      //   if (
      //     this.sel_buildingType.includes("G") &&
      //     !this.sel_buildingType.includes("ALL")
      //   ) {
      //     return r.G == true;
      //   } else {
      //     return r;
      //   }
      // })

      // .filter((r) => {
      //   //耐震
      //   if (
      //     this.sel_buildingSpec.includes("Taishin") &&
      //     !this.sel_buildingSpec.includes("ALL")
      //   ) {
      //     return r.Taishin == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   //免震
      //   if (
      //     this.sel_buildingSpec.includes("Menshin") &&
      //     !this.sel_buildingSpec.includes("ALL")
      //   ) {
      //     return r.Menshin == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   //免震
      //   if (
      //     this.sel_buildingSpec.includes("JapaneseStyle") &&
      //     !this.sel_buildingSpec.includes("ALL")
      //   ) {
      //     return r.JapaneseStyle == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   //免震
      //   if (
      //     this.sel_buildingSpec.includes("Saison") &&
      //     !this.sel_buildingSpec.includes("ALL")
      //   ) {
      //     return r.Saison == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   //免震
      //   if (
      //     this.sel_buildingSpec.includes("Famille") &&
      //     !this.sel_buildingSpec.includes("ALL")
      //   ) {
      //     return r.Famille == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   //免震
      //   if (
      //     this.sel_buildingSpec.includes("HUGme") &&
      //     !this.sel_buildingSpec.includes("ALL")
      //   ) {
      //     return r.HUGme == true;
      //   } else {
      //     return r;
      //   }
      // })
      // .filter((r) => {
      //   //免震
      //   if (
      //     this.sel_buildingSpec.includes("HUGmefam") &&
      //     !this.sel_buildingSpec.includes("ALL")
      //   ) {
      //     return r.HUGmefam == true;
      //   } else {
      //     return r;
      //   }
      // });
    },
    sekkeiHistoryNewData() {
      return this.dataHistory.filter((rec) => {
        return rec.version <= 1;
      });
    },
    sekkeiHistoryReviseData() {
      return this.dataHistory.filter((rec) => {
        return rec.version > 1;
      });
    },
    sekkeiHistoryDeletedData() {
      return this.dataHistory.filter((rec) => {
        return rec.sekkei_status == "deleted";
      });
    },
    screenSize() {
      return this.$vuetify.breakpoint.height;
    },
    isFav() {
      if (this.isFavorite) {
        return "mdi-star";
      } else {
        return "mdi-star-outline";
      }
    },
    Width() {
      if (
        this.$vuetify.breakpoint.name == "md" ||
        this.$vuetify.breakpoint.name == "lg" ||
        this.$vuetify.breakpoint.name == "xl"
      ) {
        return "width:60%";
      } else return "width:98%";
    },
    paginatedMemo() {
      const start = (this.pageNumber - 1) * this.intRowNoSekkei,
        end = start + this.intRowNoSekkei;
      return this.filteredSekkei.slice(start, end);
    },
    dynamicSpanStyle(val) {
      const contentLength = val.length;
      if (contentLength > this.maxLength) {
        const fontSizePercentage = (this.maxLength / contentLength) * 100;
        const fontSize = (this.initialFontSize * fontSizePercentage) / 100;
        return { fontSize: `${fontSize}px` };
      } else {
        return { fontSize: `${this.initialFontSize}px` };
      }
    },
    customClearIcon() {
      return "mdi-close-circle-outline";
    },
  },
};
</script>

<style scoped>
#title_ellipsis {
  display: inline-block;
  width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.button {
  /* background-color: #01579b; */
  border: none;
  cursor: pointer;
  /* color: white; */
  color: black;
  padding: 6px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.title {
  display: inline-block;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px !important;
}
.viewed {
  background-color: white;
  color: purple;
}

#id-center {
  width: 100%;
  display: table;
  table-layout: fixed;
  text-align: center;
}

#id-title {
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
