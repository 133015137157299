<template>
  <!-- NOTE: disable transation in this page -->
  <div translate="no">
    <v-container pa-0 ma-0 fluid>
      <v-layout align-center justify-center v-if="loadingPlannersReference">
        <a-spin class="mt-2" tip="Fetching data..." />
      </v-layout>

      <v-card class="ma-2 pa-2" flat style="background-color: #f6f5f5" v-else>
        <a-row :xs="24" :sm="12" :md="10" :gutter="5">
          <a-col :xs="12" :sm="3" :md="3">
            <span style="font-family:Arial;font-weight:bold;"> 検索:</span>
          </a-col>
          <!---- newly added 20230818--->
          <a-col :xs="12" :sm="3" :md="3">
            <span style="font-family:Arial;font-weight:bold;"> 構法:</span>
          </a-col>

          <a-col :xs="12" :sm="3" :md="3">
            <span style="font-family:Arial;font-weight:bold;">
              建物タイプ:
            </span>
          </a-col>

          <!-- 分類 change to 区分 --09/03/24 -->
          <a-col :xs="12" :sm="3" :md="3">
            <span style="font-family:Arial;font-weight:bold;"> 区分: </span>
          </a-col>

          <!-- Hide selectedCategory2 -- 09/03/24 -->
          <!-- <a-col :xs="12" :sm="3" :md="3">
            <span style="font-family:Arial;font-weight:bold;"> 項目: </span>
          </a-col> -->

          <!----- Removed 20230818----->
          <!-- <a-col :xs="12" :sm="3" :md="3">
                     <span style="font-family:Arial;font-weight:bold;"> Date From ~ Date To: </span>
                </a-col> -->
        </a-row>

        <a-row :xs="24" :sm="12" :md="10" :gutter="5">
          <a-col :xs="12" :sm="3" :md="3">
            <a-input
              v-model="searchText"
              placeholder="検索"
              append-icon="search"
              outlined
              :disabled="disableSearch"
            >
              <a-icon
                slot="suffix"
                type="close-circle"
                style="color: rgba(0, 0, 0, 0.45)"
                @click="() => (searchText = '')"
              />
            </a-input>
          </a-col>
          <!---- Newly added 20230818---->
          <a-col :xs="12" :sm="3" :md="3">
            <a-select
              v-model="selectedConstMethod"
              mode="multiple"
              style="width: 100%"
              :disabled="disableSearch"
            >
              <a-select-option
                v-for="item in constMethodList"
                :key="item"
                v-value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-col>
          <a-col :xs="12" :sm="3" :md="3">
            <!-- {{selectedHouseType}} -->
            <a-select
              v-model="selectedHouseType"
              mode="multiple"
              style="width: 100%"
              :disabled="disableSearch"
            >
              <a-select-option
                v-for="item in HouseTypeList"
                :key="item"
                v-value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-col>

          <a-col :xs="12" :sm="3" :md="3">
            <a-select
              v-model="selectedCategory1"
              mode="multiple"
              style="width: 100%"
              :disabled="disableSearch"
            >
              <a-select-option
                v-for="item in categoryList1"
                :key="item"
                v-value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-col>

          <!-- Hide selectedCategory2 -- 09/03/24 -->
          <!-- <a-col :xs="12" :sm="3" :md="3">
            <a-select
              v-model="selectedCategory2"
              mode="multiple"
              style="width: 100%"
              :disabled="disableSearch"
            >
              <a-select-option
                v-for="item in categoryList2"
                :key="item"
                v-value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-col> -->

          <!-- <a-col :xs="12" :sm="3" :md="3"> -->
            <!-- {{selectedDateRange}} -->
            <!--- removed 20230818--->
            <!-- <a-range-picker style="width: 100%;" v-model="selectedDateRange" :format="dateFormat" >
                        <a-icon
                        slot="suffix"
                        type="close-circle"
                        style="color: rgba(0, 0, 0, 0.45)"
                        />
                    </a-range-picker> -->
          <!-- </a-col> -->

          <!-- <a-col :xs="12" :sm="3" :md="3">
                    <a-button
                        class="mx-1"
                        dark
                        type="primary"
                        icon="plus"
                        block
                        @click="openAddUpdateDialog()"
                    >
                        Add Record
                    </a-button>
                </a-col> -->

          <a-col :xs="12" :sm="3" :md="3">
            <a-button
              class="mx-1"
              dark
              type="primary"
              icon="reload"
              block
              :disabled="refreshBtn"
              @click="refreshFunction()"
            >
              Reset  <!-- word 'Refresh' change to Reset  08/16/24 -->
            </a-button>
          </a-col>

          <!-- DELETE Download CSV ---08/22/24 -->
          <!-- <a-col :xs="12" :sm="3" :md="3">
            <download-excel
              :data="dataToDownload"
              :name="PlannerReferenceFileName"
              :disabled="refreshBtn"
            >
              <a-tooltip placement="bottom">
                <template #title>
                  <span> Export to CSV </span>
                </template> -->
                <!-- <a-button
                  class="mx-1"
                  dark
                  type="primary"
                  icon="download"
                  block
                  :disabled="refreshBtn"
                >
                  Download CSV
                </a-button> -->
              <!-- </a-tooltip>
            </download-excel>
          </a-col> -->

          <a-col :xs="12" :sm="12" :md="12">
            <h3 style="font-family:Arial;font-weight:bold" class="mt-2">
              <!-- Total: {{ filteredPlannersReference.length }} -->
              Total: {{ filteredPlannersReference.length }} of
              {{ totalPlannerReference.length }}
            </h3>
          </a-col>
        </a-row>

        <a-row xs="24" :sm="12" :md="10" :gutter="5">
          <v-simple-table dense fixed-header :height="screenSize - 290" >
            <template v-slot:default>
              <thead >
                <tr>
                  <!-----Newly added 20230822--->
                  <th class="text-center">
                    構法
                  </th>
                  <th class="text-center">
                    建物タイプ
                  </th>
                  <th class="text-center">
                    区分    <!-- 分類 change to 区分 --09/03/24 -->
                  </th>
                  <!-- Hide selectedCategory2 -- 09/03/24 -->
                  <!-- <th class="text-center">
                    項目
                  </th> -->
                  <th class="text-center">
                    日本語
                  </th>
                  <th class="text-center">
                    ローマ字
                  </th>
                  <th class="text-center">
                    英語
                  </th>
                  <th class="text-center">
                    指示記号
                  </th>
                  <th class="text-center">
                    指示方法
                  </th>
                  <th class="text-center">
                    平立面図
                  </th>
                  <!-- <th class="text-center">
                              Remarks
                            </th> -->
                  <th class="text-center">
                    更新日付 <!-- word '更新日' change to 更新日付 08/16/24 -->
                  </th>
                  <!-- <th class="text-center">
                                Actions
                            </th> -->
                </tr>
              </thead>
              <tbody>
                <td
                  colspan="12"
                  v-if="filteredPlannersReference.length == 0"
                  class="pa-2 text-center"
                >
                  <span style="font-size:20px"> No Records Found! </span>
                </td>
                <!-- tmpPlannersReference -->
                <!-- paginatedPlannerReference -->
                <tr
                  v-for="(item, i) in filteredPlannersReference"
                  :key="i"
                  style="font-family:Arial"
                >
                  <td width="8%" class="text-center">{{ item.constMethod }}</td>
                  <!---- NewlyAdded 20230822-->
                  <td width="8%" class="text-center">{{ typeof(item.houseType)=='object' ? item.houseType.join(' / ') : item.houseType }}</td>
                  <td width="7%" class="text-center">{{ item.category1 }}</td>
                  <!-- <td width="7%" class="text-center">{{ item.category2 }}</td> --> <!-- Hide category2 -- 09/03/24 -->
                  <td width="8%" class="text-center">{{ item.doc_jap }}</td>
                  <td width="8%" class="text-center">
                    {{ item.doc_romanji.toUpperCase() }}
                  </td>
                  <td width="8%" class="text-center">{{ item.doc_english }}</td>
                  <td width="6%" class="text-center">
                    <span style="font-weight:bold;color:red">
                      {{ item.abbr }}</span
                    >
                  </td>
                  <td width="13%" class="pa-5" align="center">
                    <v-tooltip top transition="scroll-y-transition">
                      <template v-slot:activator="{ on }">
                        <!-- <v-img @click="openViewImageDialog(item,'CAD_REQUEST')" v-on="on" style="border:1px solid #c2c2c2;cursor:pointer;" :src="`https://s3.${region}.amazonaws.com/${region}/PlannersReference/${item.attachment_cadRequest}.jpg`" height="120" width="120" lazy-src="https://picsum.photos/id/11/10/6"></v-img>  -->
                        <v-img
                          contain
                          @click="openViewImageDialog(item, 'CAD_REQUEST')"
                          v-on="on"
                          style="border:1px solid #c2c2c2;cursor:pointer;"
                          :src="item.blobImageSrc_CADRequest"
                          height="120"
                          width="120"
                        >
                        </v-img>
                      </template>
                      <span style="font-family:Arial">
                        Click to view image
                      </span>
                    </v-tooltip>
                  </td>
                  <td width="13%" class="pa-5" align="center" v-cloak>
                    <v-tooltip top transition="scroll-y-transition">
                      <template v-slot:activator="{ on }">
                        <!-- <v-img @click="openViewImageDialog(item,'FLOORPLAN_ELEVATION')"  v-on="on" style="border:1px solid #c2c2c2;cursor:pointer;" :src="`https://s3.${region}.amazonaws.com/${region}/PlannersReference/${item.attachment_floorPlanElevation}.jpg`" height="120" width="120" lazy-src="https://picsum.photos/id/11/10/6"></v-img>  -->
                        <v-img
                          contain
                          @click="
                            openViewImageDialog(item, 'FLOORPLAN_ELEVATION')
                          "
                          v-on="on"
                          style="border:1px solid #c2c2c2;cursor:pointer;"
                          :src="item.blobImageSrc_FloorPlanElevation"
                          height="120"
                          width="120"
                        ></v-img>
                      </template>
                      <span style="font-family:Arial">
                        Click to view image
                      </span>
                    </v-tooltip>
                  </td>
                  <!-- <td width="15%" class="text-left" >{{ item.remarks}}</td> -->
                  <td width="7%" class="text-center">{{ item.date }}</td>
                  <!-- <td>
                                <v-btn @click="openEditDialog(item)" x-small class="success"> Edit </v-btn>
                                <v-btn @click="deletePlannerReference(item)" x-small class="error"> Delete </v-btn>
                            </td> -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </a-row>
      </v-card>
   
      <!-- <v-pagination
        v-model="pageNumber"
        :length="paginationLength"
        :total-visible="5"
        dark
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        v-if="!loadingPlannersReference"
        :disabled="disableSearch"
      ></v-pagination> -->

      <!-- View Image Dialog -->
      <v-dialog
        v-model="viewImageDialog"
        width="700"
        transition="scroll-y-transition"
        persistent
        dense
      >
        <v-card>
          <v-toolbar elevation="0" dense>
            <h3 class="mt-2 mb-2" style="font-family:Arial;font-weight:bold">
              View Image -
              {{
                plannerRefObj.pictureType == "CAD_REQUEST"
                  ? "CAD Request"
                  : "Floor Plan/Elevation"
              }}
            </h3>
            <v-spacer />
            <v-btn @click="closeViewImageDialog()" fab small text>
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider />
          <v-card-text>
            <template v-if="plannerRefObj.pictureType == 'CAD_REQUEST'">
              <!-- <center><v-img class="mt-3" style="border:1px solid #c2c2c2;cursor:pointer;" :src="`https://s3.${region}.amazonaws.com/${region}/PlannersReference/${plannerRefObj.attachment_cadRequest}.jpg`" height="550" width="550" lazy-src="https://picsum.photos/id/11/10/6"></v-img>  </center> -->
              <center>
                <v-img
                  contain
                  class="mt-3"
                  style="border:1px solid #c2c2c2;cursor:pointer;"
                  :src="plannerRefObj.blobImageSrc_CADRequest"
                  height="550"
                  width="550"
                  :lazy-src="
                    `https://s3.${region}.amazonaws.com/${region}/PlannersReference/loading-gif.gif`
                  "
                ></v-img>
              </center>
            </template>
            <template v-else>
              <!-- <center> <v-img class="mt-3" style="border:1px solid #c2c2c2;cursor:pointer;" :src="`https://s3.${region}.amazonaws.com/${region}/PlannersReference/${plannerRefObj.attachment_floorPlanElevation}.jpg`" height="550" width="550" lazy-src="https://picsum.photos/id/11/10/6"></v-img></center>  -->
              <center>
                <v-img
                  contain
                  class="mt-3"
                  style="border:1px solid #c2c2c2;cursor:pointer;"
                  :src="plannerRefObj.blobImageSrc_FloorPlanElevation"
                  height="550"
                  width="550"
                  :lazy-src="
                    `https://s3.${region}.amazonaws.com/${region}/PlannersReference/loading-gif.gif`
                  "
                ></v-img>
              </center>
            </template>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="closeViewImageDialog()"
              style="font-family:Arial;"
              text
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Add Reference -->
      <v-dialog v-model="addUpdateDialog" width="800" persistent dense>
        <v-card>
          <v-toolbar elevation="0" dense>
            <h3 class="mt-2 mb-2" style="font-family:Arial;font-weight:bold">
              {{ addUpdateText }}
            </h3>
            <v-spacer />
            <v-btn @click="closeAddUpdateDialog()" fab small text>
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider />
          <v-card-text>
            <a-row :xs="24" :sm="12" :md="12" :gutter="5" class="mt-3">
              <a-col :xs="24" :sm="24" :md="24">
                <span style="font-family:Arial;font-weight:bold;color:black">
                  Abbreviation:
                </span>
                <a-input v-model="toAdd.abbr" outlined class="abbr-class">
                </a-input>
              </a-col>

              <a-col :xs="8" :sm="8" :md="8">
                <span style="font-family:Arial;font-weight:bold;color:black">
                  House Type:
                </span>
                <a-select v-model="toAdd.houseType" style="width: 100%">
                  <a-select-option
                    v-for="item in HouseTypeList"
                    :key="item"
                    v-value="item"
                    >{{ item }}</a-select-option
                  >
                </a-select>
              </a-col>

              <a-col :xs="8" :sm="8" :md="8">
                <span style="font-family:Arial;font-weight:bold;color:black">
                  Category:
                </span>
                <a-select v-model="toAdd.category1" style="width: 100%">
                  <a-select-option
                    v-for="item in categoryList1"
                    :key="item"
                    v-value="item"
                    >{{ item }}</a-select-option
                  >
                </a-select>
              </a-col>

              <!-- Hide categoryList2 --09/03/24 -->
              <!-- <a-col :xs="8" :sm="8" :md="8">
                <span style="font-family:Arial;font-weight:bold;color:black">
                  Category 2:
                </span>
                <a-select v-model="toAdd.category2" style="width: 100%">
                  <a-select-option
                    v-for="item in categoryList2"
                    :key="item"
                    v-value="item"
                    >{{ item }}</a-select-option
                  >
                </a-select>
              </a-col> -->

              <a-col :xs="24" :sm="24" :md="24">
                <span style="font-family:Arial;font-weight:bold;color:black">
                  Japanese:
                </span>
                <a-input v-model="toAdd.doc_jap" outlined> </a-input>
              </a-col>

              <a-col :xs="24" :sm="24" :md="24">
                <span style="font-family:Arial;font-weight:bold;color:black">
                  Romanji:
                </span>
                <a-input v-model="toAdd.doc_romanji" outlined> </a-input>
              </a-col>

              <a-col :xs="24" :sm="24" :md="24">
                <span style="font-family:Arial;font-weight:bold;color:black">
                  English:
                </span>
                <a-input v-model="toAdd.doc_english" outlined> </a-input>
              </a-col>

              <a-col :xs="24" :sm="24" :md="24">
                <span style="font-family:Arial;font-weight:bold;color:black">
                  CAD Request by Abbreviation:
                </span>
                <span
                  style="font-family:Arial;font-weight:bold;color:blue"
                  v-if="addUpdateText == 'Update Record'"
                >
                  ( Image: {{ toAdd.attachment_cadRequest }} )
                </span>
                <file-pond
                  credits="false"
                  name="test"
                  ref="pond"
                  label-idle="Drag or Click to add image"
                  v-model="uploadFiles_CADRequest"
                  :onaddfile="handleFilePondInit_CADRequest"
                  accepted-file-types="image/jpeg,image/png,image/bmp"
                />
              </a-col>

              <a-col :xs="24" :sm="24" :md="24">
                <span style="font-family:Arial;font-weight:bold;color:black">
                  Floor Plan/Elevation:
                </span>
                <span
                  style="font-family:Arial;font-weight:bold;color:blue;"
                  v-if="addUpdateText == 'Update Record'"
                >
                  ( Image: {{ toAdd.attachment_floorPlanElevation }} )
                </span>
                <file-pond
                  credits="false"
                  name="test"
                  ref="pond2"
                  label-idle="Drag or Click to add image"
                  v-model="uploadFiles_FloorElevation"
                  :onaddfile="handleFilePondInit_FloorElevation"
                  accepted-file-types="image/jpeg,image/png,image/bmp"
                />
              </a-col>

              <!-- <a-col :xs="24" :sm="24" :md="24">
                                <span style="font-family:Arial;font-weight:bold;color:black"> Remarks: </span>
                                <a-textarea v-model="toAdd.remarks"  placeholder="Put a text here.." :rows="4" />
                            </a-col> -->
            </a-row>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="saveUpdateReference()"
              :loading="isSavingRecord"
              class="success"
              style="font-family:Arial;"
            >
              <v-icon>mdi-content-save-check</v-icon>
              {{ addUpdateText }}
            </v-btn>
            <v-btn
              @click="closeAddUpdateDialog()"
              class="error"
              style="font-family:Arial;"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <v-overlay :value="loadingimages">
    <v-container
      class="d-flex flex-column align-center justify-center"
      fill-height>
      <v-progress-circular
        :size="64"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <div class="overlay-text"><center>Loading... Please wait<br>読み込み中... お待ちください</center></div>
    </v-container>
  </v-overlay>
    <!-- <loading-file :isFileDownloadingProps="isFileDownloading" />  -->
  </div>
  
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import tunnel from "tunnel";
import AWS from "aws-sdk";
import * as binconv from "binconv"; // 2024-08-19
// import _ from 'lodash'
// import moji from 'moji'

//import LoadingFile from './LoadingComponent.vue'

//Import Vue FilePond
import vueFilePond from "vue-filepond";

//Import FilePond styles
import "filepond/dist/filepond.min.css";

//Import FilePond plugins
//Please note that you need to install these plugins separately

//Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  components: {
    FilePond,
    // 'loading-file': LoadingFile
  },
  data() {
    return {

      // 2024-08-19
      tmpPaginatedData: [],
      currentPage: 1,
      pageSize: 30,


      loadingimages: false,
      region: process.env.VUE_APP_REGION,
      bucket: process.env.VUE_APP_BUCKET,
      totalPlannerReference: "",
      disableSearch: true,
      dateFormat: "YYYY-MM-DD",
      toAdd: {
        abbr: "---",
        houseType: null,
        category1: null,
        category2: null,
        doc_jap: null,
        doc_romanji: "",
        doc_english: "",
        remarks: "",
      },
      uploadFiles_CADRequest: [],
      uploadFiles_FloorElevation: [],
      isSavingRecord: false,

      addUpdateDialog: false,
      addUpdateText: "Add Record",
      pageNumber: 1,
      intRowNoPlannersRef: 30,
      plannersReference: [],
      isFileDownloading: false,
      loadingPlannersReference: false,
      viewImageDialog: false,
      searchText: "",
      selectedHouseType: ["ALL"],
      HouseTypeList: [
        "ALL",
        // "軸組",
        // "枠組",
        // "枠組/軸組",
        // "グランセゾン",
        // "グランセゾン/軸組",
        // "グランセゾン/枠組",
        // "グランセゾン/軸組/枠組"
        // "夢の家", //removed 20231206
        "グランセゾン",
        "セゾンF",
        "セゾンA",
        // "建物タイプ",
        "ブリアール",
        "グラン・スマート",
        // "セゾンF2-TD",
        "i-cube",
        "i-smart",
        "i-smile",
        // "i-smile+", removed 08/19/2024
        "HUGme",
        "HUGme fam",
      ],
      selectedConstMethod: ["ALL"],
      constMethodList: ["ALL", "軸組", "枠組"],
      selectedCategory1: ["ALL"],
      categoryList1: [
        "ALL",
        "基本仕様",
        "玄関まわり",
        "玄関戸",
        "部屋名",
        "洋室",
        "和室",
        "LDK",
        "トイレ・洗面所",
        "浴室",
        "内部",
        "建具",
        "床暖房",
        "収納",
        "ロスガード",
        "換気",
        "天井",
        "点検口",
        "設備",
        "外部",
        "外壁",
        "サッシ・窓まわり",
        "バルコニー・ルーフガーデン",
        "屋根",
        "パラペット",
        "太陽光",
        "外部その他",
        "その他",
      ],
      // categoryList1: [
      //   "ALL",
      //   // "基本情報",
      //   "内部",
      //   // "建具",
      //   "収納",
      //   "設備",
      //   // "住設",
      //   "外部",
      //   // "サッシ",
      //   "配置図",
      //   // "その他"
      //   //newly Added 20231206
      //   "和室",
      //   "建具",
      //   "サッシ",
      //   "玄関", // copy to categoryList2 20240819
      //   "屋根",
      //   "バルコニー",
      //   "パラペット",
      //   "太陽光",
      //   "タイル",
      //   "天井",
      //   "壁",
      //   "床",
      //   "床暖房",
      //   "換気",
      //   "その他",
      // ],
      // selectedCategory2: ["ALL"],  <!-- Hide selectedCategory2 --09/03/24 -->
      // categoryList2: [
      //   "ALL",
      //   // "夢の家",
      //   // "免震",
      //   // "構造",
      //   // "換気",// moved to categoryList1 20231206
      //   "システムバス",
      //   // "洗面台",
      //   // "バルコニー", // moved to categoryList1 20231206
      //   // "パラペット",
      //   "玄関",// moved to categoryList1 20231206
      //   "土間",
      //   // "和室",// moved to categoryList1 20231206
      //   // "部屋",
      //   // "防音室",
      //   // "和室造作",
      //   // "洋室造作/和室造作",
      //   // "洋室造作",
      //   "階段",
      //   "家具",
      //   // "壁",// moved to categoryList1 20231206
      //   "タレ壁",
      //   "パイプスペース",
      //   // "天井",// moved to categoryList1 20231206
      //   "アクセント",
      //   "インテリア",
      //   // "太陽光",// moved to categoryList1 20231206
      //   // "出窓",
      //   // "外部付帯",
      //   // "外部設備",
      //   // "タイル",// moved to categoryList1 20231206
      //   // "耐水害住宅"
      //   //newly Added 20231206
      //   "エアコン",
      //   "エコカラット",
      //   "床の間・仏間",
      //   "室内物干金物",
      //   "物入・押入",
      //   "洋室",
      //   "キッチン",
      //   "洗面所",
      //   "トイレ",
      //   "クローゼット",
      //   "ロスガード",
      //   "部屋名",
      //   "水栓",
      //   "点検口",
      //   "床補強",
      //   "柱",
      //   "棚・神棚",
      //   "ホームシアター",
      //   "照明",
      //   "車庫",
      //   "シャッター",
      //   "デザインルーバー",
      //   "給湯器",
      //   "浄化槽",
      //   "樋",
      //   "ウッドデッキ",
      //   "配置図寸法",
      //   "その他",
      // ],
      selectedDateRange: [],
      plannerRefObj: {},
      refreshBtn: true,
    };
  },
  watch: {
    //NOTE: for resetting of scroll top 2024-04-23
    pageNumber(){
      let tables = document.querySelectorAll('.v-data-table__wrapper')
      for(let table of tables){
        table.scrollTop = 0
      }
    },
    addUpdateDialog(val) {
      if (val) {
        this.HouseTypeList.shift();
        this.categoryList1.shift();
        // this.categoryList2.shift();  // Hide categoryList2 --09/03/24 -->
      } else {
        this.HouseTypeList.unshift("ALL");
        this.categoryList1.unshift("ALL");
        // this.categoryList2.unshift("ALL"); // Hide categoryList2 --09/03/24 -->
      }
    },
    searchText(val) {
      if (val) {
        this.pageNumber = 1;
      }
    },

    selectedHouseType() {
      if (
        this.selectedHouseType.includes("ALL") &&
        this.selectedHouseType.length == 2
      ) {
        this.selectedHouseType.splice(0, 1);
      }
      if (this.selectedHouseType.length == 0) {
        this.selectedHouseType = ["ALL"];
      }
      if (
        this.selectedHouseType.includes("ALL") &&
        this.selectedHouseType.length != 1
      ) {
        this.selectedHouseType = ["ALL"];
      }

      this.pageNumber = 1;
    },

    selectedCategory1() {
      if (
        this.selectedCategory1.includes("ALL") &&
        this.selectedCategory1.length == 2
      ) {
        this.selectedCategory1.splice(0, 1);
      }
      if (this.selectedCategory1.length == 0) {
        this.selectedCategory1 = ["ALL"];
      }
      if (
        this.selectedCategory1.includes("ALL") &&
        this.selectedCategory1.length != 1
      ) {
        this.selectedCategory1 = ["ALL"];
      }

      this.pageNumber = 1;
    },
    //newly added 20230822
    selectedConstMethod() {
      if (
        // removed in 08/17/24 
        // this.selectedConstMethod.includes("ALL") &&
        this.selectedConstMethod.length == 2
      ) {
        this.selectedConstMethod.splice(0, 1);
      }
      if (this.selectedConstMethod.length == 0) {
        this.selectedConstMethod = ["ALL"];
      }
      if (
        this.selectedConstMethod.includes("ALL") &&
        this.selectedConstMethod.length != 1
      ) {
        this.selectedConstMethod = ["ALL"];
      }

      this.pageNumber = 1;
    },

    // Hide selectedCategory2 --09/03/24 -->
    // selectedCategory2() {
    //   if (
    //     this.selectedCategory2.includes("ALL") &&
    //     this.selectedCategory2.length == 2
    //   ) {
    //     this.selectedCategory2.splice(0, 1);
    //   }
    //   if (this.selectedCategory2.length == 0) {
    //     this.selectedCategory2 = ["ALL"];
    //   }
    //   if (
    //     this.selectedCategory2.includes("ALL") &&
    //     this.selectedCategory2.length != 1
    //   ) {
    //     this.selectedCategory2 = ["ALL"];
    //   }

    //   this.pageNumber = 1;
    // },
    filteredPlannersReference(val) {
      // console.log(val)
      if (val) this.$forceUpdate();
    },
  },
  created() {
    console.log("Planners Reference Page");

    this.LoadDataPagination_PlannersReference();

    // this.getPlannersReference();
    //this.getPlannersReferenceForTesting();

    //console.log( 'line718', await this.promiseGetImageFromS3("${region}", "20220715124136cadfi.PNG"))
  },
  computed: {
    dataToDownload() {
      return this.filteredPlannersReference.map((rec) => {
        return {
          HouseType: rec.houseType,
          Category1: rec.category1,
          category2: rec.category2,
          Japanese: rec.doc_jap,
          Romanji: rec.doc_romanji,
          English: rec.doc_english,
          Abbr: rec.abbr,
          Remarks: rec.remarks,
          Date: rec.date,
        };
      });
    },
    PlannerReferenceFileName() {
      return `PlannerReference_${moment().format("YYYYMMDDHHmmss")}`;
    },
    screenSize() {
      return this.$vuetify.breakpoint.height;
    },
    paginationLength() {
      let l = this.filteredPlannersReference.length,
        s = this.intRowNoPlannersRef;
      return Math.ceil(l / s);
    },

    paginatedPlannerReference() {
      const start = (this.pageNumber - 1) * this.intRowNoPlannersRef,
        end = start + this.intRowNoPlannersRef;
      return this.filteredPlannersReference.slice(start, end);
      // return this.filteredPlannersReference;
    },

  //   filteredPlannersReference() {
  //     // return this.plannersReference
  //     let data = [];

  //     if (
  //       this.searchText != "" &&
  //       this.selectedHouseType.includes("ALL") &&
  //       this.selectedCategory1.includes("ALL") &&
  //       this.selectedCategory2.includes("ALL") &&
  //       this.selectedDateRange.length == 0
  //     ) {
  //       data = this.plannersReference.filter((rec) => {
  //         return (
  //           rec.doc_jap.toLowerCase().includes(this.searchText.toLowerCase()) ||
  //           rec.doc_romanji
  //             .toLowerCase()
  //             .includes(this.searchText.toLowerCase()) ||
  //           rec.doc_english
  //             .toLowerCase()
  //             .includes(this.searchText.toLowerCase()) ||
  //           rec.abbr.toLowerCase().includes(this.searchText.toLowerCase()) ||
  //           //    rec.remarks.toLowerCase().includes( this.searchText.toLowerCase() ) ||
  //           rec.houseType
  //             .toLowerCase()
  //             .includes(this.searchText.toLowerCase()) ||
  //           rec.category1
  //             .toLowerCase()
  //             .includes(this.searchText.toLowerCase()) ||
  //           rec.category2
  //             .toLowerCase()
  //             .includes(this.searchText.toLowerCase()) ||
  //           rec.date.toLowerCase().includes(this.searchText.toLowerCase())
  //         );
  //       });
  //     } else if (!this.selectedHouseType.includes("ALL")) {
  //       data = this.plannersReference.filter((rec) => {
  //         return this.selectedHouseType.includes(rec.houseType);
  //       });
  //     } else if (!this.selectedCategory1.includes("ALL")) {
  //       data = this.plannersReference.filter((rec) => {
  //         return this.selectedCategory1.includes(rec.category1);
  //       });
  //     } else if (!this.selectedCategory2.includes("ALL")) {
  //       data = this.plannersReference.filter((rec) => {
  //         return this.selectedCategory2.includes(rec.category2);
  //       });
  //     }

  //     // filter by date range;
  //     else if (this.selectedDateRange.length != 0) {
  //       const startDate = moment(this.selectedDateRange[0]).format(
  //         "YYYY-MM-DD"
  //       );
  //       const endDate = moment(this.selectedDateRange[1]).format("YYYY-MM-DD");
  //       data = this.plannersReference.filter((rec) => {
  //         return rec.date >= startDate && rec.date <= endDate;
  //       });
  //     } else {
  //       data = this.plannersReference;
  //     }

  //     return data;
  //   },
  // },

      filteredPlannersReference() {
    return this.plannersReference.filter(rec=>{
        if(this.searchText){
          let regex = new RegExp(this.searchText,"ig")
          let data = {...rec}
          data.doc_jap = data.doc_jap ? data.doc_jap : ''
          data.doc_romanji = data.doc_romanji ? data.doc_romanji : ''
          data.doc_english = data.doc_english ? data.doc_english : ''
          data.abbr = data.abbr ? data.abbr : ''
          data.remarks = data.remarks ? data.remarks : ''
          data.houseType = data.houseType ? typeof(data.houseType)=='string' ? [data.houseType] : data.houseType : []
          data.category1 = data.category1 ? data.category1 : ''
          data.category2 = data.category2 ? data.category2 : ''
          data.constMethod = data.constMethod ? data.constMethod : ''
          data.date = data.date ? data.date : ''
          return (
            data.doc_jap.match(regex) ||
            data.doc_romanji.match(regex) ||
            data.doc_english.match(regex) ||
            data.abbr.match(regex) ||
            data.remarks.match(regex) ||
            data.houseType.some(a=>a.match(regex))||
            data.category1.match(regex) ||
            data.category2.match(regex) ||
            data.constMethod.match(regex) ||
            data.date.match(regex)
          )
        }
        else{
          return rec
        }
      })

      //filter for construction method
      .filter((rec) => {
        if(!this.selectedConstMethod.includes("ALL")){
          return rec.constMethod.includes(this.selectedConstMethod[0])
        }
        else{
          return rec
        }
      })

      //filter for category1
      .filter((rec) => {
        if(!this.selectedCategory1.includes("ALL")){
          return this.selectedCategory1.includes(rec.category1)
        }
        else{
          return rec
        }
      })

      // Hide selectedCategory2 --09/03/24 -->
      //filter for category2
      // .filter((rec) => {
      //   if(!this.selectedCategory2.includes("ALL")){
      //     return this.selectedCategory2.includes(rec.category2)
      //   }
      //   else{
      //     return rec
      //   }
      // })

      //filter for house type
      .filter((rec) => {
          if(!this.selectedHouseType.includes("ALL")){
            return this.selectedHouseType.every(a=>{
              if(typeof(rec.houseType)=='object'){
                return rec.houseType.includes(a)
              }else{
                return rec.houseType==a
              }
            })
          }
          else{
            return rec
          }
      });
    },
  },
  methods: {

    async LoadDataPagination_PlannersReference(){

          try{
            this.loadingimages = true
            this.disableSearch = true
            this.refreshBtn = true
            const dataPlannersReference = await this.promiseGetPlannersReference();
            
            this.disableSearch = false;
            const initialStart = (this.currentPage - 1) * this.pageSize;
            const initialEnd = initialStart + this.pageSize;

            // page =1
            this.tmpPaginatedData =  dataPlannersReference.slice( initialStart, initialEnd );

            // Continue while there is data in the tmpPaginatedData
            while ( this.tmpPaginatedData.length > 0 ) {

              // Fetch and convert all images for cad request for the current page
              const imageConversionPromises = this.tmpPaginatedData.map(item =>
                this.promiseConvertImagesCADRequest(item.attachment_cadRequest),
              );

              // Fetch and convert all images for floor elevation for the current page
              const imageConversionPromises2 = this.tmpPaginatedData.map(item =>
                  this.promiseConvertImagesFloorElevation(item.attachment_floorPlanElevation)
              );
              this.loadingimages = false      

              // Await all promises for the current page
              const images = await Promise.all(imageConversionPromises);

              // console.log('line423', images )

              const images2 = await Promise.all(imageConversionPromises2);

               // Add the images and data to the plannersReference
              this.tmpPaginatedData.forEach((item, index) => {
                item.blobImageSrc_CADRequest = images[index].blobImageSrc_CADRequest;
                item.blobImageSrc_FloorPlanElevation = images2[index].blobImageSrc_FloorPlanElevation;
                this.plannersReference.push(item);
              });

               // Move to the next page
              this.currentPage++;

               // Update the start and end indexes for the next page
              const start = (this.currentPage - 1) * this.pageSize;
              const end = start + this.pageSize;

               // Slice the data for the next page
               this.tmpPaginatedData = dataPlannersReference.slice(start, end);
            }

            console.log("All data and images have been loaded successfully");
             this.refreshBtn = false;
            
          }catch(err) {
             console.log(err.message);
             this.isLoadingPlannerReference = false
          }
        },


    // Refactor Function
    async promiseConvertImagesCADRequest(item) {
          return new Promise((resolve, reject) => {
            const tunnelingAgent = tunnel.httpsOverHttp({
              proxy: {
                host: "hrdproxy.hrd-s.com",
                port: 81,
                proxyAuth: "administrator:system",
              },
            });

            AWS.config.update({
              httpOptions: { agent: tunnelingAgent },
              region: process.env.VUE_APP_REGION,
              accessKeyId: process.env.VUE_APP_ACCESS_KEY,
              secretAccessKey: process.env.VUE_APP_SECRET_ACCESS_KEY
            });

            const s3 = new AWS.S3({ apiVersion: "2006-03-01" });
            const options = {
              Bucket: process.env.VUE_APP_BUCKET,
              Key: `PlannersReference/${item}`,
            };

            s3.getObject(options, (err, data) => {
              if (err) {
                console.error("Error fetching image from S3:", err);
                reject(err);
              } else {
                const blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
                  type: `${data.ContentType};charset=utf-8`,
                });

                const blobImageSrc_CADRequest = URL.createObjectURL(blob);

                resolve({
                  blobImageSrc_CADRequest,
                });
              }
            });
          });
        },

        async promiseConvertImagesFloorElevation(item) {
          return new Promise((resolve, reject) => {
            const tunnelingAgent = tunnel.httpsOverHttp({
              proxy: {
                host: "hrdproxy.hrd-s.com",
                port: 81,
                proxyAuth: "administrator:system",
              },
            });

            AWS.config.update({
              httpOptions: { agent: tunnelingAgent },
              region: process.env.VUE_APP_REGION,
              accessKeyId: process.env.VUE_APP_ACCESS_KEY,
              secretAccessKey: process.env.VUE_APP_SECRET_ACCESS_KEY
            });

            const s3 = new AWS.S3({ apiVersion: "2006-03-01" });
            const options = {
              Bucket: process.env.VUE_APP_BUCKET,
              Key: `PlannersReference/${item}`,
            };

            s3.getObject(options, (err, data) => {
              if (err) {
                console.error("Error fetching image from S3:", err);
                reject(err);
              } else {
                const blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
                  type: `${data.ContentType};charset=utf-8`,
                });

                const blobImageSrc_FloorPlanElevation = URL.createObjectURL(blob);

                resolve({
                  blobImageSrc_FloorPlanElevation,
                });
              }
            });
          });
      },



    // ======================================OLD========================================== //
    promiseLoadBase64Images(bucket_name, data) {
      return new Promise(async (resolve) => {
        var tunnelingAgent = tunnel.httpsOverHttp({
          proxy: {
            // Proxy settings
            host: "hrdproxy.hrd-s.com",
            port: 81,
            proxyAuth: "administrator:system",
          },
        });

        AWS.config.update({
          httpOptions: { agent: tunnelingAgent },
          region: process.env.VUE_APP_REGION,
          accessKeyId: this.accessKeyId,
          secretAccessKey: this.secretAccessKey,
        });

        let s3 = new AWS.S3({ apiVersion: "2006-03-01" });

        const objects = [];

        for (const key of data) {
          const params = {
            Bucket: bucket_name,
            Key: `PlannersReference/${key.attachment_cadRequest}`,
          };

          const params2 = {
            Bucket: bucket_name,
            Key: `PlannersReference/${key.attachment_cadRequest}`,
          };

          const data = await s3.getObject(params).promise(); // CAD Request
          const data2 = await s3.getObject(params2).promise(); // Floor plan Elevation

          objects.push({
            data: key,
            base64Img_CadRequest:
              "data:image/jpeg;base64," +
              Buffer.from(data.Body).toString("base64"),
            base64Img_FloorElevation:
              "data:image/jpeg;base64," +
              Buffer.from(data2.Body).toString("base64"),
          });

          // console.log("line938", objects);
        }

        resolve(objects);
      });
    },
    async testGetImageFromS3(bucket_name) {
      var tunnelingAgent = tunnel.httpsOverHttp({
        proxy: {
          // Proxy settings
          host: "hrdproxy.hrd-s.com",
          port: 81,
          proxyAuth: "administrator:system",
        },
      });

      AWS.config.update({
        httpOptions: { agent: tunnelingAgent },
        region: process.env.VUE_APP_REGION,
        accessKeyId: this.accessKeyId,
        secretAccessKey: this.secretAccessKey,
      });

      let s3 = new AWS.S3({ apiVersion: "2006-03-01" });

      var sampleArr = [
        {
          attachment_cadRequest: "noImage.jpg",
          attachment_floorPlanElevation: "noImage.jpg",
        },
        {
          attachment_cadRequest: "20170505021004ps.jpg",
          attachment_floorPlanElevation: "20170505021004ps.jpg",
        },
        {
          attachment_cadRequest: "20170505023733ps2.jpg",
          attachment_floorPlanElevation: "20170505023733ps2.jpg",
        },
        {
          attachment_cadRequest: "20170505025339sw.jpg",
          attachment_floorPlanElevation: "20170505025339sw.jpg",
        },
        {
          attachment_cadRequest: "20170505025339sw2.jpg",
          attachment_floorPlanElevation: "20170505025339sw2.jpg",
        },
        {
          attachment_cadRequest: "noImage.jpg",
          attachment_floorPlanElevation: "noImage.jpg",
        },
        {
          attachment_cadRequest: "20170505021004ps.jpg",
          attachment_floorPlanElevation: "20170505021004ps.jpg",
        },
        {
          attachment_cadRequest: "20170505023733ps2.jpg",
          attachment_floorPlanElevation: "20170505023733ps2.jpg",
        },
        {
          attachment_cadRequest: "20170505025339sw.jpg",
          attachment_floorPlanElevation: "20170505025339sw.jpg",
        },
        {
          attachment_cadRequest: "20170505025339sw2.jpg",
          attachment_floorPlanElevation: "20170505025339sw2.jpg",
        },
        {
          attachment_cadRequest: "noImage.jpg",
          attachment_floorPlanElevation: "noImage.jpg",
        },
        {
          attachment_cadRequest: "20170505021004ps.jpg",
          attachment_floorPlanElevation: "20170505021004ps.jpg",
        },
        {
          attachment_cadRequest: "20170505023733ps2.jpg",
          attachment_floorPlanElevation: "20170505023733ps2.jpg",
        },
        {
          attachment_cadRequest: "20170505025339sw.jpg",
          attachment_floorPlanElevation: "20170505025339sw.jpg",
        },
        {
          attachment_cadRequest: "20170505025339sw2.jpg",
          attachment_floorPlanElevation: "20170505025339sw2.jpg",
        },
        {
          attachment_cadRequest: "noImage.jpg",
          attachment_floorPlanElevation: "noImage.jpg",
        },
        {
          attachment_cadRequest: "20170505021004ps.jpg",
          attachment_floorPlanElevation: "20170505021004ps.jpg",
        },
        {
          attachment_cadRequest: "20170505023733ps2.jpg",
          attachment_floorPlanElevation: "20170505023733ps2.jpg",
        },
        {
          attachment_cadRequest: "20170505025339sw.jpg",
          attachment_floorPlanElevation: "20170505025339sw.jpg",
        },
        {
          attachment_cadRequest: "20170505025339sw2.jpg",
          attachment_floorPlanElevation: "20170505025339sw2.jpg",
        },
      ];

      const objects = [];

      for (const key of sampleArr) {
        const params = {
          Bucket: bucket_name,
          Key: `PlannersReference/${key.attachment_cadRequest}`,
        };
        const data = await s3.getObject(params).promise();
        objects.push({
          key: key,
          content:
            "data:image/jpeg;base64," +
            Buffer.from(data.Body).toString("base64"),
        });
      }

      // console.log("line934", objects);
    },

    PromisetestGetImageFromS3(bucket_name, data) {
      // 2023-05-02
      let count = 0;
      return new Promise((resolve, reject) => {
        var tunnelingAgent = tunnel.httpsOverHttp({
          proxy: {
            // Proxy settings
            host: "hrdproxy.hrd-s.com",
            port: 81,
            proxyAuth: "administrator:system",
          },
        });

        AWS.config.update({
          httpOptions: { agent: tunnelingAgent },
          region: process.env.VUE_APP_REGION,
          accessKeyId: this.accessKeyId,
          secretAccessKey: this.secretAccessKey,
        });

        let s3 = new AWS.S3({ apiVersion: "2006-03-01" });

        for (let rec of data) {
          // CAD Request
          let params = {
            Bucket: bucket_name,
            Key: `PlannersReference/${rec.attachment_cadRequest}`,
            ResponseContentType: "arraybuffer",
          };

          // Floor Plan Elevation
          let params2 = {
            Bucket: bucket_name,
            Key: `PlannersReference/${rec.attachment_floorPlanElevation}`,
            ResponseContentType: "arraybuffer",
          };

          s3.getObject(params, function(err, data) {
            if (err) {
              console.log(err, err.stack); // an error occurred
              reject({ message: err.stack });
            } else {
              if (data) {
                s3.getObject(params2, function(err1, data1) {
                  // Convert the image to a base64-encoded data URL
                  if (err1) {
                    console.log(err1, err1.stack); // an error occurred
                    reject({ message: err1.stack });
                  } else {
                    count++;
                    var CADRequest =
                      "data:image/jpeg;base64," +
                      Buffer.from(data.Body).toString("base64");
                    var FloorElevation =
                      "data:image/jpeg;base64," +
                      Buffer.from(data1.Body).toString("base64");
                    rec.base64Img_CadRequest = CADRequest;
                    rec.base64Img_FloorElevation = FloorElevation;
                    return rec;
                  }
                });
              }
            }
          });
        }

        if (count == data.length) {
          resolve(data);
          //  return data
        }
      });
    },

    GetImageFromS3(bucket_name, filename) {
      var tunnelingAgent = tunnel.httpsOverHttp({
        proxy: {
          // Proxy settings
          host: "hrdproxy.hrd-s.com",
          port: 81,
          proxyAuth: "administrator:system",
        },
      });

      AWS.config.update({
        httpOptions: { agent: tunnelingAgent },
        region: process.env.VUE_APP_REGION,
        accessKeyId: this.accessKeyId,
        secretAccessKey: this.secretAccessKey,
      });

      let s3 = new AWS.S3({ apiVersion: "2006-03-01" });

      let params = {
        Bucket: bucket_name,
        Key: `PlannersReference/${filename}`,
        ResponseContentType: "arraybuffer",
      };

      s3.getObject(params, function(err, data) {
        if (err) {
          console.log(err, err.stack); // an error occurred
        } else {
          // Convert the image to a base64-encoded data URL
          var base64Image =
            "data:image/jpeg;base64," +
            Buffer.from(data.Body).toString("base64");
          // Render the image in an <img> tag or other element
          // document.getElementById('my-image').src = base64Image;
          console.log(base64Image);
        }
      });
    },

    promiseGetImageFromS3(bucket_name, filename) {
      return new Promise((resolve, reject) => {
        var tunnelingAgent = tunnel.httpsOverHttp({
          proxy: {
            // Proxy settings
            host: "hrdproxy.hrd-s.com",
            port: 81,
            proxyAuth: "administrator:system",
          },
        });

        AWS.config.update({
          httpOptions: { agent: tunnelingAgent },
          region: process.env.VUE_APP_REGION,
          accessKeyId: this.accessKeyId,
          secretAccessKey: this.secretAccessKey,
        });

        let s3 = new AWS.S3({ apiVersion: "2006-03-01" });

        let params = {
          Bucket: bucket_name,
          Key: `PlannersReference/${filename}`,
          ResponseContentType: "arraybuffer",
        };

        s3.getObject(params, function(err, data) {
          if (err) {
            console.log(err, err.stack); // an error occurred
            reject({ message: err.stack });
          } else {
            // Convert the image to a base64-encoded data URL
            var base64Image =
              "data:image/jpeg;base64," +
              Buffer.from(data.Body).toString("base64");
            // Render the image in an <img> tag or other element
            // document.getElementById('my-image').src = base64Image;
            resolve(base64Image);
          }
        });
      });
    },

    handleFilePondInit_CADRequest() {
      return new Promise((resolve, reject) => {
        let toResolve = [];
        if (this.uploadFiles_CADRequest != undefined) {
          toResolve = this.uploadFiles_CADRequest.map((rec) => {
            return rec.file;
          });

          // console.log("line1611", toResolve);

          if (
            toResolve[0].type == "image/jpeg" ||
            toResolve[0].type == "image/png" ||
            toResolve[0].type == "image/bmp"
          ) {
            // Check If PDF File
            resolve(toResolve);
          } else {
            // console.log("d2");
            reject({ message: "NOT Support File" });
          }
        } else {
          // console.log("d3");
          reject([]);
        }
      });
    },
    handleFilePondInit_FloorElevation() {
      return new Promise((resolve, reject) => {
        let toResolve = [];
        if (this.uploadFiles_FloorElevation != undefined) {
          toResolve = this.uploadFiles_FloorElevation.map((rec) => {
            return rec.file;
          });

          if (
            toResolve[0].type == "image/jpeg" ||
            toResolve[0].type == "image/png" ||
            toResolve[0].type == "image/bmp"
          ) {
            // Check If PDF File
            resolve(toResolve);
          } else {
            reject({ message: "NOT Support File" });
          }
        } else {
          reject([]);
        }
      });
    },

    promiseRegisterPlannerReference(
      ImageType_CadRequest,
      ImageType_FloorPlanElevation
    ) {
      return new Promise((resolve, reject) => {
        if (
          !this.toAdd.abbr ||
          !this.toAdd.houseType ||
          !this.toAdd.category1 ||
          !this.toAdd.category2 ||
          !this.toAdd.doc_jap ||
          !this.toAdd.doc_romanji ||
          !this.toAdd.doc_english ||
          this.uploadFiles_CADRequest.length == 0 ||
          this.uploadFiles_FloorElevation.length == 0
        ) {
          Swal.fire({
            icon: "warning",
            title: `Empty Field(s)`,
            showConfirmButton: false,
            timer: 1200,
          });
        } else {
          let type_CadRequest = "";
          let type_CadFloorPlanElevation = "";

          // CAD Request Type Checking
          if (
            ImageType_CadRequest == "image/jpeg" ||
            ImageType_CadRequest == "image/jpg"
          ) {
            type_CadRequest = "jpg";
          } else if (ImageType_CadRequest == "image/png") {
            type_CadRequest = "png";
          } else {
            type_CadRequest = "bmp";
          }

          // Floor Plan Elevation Type Checking
          if (
            ImageType_FloorPlanElevation == "image/jpeg" ||
            ImageType_FloorPlanElevation == "image/jpg"
          ) {
            type_CadFloorPlanElevation = "jpg";
          } else if (ImageType_FloorPlanElevation == "image/png") {
            type_CadFloorPlanElevation = "png";
          } else {
            type_CadFloorPlanElevation = "bmp";
          }

          this.toAdd.date = moment().format("YYYY-MM-DD");
          this.toAdd.attachment_cadRequest = `attachment_cadRequest_${moment().format(
            "YYYYMMDDHHmmss"
          )}.${type_CadRequest}`;
          this.toAdd.attachment_floorPlanElevation = `attachment_floorPlanElevation_${moment().format(
            "YYYYMMDDHHmmss"
          )}.${type_CadFloorPlanElevation}`;

          this.toAdd.updatedBy = "tanskie"; // CHANGE THE VALUE OF USER DETAILS IN LOCAL DSS

          const url = `${this.api}plannersReference/insertPlannersReference`;
          axios
            .post(url, this.toAdd)
            .then((res) => {
              if (res.data) {
                resolve({ data: this.toAdd });
              }
            })
            .catch((err) => {
              reject({ message: err.message });
            });
        }
      });
    },

    promiseUpdatePlannerReferenceWithoutAttachment() {
      return new Promise((resolve, reject) => {
        this.toAdd.updatedBy = "tanskie"; // PLEASE REPLACE UPDATED BY IN DSS LOCAL
        const url = `${this.api}plannersReference/updatedPlannersReference`;
        axios
          .put(url, this.toAdd)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    promiseUpdatePlannerReferenceWithAttachment_CADRequest(
      ImageType_CadRequest
    ) {
      // CAD Request Type Checking
      let type_CadRequest = "";

      if (
        ImageType_CadRequest == "image/jpeg" ||
        ImageType_CadRequest == "image/jpg"
      ) {
        type_CadRequest = "jpg";
      } else if (ImageType_CadRequest == "image/png") {
        type_CadRequest = "png";
      } else {
        type_CadRequest = "bmp";
      }

      return new Promise((resolve, reject) => {
        this.toAdd.attachment_cadRequest = `attachment_cadRequest_${moment().format(
          "YYYYMMDDHHmmss"
        )}.${type_CadRequest}`;
        //  this.toAdd.attachment_floorPlanElevation = `attachment_floorPlanElevation_${moment().format("YYYYMMDDHHmmss")}`;
        this.toAdd.updatedBy = "tanskie"; // PLEASE REPLACE UPDATED BY IN DSS LOCAL.
        const url = `${this.api}plannersReference/updatedPlannersReference`;
        axios
          .put(url, this.toAdd)
          .then((res) => {
            if (res.data) {
              resolve({ data: this.toAdd });
            }
          })
          .catch((err) => {
            console.log(err.message);
            reject({ message: err.message });
          });
      });
    },

    promiseUpdatePlannerReferenceWithAttachment_FloorPlanElevation(
      ImageType_FloorPlanElevation
    ) {
      // Floor Plan Elevation Type Checking
      let type_CadFloorPlanElevation = "";
      if (
        ImageType_FloorPlanElevation == "image/jpeg" ||
        ImageType_FloorPlanElevation == "image/jpg"
      ) {
        type_CadFloorPlanElevation = "jpg";
      } else if (ImageType_FloorPlanElevation == "image/png") {
        type_CadFloorPlanElevation = "png";
      } else {
        type_CadFloorPlanElevation = "bmp";
      }

      return new Promise((resolve, reject) => {
        //  this.toAdd.attachment_cadRequest = `attachment_cadRequest_${moment().format("YYYYMMDDHHmmss")}`;
        this.toAdd.attachment_floorPlanElevation = `attachment_floorPlanElevation_${moment().format(
          "YYYYMMDDHHmmss"
        )}.${type_CadFloorPlanElevation}`;
        this.toAdd.updatedBy = "tanskie"; // PLEASE REPLACE UPDATED BY IN DSS LOCAL.
        const url = `${this.api}plannersReference/updatedPlannersReference`;
        axios
          .put(url, this.toAdd)
          .then((res) => {
            if (res.data) {
              resolve({ data: this.toAdd });
            }
          })
          .catch((err) => {
            console.log(err.message);
            reject({ message: err.message });
          });
      });
    },

    promiseUploadFiles(files, filename) {
      let count = 0;
      return new Promise((resolve, reject) => {
        if (files.length > 0) {
          var tunnelingAgent = tunnel.httpsOverHttp({
            proxy: {
              // Proxy settings
              host: "hrdproxy.hrd-s.com",
              port: 81,
              proxyAuth: "administrator:system",
            },
          });

          AWS.config.update({
            httpOptions: { agent: tunnelingAgent },
            region: process.env.VUE_APP_REGION,
            accessKeyId: this.accessKeyId,
            secretAccessKey: this.secretAccessKey,
          });

          let s3 = new AWS.S3({ apiVersion: "2006-03-01" });

          for (let i = 0; i < files.length; i++) {
            let fileBody = files[i];

            let uploadParams = {
              Bucket: process.env.VUE_APP_BUCKET, 
              // Key: `PlannersReference/${filename}.jpg`,
              Key: `PlannersReference/${filename}`, // 2023-04-28
              Body: fileBody, // Object file na iuupload sa server
            };

            s3.upload(uploadParams, function(err, data) {
              if (err) {
                console.log("Error", err);
                reject(err);
              }
              if (data) {
                // console.log("Upload Success", data.Location);
                count++;
                if (count == files.length) {
                  resolve("Upload Success CAD Request Image!");
                  console.log("Upload Sucess CAD request Image !");
                }
              }
            });
          }
        } else {
          reject({ message: "No such files to upload" });
        }
      });
    },

    async saveUpdateReference() {
      try {
        this.isSavingRecord = true;

        if (this.addUpdateText == "Add Record") {
          let files_CADRequest = await this.handleFilePondInit_CADRequest();
          let files_FloorElevation = await this.handleFilePondInit_FloorElevation();

          // console.log("line1000", files_CADRequest[0].type);
          // console.log("line1001", files_FloorElevation[0].type);
          // ============================== FOR REGISTRATION =========================================== //
          let response = await this.promiseRegisterPlannerReference(
            files_CADRequest[0].type,
            files_FloorElevation[0].type
          );

          let uploads_CADRequest = await this.promiseUploadFiles(
            files_CADRequest,
            response.data.attachment_cadRequest
          );
          let uploads_FloorPlanElevation = await this.promiseUploadFiles(
            files_FloorElevation,
            response.data.attachment_floorPlanElevation
          );
          if (uploads_CADRequest && uploads_FloorPlanElevation) {
            Swal.fire({
              icon: "success",
              title: `Successfully Upload and Save Record`,
              showConfirmButton: false,
              timer: 1200,
            });
            this.$refs.pond.removeFiles();
            this.$refs.pond2.removeFiles();
            this.getPlannersReference();
            this.closeAddUpdateDialog();
          }
        } else {
          // =============================== FOR UPDATING ============================================= //
          console.log("For updating...");
          // console.log( 'line1826', this.uploadFiles_CADRequest );
          // console.log( 'line1827', this.uploadFiles_FloorElevation )

          if (
            this.uploadFiles_CADRequest.length == 0 &&
            this.uploadFiles_FloorElevation.length == 0
          ) {
            console.log("walang attachment");
            await this.promiseUpdatePlannerReferenceWithoutAttachment();
            Swal.fire({
              icon: "success",
              title: `Successfully Update Record`,
              showConfirmButton: false,
              timer: 1200,
            });
            this.$refs.pond.removeFiles();
            this.$refs.pond2.removeFiles();
            this.getPlannersReference();
            this.closeAddUpdateDialog();
          } else if (
            this.uploadFiles_CADRequest.length != 0 &&
            this.uploadFiles_FloorElevation.length != 0
          ) {
            console.log("may attachment parehas");

            let files_CADRequest = await this.handleFilePondInit_CADRequest();
            let files_FloorElevation = await this.handleFilePondInit_FloorElevation();

            let response1 = await this.promiseUpdatePlannerReferenceWithAttachment_CADRequest(
              files_CADRequest[0].type
            );
            let response2 = await this.promiseUpdatePlannerReferenceWithAttachment_FloorPlanElevation(
              files_FloorElevation[0].type
            );

            let data1 = await this.promiseUploadFiles(
              files_CADRequest,
              response1.data.attachment_cadRequest
            );
            let data2 = await this.promiseUploadFiles(
              files_FloorElevation,
              response2.data.attachment_floorPlanElevation
            );

            if (data1 && data2) {
              Swal.fire({
                icon: "success",
                title: `Successfully Update Record`,
                showConfirmButton: false,
                timer: 1200,
              });
              this.$refs.pond.removeFiles();
              this.$refs.pond2.removeFiles();
              this.getPlannersReference();
              this.closeAddUpdateDialog();
            }
          } else if (this.uploadFiles_CADRequest.length != 0) {
            console.log("may attachment si CAD request ");
            let files_CADRequest = await this.handleFilePondInit_CADRequest();
            let response1 = await this.promiseUpdatePlannerReferenceWithAttachment_CADRequest(
              files_CADRequest[0].type
            );

            let data1 = await this.promiseUploadFiles(
              files_CADRequest,
              response1.data.attachment_cadRequest
            );

            if (data1) {
              Swal.fire({
                icon: "success",
                title: `Successfully Update Record`,
                showConfirmButton: false,
                timer: 1200,
              });
              this.$refs.pond.removeFiles();
              this.$refs.pond2.removeFiles();
              this.getPlannersReference();
              this.closeAddUpdateDialog();
            }
          } else if (this.uploadFiles_FloorElevation.length != 0) {
            console.log("may attachment si Floor Elevation ");
            let files_FloorElevation = await this.handleFilePondInit_FloorElevation();
            let response2 = await this.promiseUpdatePlannerReferenceWithAttachment_FloorPlanElevation(
              files_FloorElevation[0].type
            );

            let data1 = await this.promiseUploadFiles(
              files_FloorElevation,
              response2.data.attachment_floorPlanElevation
            );

            if (data1) {
              Swal.fire({
                icon: "success",
                title: `Successfully Update Record`,
                showConfirmButton: false,
                timer: 1200,
              });
              this.$refs.pond.removeFiles();
              this.$refs.pond2.removeFiles();
              this.getPlannersReference();
              this.closeAddUpdateDialog();
            }
          }

          this.isSavingRecord = false;
        }
      } catch (err) {
        console.log(err.message);
        this.$refs.pond.removeFiles();
        this.$refs.pond2.removeFiles();
        this.isSavingRecord = false;
        Swal.fire({
          icon: "warning",
          title: `Error in Uploading & Saving Record`,
          showConfirmButton: false,
          timer: 1200,
        });
      }
    },
    promiseGetPlannersReference() {
      return new Promise((resolve, reject) => {
        const url = `${this.api}plannersReference/getPlannersReference`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              let toResolve = res.data
                .sort((a, b) => {
                  return a.date < b.date ? 1 : -1;
                })
                .map((obj) => {
                  delete obj.remarks;
                  return obj;
                });
              this.totalPlannerReference = toResolve;
              // console.log(toResolve.length);
              resolve(toResolve);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    async getPlannersReferenceForTesting() {
      this.loadingPlannersReference = true;
      try {
        //this.plannersReference = await this.promiseGetPlannersReference();
        let promises = this.tmpPlannersReference.map(async (item) => {
          //  await this.promiseGetImageFromS3("${region}","attachment_cadRequest_20230426075727.jpg") )
          item.base64Img_CadRequest = item.attachment_cadRequest
            ? await this.promiseGetImageFromS3(
                "${region}",
                `${item.attachment_cadRequest}`
              )
            : "";
          item.base64Img_FloorElevation = item.attachment_floorPlanElevation
            ? await this.promiseGetImageFromS3(
                "${region}",
                `${item.attachment_floorPlanElevation}`
              )
            : "";
          return item;
        });

        this.plannersReference = await Promise.all(promises);
        console.log("line1953", this.plannersReference);
        this.loadingPlannersReference = false;
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: `Error in Fetching Data, Please Try Again!`,
          showConfirmButton: false,
          timer: 1200,
        });
        this.loadingPlannersReference = false;
      }
    },

    // async getPlannersReference(){
    //     this.loadingPlannersReference = true
    //     try{

    //         this.plannersReference = await this.promiseGetPlannersReference();
    //         this.loadingPlannersReference = false;

    //     }catch(err){
    //          Swal.fire({
    //             icon: "error",
    //             title: `Error in Fetching Data, Please Try Again!`,
    //             showConfirmButton: false,
    //             timer: 1200,
    //         });
    //         this.loadingPlannersReference = false;
    //     }
    // },

    //modified by jom // commented 20230912 for trial
    // async getPlannersReference() {
    //   let count = 0;
    //   // let recValue = 0
    //   this.loadingPlannersReference = true;
    //   this.plannersReference = [];

    //   try {
    //     // this.testGetAllDataImageJom()
    //     //this.plannersReference = await this.promiseGetPlannersReference();

    //     let dataRec = await this.promiseGetPlannersReference();

    //     const length = Math.ceil(dataRec.length / 4); // Calculate the length of each subarray

    //     const splitArrays = [];
    //     for (let i = 0; i < dataRec.length; i += length) {
    //       const subarray = dataRec.slice(i, i + length);
    //       splitArrays.push(subarray);
    //     }
    //     // console.log(splitArrays)

    //     if (dataRec.length > 0) {
    //       //removed 2030711
    //       for (let [i, item] of splitArrays[0].entries()) {
    //         // console.log(i)
    //         // let index = dataRec.indexOf( item )
    //         let tunnelingAgent = tunnel.httpsOverHttp({
    //           proxy: {
    //             // Proxy settings
    //             host: "hrdproxy.hrd-s.com",
    //             port: 81,
    //             proxyAuth: "administrator:system",
    //           },
    //         });

    //         AWS.config.update({
    //           httpOptions: { agent: tunnelingAgent },
    //           region: process.env.VUE_APP_REGION,
    //           accessKeyId: this.accessKeyId,
    //           secretAccessKey: this.secretAccessKey,
    //         });

    //         let s3 = new AWS.S3({ apiVersion: "2006-03-01" });

    //         const params = {
    //           Bucket: process.env.VUE_APP_BUCKET, 
    //           Key: `PlannersReference/${item.attachment_cadRequest}`,
    //         };

    //         const params2 = {
    //           Bucket: process.env.VUE_APP_BUCKET, 
    //           Key: `PlannersReference/${item.attachment_floorPlanElevation}`,
    //         };

    //         // console.log('1616', index )
    //         // CAD Request Image
    //         s3.getObject(params)
    //           .promise()
    //           .then((data) => {
    //             // item.base64Img_CadRequest = 'data:image/jpeg;base64,' + Buffer.from(data.Body).toString('base64');
    //             this.$set(
    //               item,
    //               "base64Img_CadRequest",
    //               "data:image/jpeg;base64," +
    //                 Buffer.from(data.Body).toString("base64")
    //             );
    //           })
    //           .catch((err) => {
    //             console.log(`Error retrieving S3 Object ->  ${err}`);
    //           });

    //         // Floor Elevation Image
    //         s3.getObject(params2)
    //           .promise()
    //           .then((data2) => {
    //             // item.base64Img_FloorElevation = 'data:image/jpeg;base64,' + Buffer.from(data2.Body).toString('base64');
    //             this.$set(
    //               item,
    //               "base64Img_FloorElevation",
    //               "data:image/jpeg;base64," +
    //                 Buffer.from(data2.Body).toString("base64")
    //             );
    //           })
    //           .catch((err2) => {
    //             console.log(`Error retrieving S3 Object -> ${err2}`);
    //           });

    //         count++;
    //         // 2023-05-08
    //         if (i == splitArrays[0].length - 1) {
    //           this.plannersReference = [
    //             ...this.plannersReference,
    //             ...splitArrays[0],
    //           ];
    //         }
    //         this.$forceUpdate();
    //       } // END FOR LOOP

    //       for (let [i, item] of splitArrays[1].entries()) {
    //         // let index = dataRec.indexOf( item )
    //         let tunnelingAgent = tunnel.httpsOverHttp({
    //           proxy: {
    //             // Proxy settings
    //             host: "hrdproxy.hrd-s.com",
    //             port: 81,
    //             proxyAuth: "administrator:system",
    //           },
    //         });

    //         AWS.config.update({
    //           httpOptions: { agent: tunnelingAgent },
    //           region: process.env.VUE_APP_REGION,
    //           accessKeyId: this.accessKeyId,
    //           secretAccessKey: this.secretAccessKey,
    //         });

    //         let s3 = new AWS.S3({ apiVersion: "2006-03-01" });

    //         const params = {
    //           Bucket: process.env.VUE_APP_BUCKET, 
    //           Key: `PlannersReference/${item.attachment_cadRequest}`,
    //         };

    //         const params2 = {
    //           Bucket: process.env.VUE_APP_BUCKET, 
    //           Key: `PlannersReference/${item.attachment_floorPlanElevation}`,
    //         };

    //         // console.log('1616', index )
    //         // CAD Request Image
    //         s3.getObject(params)
    //           .promise()
    //           .then((data) => {
    //             // item.base64Img_CadRequest = 'data:image/jpeg;base64,' + Buffer.from(data.Body).toString('base64');
    //             this.$set(
    //               item,
    //               "base64Img_CadRequest",
    //               "data:image/jpeg;base64," +
    //                 Buffer.from(data.Body).toString("base64")
    //             );
    //           })
    //           .catch((err) => {
    //             console.log(`Error retrieving S3 Object ->  ${err}`);
    //           });

    //         // Floor Elevation Image
    //         s3.getObject(params2)
    //           .promise()
    //           .then((data2) => {
    //             // item.base64Img_FloorElevation = 'data:image/jpeg;base64,' + Buffer.from(data2.Body).toString('base64');
    //             this.$set(
    //               item,
    //               "base64Img_FloorElevation",
    //               "data:image/jpeg;base64," +
    //                 Buffer.from(data2.Body).toString("base64")
    //             );
    //           })
    //           .catch((err2) => {
    //             console.log(`Error retrieving S3 Object -> ${err2}`);
    //           });

    //         count++;
    //         // 2023-05-08
    //         if (i == splitArrays[1].length - 1) {
    //           // console.log("last item for array 1")
    //           this.plannersReference = [
    //             ...this.plannersReference,
    //             ...splitArrays[1],
    //           ];
    //         }
    //         this.$forceUpdate();
    //       } // END FOR LOOP
    //       for (let [i, item] of splitArrays[2].entries()) {
    //         // let index = dataRec.indexOf( item )
    //         let tunnelingAgent = tunnel.httpsOverHttp({
    //           proxy: {
    //             // Proxy settings
    //             host: "hrdproxy.hrd-s.com",
    //             port: 81,
    //             proxyAuth: "administrator:system",
    //           },
    //         });

    //         AWS.config.update({
    //           httpOptions: { agent: tunnelingAgent },
    //           region: process.env.VUE_APP_REGION,
    //           accessKeyId: this.accessKeyId,
    //           secretAccessKey: this.secretAccessKey,
    //         });

    //         let s3 = new AWS.S3({ apiVersion: "2006-03-01" });

    //         const params = {
    //           Bucket: process.env.VUE_APP_BUCKET, 
    //           Key: `PlannersReference/${item.attachment_cadRequest}`,
    //         };

    //         const params2 = {
    //           Bucket: process.env.VUE_APP_BUCKET, 
    //           Key: `PlannersReference/${item.attachment_floorPlanElevation}`,
    //         };

    //         // console.log('1616', index )
    //         // CAD Request Image
    //         s3.getObject(params)
    //           .promise()
    //           .then((data) => {
    //             // item.base64Img_CadRequest = 'data:image/jpeg;base64,' + Buffer.from(data.Body).toString('base64');
    //             this.$set(
    //               item,
    //               "base64Img_CadRequest",
    //               "data:image/jpeg;base64," +
    //                 Buffer.from(data.Body).toString("base64")
    //             );
    //           })
    //           .catch((err) => {
    //             console.log(`Error retrieving S3 Object ->  ${err}`);
    //           });

    //         // Floor Elevation Image
    //         s3.getObject(params2)
    //           .promise()
    //           .then((data2) => {
    //             // item.base64Img_FloorElevation = 'data:image/jpeg;base64,' + Buffer.from(data2.Body).toString('base64');
    //             this.$set(
    //               item,
    //               "base64Img_FloorElevation",
    //               "data:image/jpeg;base64," +
    //                 Buffer.from(data2.Body).toString("base64")
    //             );
    //           })
    //           .catch((err2) => {
    //             console.log(`Error retrieving S3 Object -> ${err2}`);
    //           });

    //         count++;
    //         // 2023-05-08
    //         if (i == splitArrays[2].length - 1) {
    //           this.plannersReference = [
    //             ...this.plannersReference,
    //             ...splitArrays[2],
    //           ];
    //         }
    //         this.$forceUpdate();
    //       } // END FOR LOOP
    //       for (let [i, item] of splitArrays[3].entries()) {
    //         let index = dataRec.indexOf(item);
    //         let tunnelingAgent = tunnel.httpsOverHttp({
    //           proxy: {
    //             // Proxy settings
    //             host: "hrdproxy.hrd-s.com",
    //             port: 81,
    //             proxyAuth: "administrator:system",
    //           },
    //         });

    //         AWS.config.update({
    //           httpOptions: { agent: tunnelingAgent },
    //           region: process.env.VUE_APP_REGION,
    //           accessKeyId: this.accessKeyId,
    //           secretAccessKey: this.secretAccessKey,
    //         });

    //         let s3 = new AWS.S3({ apiVersion: "2006-03-01" });

    //         const params = {
    //           Bucket: process.env.VUE_APP_BUCKET, 
    //           Key: `PlannersReference/${item.attachment_cadRequest}`,
    //         };

    //         const params2 = {
    //           Bucket: process.env.VUE_APP_BUCKET, 
    //           Key: `PlannersReference/${item.attachment_floorPlanElevation}`,
    //         };

    //         console.log("1616", index);
    //         // CAD Request Image
    //         s3.getObject(params)
    //           .promise()
    //           .then((data) => {
    //             // item.base64Img_CadRequest = 'data:image/jpeg;base64,' + Buffer.from(data.Body).toString('base64');
    //             this.$set(
    //               item,
    //               "base64Img_CadRequest",
    //               "data:image/jpeg;base64," +
    //                 Buffer.from(data.Body).toString("base64")
    //             );
    //           })
    //           .catch((err) => {
    //             console.log(`Error retrieving S3 Object ->  ${err}`);
    //           });

    //         // Floor Elevation Image
    //         s3.getObject(params2)
    //           .promise()
    //           .then((data2) => {
    //             // item.base64Img_FloorElevation = 'data:image/jpeg;base64,' + Buffer.from(data2.Body).toString('base64');
    //             this.$set(
    //               item,
    //               "base64Img_FloorElevation",
    //               "data:image/jpeg;base64," +
    //                 Buffer.from(data2.Body).toString("base64")
    //             );
    //           })
    //           .catch((err2) => {
    //             console.log(`Error retrieving S3 Object -> ${err2}`);
    //           });

    //         count++;
    //         // 2023-05-08
    //         // console.log(i)
    //         // console.log(splitArrays[3].length-1)
    //         if (i == splitArrays[3].length - 1) {
    //           this.plannersReference = [
    //             ...this.plannersReference,
    //             ...splitArrays[3],
    //           ];
    //           setTimeout(() => {
    //             this.refreshBtn = false;
    //           }, 8000);
    //         }
    //         // console.log("this is the planner reference",this.plannersReference)
    //         // this.loadingPlannersReference = false;
    //         this.$forceUpdate();
    //       } // END FOR LOOP
    //     }

    //     if (count == dataRec.length) {
    //       //  this.plannersReference = dataRec
    //       this.loadingPlannersReference = false;
    //       //  setTimeout(()=>{
    //       //     this.refreshBtn=false
    //       //  },5000)
    //     }
    //   } catch (err) {
    //     Swal.fire({
    //       icon: "error",
    //       title: `Error in Fetching Data, Please Try Again!`,
    //       showConfirmButton: false,
    //       timer: 1200,
    //     });
    //     this.loadingPlannersReference = false;
    //   }
    // },
    async getPlannersReference() {
      let count = 0;
      // let recValue = 0
      this.loadingPlannersReference = true;
      this.plannersReference = [];
      this.loadingimages = true;

      try {
        // this.testGetAllDataImageJom()
        //this.plannersReference = await this.promiseGetPlannersReference();

        let dataRec = await this.promiseGetPlannersReference();

        const length = Math.ceil(dataRec.length / 4); // Calculate the length of each subarray

        const splitArrays = [];
        for (let i = 0; i < dataRec.length; i += length) {
          const subarray = dataRec.slice(i, i + length);
          splitArrays.push(subarray);
        }
        // console.log(splitArrays)

        if (dataRec.length > 0) {
          //removed 2030711
          let processOne = () => {
            console.log("pumasok dito");
            for (let [i, item] of splitArrays[0].entries()) {
              // console.log(i)
              // let index = dataRec.indexOf( item )
              let tunnelingAgent = tunnel.httpsOverHttp({
                proxy: {
                  // Proxy settings
                  host: "hrdproxy.hrd-s.com",
                  port: 81,
                  proxyAuth: "administrator:system",
                },
              });

              AWS.config.update({
                httpOptions: { agent: tunnelingAgent },
                region: process.env.VUE_APP_REGION,
                accessKeyId: this.accessKeyId,
                secretAccessKey: this.secretAccessKey,
              });

              let s3 = new AWS.S3({ apiVersion: "2006-03-01" });

              const params = {
                Bucket: process.env.VUE_APP_BUCKET, 
                Key: `PlannersReference/${item.attachment_cadRequest}`,
              };

              const params2 = {
                Bucket: process.env.VUE_APP_BUCKET, 
                Key: `PlannersReference/${item.attachment_floorPlanElevation}`,
              };
              // console.log('1616', index )
              // CAD Request Image
              s3.getObject(params)
                .promise()
                .then((data) => {
                  // item.base64Img_CadRequest = 'data:image/jpeg;base64,' + Buffer.from(data.Body).toString('base64');
                  // const blobimage = new Blob([data.Body]);
                  this.$set(
                    item,
                    "base64Img_CadRequest",
                    "data:image/jpeg;base64," +
                      Buffer.from(data.Body).toString("base64")
                    // blobimage
                  );
                })
                .catch((err) => {
                  console.log(`Error retrieving S3 Object ->  ${err}`);
                });
              // Floor Elevation Image
              s3.getObject(params2)
                .promise()
                .then((data2) => {
                  // item.base64Img_FloorElevation = 'data:image/jpeg;base64,' + Buffer.from(data2.Body).toString('base64');
                  // const blobimage = new Blob([data.Body]);
                  this.$set(
                    item,
                    "base64Img_FloorElevation",
                    "data:image/jpeg;base64," +
                      Buffer.from(data2.Body).toString("base64")
                    // blobimage
                  );
                  
                })
                .catch((err2) => {
                  console.log(`Error retrieving S3 Object -> ${err2}`);
                });

              count++;
              // 2023-05-08
              if (i == splitArrays[0].length - 1) {
                this.plannersReference = [
                  ...this.plannersReference,
                  ...splitArrays[0],
                ];
              }
              this.$forceUpdate();
            } // END FOR LOOP
          };
          let processTwo = () => {
            for (let [i, item] of splitArrays[1].entries()) {
              // let index = dataRec.indexOf( item )
              let tunnelingAgent = tunnel.httpsOverHttp({
                proxy: {
                  // Proxy settings
                  host: "hrdproxy.hrd-s.com",
                  port: 81,
                  proxyAuth: "administrator:system",
                },
              });

              AWS.config.update({
                httpOptions: { agent: tunnelingAgent },
                region: process.env.VUE_APP_REGION,
                accessKeyId: this.accessKeyId,
                secretAccessKey: this.secretAccessKey,
              });

              let s3 = new AWS.S3({ apiVersion: "2006-03-01" });

              const params = {
                Bucket: process.env.VUE_APP_BUCKET, 
                Key: `PlannersReference/${item.attachment_cadRequest}`,
              };

              const params2 = {
                Bucket: process.env.VUE_APP_BUCKET, 
                Key: `PlannersReference/${item.attachment_floorPlanElevation}`,
              };
              // console.log('1616', index )
              // CAD Request Image
              s3.getObject(params)
                .promise()
                .then((data) => {
                  // item.base64Img_CadRequest = 'data:image/jpeg;base64,' + Buffer.from(data.Body).toString('base64');
                  // const blobimage = new Blob([data.Body]);
                  this.$set(
                    item,
                    "base64Img_CadRequest",
                    "data:image/jpeg;base64," +
                      Buffer.from(data.Body).toString("base64")
                    // blobimage
                  );
                })
                .catch((err) => {
                  console.log(`Error retrieving S3 Object ->  ${err}`);
                });

              // Floor Elevation Image
              s3.getObject(params2)
                .promise()
                .then((data2) => {
                  // item.base64Img_FloorElevation = 'data:image/jpeg;base64,' + Buffer.from(data2.Body).toString('base64');
                  // const blobimage = new Blob([data.Body]);
                  this.$set(
                    item,
                    "base64Img_FloorElevation",
                    "data:image/jpeg;base64," +
                      Buffer.from(data2.Body).toString("base64")
                    // blobimage
                  );
                })
                .catch((err2) => {
                  console.log(`Error retrieving S3 Object -> ${err2}`);
                });

              count++;
              // 2023-05-08
              if (i == splitArrays[1].length - 1) {
                // console.log("last item for array 1")
                this.plannersReference = [
                  ...this.plannersReference,
                  ...splitArrays[1],
                ];
              }
              this.$forceUpdate();
            } // END FOR LOOP
          };
          let processThree = () => {
            for (let [i, item] of splitArrays[2].entries()) {
              // let index = dataRec.indexOf( item )
              let tunnelingAgent = tunnel.httpsOverHttp({
                proxy: {
                  // Proxy settings
                  host: "hrdproxy.hrd-s.com",
                  port: 81,
                  proxyAuth: "administrator:system",
                },
              });

              AWS.config.update({
                httpOptions: { agent: tunnelingAgent },
                region: process.env.VUE_APP_REGION,
                accessKeyId: this.accessKeyId,
                secretAccessKey: this.secretAccessKey,
              });

              let s3 = new AWS.S3({ apiVersion: "2006-03-01" });

              const params = {
                Bucket: process.env.VUE_APP_BUCKET, 
                Key: `PlannersReference/${item.attachment_cadRequest}`,
              };

              const params2 = {
                Bucket: process.env.VUE_APP_BUCKET, 
                Key: `PlannersReference/${item.attachment_floorPlanElevation}`,
              };

              // console.log('1616', index )
              // CAD Request Image
              s3.getObject(params)
                .promise()
                .then((data) => {
                  // item.base64Img_CadRequest = 'data:image/jpeg;base64,' + Buffer.from(data.Body).toString('base64');
                  // const blobimage = new Blob([data.Body]);
                  this.$set(
                    item,
                    "base64Img_CadRequest",
                    "data:image/jpeg;base64," +
                      Buffer.from(data.Body).toString("base64")
                    // blobimage
                  );
                })
                .catch((err) => {
                  console.log(`Error retrieving S3 Object ->  ${err}`);
                });

              // Floor Elevation Image
              s3.getObject(params2)
                .promise()
                .then((data2) => {
                  // item.base64Img_FloorElevation = 'data:image/jpeg;base64,' + Buffer.from(data2.Body).toString('base64');
                  // const blobimage = new Blob([data2.Body]);
                  this.$set(
                    item,
                    "base64Img_FloorElevation",
                    "data:image/jpeg;base64," +
                      Buffer.from(data2.Body).toString("base64")
                    // blobimage
                  );
                })
                .catch((err2) => {
                  console.log(`Error retrieving S3 Object -> ${err2}`);
                });

              count++;
              // 2023-05-08
              if (i == splitArrays[2].length - 1) {
                this.plannersReference = [
                  ...this.plannersReference,
                  ...splitArrays[2],
                ];
              }
              this.$forceUpdate();
            } // END FOR LOOP
          };
          //
          let processFour = () => {
            for (let [i, item] of splitArrays[3].entries()) {
              // let index = dataRec.indexOf(item);
              let tunnelingAgent = tunnel.httpsOverHttp({
                proxy: {
                  // Proxy settings
                  host: "hrdproxy.hrd-s.com",
                  port: 81,
                  proxyAuth: "administrator:system",
                },
              });

              AWS.config.update({
                httpOptions: { agent: tunnelingAgent },
                region: process.env.VUE_APP_REGION,
                accessKeyId: this.accessKeyId,
                secretAccessKey: this.secretAccessKey,
              });

              let s3 = new AWS.S3({ apiVersion: "2006-03-01" });

              const params = {
                Bucket: process.env.VUE_APP_BUCKET, 
                Key: `PlannersReference/${item.attachment_cadRequest}`,
              };

              const params2 = {
                Bucket: process.env.VUE_APP_BUCKET, 
                Key: `PlannersReference/${item.attachment_floorPlanElevation}`,
              };

              // console.log("1616", index);
              // CAD Request Image
              s3.getObject(params)
                .promise()
                .then((data) => {
                  // item.base64Img_CadRequest = 'data:image/jpeg;base64,' + Buffer.from(data.Body).toString('base64');
                  // const blobimage = new Blob([data.Body]);
                  this.$set(
                    item,
                    "base64Img_CadRequest",
                    "data:image/jpeg;base64," +
                      Buffer.from(data.Body).toString("base64")
                    // blobimage
                  );
                })
                .catch((err) => {
                  console.log(`Error retrieving S3 Object ->  ${err}`);
                });

              // Floor Elevation Image
              s3.getObject(params2)
                .promise()
                .then((data2) => {
                  // item.base64Img_FloorElevation = 'data:image/jpeg;base64,' + Buffer.from(data2.Body).toString('base64');
                  // const blobimage = new Blob([data2.Body]);
                  this.$set(
                    item,
                    "base64Img_FloorElevation",
                    "data:image/jpeg;base64," +
                      Buffer.from(data2.Body).toString("base64")
                    // blobimage
                  );
                })
                .catch((err2) => {
                  console.log(`Error retrieving S3 Object -> ${err2}`);
                });

              count++;
              // 2023-05-08
              // console.log(i)
              // console.log(splitArrays[3].length-1)
              if (i == splitArrays[3].length - 1) {
                this.plannersReference = [
                  ...this.plannersReference,
                  ...splitArrays[3],
                ];
                setTimeout(() => {
                  this.refreshBtn = false;
                }, 1000);
                this.disableSearch = false;
              }
              // console.log("this is the planner reference",this.plannersReference)
              // this.loadingPlannersReference = false;
              // console.log("after loading");
              this.$forceUpdate();
            } // END FOR LOOP
          };

          await processOne();
          await setTimeout(() => {
            processTwo();
          }, 500);
          await setTimeout(() => {
            processThree();
          }, 600);
          await setTimeout(() => {
            processFour();
            this.loadingimages = false;
          }, 800);
          this.loadingPlannersReference = false;
        }

        if (count == dataRec.length) {
          //  this.plannersReference = dataRec
          // this.loadingPlannersReference = false;
          //  setTimeout(()=>{
          //     this.refreshBtn=false
          //  },5000)
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: `Error in Fetching Data, Please Try Again!`,
          showConfirmButton: false,
          timer: 1200,
        });
        this.loadingPlannersReference = false;
        this.loadingimages = false;
      }
    },
    openViewImageDialog(item, pictureType = "") {
      item.pictureType = pictureType;

      this.plannerRefObj = item;
      this.viewImageDialog = !this.viewImageDialog;

      // var tunnelingAgent = tunnel.httpsOverHttp({
      //         proxy: {
      //             // Proxy settings
      //             host: "hrdproxy.hrd-s.com",
      //             port: 81,
      //             proxyAuth: "administrator:system"
      //         },
      //     });

      // AWS.config.update(
      // {
      //     httpOptions: { agent: tunnelingAgent },
      //     region: process.env.VUE_APP_REGION,
      //     accessKeyId: this.accessKeyId
      //     secretAccessKey: this.secretAccessKey
      // });

      // let s3 = new AWS.S3({apiVersion: '2006-03-01'});

      // let params = {}

      // // Check if CAD Request Image or Floor Elevation
      // if (pictureType == 'CAD_REQUEST') {
      //     params = {
      //         Bucket: process.env.VUE_APP_BUCKET, 
      //         Key: `PlannersReference/${item.attachment_cadRequest}`,
      //         // ResponseContentType: 'arraybuffer'
      //    };
      // }
      // else{
      //    params = {
      //         Bucket: process.env.VUE_APP_BUCKET, 
      //         Key: `PlannersReference/${item.attachment_floorPlanElevation}`,
      //         // ResponseContentType: 'arraybuffer'
      //    };
      // }

      // var self = this;

      // s3.getObject(params, function(err, data) {
      //     if (err) {
      //              console.log(`Error retrieving S3 Object ->  ${err}`);
      //              alert('No Image Found in File server!');
      //     }
      //     else {
      //       // Convert the image to a base64-encoded data URL
      //       console.log('line1653', pictureType )
      //         if( pictureType == 'CAD_REQUEST' ) {
      //             item.pictureType = 'CAD_REQUEST'
      //             item.base64Img_CadRequest = 'data:image/jpeg;base64,' + Buffer.from(data.Body).toString('base64')
      //             // this.$set( this.plannerRefObj, 'base64Img_CadRequest','data:image/jpeg;base64,' + Buffer.from(data.Body).toString('base64'))
      //         }
      //         else{
      //             item.pictureType  = 'FLOORPLAN_ELEVATION'
      //             item.base64Img_FloorElevation = 'data:image/jpeg;base64,' + Buffer.from(data.Body).toString('base64')
      //             //this.$set( this.plannerRefObj, 'base64Img_FloorElevation' ,'data:image/jpeg;base64,' + Buffer.from(data.Body).toString('base64'))

      //         }

      //         self.plannerRefObj = item;
      //         self.viewImageDialog =  !self.viewImageDialog
      //     }
      // });
    },
    closeViewImageDialog() {
      this.plannerRefObj = {};
      this.viewImageDialog = !this.viewImageDialog;
    },
    openAddUpdateDialog() {
      this.addUpdateDialog = !this.addUpdateDialog;
    },
    closeAddUpdateDialog() {
      this.addUpdateText = "Add Record";
      this.toAdd = {
        abbr: "---",
        houseType: null,
        category1: null,
        category2: null,
        doc_jap: null,
        doc_romanji: null,
        doc_english: null,
        remarks: "",
      };
      this.uploadFiles_CADRequest = [];
      this.uploadFiles_FloorElevation = [];
      this.$refs.pond.removeFiles();
      this.$refs.pond2.removeFiles();
      this.isSavingRecord = false;
      this.addUpdateDialog = !this.addUpdateDialog;
    },
    refreshFunction() {
      // this.getPlannersReference();
      this.LoadDataPagination_PlannersReference() // 2024-08-19
      this.selectedConstMethod = ["ALL"];
      this.selectedHouseType = ["ALL"];
      this.selectedCategory1 = ["ALL"];
      // this.selectedCategory2 = ["ALL"];  // Hide selectedCategory2 --09/03/24 -->
      this.loadingimages = false    
    },

    openEditDialog(item) {
      this.toAdd = item;
      this.addUpdateText = "Update Record";
      this.addUpdateDialog = !this.addUpdateDialog;
    },

    deletePlannerReference(item) {
      let isDeleted = confirm(`Are you sure want to delete this reference? `);
      if (isDeleted) {
        const url = `${this.api}plannersReference/softDeletePlannersReference`;
        axios
          .put(url, {
            id: item.id,
            updatedBy: "tanskie", // PLEASE REPLACE UPDATE BY IN DSS LOCAL
          })
          .then((res) => {
            if (res.data) {
              Swal.fire({
                icon: "success",
                title: `Successfully Deleted!`,
                showConfirmButton: false,
                timer: 1000,
              });
              this.getPlannersReference();
            }
          })
          .catch((err) => {
            console.log(err.message);
            Swal.fire({
              icon: "error",
              title: `Error to delete this reference..`,
              showConfirmButton: false,
              timer: 1200,
            });
          });
      }
    },
    async testGetAllDataImageJom() {
      // THe start of testing get of all objects

      //  var tunnelingAgent = tunnel.httpsOverHttp({
      // proxy: {
      //         // Proxy settings
      //         host: "hrdproxy.hrd-s.com",
      //         port: 81,
      //         proxyAuth: "administrator:system"
      //     },
      // });

      // AWS.config.update(
      // {
      //     httpOptions: { agent: tunnelingAgent },
      //     region: process.env.VUE_APP_REGION,
      //     accessKeyId: this.accessKeyId,
      //     secretAccessKey: this.secretAccessKey
      // });

      // let s3 = new AWS.S3({apiVersion: '2006-03-01'});

      // const params = {
      //     Bucket: process.env.VUE_APP_BUCKET, 
      //     Delimiter:'/',
      //     Prefix: `PlannersReference/`
      // };

      // const response = await s3.listObjects(params).promise();
      // console.log(response)

      // // Iterate through the response and extract the file names
      // const files = response.Contents.map(file => file.Key);

      // console.log('Files in folder:', files);

      const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

      const length = Math.ceil(array.length / 4); // Calculate the length of each subarray

      const splitArrays = [];
      for (let i = 0; i < array.length; i += length) {
        const subarray = array.slice(i, i + length);
        splitArrays.push(subarray);
      }

      console.log(splitArrays);
    },
  },
};
</script>
<style scoped>
#title_ellipsis {
  display: inline-block;
  width: 93%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

table > thead > tr > th {
  background-color: #213c57 !important;
  border: 1px solid gray !important;
  padding: 0px !important;
  font-size: 12px !important;
  margin: 0px !important;
  color: white !important;
  font-family: Arial !important;
}

table,
td,
th {
  border: 1px solid #e3e3e3 !important;
  border-collapse: collapse !important;
}

.abbr-class {
  color: red;
  font-weight: bold;
  text-align: center;
}

/* 
table, td ,th {
  border: 1px solid gray !important;
  border-collapse: collapse !important;

}

table>thead>tr>th{
  background-color: #213c57 !important;
  border: 1px solid gray !important;
  padding: 0px !important;
  font-size: 11px !important;
  margin: 0px !important;
}

table>thead>tr{
  padding: 0px !important;
} */
</style>
