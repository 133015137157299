<template>
  <!-- NOTE: disable transation in this page -->
  <div translate="no">
    <v-layout align-center justify-center v-if="Loading">
      <a-spin class="mt-2" tip="Loading..." />
    </v-layout>

    <v-card
      v-if="!Loading"
      class="ma-2 pa-2"
      flat
      style="background-color:#f6f5f5;"
    >
      <v-row dense>
        <v-col cols="10" sm="6" md="4">
          <a-input
            addon-before="Search"
            style="width: 100%"
            v-model="toSearchDXF"
            placeholder="Search file"
            append-icon="search"
            outlined
          ></a-input>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <!-- <v-layout row wrap> -->
            <span style="font-weight: bold" class="mt-1">項目 : </span>
            <a-select v-model="selectedItem" :style="WidthFile" id="v-step-f2">
              <a-select-option
                v-for="item in OptItems"
                :key="item"
                v-value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          <!-- </v-layout> -->
        </v-col>

        <!-- <v-col cols="12" sm="6" md="4">
              <v-layout row wrap>
                <span style="font-weight: bold" class="mt-1 ml-5">品種 : </span>
              <a-select v-model="selectedVariety" :style="WidthFile" id="v-step-f2">
                <a-select-option
                  v-for="item in OptVariety"
                  :key="item"
                  v-value="item"
                  >{{ item }}</a-select-option
                > </a-select>
              </v-layout>
            </v-col> -->

        <v-col cols="12" sm="6" md="4">
          <!-- <v-layout row wrap> -->
            <span style="font-weight: bold" class="mt-1">構法 : </span>
            <a-select
              v-model="selectedMethod"
              :style="WidthFile"
              id="v-step-f2"
            >
              <a-select-option
                v-for="item in OptMethod"
                :key="item"
                v-value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          <!-- </v-layout> -->
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <!-- THIS MODULE IS IMPORTED ON main.js ==> vue-json-csv -->
          <!-- 2022-02-08 -->
          <!-- <v-btn class="ml-3" style="background-color: #0f3057; color: white" small >             
                <download-csv
                  name    = "dxfProductData.csv"
                  encoding
                  :data   = "dxfProductToExport">
                  Export to CSV
                  <v-icon>mdi-download</v-icon>
                </download-csv>
              </v-btn> -->
        </v-col>
      </v-row>
    </v-card>
    <span v-if="!Loading" style="color: #0f3057; font-weight: bold" flat
      >Total: {{ filteredDXF.length }}</span
    >

    <v-data-table
      v-if="!Loading"
      :headers="columns"
      :items="filteredDXF"
      fixed-header
      dense
      :height="$vuetify.breakpoint.height - 265"
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [50, 100, 1000, -1],
      }"
      :page.sync="currentPage"
    >
      <template v-slot:item.dxfFile="{ item }">
        <span
          style="cursor: pointer"
          @click="openDXF(item)"
          class="primary--text"
          >データリンク</span
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import AWS from "aws-sdk";
import * as binconv from "binconv";
import FileSaver from "file-saver";
import Swal from "sweetalert2";
import config from "../config";
import tunnel from "tunnel";
import _ from "lodash";
export default {
  data() {
    return {
      // pagination: {//NOTE: for resetting scroll top 2024-04-2023
        currentPage: 1,
      // },
      ////Construction Method Filter////
      selectedMethod: "All",
      OptMethod: ["All", "軸組", "枠組", "軸組 枠組"],
      ////Item Filter////
      selectedItem: "All",
      OptItems: ["All"],
      ////Variety Filter////
      OptVariety: [
        "All",
        "エコカラット割り付け図(｢i-cube 240+｣)",
        "エコカラット割り付け図(｢i-cube 260+｣)",
        "エコカラット割り付け図(｢i-smart 240+｣)",
        "エコカラット割り付け図(｢i-smart 260+｣)",
        "エコカラット割り付け図(｢夢の家 240｣)",
        "エコカラット割り付け図(｢夢の家 265｣)",
        "オリジナルテレビボード",
        "玄関上がり框",
        "勾配天井",
        "自在棚",
        "システムクローゼット(ユニット) ケーシング枠",
        "システムパントリー(ユニット)ケーシング枠",
        "白木調造作造作材",
        "スリットウォール",
        "スリットスライダー建具 (洋室)",
        "スリットスライダー建具 (和室)",
        "スリットルーバー",
        "ソリッドウッドパネル",
        "ソロモンマホガニー造作材",
        "タレ壁３方枠（白木調造作）",
        "タレ壁３方枠（白松造作）",
        "タレ壁３方枠（木調造作）",
        "タレ壁３方枠（木調造作/白木調造作）",
        "トータルシステムクローゼット(ウォークインタイプ)",
        "トータルシステムクローゼット(ユニット)",
        "トータルシステムクローゼット(ユニット) ケーシング枠",
        "ブックシェルフ(ユニット))ケーシング枠",
        "フリーカウンター",
        "丸棒手摺金物",
        "ユニット出窓",
        "リモコンニッチ",
        "ロフト",
        "和室建具平面図（白木調造作）",
        "和室建具平面図（白松造作）",
        "和室建具平面図（檜造作）",
        "階段関係",
        "階段関係　（240+）",
        "階段関係　（260+）",
        "掘座卓",
        "高性能樹脂サッシ(Sマホガ二ー造作)",
        "高性能樹脂サッシ(キッチン樹脂枠)",
        "高性能樹脂サッシ(白木調造作)",
        "高性能樹脂サッシ(白松造作)",
        "高性能樹脂サッシ(白松造作・内障子枠)",
        "高性能樹脂サッシ(檜造作)",
        "高性能樹脂サッシ(木調造作)",
        "高性能樹脂サッシ（木調造作/白木調造作）",
        "室内明かりとり",
        "住設",
        "造作材",
        "白松造作造作材",
        "木調造作材",
        "洋室建具平面図（Sマホガニー造作）",
        "洋室建具平面図（木調造作）",
        "洋室建具平面図（木調造作/白木調造作）",
        "檜造作造作材",
      ],
      selectedVariety: "All",

      Loading: false,
      toSearchDXF: "",
      dxfProducts: [],

      columns: [
        {
          value: "item",
          text: "項目",
          divider: true,
          // width: "25%",
          class: "blue darken-4 white--text",
          sortable: true,
        },
        // keysha -- add new column -- variety
        {
          // dataIndex: "variety",
          value: "variety",
          text: "品種",
          divider: true,
          // width: "25%",
          class: "blue darken-4 white--text",
          sortable: false,
        },
        {
          value: "productName",
          text: "商品名",
          divider: true,
          // width: "25%",
          class: "blue darken-4 white--text",
          sortable: true,
        },
        {
          value: "detail",
          text: "詳細",
          divider: true,
          // width: "15%",
          class: "blue darken-4 white--text",
          sortable: true,
        },
        {
          value: "constructionMethod",
          text: "構法",
          divider: true,
          // width: "4%",
          class: "blue darken-4 white--text",
          sortable: true,
        },
        {
          value: "dxfFile",
          text: "DXF",
          divider: true,
          width: "7%",
          class: "blue darken-4 white--text",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    openDXF(data) {
      var tunnelingAgent = tunnel.httpsOverHttp({
        proxy: {
          // Proxy settings
          host: "hrdproxy.hrd-s.com",
          port: 81,
          proxyAuth: "administrator:system",
        },
      });

      AWS.config.update({
        httpOptions: { agent: tunnelingAgent },
        region: "us-east-2",
        credentials: config,
      });
      var s3 = new AWS.S3({
        AccessKeyID: this.accessKeyId,
        SecretAccessKey: this.secretAccessKey,
        Region: "us-east-2",
        params: {
          bucket: "rulebook.files",
        },
      });

      var options = {
        Bucket: "rulebook.files",
        Key: `DXFProduct/${data.filename}`,
      };
      s3.getObject(options, function(err, dataS3) {
        if (err) {
          let eText = "";
          if (err == "AccessDenied: Access Denied") {
            eText = "File Not Found";
          } else {
            eText = err;
          }
          Swal.fire({
            title: "Error!",
            text: eText,
            icon: "error",
          });
          console.log(err, err.stack);
        } else {
          var blob = new Blob([binconv.uint8ArrayToBlob(dataS3.Body)], {
            type: `${data.ContentType};charset=utf-8`,
          });
          ////SAVING AS THE CONVERTED FILE
          FileSaver.saveAs(blob, `${data.filename}`);
        }
      });
    },
    loadDXFProduct() {
      this.Loading = true;
      let tmpData = [];
      let tmpItems = [];
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      let url = `${this.api}dxfProduct/getAll`;
      axios.get(url).then((res) => {
        tmpData = res.data
          .filter((r) => {
            return !r.deleted;
          })
          .map((r, index) => {
            r.key = index;
            return r;
          });

        tmpItems = _.uniq(
          _.map(
            _.filter(tmpData, function(o) {
              return o.item != undefined;
            }),
            "item"
          )
        );

        // Sort Priority (1) item (2) variety (3) productName (4) detail
        this.dxfProducts = _.orderBy(tmpData, [
          "item",
          "variety",
          "productName",
          "detail",
        ]);

        // Add Items
        this.OptItems.push(...tmpItems);

        this.Loading = false;
      });
    },
  },
  computed: {
    dxfProductToExport() {
      return this.filteredDXF.map((r) => {
        return r;
      });
    },
    WidthFile() {
      if (this.$vuetify.breakpoint.name == "xs") {
        return "width:87%; margin-left:26px";
      } else return "width:88%";
    },
    filteredDXF() {
      return this.dxfProducts
        .filter((data) => {
          let search = new RegExp(this.toSearchDXF.toUpperCase(), "g");
          return JSON.stringify(Object.values(data))
            .toUpperCase()
            .match(search);
        })
        .filter((r) => {
          if (this.selectedVariety == "All") {
            return r;
          } else {
            return r.variety == this.selectedVariety;
          }
        })
        .filter((r) => {
          if (this.selectedMethod == "All") {
            return r;
          } else {
            return r.constructionMethod == this.selectedMethod;
          }
        })
        .filter((r) => {
          if (this.selectedItem == "All") {
            return r;
          } else {
            return r.item == this.selectedItem;
          }
        });
    },
  },
  watch:{
    //NOTE: for resetting of scroll top 2024-04-23
    currentPage(){
      let tables = document.querySelectorAll('.v-data-table__wrapper')
      for(let table of tables){
        table.scrollTop = 0
      }
    },
  },
  created() {
    this.loadDXFProduct();
  },
};
</script>
