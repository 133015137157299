<template>
    <div>
        <v-container class="pa-0 ma-0" fluid>

            <v-layout align-center justify-center v-if="loadingCADRequestMaterials">
                <a-spin class="mt-2" tip="Loading..." />
            </v-layout>

            <v-row>
                <v-col xs="4" sm="4" md="4" lg="4">
                    <ul v-for="(item, i ) in CADOtherRecords.subItems" :key="i" >
                        <li @click="openURL_CADRequestMaterials(item, CADOtherRecords.id )" style="color:#1C3879;text-decoration:underline;cursor: pointer"> {{ item.filename }} </li>
                    </ul>
                </v-col> 
            </v-row>
            <!-- ////////////////////// -->
            <v-row v-if="!loadingCADRequestMaterials" style="margin-top:-25px;">
                <v-col xs="6" sm="6" md="6" lg="7">
                    <v-simple-table class="main-table">
                        <template v-slot:default>
                            <thead style="background-color:#213b57">
                                <tr width="100%">
                                    <th colspan="3" class="pa-3"> 
                                        <h1 style="font-family:Arial;color:black;font-weight:bold" class=" white--text text-center"> 外部シート入力用シート  </h1>
                                    </th>
                                </tr>
                                <tr>
                                    <th colspan="3">
                                        <h3 style="font-family:Arial;color:black" class="white--text text-left">  必要なページを印刷し、チェックボックスに記載をして、他資料と併せてCAD依頼を行って下さい。  </h3>
                                    </th>
                                </tr>
                                <tr>
                                    <th colspan="3">
                                        <h3 style="font-family:Arial;color:black" class="white--text text-left">  地域性は反映されていません。そのためCAD依頼時に指示があっても、  </h3>
                                        <h3 style="font-family:Arial;color:black" class="white--text text-left">  HRDにて反映されない場合もありますのでご注意下さい。  </h3>
                                    </th>
                                </tr>
                                <tr>
                                    <th colspan="3">
                                        <h3 style="font-family:Arial;color:black" class="white--text text-right mb-n1">   最終更新：{{externalSheet}} </h3>  
                                    </th>
                                </tr>
                                <tr style="background-color:#213b57">
                                    <th>
                                        <h3 style="font-family:Arial;color:black;font-weight:bold" class="white--text text-center mb-1">   構法 </h3>  
                                    </th>
                                    <th>
                                        <h3 style="font-family:Arial;color:black;font-weight:bold" class="white--text text-center mb-1">   項目 </h3>  
                                    </th>
                                </tr>
                            </thead>
                            <template v-for="(data, index) in filteredExternalSheet">
                                <tbody :key="index">                               
                                    <tr v-for="(rec,a) in data.subItems" :key="a">
                                        <!-- <td  v-if="index == 0" style="font-family:Arial" :rowspan="requestMaterials.length" >    -->
                                        <td v-if="a == 0" class="text-center" :rowspan="data.subItems.length" >  
                                            <span style="font-family:Arial" class="text-center">  {{ data.item }} </span>
                                        </td>
                                        <td class="text-center">                                   
                                            <span @click="openURLRequestMaterials(rec)" style="font-family:Arial;color:#1C3879;text-decoration:underline;cursor:pointer" class="text-center" >  {{ rec.filename  }}  </span>                                   
                                        </td>       
                                    </tr>
                                </tbody>
                            </template>                      
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            <!-- <v-row>
                <v-col xs="4" sm="4" md="4" lg="4">
                    <ul >
                    <li @click="openURLPDF()" style="color:#1C3879;text-decoration:underline;cursor: pointer"> 電気図面関連資料 </li>
                    </ul>
                </v-col> 
            </v-row> -->
            <!-- ///////////////////////////////////// -->
            <v-row v-if="!loadingCADRequestMaterials" style="margin-top:-25px;">
                <v-col xs="6" sm="6" md="6" lg="7">
                    <v-simple-table class="main-table">
                        <template v-slot:default>
                            <thead style="background-color:#213b57">
                                <tr width="100%">
                                    <th colspan="2" class="pa-3"> 
                                        <h1 style="font-family:Arial;color:black;font-weight:bold" class="text-center white--text"> 設備シート依頼用入力シート  </h1>
                                    </th>
                                </tr>
                                <tr>
                                    <th colspan="2">
                                        <h3 style="font-family:Arial;color:black" class="text-center white--text">  必要なページを印刷し、チェックボックスに記載をして、他資料と併せてCAD依頼を行って下さい。  </h3>
                                    </th>
                                </tr>
                                <tr>
                                    <th colspan="2">
                                        <!-- <h3 style="font-family:Arial;color:black" class="text-right white--text">  最終更新：2023/12/26  </h3>   -->
                                        <h3 style="font-family:Arial;color:black" class="text-right white--text">  最終更新：{{equipmentSheet}}  </h3>  
                                    </th>
                                </tr>
                            </thead>
                            <template v-for="(data, index) in filteredRequestMaterials">
                                <tbody :key="index">                               
                                    <tr v-for="(rec,a) in data.subItems" :key="a">
                                        <!-- <td  v-if="index == 0" style="font-family:Arial" :rowspan="requestMaterials.length" >    -->
                                        <td v-if="a == 0" class="text-center" :rowspan="data.subItems.length" >  
                                            <span style="font-family:Arial;font-weight:bold" class="text-center">  {{ data.item }} </span>
                                        </td>
                                        <td>                                   
                                            <span @click="openURL_CADRequestMaterials(rec, data.id )" style="font-family:Arial;color:#1C3879;text-decoration:underline;cursor:pointer" class="text-center" >  {{ rec.filename  }}  </span>                                   
                                        </td>       
                                    </tr>
                                </tbody>
                            </template>                      
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-container>
        <template>
        </template>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    data(){
        return {   
            memoDetails: {}, 
            requestMaterials: [],
            CADOtherRecords: {
                subItems: []
            },
            requestMaterialsExternal: [
              {
                item: "軸",
                subItems: [
                  {
                    filename: "グラン・セゾン",
                    filename_attachment: "230720084008-グラン・セゾン",
                    extension: "pdf",
                  },
                ],
              },
              {
                item: "枠",
                subItems: [
                  {
                    filename: "グラン・スマート",
                    filename_attachment: "230720083943-グラン・スマート",
                    extension: "pdf",
                  },
                  {
                    filename: "i-smart",
                    filename_attachment: "230720083959-i-smart",
                    extension: "pdf",
                  },
                  {
                    filename: "i-cube",
                    filename_attachment: "230720084004-i-cube",
                    extension: "pdf",
                  },
                  {
                    filename: "i-smile",
                    filename_attachment: "230720083953-i-smile",
                    extension: "pdf",
                  },
                  {
                    filename: "i-smile+",
                    filename_attachment: "i-smile+",
                    extension: "pdf",
                  },
                ],
              },
            ],
            loadingCADRequestMaterials: false,
            date: '',
            equipmentSheet: '',
            externalSheet: '',
        }
    },

    computed: {
        filteredRequestMaterials(){
            return this.requestMaterials.filter(rec => !rec.ExternalSheet && !rec.ExcludedSheet)
        },
        filteredExternalSheet(){
            return this.requestMaterials.filter(rec => rec.ExternalSheet)
        }
    },

    created(){
        this.GetAllCADRequest();
    },
    methods: {
        openURLRequestMaterials( data ) {
            if(data.filename_attachment == "i-smile+"){
                data.filename_attachment = "i-smile%2B"
            }
            // console.log(data);
            let objURL = `https://s3.us-east-2.amazonaws.com/rulebook.files`
            window.open( `${objURL}/CAD依頼資料/${data.filename_attachment}.${data.extension}`) 
            // window.open(`/DSSPDFViewer?documentType=CADRequestMaterial&file=${data.filename_attachment}.${data.extension}`,"_blank")
        },
        async GetAllCADRequest(){
            try{
                this.loadingCADRequestMaterials = true
                let url = `${this.api}cadRequestMaterials/getlAllCADRequest`
                let response = await axios.get(url)
                //  =================== CAD Request Materials ============= // 

                this.requestMaterials  = response.data
                // .filter(rec=>{
                //     return rec.item != '-'
                // })
                .sort( (a,b) => {
                    return a.sequence - b.sequence
                })

                this.sortSubItems(this.requestMaterials)
                // console.log(this.requestMaterials)
                
                // =========== CAD Other Records =========================== // 
                
                let CADOtherRecordsSubItems = response.data.filter(rec=>{
                    return rec.item == '-'
                })[0]

                this.CADOtherRecords = {
                    id: response.data.filter(rec=>{
                    return rec.item == '-'
                    })[0].id,
                    subItems:  CADOtherRecordsSubItems.subItems
                }
                let date = this.requestMaterials.filter(r=>r.id === 'jackael')

                this.date = date[0].UpdatedDateMod
                // console.log(this.date);
                this.externalSheet = this.date[0].外部シート入力用シート
                this.equipmentSheet = this.date[1].設備シート依頼用入力シート
                // console.log("externalSheet",this.externalSheet);
                // console.log("equipmentSheet",this.equipmentSheet);
                


                this.loadingCADRequestMaterials = false

            }catch(err){
                alert('Error Fetching Records, Please try again..');
                this.loadingCADRequestMaterials = false
                console.log(err.message)
            }
        },

        sortSubItems(items) {
            return items.map(item => {
                // Check if subItems exists and is an array
                const sortedSubItems = Array.isArray(item.subItems) ? item.subItems.sort((a, b) => a.sequence - b.sequence) : [];
                return {
                ...item,
                subItems: sortedSubItems
                };
            });
        },

        openURL_CADRequestMaterials( data, rec_id ) {
            // console.log("datas",data)
            // console.log("rec_id",rec_id)
            this.memoDetails = data
            if( data.extension == 'xls' || data.extension == 'xlsx') {
                
                let objURL = `https://s3.us-east-2.amazonaws.com/rulebook.files`

                // console.log("URL0000",window.open( `${objURL}/CAD依頼資料/${data.filename_attachment}.${data.extension}`))
                window.open( `${objURL}/CAD依頼資料/${data.filename_attachment}.${data.extension}`)

            }else{
                window.open(
                `${this.linkURL}/CAD_Request/${rec_id}/?filename=${data.filename_attachment}`, // 2023-03-20
                "_blank"
                );
                // window.open(`/DSSPDFViewer?documentType=CADRequestMaterial&file=${data.filename_attachment}.${data.extension}`,"_blank")
            }

        }
    }
}
</script>

<style scoped>
.main-table {
    /* border: 1px solid black; */
    padding: 20px;
}

table, td , th {
  border: 1px solid gray !important;
  border-collapse: collapse !important;
  margin:2px;
}

.v-application .text-center {
    text-align: center !important;
    margin-bottom: -5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.875rem ;
    height: 30px ;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    height: 30px;
    padding-top: 5px;
    padding-bottom: 1px;
}

 .v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 40px;
}


</style>